import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get all the employee qualification
function getEmployeeUniform(payload) {
  const endpoint = BASE_URL + 'employeeUniform/getEmployeeUniform';
  return axios.post(endpoint, payload);
}

//create or update employee qualification
function createEmployeeUniform(employeeUniform) {
  const endpoint = BASE_URL + 'employeeUniform/createEmployeeUniform';
  return axios.post(endpoint, employeeUniform, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//create or update employee qualification
function updateEmployeeUniform(employeeUniform) {
  const endpoint = BASE_URL + 'employeeUniform/updateEmployeeUniform';
  return axios.put(endpoint, employeeUniform, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const employeeUniformService = {
  getEmployeeUniform,
  createEmployeeUniform,
  updateEmployeeUniform
};

export default employeeUniformService;
