import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserGeneralTopbar from './UserGeneralTopbar';
import keyPayEmployeeService from '../../../../services/KeypayEmployee';
import { getFormattedEmployeeFromSelectedObj } from '../../helper/helper';
import * as Constants from '../../../Constant';
import UserPortalAccessDialog from '../../dialog/UserPortalAccessDialog';
import useFetchEmployeeRecords from '../../hooks/useFetchEmployeeRecords';
import OfficeInfo from '../../../MyProfile/OfficeInfo/OfficeInfo';

import axios from 'axios';

import ReactSelect from 'react-select';

import Grid from '@mui/material/Grid';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox, Paper, Switch, Typography, useMediaQuery } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { toast } from 'react-hot-toast';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import BusinessIcon from '@material-ui/icons/Business';
import UserOfficeInfo from './UserOfficeInfo';
import employeeService from '../../../../services/EmployeeService';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex'
  }
}));

const UserGeneralDialogContent = ({
  row,
  onChange,
  vendorRecords,
  keyPayEmployeeRecords,
  selectedEmployeeToRegister,
  setSelectedEmployeeToRegister,
  selectedUserType,
  setSelectedUserType,
  approvalRequired,
  setApprovalRequired,
  selectedApprover,
  setSelectedApprover,
  open,
  portalAccess,
  setPortalAccess,
  portalAccessDialog,
  setPortalAccessDialog,
  setRows,
  rows
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [annualLeave, setAnnualLeave] = useState(0);
  const [rdo, setRdo] = useState(0);
  const [personalLeave, setPersonalLeave] = useState(0);

  useEffect(() => {
    if (open && row.KeyPayEmployeeId) {
      const fetchKeyPayEmployee = async () => {
        try {
          const keyPayEmployee = await keyPayEmployeeService.getOneKeyPayEmployee(row.KeyPayEmployeeId);

          const formattedEmployee = getFormattedEmployeeFromSelectedObj(keyPayEmployee.data.data);

          setSelectedEmployeeToRegister(formattedEmployee);
        } catch (error) {
          console.log('error is:', error);
        }
      };
      fetchKeyPayEmployee();
    }
  }, [row.KeyPayEmployeeId, setSelectedEmployeeToRegister, open]);

  //get employee leave balance from KeyPay
  useEffect(() => {
    if (open && row.KeyPayEmployeeId) {
      const fetchEmployeeLeaveBalance = async () => {
        try {
          const keyPayEmployeeLeaveBalance = await keyPayEmployeeService.getEmployeeLeaveBalance(row.KeyPayEmployeeId);

          const employeeLeaveBalanceData = await keyPayEmployeeLeaveBalance.data.data;
          employeeLeaveBalanceData.map((leaveItem) => {
            if (leaveItem.leaveCategoryId === 55346) {
              setAnnualLeave(leaveItem.accruedAmount);
            }
            if (leaveItem.leaveCategoryId === 403364) {
              setRdo(leaveItem.accruedAmount);
            }
            if (leaveItem.leaveCategoryId === 55347) {
              setPersonalLeave(leaveItem.accruedAmount);
            }
          });

          // setSelectedEmployeeToRegister(formattedEmployee);
        } catch (error) {
          console.log('error is:', error);
        }
      };
      fetchEmployeeLeaveBalance();
    }
  }, [row.KeyPayEmployeeId, open]);

  const userListOptions = rows?.map((row) => {
    return { value: row.DisplayName, label: row.DisplayName, id: row.id };
  });

  const userTypeOptions = [
    { value: 'User', label: 'User' },
    { value: 'Admin', label: 'Admin' }
  ];

  const handlePortalAccessDialog = () => {
    setPortalAccessDialog(true);
  };

  const handlePortalAccessDialogClose = () => {
    setPortalAccessDialog(false);
  };

  const sendLoginDetailsToUser = () => {
    if (!row.id) {
      setPortalAccess(true);
      setPortalAccessDialog(false);
    }

    if (portalAccessDialog && row.id) {
      const employeePayload = {
        email: row.email
      };

      employeeService
        .sendLoginDetailsToUser(employeePayload)
        .then(() => {
          const updatedRow = rows.map((r) => {
            if (r.id === row.id) {
              return { ...r, portalAccess: true };
            }
            return r;
          });

          setRows(updatedRow);
          setPortalAccess(true);

          toast.success('Successfully sent login details to the user');
        })
        .catch((error) => {
          toast.error("Couldn't send user credentials. Please check all employee details" || error?.message);
        })
        .finally(() => {
          setPortalAccessDialog(false);
        });
    }
  };

  const removeLoginDetailsOfUser = () => {
    if (!row.id) {
      setPortalAccess(false);
      setPortalAccessDialog(false);
    }

    if (portalAccessDialog && portalAccess && row.id) {
      const employeePayload = {
        email: row?.email
      };

      employeeService
        .removeLoginDetailsOfUser(employeePayload)
        .then(() => {
          const updatedRow = rows.map((r) => {
            if (r.id === row.id) {
              return { ...r, portalAccess: false };
            }
            return r;
          });
          setRows(updatedRow);
          setPortalAccess(false);

          toast.success('Successfully removed user from portal access.');
        })
        .catch((error) => {
          toast.error('could not remove user portal access.' || error?.message);
        })
        .finally(() => {
          setPortalAccessDialog(false);
        });
    }
  };

  const handleApprovalRequired = (e) => {
    setApprovalRequired(e.target.checked);
    setSelectedApprover();
  };

  //to set approver of targeted/edited user
  const handleSelectedApprover = (selectedApprover) => {
    setSelectedApprover(selectedApprover);
  };

  //to set user type
  const handleSelectedUserType = (selectedUserType) => {
    setSelectedUserType(selectedUserType);
  };

  return (
    <div>
      {!row.id && (
        <UserGeneralTopbar
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
          setSelectedEmployeeToRegister={setSelectedEmployeeToRegister}
        />
      )}

      <Grid container spacing={3}>
        {/* name and contacts item container */}
        <Grid item container xs={12} mt={3}>
          <Paper className={classes.paper}>
            {/* header */}
            <Grid item xs={12} className={classes.iconHeader}>
              <PermContactCalendarIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Name and contact</Typography>
            </Grid>

            <Grid item container xs={12} spacing={2} pl={isSmall ? 0 : 3}>
              {/* title  */}
              <Grid item xs={12} sm={2} marginTop={2}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Title
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="Title"
                  value={row.Title || selectedEmployeeToRegister?.Title || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* first name */}
              <Grid item xs={12} sm={4} marginTop={isSmall ? 0 : 2}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  First Name
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="FirstName"
                  value={row.FirstName || selectedEmployeeToRegister?.FirstName || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* middle name */}
              <Grid item xs={12} sm={3} marginTop={isSmall ? 0 : 2}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Middle Name
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="MiddleName"
                  value={row.MiddleName || selectedEmployeeToRegister?.MiddleName || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* last name */}
              <Grid item xs={12} sm={3} marginTop={isSmall ? 0 : 2}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Last Name
                </InputLabel>
                <BootstrapInput
                  name="FamilyName"
                  value={row.FamilyName || selectedEmployeeToRegister?.FamilyName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* email */}
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Email
                </InputLabel>
                <BootstrapInput
                  name="Email"
                  value={row.email || selectedEmployeeToRegister?.email || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* phone */}
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Phone
                </InputLabel>
                <BootstrapInput
                  name="PrimaryPhone"
                  value={row.PrimaryPhone || selectedEmployeeToRegister?.PrimaryPhone || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/*Job Title from keypay*/}
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Job Title
                </InputLabel>
                <BootstrapInput
                  name="jobTitle"
                  value={row.jobTitle || selectedEmployeeToRegister?.jobTitle || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* keypay id as external id */}
              <Grid item xs={12} sm={3}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  External Id
                </InputLabel>
                <BootstrapInput
                  name="keyPayId"
                  value={row.KeyPayEmployeeId || selectedEmployeeToRegister?.KeyPayEmployeeId || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  disabled
                />
              </Grid>

              {/* Date of birth */}
              <Grid item xs={12} sm={3}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Date of Birth
                </InputLabel>
                <BootstrapInput
                  name="DateOfBirth"
                  value={row.DateOfBirth || selectedEmployeeToRegister?.DateOfBirth || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  type="date"
                />
              </Grid>

              {/*Anniversary date */}
              <Grid item xs={12} sm={3}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Anniversary Date
                </InputLabel>
                <BootstrapInput
                  name="AnniversaryDate"
                  value={row.AnniversaryDate || selectedEmployeeToRegister?.AnniversaryDate || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  type="date"
                />
              </Grid>

              {/* hourly rate */}
              <Grid item xs={12} sm={3}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Hourly Rate
                </InputLabel>
                <BootstrapInput
                  name="keyPayEmployeeHourlyRate"
                  value={row.keyPayEmployeeRate || selectedEmployeeToRegister?.keyPayEmployeeHourlyRate || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* bank information container */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {/* header */}
            <Grid item xs={12} className={classes.iconHeader}>
              <AccountBalanceIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Bank Information</Typography>
            </Grid>

            <Grid item container xs={12} mt={1} spacing={2} pl={isSmall ? 0 : 3}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Name
                </InputLabel>
                <BootstrapInput
                  name="bankAccount1_AccountName"
                  value={row.bankAccount1_AccountName || selectedEmployeeToRegister?.bankAccount1_AccountName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  BSB
                </InputLabel>
                <BootstrapInput
                  name="bankAccount1_BSB"
                  value={row.bankAccount1_BSB || selectedEmployeeToRegister?.bankAccount1_BSB || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Number
                </InputLabel>
                <BootstrapInput
                  name="bankAccount1_AccountNumber"
                  value={row.bankAccount1_AccountNumber || selectedEmployeeToRegister?.bankAccount1_AccountNumber || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Superfund information container */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <AccountBalanceIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>SuperFund Information</Typography>
            </Grid>

            <Grid item container xs={12} mt={1} spacing={2} pl={isSmall ? 0 : 3}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Name
                </InputLabel>
                <BootstrapInput
                  name="superFund1_FundName"
                  value={row.superFund1_FundName || selectedEmployeeToRegister?.superFund1_FundName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Code
                </InputLabel>
                <BootstrapInput
                  name="superFund1_ProductCode"
                  value={row.superFund1_ProductCode || selectedEmployeeToRegister?.superFund1_ProductCode || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Number
                </InputLabel>
                <BootstrapInput
                  name="superFund1_MemberNumber"
                  value={row.superFund1_MemberNumber || selectedEmployeeToRegister?.superFund1_MemberNumber || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* address item container */}
        <Grid item container xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <LocationOnIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Address</Typography>
            </Grid>

            <Grid item container xs={12} mt={1} spacing={2} pl={isSmall ? 0 : 3}>
              <Grid item xs={12}>
                <p
                  style={{
                    padding: '0',
                    margin: '0',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#393A3D',
                    lineHeight: '0.1'
                  }}
                >
                  Billing address
                </p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Street address
                </InputLabel>
                <BootstrapInput
                  name="Line1"
                  value={row.Line1 || selectedEmployeeToRegister?.Line1 || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  State
                </InputLabel>
                <BootstrapInput
                  name="CountrySubDivisionCode"
                  value={row.CountrySubDivisionCode || selectedEmployeeToRegister?.CountrySubDivisionCode || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Postcode
                </InputLabel>
                <BootstrapInput
                  name="PostalCode"
                  value={row.PostalCode || selectedEmployeeToRegister?.PostalCode || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Country
                </InputLabel>
                <BootstrapInput
                  name="Country"
                  value={row.Country || selectedEmployeeToRegister?.Country || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* type and approval request container  */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <VerifiedUserIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Administration</Typography>
            </Grid>

            <Grid item container xs={12} mt={'0px'} spacing={2} pl={isSmall ? 0 : 3}>
              {/* user type */}
              <Grid item xs={12} sm={6}>
                <Typography htmlFor="bootstrap" style={{ marginBottom: '0.5rem', color: '#393A3D', fontSize: '14px' }}>
                  User Type
                </Typography>

                <ReactSelect
                  maxMenuHeight={250}
                  classNamePrefix="select"
                  value={selectedUserType}
                  isSearchable={true}
                  name="approverList"
                  options={userTypeOptions}
                  onChange={handleSelectedUserType}
                />
              </Grid>

              {/* portal access */}
              <Grid item xs={12} sm={6}>
                <Typography htmlFor="bootstrap" style={{ marginBottom: '0.5rem', color: '#393A3D', fontSize: '14px' }}>
                  Portal Access
                </Typography>
                <Switch
                  checked={row?.portalAccess || portalAccess}
                  onChange={handlePortalAccessDialog}
                  color="primary"
                  name="portalAccess"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {portalAccessDialog && (
                  <UserPortalAccessDialog
                    open={portalAccessDialog}
                    setPortalAccessDialog={setPortalAccessDialog}
                    portalAccess={portalAccess}
                    handleClose={handlePortalAccessDialogClose}
                    sendLoginDetailsToUser={sendLoginDetailsToUser}
                    removeLoginDetailsOfUser={removeLoginDetailsOfUser}
                    employeeId={row.id}
                  />
                )}
              </Grid>

              {/* approval required */}
              <Grid item xs={12} sm={12}>
                <Typography fontSize={14}>Approval</Typography>

                <Checkbox
                  checked={approvalRequired}
                  onChange={handleApprovalRequired}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography component="span" style={{ marginBottom: '0.5rem', color: '#393A3D', fontSize: '15px' }}>
                  Requires Approval?
                </Typography>

                {approvalRequired && (
                  <Grid item xs={12} style={{ marginBottom: '2rem', marginLeft: '2rem' }}>
                    <Typography style={{ color: 'rgb(107, 108, 114)', fontSize: '14px' }}>Select Approval</Typography>
                    <ReactSelect
                      maxMenuHeight={250}
                      classNamePrefix="select"
                      value={selectedApprover}
                      isSearchable={true}
                      name="approverList"
                      options={userListOptions}
                      onChange={handleSelectedApprover}
                      styles={{
                        menuList: (base) => ({
                          ...base,
                          height: '165px',
                          '::-webkit-scrollbar': {
                            width: '9px'
                          },
                          '::-webkit-scrollbar-thumb': {
                            background: '#888'
                          },
                          '::-webkit-scrollbar-thumb:hover': {
                            background: '#555'
                          }
                        })
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* type and approval request container complete */}

        {/* user's office info */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <BusinessIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Office Info</Typography>
            </Grid>
            <Grid item container xs={12} mt={'12px'} spacing={2} pl={isSmall ? 0 : 3}>
              <UserOfficeInfo employeeId={row.id} />
            </Grid>
          </Paper>
        </Grid>

        {/* user's leave balances, annual leave, Rdo, Personal leave */}
        <Grid item xs={12} style={{ marginBottom: '2rem' }}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <FlightTakeoffIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Leave Balances</Typography>
            </Grid>

            <Grid item container xs={12} mt={'0px'} spacing={2} pl={isSmall ? 0 : 3}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Annual Leave
                </InputLabel>
                <BootstrapInput
                  name="annualLeave"
                  value={annualLeave || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  RDO
                </InputLabel>
                <BootstrapInput
                  name="rdo"
                  value={rdo || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Personal Leave
                </InputLabel>
                <BootstrapInput
                  name="personalLeave"
                  value={personalLeave || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserGeneralDialogContent;

UserGeneralDialogContent.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  vendorRecords: PropTypes.array,
  keyPayEmployeeRecords: PropTypes.array,
  selectedEmployeeToRegister: PropTypes.any,
  setSelectedEmployeeToRegister: PropTypes.func,
  rows: PropTypes.array,
  selectedUserType: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  setSelectedUserType: PropTypes.func,
  approvalRequired: PropTypes.bool,
  setApprovalRequired: PropTypes.func,
  selectedApprover: PropTypes.any,
  setSelectedApprover: PropTypes.func,
  open: PropTypes.bool,
  portalAccess: PropTypes.bool,
  setPortalAccess: PropTypes.func,
  portalAccessDialog: PropTypes.bool,
  setPortalAccessDialog: PropTypes.func,
  setRows: PropTypes.func
};
