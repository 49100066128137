import React, { useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import ButtonMenu from '../CustomerNew/ButtonMenu/ButtonMenu';
import AlertDialog from '../../shared/dialog/AlertDialog';
import { formattedClass } from './table/helper';
import classService from '../../services/ClassService';
import ClassTable from './table/ClassTable';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    borderRadius: '4px',
    // backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  disableButton: {
    borderRight: '1px solid white',
    borderRadius: '4px 0 0 4px'
  }
}));

const Class = () => {
  const classes = useStyles();

  const [classLoading, setClassLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);

  const [open, setOpen] = useState(false);
  const [buttonOptions] = useState(['Enable', 'Import class']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');

  const [classStatus, setClassStatus] = useState(false);

  useEffect(() => {
    const fetchClassRefs = async () => {
      try {
        const classRecords = await classService.getClassListFromQBO();
        const formattedClassList = formattedClass(classRecords.data.data.QueryResponse.Class);

        const classFromDb = await classService.getClassListFromDatabase();
        const classRecordsDb = classFromDb.data.data;

        const updatedRows = formattedClassList.map((row) => {
          const exists = classRecordsDb.find((c) => row.Id === c.Id);
          return exists ? ((row.classStatus = exists.classStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setRows(updatedRows);
      } catch (error) {
        toast.error();
      }
    };
    fetchClassRefs();
  }, []);

  const handleDisableButton = () => {
    if (!selection.length) {
      return toast.error('Please select class  to disable.');
    }

    setOpen(true);
    setMessage('Are you sure you want to disable the selected class?');
    setClassStatus(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleOptions = (e, option) => {
    setAnchorEl(false);

    if (option === 'Enable') {
      if (!selection.length) {
        return toast.error('Please select class to disable.');
      }
      setOpen(true);
      setMessage('Are you sure you want to enable the selected class?');
      setClassStatus(true);
    }

    // if (option === 'Import Class') {
    //   setImportFileDialogOpen(true);
    //   setMessage('Select file to import');
    // }
  };

  const handleClassStatusChange = () => {
    classService
      .updateClassStatus(selection, classStatus)
      .then((data) => {
        const classes = data.data.class;

        const updatedRows = rows.map((row) => {
          const exists = classes.find((c) => row.id === c.Id);
          return exists ? ((row.classStatus = exists.classStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setRows(updatedRows);

        toast.success('Successfully updated class');
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not update class');
      })
      .finally(() => {
        setSelection([]);
      });

    setOpen(false);
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.disableButton}
          onClick={handleDisableButton}
          disabled={classLoading}
        >
          {classLoading && (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          Disable
        </Button>

        <ButtonMenu
          buttonList={buttonOptions}
          handleOptions={handleOptions}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          classLoading={classLoading}
        />
      </div>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        handleStatusChange={handleClassStatusChange}
        message={message}
        handleDialogClose={handleDialogClose}
      />

      <ClassTable rows={rows} setRows={setRows} selection={selection} setSelection={setSelection} />
    </div>
  );
};

export default Class;
