import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Grid from '@mui/material/Grid';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Avatar, Checkbox, CircularProgress, Paper, TextField, Typography, useMediaQuery } from '@material-ui/core';
import ReactSelect from 'react-select';
import useFetchClassRefRecords from '../../MyTime/hooks/useFetchClassRefs';
import { useFetchQboAccounts } from '../../MyTime/hooks/useFetchQboAccounts';
import { useFetchTaxCode } from '../../MyTime/hooks/useFetchTaxCode';
import useFetchVendorRefRecords from '../../MyTime/hooks/useFetchVendorRefs';
import itemService from '../../../services/ItemService';
import { toast } from 'react-hot-toast';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex'
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  }
}));

export default function ItemDialogContent({ row, onChange, onCancelChanges }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [PurchaseTaxIncluded, setPurchaseTaxIncluded] = useState(false);
  const [SalesTaxIncluded, setSalesTaxIncluded] = useState(false);

  const [saleProduct, setSaleProduct] = useState(row.Description ? true : false);
  const [purchaseProduct, setPurchaseProduct] = useState(row.PurchaseDesc ? true : false);

  const { classRefs, classRefLoading } = useFetchClassRefRecords();
  const { qboAccounts, qboAccountLoading } = useFetchQboAccounts();
  const { taxCode, taxCodeLoading } = useFetchTaxCode();
  const { vendorRefs, vendorRefLoading } = useFetchVendorRefRecords();

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [selectedSalesTaxCode, setSelectedSalesTaxCode] = useState();
  const [selectedPurchaseTaxCode, setSelectedPurchaseTaxCode] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedIncomeAccount, setSelectedIncomeAccount] = useState();
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedBlob, setSelectedBlob] = useState();

  const vendorRefOptions = vendorRefs.map((v) => {
    return { value: v.name, label: v.name, id: v.value };
  });

  const classRefOptions = classRefs.map((c) => {
    return { value: c.name, label: c.name, id: c.value };
  });

  const qboAccountOptions = qboAccounts.map((a) => {
    return { value: a.name, label: a.name, id: a.value };
  });

  const taxCodeOptions = taxCode.map((t) => {
    return { value: t.name, label: t.name, id: t.value };
  });

  const handleSelectedVendor = (selectedVendor) => {
    setSelectedVendor(selectedVendor);
  };

  const handleSelectedCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const handleSelectedClass = (selectedClass) => {
    setSelectedClass(selectedClass);
  };

  const handleSelectedIncomeAccount = (selectedIncomeAccount) => {
    setSelectedIncomeAccount(selectedIncomeAccount);
  };

  const handleSelectedExpenseAccount = (selectedExpenseAccount) => {
    setSelectedExpenseAccount(selectedExpenseAccount);
  };

  const handleSelectedSalesTaxCode = (selectedSalesTaxCode) => {
    setSelectedSalesTaxCode(selectedSalesTaxCode);
  };

  const handleSelectedPurchaseTaxCode = (selectedPurchaseTaxCode) => {
    setSelectedPurchaseTaxCode(selectedPurchaseTaxCode);
  };

  const handleFileChange = (e) => {
    // eslint-disable-next-line no-undef
    setSelectedBlob(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : '');
    setSelectedFiles(e.target.files[0]);
  };

  const handleUpdatedItem = () => {
    const ExpenseAccountRef = selectedExpenseAccount ? selectedExpenseAccount : row.ExpenseAccountRef;
    const IncomeAccountRef = selectedIncomeAccount ? selectedIncomeAccount : row.IncomeAccountRef;
    const PrefVendorRef = selectedVendor ? selectedVendor : row.PrefVendorRef;
    const PurchaseTaxCodeRef = selectedPurchaseTaxCode ? selectedPurchaseTaxCode : row.PurchaseTaxCodeRef;
    const SalesTaxCodeRef = selectedSalesTaxCode ? selectedPurchaseTaxCode : row.SalesTaxCodeRef;
    const ClassRef = selectedClass ? selectedClass : row.ClassRef;

    const updateItemPayload = {
      ...row,
      ExpenseAccountRef: ExpenseAccountRef,
      IncomeAccountRef: IncomeAccountRef,
      PrefVendorRef: PrefVendorRef,
      PurchaseTaxCodeRef: PurchaseTaxCodeRef,
      SalesTaxCodeRef: SalesTaxCodeRef,
      ClassRef: ClassRef
    };

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append('updateItemPayload', JSON.stringify(updateItemPayload));
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    if (selectedFiles) {
      const file = selectedFiles;
      formData.append('file', file);
    }

    itemService
      .updateItemInQbo(formData)
      .then(() => {
        // eslint-disable-next-line no-undef
        window.location.reload();
        toast.success('Successfully updated item');
      })
      .catch((error) => {
        const qboErrorMessage = error.response?.data?.message?.Fault?.Error[0]?.Detail;
        const validationErrorMessage = error?.response?.data?.message;
        toast.error(validationErrorMessage || qboErrorMessage || 'Could not update item in  qbo');
      });
  };

  const deleteAttachable = () => {
    itemService
      .deleteProductAndServiceAttachable(row.id)
      .then(() => {
        // eslint-disable-next-line no-undef
        window.location.reload();
        toast.success('Successfully deleted image from attached item');
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not delete image from qbo.');
      });
  };

  return (
    <div style={{ height: '700px' }}>
      <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: '1.5rem', marginBottom: '1rem', paddingBottom: '0px', lineHeight: '1' }}
      >
        Item/Products and Services
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Name  */}
                    <Grid item xs={12} sm={12}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Name*
                      </InputLabel>
                      <BootstrapInput
                        id="bootstrap"
                        name="Name"
                        value={row.Name || ''}
                        onChange={onChange}
                        fullWidth={true}
                        disabled={true}
                      />
                    </Grid>

                    {/* Sku */}
                    <Grid item xs={12} sm={12}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        SKU
                      </InputLabel>
                      <BootstrapInput
                        id="bootstrap"
                        name="Sku"
                        value={row.Sku || ''}
                        onChange={onChange}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* image */}
                <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                      Image
                    </InputLabel>

                    <Avatar
                      variant="square"
                      src={row.itemImageUrl || selectedBlob || ''}
                      alt="item_image"
                      className={classes.large}
                    />

                    <div style={{ marginTop: '1rem', display: 'flex' }}>
                      <Button component="label">
                        Edit
                        <input type="file" accept=".jpeg, .jpg, .png" onChange={handleFileChange} hidden />
                      </Button>
                      <Button style={{ color: 'red' }} onClick={deleteAttachable}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* change category name and other handlers */}
            <Grid item xs={12}>
              <Typography className={classes.typography}>Category*</Typography>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={row.vendor}
                isSearchable={true}
                name="category"
                // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={vendorRefOptions}
                onChange={(selectedOption) => {
                  return handleSelectedCategory({ name: selectedOption.value, value: selectedOption.id });
                }}
              />
            </Grid>

            {/* class */}
            <Grid item xs={12}>
              <Typography className={classes.typography}>Class</Typography>
              {classRefLoading ? (
                <>
                  <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={{
                    value: row.ClassRef?.value || '',
                    label: row.ClassRef?.name || '',
                    id: row.ClassRef?.value || ''
                  }}
                  isSearchable={true}
                  name="class"
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={classRefOptions}
                  onChange={(selectedOption) => {
                    return handleSelectedClass({ name: selectedOption.value, value: selectedOption.id });
                  }}
                />
              )}
            </Grid>

            {/*  If (I sell this product/service to my customers.) is ticked */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={saleProduct}
                    onChange={() => setSaleProduct(!saleProduct)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span style={{ padding: '0', margin: '0', fontSize: '14px' }}>
                    I sell this product/service to my customers.
                  </span>
                </Grid>
                {/* saleProduct true? then only show below details, vice-versa */}
                {saleProduct && (
                  <Grid item container xs={12} spacing={2}>
                    {/* Description */}
                    <Grid item xs={12}>
                      <TextField
                        id="Description"
                        type="text"
                        name="Description"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        fullWidth={true}
                        value={row.Description}
                        onChange={onChange}
                      />
                    </Grid>
                    {/* sales price/rate */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Sales price/rate
                      </InputLabel>
                      <BootstrapInput
                        id="bootstrap"
                        name="UnitPrice"
                        value={row.UnitPrice || ''}
                        onChange={onChange}
                        fullWidth={true}
                      />
                    </Grid>

                    {/* Income account ref */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Income Account
                      </InputLabel>
                      {qboAccountLoading ? (
                        <>
                          <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            value: row.IncomeAccountRef?.value,
                            label: row.IncomeAccountRef?.name,
                            id: row.IncomeAccountRef?.value
                          }}
                          isSearchable={true}
                          name="IncomeAccountRef"
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={qboAccountOptions}
                          onChange={(selectedOption) => {
                            return handleSelectedIncomeAccount({
                              name: selectedOption.value,
                              value: selectedOption.id
                            });
                          }}
                        />
                      )}
                    </Grid>

                    {/* api:setSalesTSalesTaxIncludedaxIncluded */}
                    <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={SalesTaxIncluded}
                        onChange={() => setSalesTaxIncluded(!SalesTaxIncluded)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        name="SalesTaxIncluded"
                      />
                      <span style={{ padding: '0', margin: '0', fontSize: '14px' }}>Inclusive of GST</span>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        GST
                      </InputLabel>
                      {taxCodeLoading ? (
                        <>
                          <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            value: row.SalesTaxCodeRef?.value,
                            label: row.SalesTaxCodeRef?.name,
                            id: row.SalesTaxCodeRef?.value
                          }}
                          isSearchable={true}
                          name="SalesTaxCodeRef"
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={taxCodeOptions}
                          onChange={(selectedOption) => {
                            return handleSelectedSalesTaxCode({ name: selectedOption.value, value: selectedOption.id });
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/*  If ( I purchase this product/service from a supplier.) is ticked */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* purchase desc  api:PurchaseDesc*/}
                <Grid item xs={12}>
                  <Typography>Purchasing information</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={purchaseProduct}
                    onChange={() => setPurchaseProduct(!purchaseProduct)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span style={{ padding: '0', margin: '0', fontSize: '14px' }}>
                    I purchase this product/service from a supplier.
                  </span>
                </Grid>

                {purchaseProduct && (
                  <Grid item container xs={12} spacing={2}>
                    {/* purchase desc */}
                    <Grid item xs={12}>
                      <TextField
                        id="PurchaseDesc"
                        type="text"
                        name="PurchaseDesc"
                        variant="outlined"
                        multiline
                        maxRows={3}
                        fullWidth={true}
                        value={row.PurchaseDesc}
                        onChange={onChange}
                      />
                    </Grid>

                    {/* api:PurchaseCost */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Cost
                      </InputLabel>
                      <BootstrapInput
                        name="PurchaseCost"
                        value={row.PurchaseCost || ''}
                        onChange={onChange}
                        id="bootstrap"
                        fullWidth={true}
                      />
                    </Grid>

                    {/* Expense account */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Expense Account
                      </InputLabel>
                      {qboAccountLoading ? (
                        <>
                          <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            value: row.ExpenseAccountRef?.value,
                            label: row.ExpenseAccountRef?.name,
                            id: row.ExpenseAccountRef?.value
                          }}
                          isSearchable={true}
                          name="ExpenseAccountRef"
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={qboAccountOptions} //change it later
                          onChange={(selectedOption) => {
                            return handleSelectedExpenseAccount({
                              name: selectedOption.value,
                              value: selectedOption.id
                            });
                          }}
                        />
                      )}
                    </Grid>

                    {/* Inclusive of purchase tax */}
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={PurchaseTaxIncluded}
                        onChange={() => setPurchaseTaxIncluded(!PurchaseTaxIncluded)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span style={{ padding: '0', margin: '0', fontSize: '14px' }}>Inclusive of purchase tax</span>
                    </Grid>

                    {/* purchase tax code ref/ Purchase tax */}
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.typography}>Purchase tax</Typography>
                      {taxCodeLoading ? (
                        <>
                          <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            value: row.PurchaseTaxCodeRef?.value,
                            label: row.PurchaseTaxCodeRef?.name,
                            id: row.PurchaseTaxCodeRef?.value
                          }}
                          isSearchable={true}
                          name="PurchaseTaxCodeRef"
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={taxCodeOptions}
                          onChange={(selectedOption) => {
                            return handleSelectedPurchaseTaxCode({
                              name: selectedOption.value,
                              value: selectedOption.id
                            });
                          }}
                        />
                      )}
                    </Grid>
                    {/* Preferred Supplier , api:PrefVendorRef */}
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.typography}>Preferred Supplier*</Typography>
                      {vendorRefLoading ? (
                        <>
                          <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            value: row.PrefVendorRef?.value,
                            label: row.PrefVendorRef?.name,
                            id: row.PrefVendorRef?.value
                          }}
                          isSearchable={true}
                          name="PrefVendorRef"
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          options={vendorRefOptions}
                          onChange={(selectedOption) => {
                            return handleSelectedVendor({ name: selectedOption.value, value: selectedOption.id });
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdatedItem} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
}

ItemDialogContent.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func
};
