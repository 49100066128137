import React, { useState } from 'react';
import UserTable from './table/UserTable';
import { Toaster } from 'react-hot-toast';
import useFetchEmployeeRecords from './hooks/useFetchEmployeeRecords';
import { userListOptions } from './filter/UserFilter';

const User = () => {
  // Get user list from localStorage
  // eslint-disable-next-line no-undef
  const users = localStorage.getItem('userList');

  // Initialize filter state
  const [filter, setFilter] = useState({
    userList: users || userListOptions[0]
  });

  // Fetch employee records based on the filter
  const { rows, setRows, employeeLoading, employeeError } = useFetchEmployeeRecords(filter);

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />
      {employeeError && <div>Error: {employeeError}</div>}
      {employeeLoading ? (
        <div>Loading...</div>
      ) : (
        <UserTable rows={rows} setRows={setRows} filter={filter} setFilter={setFilter} />
      )}
    </div>
  );
};

export default User;
