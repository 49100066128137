/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { toast } from 'react-hot-toast';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { firstAid } from '../helper';
import { firstAidSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const FirstAidQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [firstAidQualification, setFirstAidQualification] = useState({
    firstAid_licenseNumber: '',
    firstAid_issueDate: '',
    firstAid_expiryDate: '',
    firstAid_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlFirstAid,
    handleSubmit: handleFirstAidSubmit,
    reset: resetFirstAid,
    getValues: getFirstAidValues,
    formState: { errors: errorsFirstAid, dirtyFields: dirtyFieldsFirstAid }
  } = useForm({
    resolver: zodResolver(firstAidSchema),
    defaultValues: firstAidQualification
  });

  useEffect(() => {
    const fetchEmployeeQualification = async () => {
      try {
        setSubmitting(true);
        if (expanded === 'panel13') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName('First Aid');
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let firstAidQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'First Aid').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'First Aid')[0];
            firstAidQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              firstAid_licenseNumber: '',
              firstAid_expiryDate: '',
              firstAid_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'First Aid')[0];
            firstAidQualification = firstAid(filteredQualification);
          }

          setFirstAidQualification(firstAidQualification);
          resetFirstAid(firstAidQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get employee First Aid qualification');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualification();
  }, [resetFirstAid, expanded]);

  const onSubmitFirstAid = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getFirstAidValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        issueDate: dirtyValues.firstAid_issueDate,
        expiryDate: dirtyValues.firstAid_expiryDate,
        referenceNumber: dirtyValues.firstAid_licenseNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsFirstAid.firstAid_photoOfCard) {
        formData.append('avatar', dirtyValues.firstAid_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }

      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedFirstAid = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   firstAid_licenseNumber: dataObj.referenceNumber || '',
          //   firstAid_issueDate: dataObj.issueDate ? format(new Date(dataObj.issueDate), 'yyyy-MM-dd') : '',
          //   firstAid_expiryDate: dataObj.expiryDate ? format(new Date(dataObj.expiryDate), 'yyyy-MM-dd') : '',
          //   firstAid_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetFirstAid(updatedFirstAid);
          // toast.success('Successfully updated First Aid qualification');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update first aid qualification');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleFirstAidSubmit(onSubmitFirstAid)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> License Number</h5>
              <Controller
                name="firstAid_licenseNumber"
                control={controlFirstAid}
                render={({ field }) => (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsFirstAid.firstAid_licenseNumber}
                    helperText={errorsFirstAid.firstAid_licenseNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> Issue Date</h5>
              <Controller
                name="firstAid_issueDate"
                control={controlFirstAid}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsFirstAid.firstAid_issueDate}
                    helperText={errorsFirstAid.firstAid_issueDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> Expiry Date</h5>
              <Controller
                name="firstAid_expiryDate"
                control={controlFirstAid}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsFirstAid.firstAid_expiryDate}
                    helperText={errorsFirstAid.firstAid_expiryDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card</h5>
              <Controller
                name="firstAid_photoOfCard"
                control={controlFirstAid}
                render={({ field }) => (
                  <>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsFirstAid.firstAid_photoOfCard && (
                      <p style={{ color: 'red' }}>{errorsFirstAid?.firstAid_photoOfCard?.message}</p>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>First Aid</b> update request to the admin. You will get notified once it gets
                  accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default FirstAidQualification;

FirstAidQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
