import { differenceInCalendarDays, parse } from 'date-fns';

export function validateDateDifference(weekStartDate, weekEndDate) {
  const result = differenceInCalendarDays(new Date(weekEndDate), new Date(weekStartDate));
  if (result % 6 !== 0) return false;
  return true;
}

export function getFormattedEmployeeRecords(employeeRefList) {
  return employeeRefList.map((item) => ({
    value: item._id || item.id,
    name: item.DisplayName
  }));
}

//only add workType name not value and id
export function formattedWorkType(changedRow) {
  if (!changedRow?.workType) return changedRow;
  if (changedRow?.workType) {
    return { ...changedRow, workType: changedRow.workType };
  }
}

//check workType, check accrued hours only for rdo, annual leave, sick leave
export function checkWorkType(workType) {
  if (workType == 'Annual Leave' || workType == 'Rostered Day Off' || workType == "Personal/Carer's Leave") return true;
  return false;
}

//calculate hours
export function calculateHours(StartTime, FinishTime, rowDate) {
  const startDateMs = parse(StartTime, 'HH:mm', new Date(rowDate));
  const endDateMs = parse(FinishTime, 'HH:mm', new Date(rowDate));

  const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);

  return Number(diffHr);
}

//check leave balance is valid or not, can take leave timeSheet until leave balance minus hours is > -2
export function isLeaveBalanceValid(leaveBalance, hours) {
  if (leaveBalance - hours >= -2) return true;
  return false;
}

//check for timesheet validity
//either timesheet must have classRef/customerRef or workTpe
export function isClassAndCustomerRefValid(timesheet) {
  if (timesheet.ClassRef && timesheet.CustomerRef && !timesheet.workType) return true;

  if (timesheet.workType && timesheet.ClassRef === null && timesheet.CustomerRef === null) return true;

  return false;
}

//check if employeeRef is valid
export function isEmployeeRefValidInEditedTimesheet(timesheet) {
  if (timesheet.EmployeeRef) return true;
  return false;
}

//validate timesheet before creating bill
export function isSelectedTimeSheetsValid(timeSheets) {
  const invalidIndices = [];

  timeSheets.forEach((item, index) => {
    const hasCustomer = item.CustomerRef !== null && item.CustomerRef !== undefined;
    const hasClass = item.ClassRef !== null && item.ClassRef !== undefined;
    const hasWorkType = item.workType !== undefined && item.workType !== null && item.workType !== '';

    if ((hasCustomer && !hasClass) || (!hasCustomer && hasClass) || (hasWorkType && hasCustomer && hasClass)) {
      invalidIndices.push(index);
    }
  });

  return invalidIndices;
}
