import React, { useEffect, useState } from 'react';
import itemService from '../../services/ItemService';
import { Toaster, toast } from 'react-hot-toast';
import ItemTable from './table/ItemTable';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import ButtonMenu from '../CustomerNew/ButtonMenu/ButtonMenu';
import AlertDialog from '../../shared/dialog/AlertDialog';
import { formattedItem } from './table/helper';
import ItemFileUploadDialog from './dialog/ItemFileUploadDialog';
import { useDebounce } from 'react-use';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    borderRadius: '4px',
    // backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  disableButton: {
    borderRight: '1px solid white',
    borderRadius: '4px 0 0 4px'
  }
}));

const Item = () => {
  const classes = useStyles();

  const [itemLoading, setItemLoading] = useState(false);

  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);

  const [open, setOpen] = useState(false);
  const [buttonOptions] = useState(['Enable', 'Import item', 'Import item file']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');

  const [itemStatus, setItemStatus] = useState(false);
  const [itemFilUpload, setItemFileUploadOpen] = useState(false);
  const [itemFiles, setItemFiles] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizes] = useState([100, 200, 300]);
  const [totalCount, setTotalCount] = useState(0);

  const [searchValue, setSearchState] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const fetchItemRefs = async () => {
      try {
        setItemLoading(true);

        const queryParams = {
          take: pageSize,
          skip: pageSize * currentPage
        };

        if (debouncedValue.length > 1) {
          queryParams.queryString = debouncedValue;
        }

        const itemRefs = await itemService.getItemsFromQBO(queryParams);
        const itemRefsValid = itemRefs?.data?.data?.QueryResponse?.Item?.length;

        if (!itemRefsValid) {
          return setRows([]);
        }
        const formattedItemList = formattedItem(itemRefs.data.data.QueryResponse.Item);
        setTotalCount(itemRefs.data.newTotalCount);

        const itemFromDB = await itemService.getItemsFromDatabase();
        const itemRecords = itemFromDB.data.data;

        const updatedRows = formattedItemList.map((row) => {
          const exists = itemRecords.find((c) => row.Id === c.Id);
          return exists
            ? ((row.itemStatus = exists.itemStatus ? 'Enabled' : 'Disabled'),
              (row.itemImageUrl = exists.itemImageUrl),
              row)
            : row;
        });

        setRows(updatedRows);
      } catch (error) {
        const errorMessage = error.response?.data?.message?.Fault?.Error[0]?.Detail;
        toast.error(errorMessage || error?.message || 'Could not fetch items from qbo');
      } finally {
        setItemLoading(false);
      }
    };
    fetchItemRefs();
  }, [currentPage, pageSize, debouncedValue]);

  const handleDisableButton = () => {
    if (!selection.length) {
      return toast.error('Please select ite  to disable.');
    }

    setOpen(true);
    setMessage('Are you sure you want to disable the selected item?');
    setItemStatus(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleOptions = (e, option) => {
    setAnchorEl(false);

    if (option === 'Enable') {
      if (!selection.length) {
        return toast.error('Please select item to disable.');
      }
      setOpen(true);
      setMessage('Are you sure you want to enable the selected item?');
      setItemStatus(true);
    }

    // if (option === 'Import Item') {
    //   setImportFileDialogOpen(true);
    //   setMessage('Select file to import');
    // }

    if (option === 'Import item file') {
      setItemFileUploadOpen(true);
    }
  };

  const handleItemStatusChange = () => {
    itemService
      .updateItemStatus(selection, itemStatus)
      .then((data) => {
        const items = data.data.item;

        const updatedRows = rows.map((row) => {
          const exists = items.find((item) => row.id === item.Id);
          return exists ? ((row.itemStatus = exists.itemStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setRows(updatedRows);

        toast.success('Successfully updated item');
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not update item');
      })
      .finally(() => {
        setSelection([]);
      });

    setOpen(false);
  };

  //once save button is clicked in ItemFileUploadDialog
  const handleItemFiles = () => {};

  // const handleSearchChange = (value) => {
  //   setSearchState(value);
  // };

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchValue);
    },
    2000,
    [searchValue]
  );
  const handleInputChange = (value) => {
    setSearchState(value);
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.disableButton}
          onClick={handleDisableButton}
          disabled={itemLoading}
        >
          {itemLoading && (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          Disable
        </Button>
        {!itemLoading && (
          <ButtonMenu
            buttonList={buttonOptions}
            handleOptions={handleOptions}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            itemLoading={itemLoading}
          />
        )}
      </div>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        handleStatusChange={handleItemStatusChange}
        message={message}
        handleDialogClose={handleDialogClose}
      />

      <ItemTable
        rows={rows}
        setRows={setRows}
        selection={selection}
        setSelection={setSelection}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageSizes={pageSizes}
        totalCount={totalCount}
        itemLoading={itemLoading}
        searchValue={searchValue}
        handleInputChange={handleInputChange}
      />

      <ItemFileUploadDialog
        setItemFileUploadOpen={setItemFileUploadOpen}
        open={itemFilUpload}
        handleItemFiles={handleItemFiles}
        setItemFiles={setItemFiles}
      />
    </div>
  );
};

export default Item;
