import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//add new expense in verd database with status :"WithEmployee"
function saveAsDraftExpense(formData) {
  const url = BASE_URL + 'expenses/saveAsDraftExpense';
  return axios.post(url, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//add new expense in verd database with status: "Approver"
function saveAndSubmitExpense(formData) {
  const url = BASE_URL + 'expenses/saveAndSubmitExpense';
  return axios.post(url, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//fetch user's expense records
function getExpenses() {
  const url = BASE_URL + 'expenses/getExpenses';
  return axios.get(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update expenses from "WithEmployee" to "WithApprover" when user submit
function updateExpenseStatus(selectedExpenses) {
  const url = BASE_URL + 'expenses/updateExpenseStatus';
  return axios.put(
    url,
    { selectedExpenses: selectedExpenses },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update expense by users
function updateExpense(editRow, updateExpensePayload) {
  const endpoint = BASE_URL + `expenses/updateExpense/${editRow}`;
  return axios.put(
    endpoint,
    {
      updateExpensePayload: updateExpensePayload
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//fetch all expenses in admin expense
function getAllExpenses() {
  const url = BASE_URL + 'expenses/getAllExpenses';
  return axios.get(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//approve expense by admin: change status from "WithEmployee" / "WithApprover" to "Approved"
function updateExpenseStatusByAdmin(selectedExpense) {
  const url = BASE_URL + 'expenses/updateExpenseStatusByAdmin';
  return axios.put(
    url,
    { selectedExpense: selectedExpense },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//delete expense item
function deleteExpense(expenseId) {
  const url = BASE_URL + `expenses/deleteExpense/${expenseId}`;
  return axios.delete(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//change status approved to WithApprover from Approved
function changeExpensesStatus(expenseIds) {
  const url = BASE_URL + 'expenses/changeExpensesStatus';
  return axios.put(
    url,
    { expenseIds: expenseIds },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const expenseService = {
  saveAsDraftExpense,
  saveAndSubmitExpense,
  getExpenses,
  updateExpenseStatus,
  updateExpense,
  getAllExpenses,
  updateExpenseStatusByAdmin,
  deleteExpense,
  changeExpensesStatus
};

export default expenseService;
