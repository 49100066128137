import React, { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';

import clientService from '../../services/ClientService';
import CustomerTable from './table/CustomerTable';
import { Button, CircularProgress } from '@material-ui/core';
import CustomerAlertDialog from './dialog/CustomerAlertDialog';
import ButtonMenu from './ButtonMenu/ButtonMenu';

import { makeStyles } from '@material-ui/core/styles';
import ImportCustomerDialog from './dialog/ImportCustomerDialog';
import useFetchCustomer from './hooks/useFetchCustomer';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    borderRadius: '4px',
    // backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  disableButton: {
    borderRight: '1px solid white',
    borderRadius: '4px 0 0 4px'
  }
}));

const Customer = () => {
  const classes = useStyles();
  const { customerRows, setCustomerRows, customerLoading } = useFetchCustomer();

  const [open, setOpen] = useState(false);
  const [buttonOptions] = useState(['Enable', 'Import customer']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');

  const [selection, setSelection] = useState([]);
  const [customerStatus, setCustomerStatus] = useState(false);
  const [importFileDialogOpen, setImportFileDialogOpen] = useState(false);
  const [importFile, setImportFile] = useState([]);

  const handleDisableButton = () => {
    if (!selection.length) {
      return toast.error('Please select customer to disable.');
    }

    setOpen(true);
    setMessage('Are you sure you want to disable the selected customer?');
    setCustomerStatus(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleOptions = (e, option) => {
    setAnchorEl(false);

    if (option === 'Enable') {
      if (!selection.length) {
        return toast.error('Please select customer to disable.');
      }
      setOpen(true);
      setMessage('Are you sure you want to enable the selected customer?');
      setCustomerStatus(true);
    }

    if (option === 'Import customer') {
      setImportFileDialogOpen(true);
      setMessage('Select file to import');
    }
  };

  //customerStatus is based on showing customer list that are enabled to verd employee. customerStatus is not based on quickbooks active.
  const handleCustomerStatus = () => {
    clientService
      .updateCustomerStatus(selection, customerStatus)
      .then((data) => {
        const customers = data.data.customer;

        const updatedRows = customerRows.map((row) => {
          const exists = customers.find((c) => row.id === c.Id);
          return exists ? ((row.customerStatus = exists.customerStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setCustomerRows(updatedRows);

        toast.success('Successfully updated customer');
      })
      .catch((error) => {
        toast.error('Could not update customer' || error);
      })
      .finally(() => {
        setSelection([]);
      });

    setOpen(false);
  };

  //once import customer save button is clicked
  const handleImportedCustomer = async () => {
    const customersData = importFile.slice(1);
    let filteredPayload = customersData.filter((obj) => Object.keys(obj).length !== 0);

    // Modifying the "Phone" field type to string
    let customersPayload = filteredPayload.map((customer) => {
      return {
        ...customer,
        Phone: customer.Phone?.toString() || '',
        SyncToken: customer.SyncToken?.toString() || ''
      };
    });

    console.log('customersPayload', customersPayload);

    clientService
      .updateImportedCustomersInQbo(customersPayload)
      .then((response) => {
        console.log('response', response);

        return toast.success('Successfully updated customers.');
      })
      .catch((error) => {
        let errorMessage, errorDetails;

        if (error?.response?.data?.message[0]?.Fault?.Error[0]?.Detail) {
          errorMessage = error?.response?.data?.message[0]?.Fault?.Error[0]?.Message;
          errorDetails = error?.response?.data?.message[0]?.Fault?.Error[0]?.Detail;
          if (errorMessage === 'Stale Object Error') {
            return toast.error('Customer is already updated. To update again export and then import with changes');
          }
          return toast.error(errorMessage);
        }

        if (error?.response?.data?.message) {
          let originalString = error?.response?.data?.message;
          errorMessage = originalString.replace(/\\|"|\[|\]/g, '');
          return toast.error(errorMessage || 'Could not update customers');
        }

        return toast.error(errorDetails || errorMessage || 'Could not update customer.');
      })
      .finally(() => {
        setImportFileDialogOpen(false);
      });
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.disableButton}
          onClick={handleDisableButton}
          disabled={customerLoading}
        >
          {customerLoading && (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          Disable
        </Button>

        <ButtonMenu
          buttonList={buttonOptions}
          handleOptions={handleOptions}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          customerLoading={customerLoading}
        />
      </div>

      {customerLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <CustomerTable
          rows={customerRows}
          setRows={setCustomerRows}
          selection={selection}
          setSelection={setSelection}
        />
      )}

      <CustomerAlertDialog
        open={open}
        setOpen={setOpen}
        handleCustomerStatus={handleCustomerStatus}
        message={message}
        handleDialogClose={handleDialogClose}
      />

      <ImportCustomerDialog
        open={importFileDialogOpen}
        setImportFileDialogOpen={setImportFileDialogOpen}
        handleImportedCustomer={handleImportedCustomer}
        setImportFile={setImportFile}
      />
    </div>
  );
};

export default Customer;
