import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//list client in addnewdialogform
function getClientListFromDatabase() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'client/getCustomersFromDatabase';
  return axios.post(endpoint, payload);
}
//edit client when cost plus is changed in customer accounting tab
function editClient(editClientPayload) {
  const endpoint = BASE_URL + 'client/editClient';

  return axios.put(
    endpoint,
    { editClientPayload: editClientPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update client in database
function getClientListFromQBO() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'client/getCustomersFromQBO';
  return axios.post(endpoint, payload);
}

//update client customerStatus
function updateCustomerStatus(selectedCustomerIds, customerStatus) {
  const endpoint = BASE_URL + 'client/updateCustomerStatus';

  return axios.put(
    endpoint,
    { selectedCustomerIds: selectedCustomerIds, customerStatus: customerStatus },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update quickbook client from general tab in customer
function updateQuickBookCustomer(customerPayload) {
  const endpoint = BASE_URL + 'client/updateQuickBookCustomer';

  return axios.put(
    endpoint,
    { customerPayload: customerPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//collection of customers are modified, update them in qbo
function updateImportedCustomersInQbo(customersPayload) {
  const endpoint = BASE_URL + 'client/updateImportedCustomersInQbo';

  return axios.put(
    endpoint,
    { customersPayload: customersPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const clientService = {
  getClientListFromDatabase,
  editClient,
  getClientListFromQBO,
  updateCustomerStatus,
  updateQuickBookCustomer,
  updateImportedCustomersInQbo
};

export default clientService;
