import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import vehicleTrackerService from '../../services/VehicleTrackerService';
import { getFormattedTrackerRecords } from './helper/helper';
import VehicleTrackerItem from './VehicleTrackerItem';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = () => {
  const latitude = -37.79869;
  const longitude = 144.89608;
  const position = [latitude, longitude];

  const [vehicleTrackerRecords, setVehicleTrackerRecords] = useState();

  const text = 'No device records to show';

  useEffect(() => {
    const fetchVehicleTrackerRecords = async () => {
      try {
        const vehicleResponse = await vehicleTrackerService.getTrackerRecords();

        const formattedRecords = getFormattedTrackerRecords(vehicleResponse.data.data);

        setVehicleTrackerRecords(formattedRecords);
      } catch (error) {
        // eslint-disable-next-line no-undef, no-console
        console.error(error);
      }
    };

    fetchVehicleTrackerRecords();
  }, []);

  return (
    <MapContainer center={position} zoom={11} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {vehicleTrackerRecords?.length > 0 ? (
        vehicleTrackerRecords.map((vehicleTrackerItem) => (
          <VehicleTrackerItem vehicleTrackerItem={vehicleTrackerItem} key={vehicleTrackerItem.deviceName} />
        ))
      ) : (
        <Marker position={position}>
          <Popup>
            {text} <br />
          </Popup>
          <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
            <span>{text}</span>
          </Tooltip>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
