import { useEffect, useState } from 'react';
import accountService from '../../../services/AccountService';
import { formatCreditCardAccount } from '../header/helper';

export function useFetchCreditCardAccounts() {
  const [creditCardAccount, setCreditCardAccount] = useState([]);
  const [creditCardAccountError, setCreditCardAccountError] = useState('');
  const [creditCardAccountLoading, setCreditCardAccountLoading] = useState(false);

  useEffect(() => {
    const fetchCreditCardAccount = async () => {
      try {
        setCreditCardAccountLoading(true);
        const creditCardAccount = await accountService.getCreditCardAccountsFromQbo();

        const formattedAccounts = formatCreditCardAccount(creditCardAccount.data.data.QueryResponse.Account);
        setCreditCardAccount(formattedAccounts);
      } catch (error) {
        setCreditCardAccountError(error?.message || 'Could not fetch credit card accounts');
      } finally {
        setCreditCardAccountLoading(false);
        setCreditCardAccountError();
      }
    };
    fetchCreditCardAccount();
  }, []);

  return { creditCardAccount, creditCardAccountError, creditCardAccountLoading };
}
