import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox, Paper, Typography, useMediaQuery } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex'
  }
}));

const GeneralDialogContent = ({ row, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [shippingAddress, setShippingAddress] = useState(false);

  return (
    <div>
      <Grid container spacing={3}>
        {/* name and contacts item container */}
        <Grid item container xs={12} mt={3}>
          <Paper className={classes.paper}>
            {/* header */}
            <Grid item xs={12} className={classes.iconHeader}>
              <PermContactCalendarIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Name and contact</Typography>
            </Grid>
            <Grid item container xs={12} spacing={2} pl={isSmall ? 0 : 3}>
              {/* title  */}
              <Grid item xs={12} sm={2} marginTop={2} marginRight={isSmall ? 0 : 1.4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Title
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="Title"
                  value={row.Title || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* first name */}
              <Grid item xs={12} sm={3} marginTop={isSmall ? 0 : 2} marginRight={isSmall ? 0 : 1.4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  First Name
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="GivenName"
                  value={row.GivenName || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* middle name */}
              <Grid item xs={12} sm={3} marginTop={isSmall ? 0 : 2} marginRight={isSmall ? 0 : 1.4}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Middle Name
                </InputLabel>
                <BootstrapInput
                  id="bootstrap"
                  name="MiddleName"
                  value={row.MiddleName || ''}
                  onChange={onChange}
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* last name */}
              <Grid item xs={12} sm={3} marginTop={isSmall ? 0 : 2}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Last Name
                </InputLabel>
                <BootstrapInput
                  name="FamilyName"
                  value={row.FamilyName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/*company display name */}
              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Customer Display Name*
                </InputLabel>
                <BootstrapInput
                  name="CustomerDisplayName"
                  value={row.FullyQualifiedName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* company name */}
              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Company Name
                </InputLabel>
                <BootstrapInput
                  name="CompanyName"
                  value={row.CompanyName || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* email */}
              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Email
                </InputLabel>
                <BootstrapInput
                  name="Email"
                  value={row.Email || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              {/* phone */}
              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Phone
                </InputLabel>
                <BootstrapInput
                  name="Phone"
                  value={row.Phone || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* address item container */}
        <Grid item container xs={12}>
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.iconHeader}>
              <LocationOnIcon style={{ marginRight: '4px' }} />
              <Typography fontSize={16}>Address</Typography>
            </Grid>

            <Grid item container xs={12} mt={1} spacing={2} pl={isSmall ? 0 : 3}>
              <Grid item xs={12}>
                <p style={{ padding: '0', margin: '0', fontSize: '14px', fontWeight: '400', color: '#393A3D' }}>
                  Billing address
                </p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Street address
                </InputLabel>
                <BootstrapInput
                  name="Line1"
                  value={row.Line1 || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  City
                </InputLabel>
                <BootstrapInput
                  name="City"
                  value={row.City || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  State
                </InputLabel>
                <BootstrapInput
                  name="CountrySubDivisionCode"
                  value={row.CountrySubDivisionCode || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                  Postcode
                </InputLabel>
                <BootstrapInput
                  name="PostalCode"
                  value={row.PostalCode || ''}
                  onChange={onChange}
                  id="bootstrap"
                  className={classes.bootstrapInput}
                />
              </Grid>

              <Grid item container xs={12} mt={2}>
                <Grid item xs={12}>
                  <Typography>Shipping Address</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={shippingAddress}
                    onChange={() => setShippingAddress(!shippingAddress)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <p style={{ padding: '0', margin: '0', fontSize: '14px' }}>Same as Billing address</p>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralDialogContent;

GeneralDialogContent.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func
};
