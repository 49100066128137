import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height: 400,
    // height: 600,
    width: '100%',
    maxWidth: '900px',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function CustomerTab({ customerTabIndex, setCustomerTabIndex }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setCustomerTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={customerTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Accounting" {...a11yProps(1)} />
          {/* <Tab label="Team" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
    </div>
  );
}
