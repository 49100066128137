/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useState } from 'react';
import saveAs from 'file-saver';

import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
  EditingState,
  PagingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  PagingPanel,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  SearchPanel,
  Toolbar,
  TableSelection,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import ItemDialogContent from './ItemDialogContent';
import ImageFullScreenDialog from '../../../shared/dialog/ImageFullScreenDialog';
/* eslint-disable no-shadow */

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <ItemDialogContent
        row={row}
        onCancelChanges={onCancelChanges}
        onChange={onChange}
        onApplyChanges={onApplyChanges}
      />
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name
const PopupEditing = React.memo(({ popupComponent: Popup }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name)
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

const Cell = (props) => {
  if (props.column.name === 'itemImageUrl') {
    return <Table.Cell {...props} value={<img src={props.value} style={{ width: '30px', height: '30px' }} />} />;
  }

  return <Table.Cell {...props} />;
};

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};

const getRowId = (row) => row.id;

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

export default function ItemTable({
  rows,
  setRows,
  selection,
  setSelection,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pageSizes,
  totalCount,
  searchValue,
  handleInputChange
}) {
  const [columns] = useState([
    { name: 'Name', title: 'Name' },
    { name: 'itemStatus', title: 'Action' },
    { name: 'id', title: 'ItemId' },
    { name: 'Description', title: 'Description' },
    { name: 'FullyQualifiedName', title: 'FullyQualifiedName' },
    { name: 'Sku', title: 'Sku' },

    { name: 'Taxable', title: 'Taxable' },
    { name: 'UnitPrice', title: 'UnitPrice' },
    { name: 'Type', title: 'Type' },

    { name: 'SalesTaxIncluded', title: 'SalesTaxIncluded' },
    { name: 'PurchaseDesc', title: 'PurchaseDesc' },
    { name: 'PurchaseTaxIncluded', title: 'PurchaseTaxIncluded' },
    { name: 'PurchaseCost', title: 'PurchaseCost' },

    {
      name: 'PurchaseTaxCodeRef',
      title: 'PurchaseTaxCodeRef',
      getCellValue: (row) => (row.PurchaseTaxCodeRef ? row?.PurchaseTaxCodeRef?.name : undefined)
    },
    {
      name: 'SalesTaxCodeRef',
      title: 'SalesTaxCodeRef',
      getCellValue: (row) => (row.SalesTaxCodeRef ? row?.SalesTaxCodeRef?.name : undefined)
    },
    { name: 'ClassRef', title: 'ClassRef', getCellValue: (row) => (row.ClassRef ? row?.ClassRef?.name : undefined) },
    {
      name: 'ParentRef',
      title: 'ParentRef',
      getCellValue: (row) => (row.ParentRef ? row?.ParentRef?.name : undefined)
    },
    {
      name: 'PrefVendorRef',
      title: 'PrefVendorRef',
      getCellValue: (row) => (row.PrefVendorRef ? row?.PrefVendorRef?.name : undefined)
    },
    {
      name: 'IncomeAccountRef',
      title: 'IncomeAccountRef',
      getCellValue: (row) => (row.IncomeAccountRef ? row?.IncomeAccountRef?.name : undefined)
    },
    {
      name: 'ExpenseAccountRef',
      title: 'ExpenseAccountRef',
      getCellValue: (row) => (row.ExpenseAccountRef ? row?.ExpenseAccountRef?.name : undefined)
    },

    { name: 'TrackQtyOnHand', title: 'TrackQtyOnHand' },
    { name: 'SyncToken', title: 'SyncToken' },
    { name: 'itemImageUrl', title: 'ItemImage' }
  ]);

  //it was using useLocalStorage customersColumnWidths, update it once customer columns are done
  const [customerColumnWidths, setCustomerColumnWidths] = useState([
    { columnName: 'Name', width: 180 },
    { columnName: 'itemStatus', width: 180 },
    { columnName: 'id', width: 180 },
    { columnName: 'Description', width: 180 },
    { columnName: 'FullyQualifiedName', width: 180 },
    { columnName: 'Sku', width: 180 },

    { columnName: 'Taxable', width: 180 },
    { columnName: 'UnitPrice', width: 180 },
    { columnName: 'Type', width: 180 },

    { columnName: 'SalesTaxIncluded', width: 180 },
    { columnName: 'PurchaseDesc', width: 180 },
    { columnName: 'PurchaseTaxIncluded', width: 180 },
    { columnName: 'PurchaseCost', width: 180 },

    { columnName: 'PurchaseTaxCodeRef', width: 180 },
    { columnName: 'SalesTaxCodeRef', width: 180 },
    { columnName: 'ClassRef', width: 180 },
    { columnName: 'ParentRef', width: 180 },
    { columnName: 'PrefVendorRef', width: 180 },

    { columnName: 'IncomeAccountRef', width: 180 },
    { columnName: 'ExpenseAccountRef', width: 180 },

    { columnName: 'TrackQtyOnHand', width: 180 },
    { columnName: 'SyncToken', width: 180 },
    { columnName: 'itemImageUrl', width: 180 }
  ]);
  const [customerColumnOrder, setCustomerColumnOrder] = useState([
    'FullyQualifiedName',
    'Name',
    'itemStatus',
    'id',
    'Description',
    'Sku',

    'Taxable',
    'UnitPrice',
    'Type',

    'PurchaseDesc',
    'SalesTaxIncluded',
    'PurchaseTaxIncluded',
    'PurchaseCost',

    'PurchaseTaxCodeRef',
    'SalesTaxCodeRef',
    'ClassRef',
    'ParentRef',
    'PrefVendorRef',

    'IncomeAccountRef',
    'ExpenseAccountRef',

    'TrackQtyOnHand',
    'SyncToken',
    'itemImageUrl'
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'FullyQualifiedName', direction: 'asc' }]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['']);

  const itemImageUrl = ['itemImageUrl'];

  const commitChanges = ({ changed }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  return (
    <div>
      <span>Total rows selected: {selection.length}</span>
      <Paper style={{ position: 'relative' }}>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedSelection />

          <DragDropProvider />

          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />

          <SearchState value={searchValue} onValueChange={handleInputChange} />
          <IntegratedFiltering />
          {/* <IntegratedPaging /> */}
          <CustomPaging totalCount={totalCount} />

          <ImageProvider for={itemImageUrl} />

          <Table cellComponent={Cell} />
          <TableColumnResizing columnWidths={customerColumnWidths} onColumnWidthsChange={setCustomerColumnWidths} />
          <TableColumnReordering order={customerColumnOrder} onOrderChange={setCustomerColumnOrder} />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditColumn showEditCommand />
          <PopupEditing popupComponent={Popup} />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rows}
          selection={selection}
          onSave={onSave}
          getRowId={getRowId}
          hiddenColumnNames={hiddenColumnNames}
        />
      </Paper>
    </div>
  );
}
