import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import expenseService from '../../../../services/ExpenseService';
import { getFormattedTableRows } from '../../../MyExpense/table/helper';

export default function useFetchExpenseFilter(filter) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchAllExpense = async () => {
      try {
        setLoading(true);
        setError(null);

        const expenseRecords = await expenseService.getAllExpenses();
        const formattedExpense = getFormattedTableRows(expenseRecords.data.data);

        const filteredRows = formattedExpense.filter((row) => {
          if (filter.expenseStatus !== 'All') {
            if (
              filter.expenseStatus === row.status &&
              (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
              new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
            ) {
              return true;
            }

            return false;
          }

          if (filter.timesheetPeriod === 'All') return true;

          if (
            (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
            new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }

          return false;
        });

        const searchedRows = searchRows(filteredRows, filter.searchQuery);

        setRows(searchedRows);
      } catch (error) {
        setError(error.message || 'Could not fetch all expense data');
      } finally {
        setLoading(false);
      }
    };

    fetchAllExpense();
  }, [filter.endDate, filter.startDate, filter.expenseStatus, filter.timesheetPeriod, filter.searchQuery]);
  return { rows, setRows, error, loading };
}

function searchRows(allRows, query) {
  const searchedRows = allRows.filter((r) => {
    return (
      r.Day?.toLowerCase().includes(query) ||
      r.CustomerRef?.name.toLowerCase().includes(query) ||
      r.EmployeeRef?.name.toLowerCase().includes(query) ||
      r.status.toLowerCase().includes(query) ||
      r.variationNote?.toLowerCase().includes(query) ||
      r.Description.toLowerCase().includes(query) ||
      r.ClassRef?.name.toLowerCase().includes(query) ||
      r.VendorRef?.name.toLowerCase().includes(query) ||
      r.ItemRef?.name.toLowerCase().includes(query)
    );
  });

  return searchedRows;
}
