import React, { useState } from 'react';

import AssetTable from './table/AssetTable';
import useFetchEmployeeRefRecords from '../admin/AdminTimesheet/hooks/useFetchEmployeeRefs';
import ImportAssetDialog from './dialog/ImportAssetDialog';
import useFetchAssetRecords from './hooks/useFetchAssetRecords';
import assetService from '../../services/AssetService';
import toast, { Toaster } from 'react-hot-toast';
import AssetImportButtonGroup from './buttonGroup/AssetImportButtonGroup';
import { formattedAssets } from './table/hepler';
import { useLocalStorage } from 'react-use';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import AssignAssetToEmployee from './select/AssignAssetToEmployee';
import AddAsset from './dialog/AddAsset';

const useStyles = makeStyles(() => ({
  searchContainer: {
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
    float: 'right',
    paddingLeft: '20px',
    marginLeft: '1rem',
    marginBottom: '-4rem'
  },
  searchInput: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#fafafa'
  }
}));

const Asset = () => {
  const classes = useStyles();

  const { employeeRefs } = useFetchEmployeeRefRecords();

  const [filter, setFilter] = useState({
    searchQuery: ''
  });

  const { assets, setAssets, assetLoading } = useFetchAssetRecords(filter);

  const [columns] = useState([
    { name: 'assetId', title: 'ID.' },
    { name: 'tokenMac', title: 'TOKEN MAC' },
    { name: 'make', title: 'MAKE' },
    { name: 'model', title: 'MODEL #' },
    { name: 'description', title: 'DESCRIPTION' },
    { name: 'tradeSpecific', title: 'TRADE SPECIFIC' },
    { name: 'supplier', title: 'SUPPLIER' },
    { name: 'category', title: 'CATEGORY' },
    { name: 'dateOfPurchase', title: 'DATE OF PURCHASE' },
    { name: 'purchasePrice', title: 'PURCHASE PRICE' },
    { name: 'owner', title: 'OWNER' },
    { name: 'operator', title: 'OPERATOR' },
    { name: 'location', title: 'LOCATION' },
    { name: 'locationDate', title: 'LOCATION DATE' },
    { name: 'insuranceName', title: 'INSURANCE NAME' },
    { name: 'insurancePolicy', title: 'INSURANCE POLICY' },
    { name: 'insuranceExpiry', title: 'INSURANCE EXPIRY' },
    { name: 'maintenanceSchedule', title: 'MAINTENANCE SCHEDULE ' },
    { name: 'disposalDate', title: 'DISPOSAL DATE' },
    { name: 'image', title: 'IMAGE' },
    { name: 'comments', title: 'COMMENTS/NOTES' },
    { name: 'activeStatus', title: 'ActiveStatus' },
    { name: 'fixedLocation', title: 'FixedLocation' }
    // { name: 'maps', title: 'Maps' }
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(() => {
    // Retrieve stored value from local storage
    // eslint-disable-next-line no-undef
    const storedPageSize = localStorage.getItem('assetPageSize');
    // Return stored value if available, otherwise return a default value
    return storedPageSize ? parseInt(storedPageSize, 10) : 50; // Default page size is 25
  });
  const [pageSizes] = useState([50, 100, 300]);
  const [sorting, setSorting] = useState([{ columnName: 'assetId', direction: 'asc' }]);

  const [importAssetDialog, setImportAssetDialog] = useState(false);
  const [assetRecords, setAssetRecords] = useState();
  const [assetUploadLoading, setAssetUploadLoading] = useState(false);

  const [assetImages, setAssetImages] = useState();

  const [selection, setSelection] = useState([]);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage('assetHiddenColumnNames', [
    'insuranceName',
    'insurancePolicy',
    'insuranceExpiry',
    'tokenMac',
    'maintenanceSchedule',
    'disposalDate',
    'tradeSpecific'
  ]);

  const [buttonSelectedIndex, setButtonSelectedIndex] = useState(0);
  const [addAssetDialogOpen, setAddAssetDialogOpen] = useState(false);

  // Function to handle change in page size
  const handlePageSizeChange = (newPageSize) => {
    // Update local storage
    // eslint-disable-next-line no-undef
    localStorage.setItem('assetPageSize', newPageSize);
    // Update state with new page size

    setPageSize(newPageSize);
  };

  //import the asset files/ .csv , .xlsx files
  const uploadAssetFiles = async (e) => {
    e.preventDefault();

    setAssetUploadLoading(true);

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('file', assetRecords);

    assetService
      .createAssets(formData)
      .then((data) => {
        const formatAsset = formattedAssets(data?.data?.data);
        setAssets(formatAsset);

        toast.success('Successfully uploaded assets file');
        setImportAssetDialog(false);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(
          errorMessage || 'File should start from the column header "ID., TOKEN MAC and so on". Format did not match'
        );
      })
      .finally(() => {
        setAssetUploadLoading(false);
      });
  };

  //upload asset's images
  const uploadAssetImages = async (e) => {
    e.preventDefault();

    setAssetUploadLoading(true);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    for (let i = 0; i < assetImages.length; i++) {
      formData.append('file', assetImages[i]);
    }

    assetService
      .uploadAssetImages(formData)
      .then((data) => {
        const formatAsset = formattedAssets(data.data.data);

        const updateAssets = assets.map((asset) => {
          const foundAsset = formatAsset.find((formattedAsset) => formattedAsset.id === asset.id);
          if (foundAsset) {
            return { ...asset, image: foundAsset.image };
          } else {
            return asset; // Return the original asset if not found
          }
        });

        setAssets(updateAssets);
        toast.success('Successfully added asset images');
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        toast.error(errorMessage || 'Server error');
      })
      .finally(() => {
        setAssetUploadLoading(false);
        setImportAssetDialog(false);
      });
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      {buttonSelectedIndex === 0 && addAssetDialogOpen && assets.length > 0 ? (
        <AddAsset
          rows={assets}
          employeeRefs={employeeRefs}
          setRows={setAssets}
          open={addAssetDialogOpen}
          setOpen={setAddAssetDialogOpen}
        />
      ) : (
        <ImportAssetDialog
          open={importAssetDialog}
          setOpen={setImportAssetDialog}
          uploadAssetFiles={uploadAssetFiles}
          setAssetRecords={setAssetRecords}
          buttonSelectedIndex={buttonSelectedIndex}
          setAssetImages={setAssetImages}
          uploadAssetImages={uploadAssetImages}
          assetUploadLoading={assetUploadLoading}
        />
      )}

      <div style={{ marginBottom: '5rem', display: 'flex', justifyContent: 'space-between' }}>
        {selection.length > 0 && (
          <AssignAssetToEmployee
            rows={assets}
            employeeRefs={employeeRefs}
            selection={selection}
            setSelection={setSelection}
            setRows={setAssets}
          />
        )}
        <AssetImportButtonGroup
          selectedIndex={buttonSelectedIndex}
          setSelectedIndex={setButtonSelectedIndex}
          setImportAssetDialog={setImportAssetDialog}
          setAssetImages={setAssetImages}
          setAddAssetDialogOpen={setAddAssetDialogOpen}
        />
      </div>

      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          value={filter.searchQuery}
          onChange={(e) => setFilter({ ...filter, searchQuery: e.target.value })}
          placeholder="Search..."
          className={classes.searchInput}
        />
      </div>

      {employeeRefs.length > 0 && (
        <AssetTable
          rows={assets}
          setRows={setAssets}
          columns={columns}
          sorting={sorting}
          setSorting={setSorting}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          pageSizes={pageSizes}
          hiddenColumnNames={hiddenColumnNames}
          setHiddenColumnNames={setHiddenColumnNames}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </div>
  );
};

export default Asset;
