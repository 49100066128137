/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { parse, format } from 'date-fns';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  IntegratedSummary,
  SummaryState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableColumnReordering,
  DragDropProvider,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  TableSummaryRow
} from '@devexpress/dx-react-grid-material-ui';
import { useLocalStorage } from 'react-use';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& tfoot': {
      display: 'none'
    }
  }
});

const DateFormatter = ({ value }) => <span>{format(value, 'dd/MM/yyyy')}</span>;

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;

const TableHeaderContent = ({ totalEmployeeCount, totalDateCount, totalHours, column, children, ...restProps }) => {
  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      {column.name === 'EmployeeRef' ? <div style={{ marginRight: '5px' }}>({totalEmployeeCount}) </div> : null}
      {column.name === 'Hours' ? <div style={{ marginRight: '5px' }}>({totalHours}) </div> : null}
      {column.name === 'Day' ? <div style={{ marginRight: '5px' }}>({totalDateCount}) </div> : null}
      {children}
    </TableHeaderRow.Content>
  );
};

const ApproveTimesheetTable = ({ rows }) => {
  const classes = useStyles();

  const [columns] = useState([
    {
      name: 'EmployeeRef',
      title: 'Employee Name',
      getCellValue: (row) => (row.EmployeeRef ? row.EmployeeRef.name : undefined)
    },
    { name: 'StartTime', title: 'Start Time', required: true },
    { name: 'FinishTime', title: 'Finish Time', required: true },
    {
      name: 'Hours',
      title: 'Hours',
      getCellValue: (row) => {
        if (row.StartTime && row.FinishTime) {
          const startDateMs = parse(row.StartTime, 'HH:mm', new Date(row.Date));
          const endDateMs = parse(row.FinishTime, 'HH:mm', new Date(row.Date));

          const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);

          return Number(diffHr);
        }
      },
      required: true
    },
    {
      name: 'Day',
      title: 'Day',
      required: true,
      getCellValue: (row) => {
        if (row.Date) {
          const columnDay = format(new Date(row.Date), 'EEEE');
          return columnDay;
        }
      }
    },
    { name: 'Date', title: 'Date', required: true },
    { name: 'Description', title: 'Description', required: true },
    { name: 'status', title: 'Status', required: true },
    {
      name: 'CustomerRef',
      title: 'Customer Ref',
      getCellValue: (row) => (row.CustomerRef ? row.CustomerRef.name : undefined),
      required: true
    },
    {
      name: 'ClassRef',
      title: 'Service',
      getCellValue: (row) => (row.ClassRef ? row.ClassRef.name : undefined),
      required: true
    },
    { name: 'BillableStatus', title: 'Billable Status' },
    { name: 'variationNote', title: 'Variation Note' },
    { name: 'image', title: 'Image' },
    { name: 'VendorRef', title: 'Supplier', getCellValue: (row) => (row.VendorRef ? row.VendorRef.name : undefined) },
    { name: 'email', title: 'Email' },
    { name: 'jobTitle', title: 'Job Title' }
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'EmployeeRef', direction: 'asc' }]);
  const [columnWidths, setColumnWidths] = useLocalStorage('approveTimesheetColumnWidths', [
    { columnName: 'EmployeeRef', width: 180 },
    { columnName: 'StartTime', width: 180 },
    { columnName: 'FinishTime', width: 180 },
    { columnName: 'Hours', width: 180 },
    { columnName: 'Day', width: 180 },
    { columnName: 'Date', width: 180 },
    { columnName: 'Description', width: 180 },
    { columnName: 'status', width: 180 },
    { columnName: 'CustomerRef', width: 180 },
    { columnName: 'ClassRef', width: 180 },
    { columnName: 'BillableStatus', width: 180 },
    { columnName: 'variationNote', width: 180 },
    { columnName: 'image', width: 180 },
    { columnName: 'VendorRef', width: 180 },
    { columnName: 'email', width: 180 },
    { columnName: 'jobTitle', width: 240 }
  ]);

  const [columnOrder, setColumnOrder] = useLocalStorage('approveTimesheetColumnOrder', [
    'EmployeeRef',
    'StartTime',
    'FinishTime',
    'Hours',
    'Day',
    'Date',
    'Description',
    'status',
    'CustomerRef',
    'ClassRef',
    'BillableStatus',
    'variationNote',
    'image',
    'VendorRef',
    'email',
    'jobTitle'
  ]);
  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage('approveTimesheetHiddenColumnNames', [
    'image',
    'VendorRef',
    'email',
    'jobTitle'
  ]);

  const dateColumns = ['Date'];

  const [totalSummaryItems] = useState([
    { columnName: 'Hours', type: 'Hours' },
    { columnName: 'Date', type: 'Date' },
    { columnName: 'EmployeeRef', type: 'EmployeeRef' }
  ]);

  const [totalDateCount, setTotalDateCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [hideComponent] = useState(true);

  //to calculate timesheet date
  const summaryCalculator = (type, rows, getValue) => {
    // eslint-disable-next-line no-undef
    if (type === 'Date') {
      const changedRows = rows.map((row) => new Date(getValue(row)).getTime()).filter(Number);
      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalDateCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    if (type === 'Hours') {
      const totalHours = rows
        .map((row) => getValue(row))
        .filter(Number)
        .reduce((a, c) => a + c, 0);

      setTotalHours(totalHours);
      return totalHours;
    }
    if (type === 'EmployeeRef') {
      const changedRows = rows.map((row) => {
        if (!getValue(row)) return;
        return getValue(row);
      });

      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalEmployeeCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const formatlessSummaryTypes = ['Hours', 'Date', 'EmployeeRef'];

  const messages = {
    Hours: 'TotalHours',
    Date: 'TotalDays',
    EmployeeRef: 'TotalEmployees'
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Grid rows={rows} columns={columns}>
          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />
          <SummaryState totalItems={totalSummaryItems} />

          <IntegratedSummary calculator={summaryCalculator} />

          <DragDropProvider />
          <DateTypeProvider for={dateColumns} />

          <Table />
          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
          <TableHeaderRow
            contentComponent={(props) => (
              <TableHeaderContent
                {...props}
                totalEmployeeCount={totalEmployeeCount}
                totalDateCount={totalDateCount}
                totalHours={totalHours}
              />
            )}
            showSortingControls
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          <ColumnChooser />
          <TableSummaryRow formatlessSummaryTypes={formatlessSummaryTypes} messages={messages} />
        </Grid>
      </Paper>
    </div>
  );
};

export default ApproveTimesheetTable;

ApproveTimesheetTable.propTypes = {
  rows: PropTypes.array
};
