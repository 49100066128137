import axios from 'axios';
import { BASE_URL } from '../components/Constant';

// get employee notification that haven't read
function getNotification() {
  const endpoint = BASE_URL + 'notification/getNotification';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//create employee notification
function createNotification(notificationData) {
  const endpoint = BASE_URL + 'notification/createNotification';

  return axios.post(
    endpoint,
    { notificationData: notificationData },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//create employee notification
function updateNotification(notificationData) {
  const endpoint = BASE_URL + 'notification/updateNotification';

  return axios.put(
    endpoint,
    { notificationData: notificationData },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//change isRead from is false to true, if notification type is notificationStatusChange
function updateNotificationIsRead(notificationIds) {
  const endpoint = BASE_URL + 'notification/updateNotificationIsRead';

  return axios.put(
    endpoint,
    { notificationIds: notificationIds },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

const notificationService = {
  getNotification,
  createNotification,
  updateNotification,
  updateNotificationIsRead
};

export default notificationService;
