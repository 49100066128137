import { format } from 'date-fns';

export function formattedEmployeeQualification(employeeQualificationData, qboQualificationData) {
  if (!employeeQualificationData.length) {
    const filteredQualificationData = qboQualificationData.filter(
      (qboQualification) => qboQualification.name === 'OH&S (White Card)' || qboQualification.name === 'Driver License'
    );
    return filteredQualificationData.map((qboQualification) => {
      if (qboQualification.name === 'OH&S (White Card)') return { ohsCardQualification: ohsCard(qboQualification) };
      if (qboQualification.name === 'Driver License')
        return { driverLicenseQualification: driverLicense(qboQualification) };
    });
  }

  return employeeQualificationData.map((qualification) => {
    if (qualification.name === 'OH&S (White Card)') return { ohsCardQualification: ohsCard(qualification) };
    if (qualification.name === 'Driver License') return { driverLicenseQualification: driverLicense(qualification) };
  });

  // qboQualificationData.map((qualification) => {});
}

export function getQualificationObject(qualificationName, qualificationData) {
  if (qualificationName === 'OH&S (White Card)') return ohsCard(qualificationData);
  if (qualificationName === 'Driver License') return driverLicense(qualificationData);
  if (qualificationName === 'First Aid') return firstAid(qualificationData);
}

export function ohsCard(qualification) {
  const ohsCardQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification?.documents ? qualification?.documents[0]?.id : null,
    ohs_cardNumber: qualification.referenceNumber || '',
    ohs_completionDate: qualification.issueDate ? format(new Date(qualification.issueDate), 'yyyy-MM-dd') : '',
    ohs_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
    //ohs_photoOfCard: `data:${qualification.content?.contentType};base64,${qualification.content?.bytes}` || ''
  };
  return ohsCardQualification;
}

//driverLicense content: License number, expiry date, photo of card
export function driverLicense(qualification) {
  const driverLicenseQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    driverLicense_licenseNumber: qualification.referenceNumber || '',
    driverLicense_licenseExpiry: qualification.expiryDate
      ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd')
      : '',
    driverLicense_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
    // driverLicense_photoOfCard: `data:${qualification.content?.contentType};base64,${qualification.content?.bytes}` || ''
  };
  return driverLicenseQualification;
}

//workingWithChildren content: Card Number , Expiration Date, Photo of card
export function workingWithChildren(qualification) {
  const workingWithChildrenQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    workingWithChildren_cardNumber: qualification.referenceNumber || '',
    workingWithChildren_expiryDate: qualification.expiryDate
      ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd')
      : '',
    workingWithChildren_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return workingWithChildrenQualification;
}

// ewp contains:  License Number, Expiration Date, EWP Type, Photo of card
export function ewp(qualification) {
  const ewpCardQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    ewp_licenseNumber: qualification.referenceNumber || '',
    ewp_completionDate: qualification.issueDate ? format(new Date(qualification.issueDate), 'yyyy-MM-dd') : '',
    ewp_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return ewpCardQualification;
}

//Long Service Leave content: Card Number, Photo of card
export function longServiceLeave(qualification) {
  const longServiceLeaveQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    longServiceLeave_cardNumber: qualification.referenceNumber || '',
    longServiceLeave_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return longServiceLeaveQualification;
}

//Superannuation content: Member number ,Photo of card
export function superannuation(qualification) {
  const superannuationQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    superannuation_memberNumber: qualification.referenceNumber || '',
    superannuation_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return superannuationQualification;
}

//Redundancy content Card Number Photo of card
export function redundancy(qualification) {
  const redundancyCardQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    redundancy_cardNumber: qualification.referenceNumber || '',
    redundancy_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return redundancyCardQualification;
}

//Union content: Card number, Expiration date, Photo of card
export function unionCard(qualification) {
  const unionCardQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    unionCard_cardNumber: qualification.referenceNumber || '',
    unionCard_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    unionCard_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return unionCardQualification;
}

//A Grade  content: Card number,  Expiration date, Photo of card
export function aGrade(qualification) {
  const aGradeCardQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    aGrade_cardNumber: qualification.referenceNumber || '',
    aGrade_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    aGrade_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return aGradeCardQualification;
}

//Refrigeration Handling License content Card Number, Expiration Date, Type, Photo of card
export function refrigerationHandlingLicense(qualification) {
  const refrigerationHandlingLicenseQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    refrigerationHandlingLicense_cardNumber: qualification.referenceNumber || '',
    refrigerationHandlingLicense_expiryDate: qualification.expiryDate
      ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd')
      : '',
    refrigerationHandlingLicense_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return refrigerationHandlingLicenseQualification;
}

// VBA License (Plumbing) content: Card Number,  Expiration date, Photo of card (Front), Photo of card (Back)
//front and back image are composite
export function vbaLicense(qualification) {
  const vbaLicenseQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    vbaLicense_cardNumber: qualification.referenceNumber || '',
    vbaLicense_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    vbaLicense_photoOfCardFrontAndBack: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return vbaLicenseQualification;
}

//Plant Licence content: Type, Card Number, Expiration Date,Photo of card
export function plantLicense(qualification) {
  const plantLicenseQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    plantLicense_cardNumber: qualification.referenceNumber || '',
    plantLicense_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    plantLicense_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return plantLicenseQualification;
}

//Police Check content: Number, Issue Date, Expiration Date, Photo of check
export function policeCheck(qualification) {
  const policeCheckQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    policeCheck_number: qualification.referenceNumber || '',
    policeCheck_issueDate: qualification.issueDate ? format(new Date(qualification.issueDate), 'yyyy-MM-dd') : '',
    policeCheck_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    policeCheck_photoOfCheck: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return policeCheckQualification;
}

//First Aid content: Type, Number, Issue Date, Expiration Date, Photo of First aid
export function firstAid(qualification) {
  const firstAidQualification = {
    qualificationName: qualification.name || '',
    qualificationId: qualification.qualificationId || qualification.id || null,
    documentId: qualification.documents ? qualification.documents[0]?.id : null,
    firstAid_licenseNumber: qualification.referenceNumber || '',
    firstAid_issueDate: qualification.issueDate ? format(new Date(qualification.issueDate), 'yyyy-MM-dd') : '',
    firstAid_expiryDate: qualification.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
    firstAid_photoOfCard: qualification.content?.bytes
      ? base64toBlob(qualification.content?.bytes, qualification.content?.contentType, qualification.content?.filename)
      : null
  };
  return firstAidQualification;
}

// convert base64 to blob, to show in img //incoming from backend
export const base64toBlob = (base64String, mimeType, filename) => {
  // eslint-disable-next-line no-undef
  const byteCharacters = atob(base64String);
  // eslint-disable-next-line no-undef
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob([byteArray], { type: mimeType });
  blob.name = filename;
  return blob;
};
