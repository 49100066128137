import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import employeeQualificationService from '../../../../services/EmployeeQualificationService';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import { base64toBlob } from '../../../MyQualification/helper';
import { format } from 'date-fns';

const Qualification = ({ qualificationId, KeyPayEmployeeId, displayName }) => {
  const [employeeQualification, setEmployeeQualification] = useState({
    expiryDate: '',
    issueDate: '',
    fileContent: null,
    cardNumber: ''
  });
  const [message, setMessage] = useState('');
  useEffect;

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        const qualificationPayload = {
          // eslint-disable-next-line no-undef
          token: localStorage.getItem('token'),
          qualificationId: qualificationId,
          KeyPayEmployeeId: KeyPayEmployeeId
        };

        const response = await employeeQualificationService.getEmployeeQualificationById(qualificationPayload);

        if (response?.status === 200 && response?.data) {
          const qualification = response?.data?.data;
          const photoOfCard = base64toBlob(
            qualification.content?.bytes,
            qualification.content?.contentType,
            qualification.content?.filename
          );
          setEmployeeQualification({
            expiryDate: qualification?.expiryDate ? format(new Date(qualification.expiryDate), 'yyyy-MM-dd') : '',
            issueDate: qualification?.issueDate ? format(new Date(qualification.issueDate), 'yyyy-MM-dd') : '',
            // eslint-disable-next-line no-undef
            fileContent: photoOfCard ? URL.createObjectURL(photoOfCard) : '',
            cardNumber: qualification?.referenceNumber || ''
          });
        } else {
          setMessage(response?.message?.message || `${displayName} hasn't updated this qualification`);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setMessage(
            error?.response?.data?.message ||
              error?.response?.data?.message?.message ||
              `${displayName} hasn't updated this qualification`
          );
        } else {
          setMessage(error?.response?.data?.message?.message || `${displayName} hasn't updated this qualification`);
        }
      }
    };
    fetchEmployeeQualificationById();
  }, [KeyPayEmployeeId, qualificationId, displayName]);

  return (
    <div>
      {message ? (
        <p style={{ padding: '5px 10px', color: 'red' }}>{message}</p>
      ) : (
        <Grid container spacing={3}>
          {employeeQualification?.cardNumber && (
            <Grid item xs={12} sm={4}>
              <TextField
                name="cardNumber"
                type="text"
                fullWidth
                variant="outlined"
                value={employeeQualification?.cardNumber || ''}
              />
            </Grid>
          )}
          {employeeQualification?.expiryDate && (
            <Grid item xs={12} sm={4}>
              <TextField
                name="expiryDate"
                type="date"
                fullWidth
                variant="outlined"
                value={employeeQualification?.expiryDate || ''}
              />
            </Grid>
          )}
          {employeeQualification?.issueDate && (
            <Grid item xs={12} sm={4}>
              <TextField
                name="issueDate"
                type="date"
                fullWidth
                variant="outlined"
                value={employeeQualification?.issueDate || ''}
              />
            </Grid>
          )}
          {employeeQualification?.fileContent ? (
            <Grid item xs={12} sm={4}>
              <img
                name="fileContent"
                type="date"
                src={employeeQualification?.fileContent || ''}
                alt="photo of the card"
                style={{ width: '50%', height: '50%', objectFit: 'contain' }}
              />
            </Grid>
          ) : (
            <CircularProgress size={30} style={{ marginLeft: '1rem' }} />
          )}
        </Grid>
      )}
    </div>
  );
};

export default Qualification;
Qualification.propTypes = {
  qualificationId: PropTypes.any,
  KeyPayEmployeeId: PropTypes.any,
  displayName: PropTypes.any
};
