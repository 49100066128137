/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { unionCard } from '../helper';
import { unionCardSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2)
  }
}));

const UnionCardQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [unionCardQualification, setUnionCardQualification] = useState({
    unionCard_cardNumber: '',
    unionCard_expiryDate: '',
    unionCard_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlUnionCard,
    handleSubmit: handleUnionCardSubmit,
    reset: resetUnionCard,
    getValues: getUnionCardValues,
    formState: { errors: errorsUnionCard, dirtyFields: dirtyFieldsUnionCard }
  } = useForm({
    resolver: zodResolver(unionCardSchema),
    defaultValues: unionCardQualification
  });

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        setSubmitting(true);

        if (expanded === 'panel11') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName('Union Card');
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let unionCardQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'Union Card').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'Union Card')[0];
            unionCardQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              unionCard_cardNumber: '',
              unionCard_expiryDate: '',
              unionCard_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'Union Card')[0];
            unionCardQualification = unionCard(filteredQualification);
          }
          setUnionCardQualification(unionCardQualification);
          resetUnionCard(unionCardQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get Union Card');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualificationById();
  }, [resetUnionCard, expanded]);

  const onSubmitUnionCard = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getUnionCardValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        expiryDate: dirtyValues.unionCard_expiryDate,
        referenceNumber: dirtyValues.unionCard_cardNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsUnionCard.unionCard_photoOfCard) {
        formData.append('avatar', dirtyValues.unionCard_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }
      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedUnionCard = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   unionCard_cardNumber: dataObj.referenceNumber || '',
          //   unionCard_expiryDate: dataObj.expiryDate ? format(new Date(dataObj.expiryDate), 'yyyy-MM-dd') : '',
          //   unionCard_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetUnionCard(updatedUnionCard);
          // toast.success('Successfully updated Union Card');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update Union Card');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleUnionCardSubmit(onSubmitUnionCard)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> Card Number</h5>
              <Controller
                name="unionCard_cardNumber"
                control={controlUnionCard}
                render={({ field }) => (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsUnionCard.unionCard_cardNumber}
                    helperText={errorsUnionCard.unionCard_cardNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> License Expiry</h5>
              <Controller
                name="unionCard_expiryDate"
                control={controlUnionCard}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsUnionCard.unionCard_expiryDate}
                    helperText={errorsUnionCard.unionCard_expiryDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card</h5>
              <Controller
                name="unionCard_photoOfCard"
                control={controlUnionCard}
                render={({ field }) => (
                  <div>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsUnionCard.unionCard_photoOfCard && (
                      <p style={{ color: 'red' }}>{errorsUnionCard?.unionCard_photoOfCard?.message}</p>
                    )}
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>Union Card</b> update request to the admin. You will get notified once it
                  gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default UnionCardQualification;

UnionCardQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
