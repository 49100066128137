import React, { useEffect, useState } from 'react';
import { startOfWeek, lastDayOfWeek, addDays } from 'date-fns';

import ApproveTimesheetTable from './table/ApproveTimesheetTable';
import timesheetService from '../../../services/TimesheetService';
import { getFormattedTableRows } from '../../MyTime/table/helpers';
import ApproveTimesheetStatus from './header/ApproveTimesheetStatus';
import ApproveTimesheetFilter, { timesheetPeriodOptions } from './filter/ApproveTimesheetFilter';
import useFetchApproveTimesheetStatus from './hooks/useFetchApproveTimesheetStatus';

export default function ApproveTimesheet() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [rows, setRows] = useState([]);

  const [filter, setFilter] = useState({
    timesheetPeriod: timesheetPeriodOptions[0],
    startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
    timesheetStatus: 'All'
  });

  const { timesheetStatusList } = useFetchApproveTimesheetStatus(filter, setRows);

  useEffect(() => {
    const fetchCurrentWeekTimesheet = async () => {
      try {
        setLoading(true);
        setError(null);

        const employeeTimesheet = await timesheetService.getEmployeeSubmittedTimesheet();
        const emplployeeTimesheetRecords = employeeTimesheet.data.data;

        const formattedTimesheet = getFormattedTableRows(emplployeeTimesheetRecords);

        const filteredTimesheet = formattedTimesheet.filter((row) => {
          if (filter.timesheetStatus !== 'All') {
            if (
              filter.timesheetStatus === row.status &&
              (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
              new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
            ) {
              return true;
            }

            return false;
          }
          if (
            (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
            new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }
          return false;
        });

        setRows(filteredTimesheet);
      } catch (e) {
        setError(e.message || 'Could not fetch timesheet data');
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentWeekTimesheet();
  }, [filter.endDate, filter.startDate, filter.timesheetStatus]);

  return (
    <div>
      <ApproveTimesheetStatus timesheetStatusList={timesheetStatusList} filter={filter} setFilter={setFilter} />
      <ApproveTimesheetFilter filter={filter} setFilter={setFilter} />

      <ApproveTimesheetTable rows={rows} />
    </div>
  );
}
