import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import ReactSelect from 'react-select';
import assetService from '../../../services/AssetService';

import PropTypes from 'prop-types';
import notificationService from '../../../services/NotificationService';
import { isNotificationDataValid } from '../table/helper';
import toast from 'react-hot-toast';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import employeeService from '../../../services/EmployeeService';

const AssetRequestSelect = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [assetRecords, setAssetRecords] = useState();
  const [assetsError, setAssetError] = useState(null);
  const [assetLoading, setAssetLoading] = useState(false);

  const [recipientDisplayName, setRecipientDisplayName] = useState([]);
  const [assetSelected, setAssetSelected] = useState(false);
  const [selectedAssetToRequest, setSelectedAssetToRequest] = useState();
  const [selectedColumnOption, setSelectedColumnOption] = useState();
  const [recipientType, setRecipientType] = useState(null);

  const [searchQuery, setSearchQuery] = useState(null);

  const [openMenu, setOpenMenu] = useState(false);

  const chooseColumnOptions = [
    { value: 'assetId', label: 'Asset Id' },
    { value: 'make', label: 'Make' },
    { value: 'model', label: 'Model' },
    { value: 'description', label: 'Description' },
    { value: 'supplier', label: 'Supplier' },
    { value: 'category', label: 'Category' }
  ];

  useEffect(() => {
    const fetchAssetRecords = async () => {
      try {
        const assets = await assetService.getAssets();
        const assetRecords = assets.data.data;

        const filteredAsset = assetRecords.filter((a) => a.description);

        setAssetRecords(filteredAsset);
      } catch (e) {
        setAssetError(e.message || 'Could not fetch asset records');
      } finally {
        setAssetLoading(false);
      }
    };
    fetchAssetRecords();
  }, []);

  //if assetStatus are onRequest they won't be available to choose,
  const assetRecordOptions = assetRecords?.map((a) => {
    // eslint-disable-next-line no-undef
    const employeeDisplayName = localStorage.getItem('name');
    if (a.assetStatus === 'onRequest' || employeeDisplayName === a.operator) {
      return {
        value: a[selectedColumnOption],
        label: a.assetStatus ? `${a[selectedColumnOption]} (${a.assetStatus || ''})` : a[selectedColumnOption],
        id: a.assetId,
        isDisabled: true
      };
    }

    return { value: a[selectedColumnOption], label: `${a[selectedColumnOption]} ${a.description}`, id: a.assetId };
  });

  //choose asset to request for approval from admin or other users
  const handleSelectedAssetItem = async (selected) => {
    setAssetSelected(true);
    setSelectedAssetToRequest(selected);

    const assetMatchedEmployee = assetRecords?.filter((a) => a.assetId === selected?.value)[0];

    if (!assetMatchedEmployee?.operator || assetMatchedEmployee?.operator === 'BOXSHALL GROUP') {
      //first fetch all the employee
      const data = await employeeService.getAdmins();
      const adminRecords = data.data.data;

      const adminNames = [];

      adminRecords.forEach((item) => {
        adminNames.push(item.name);
      });

      setRecipientType('admin');

      setRecipientDisplayName(adminNames);
    } else {
      setRecipientDisplayName([assetMatchedEmployee.operator]);
    }
  };

  //once asset is choose send approval request to admin or other user that matches the DisplayName with operator in asset
  const handleSendRequestToEmployee = (e) => {
    e.preventDefault();
    //who is requesting the asset
    // eslint-disable-next-line no-undef
    const senderDisplayName = localStorage.getItem('name');

    const assetId = assetRecords
      ?.filter((r) => r.assetId === selectedAssetToRequest.value)
      .map((r) => {
        return { assetId: r.assetId, description: r.description };
      });

    console.log('assetId', assetId);

    const notificationData = {
      assetId: assetId,
      senderDisplayName: senderDisplayName,
      recipientDisplayName: recipientDisplayName,
      isRead: false,
      type: 'assetApprovalRequest',
      notificationStatus: 'pending'
    };

    console.log('notificationData', notificationData);

    const validNotificationData = isNotificationDataValid(notificationData);
    console.log('validNotificationData', validNotificationData);

    if (!validNotificationData) {
      return toast.error('Recipient not found');
    }

    notificationService
      .createNotification(notificationData)
      .then((data) => {
        if (recipientDisplayName.length > 1) {
          return toast.success('Successfully sent request to admin');
        }
        if (recipientDisplayName.length === 1) {
          toast.success(`Successfully sent request to ${recipientDisplayName[0]}` || 'Successfully sent request');
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Asset request hasn't sent");
      })
      .finally(() => {
        setRecipientDisplayName([]);
        setAssetSelected(false);
        setRecipientType(null);
      });
  };

  const handleInputChange = (query, { action }) => {
    setSearchQuery(query);
    if (action === 'input-change' && query.length >= 3) {
      // if (action === 'input-change') {
      setOpenMenu(true);
    }
  };

  const hideMenu = () => {
    setOpenMenu(false);
  };

  const handleSelectedColumnOption = (selectedColumnOption) => {
    setSelectedColumnOption(selectedColumnOption?.value);
  };

  return (
    <Grid
      container
      spacing={isSmall ? 1 : 2}
      style={{
        marginBottom: '4rem'
      }}
    >
      <Grid item xs={isSmall ? 11 : 'auto'} style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
        Choose the asset to request by:
      </Grid>

      <Grid item xs={isSmall ? 11 : 3}>
        <ReactSelect
          className="basic-single"
          classNamePrefix="select"
          defaultValue=""
          isSearchable={true}
          isClearable={true}
          name="client"
          options={chooseColumnOptions}
          onChange={(selectedOption) => {
            if (selectedOption === null) {
              // Handle the case when the selected option is cleared
              handleSelectedColumnOption(null); // or handle it based on your requirements
            } else {
              handleSelectedColumnOption(selectedOption);
            }
          }}
        />
      </Grid>

      {selectedColumnOption && (
        <Grid item xs={isSmall ? 11 : 3}>
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue=""
            onBlur={hideMenu}
            isSearchable={true}
            isClearable={true}
            openOnClick={false}
            openMenuOnClick={false}
            openOnFocus={false}
            arrowRenderer={null}
            menuIsOpen={openMenu}
            name="chooseAsset"
            options={searchQuery?.length >= 3 ? assetRecordOptions : []}
            onChange={(selectedOption) => {
              if (selectedOption === null) {
                // Handle the case when the selected option is cleared
                handleSelectedAssetItem(null); // or handle it based on your requirements
                setAssetSelected(false);
                hideMenu;
              } else {
                handleSelectedAssetItem({ name: selectedOption.value, value: selectedOption.id });
                hideMenu();
              }
            }}
            // onChange={hideMenu}
            onInputChange={handleInputChange}
          />
        </Grid>
      )}

      {selectedColumnOption && assetSelected && recipientDisplayName.length === 1 ? (
        <Grid item xs={isSmall ? 11 : 3}>
          <Button color="primary" variant="contained" onClick={handleSendRequestToEmployee}>
            {`Send Request to ${recipientDisplayName[0]}`}
          </Button>
        </Grid>
      ) : (
        selectedColumnOption &&
        assetSelected &&
        recipientDisplayName.length > 1 &&
        recipientType !== null && (
          <Grid item xs={isSmall ? 11 : 3} style={{ display: 'flex', alignItems: 'center' }}>
            <Button color="primary" variant="contained" onClick={handleSendRequestToEmployee}>
              Send request to admin
            </Button>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default AssetRequestSelect;

AssetRequestSelect.propTypes = {
  employeeRefs: PropTypes.array
};
