import { BASE_URL } from '../components/Constant';
import axios from 'axios';

function getClassListFromDatabase() {
  const endpoint = BASE_URL + 'class/getClassFromDatabase';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

function getClassListFromQBO() {
  const endpoint = BASE_URL + 'class/getClassFromQBO';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update classStatus to show/hide class items for verd employee
function updateClassStatus(selectedClassIds, classStatus) {
  const endpoint = BASE_URL + 'class/updateClassStatus';

  return axios.put(
    endpoint,
    { selectedClassIds: selectedClassIds, classStatus: classStatus },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const classService = {
  getClassListFromDatabase,
  getClassListFromQBO,
  updateClassStatus
};

export default classService;
