import { useEffect, useState } from 'react';
import assetService from '../../../services/AssetService';
import { formattedAssets } from '../../Asset/table/hepler';

export default function useFetchEmployeeAssetRecords() {
  const [employeeAssets, setEmployeeAssets] = useState([]);
  const [employeeAssetsError, setEmployeeAssetError] = useState(null);
  const [employeeAssetLoading, setEmployeeAssetLoading] = useState(false);

  useEffect(() => {
    const fetchAssetRecords = async () => {
      try {
        setEmployeeAssetLoading(true);
        setEmployeeAssetError(null);

        const assetRecords = await assetService.getEmployeeAssets();

        const assetsList = formattedAssets(assetRecords.data.data);

        setEmployeeAssets(assetsList);
      } catch (e) {
        setEmployeeAssetError(e.message || 'Could not fetch asset records');
      } finally {
        setEmployeeAssetLoading(false);
      }
    };

    fetchAssetRecords();
  }, []);

  return { employeeAssets, setEmployeeAssets, employeeAssetLoading, employeeAssetsError };
}
