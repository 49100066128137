import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import { addDays } from 'date-fns';
import expenseService from '../../../../services/ExpenseService';
import { getFormattedTableRows } from '../../../MyExpense/table/helper';

export default function useFetchAllExpenseStatus(filter, setRows) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [expenseStatusList, setExpenseStatusList] = useState([]);

  useEffect(() => {
    const fetchAllExpense = async () => {
      try {
        const expenseRecords = await expenseService.getAllExpenses();
        const formattedExpense = getFormattedTableRows(expenseRecords.data.data);

        setLoading(false);

        const filteredRows = formattedExpense.filter((row) => {
          if (
            (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
            new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }
          if (filter.headerStatusChange) {
            if (
              (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
              new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
            ) {
              return true;
            }
          }
        });

        const withEmployeeExpense = filteredRows.filter((expense) => expense.status === 'WithEmployee');
        if (filter.expenseStatus === 'WithEmployee') {
          setRows(withEmployeeExpense);
        }
        const withApproverExpense = filteredRows.filter((expense) => expense.status === 'WithApprover');
        if (filter.expenseStatus === 'WithApprover') {
          setRows(withApproverExpense);
        }
        const approvedExpense = filteredRows.filter((expense) => expense.status === 'Approved');
        if (filter.expenseStatus === 'Approved') {
          setRows(approvedExpense);
        }

        const withEmployeeHours = withEmployeeExpense.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let withEmployeeTotalHours = 0;
        if (withEmployeeHours.length) {
          withEmployeeTotalHours = withEmployeeHours.reduce((a, c) => Number(a) + Number(c));
        }

        const withApproverHours = withApproverExpense.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let withApproverTotalHours = 0;
        if (withApproverHours.length) {
          withApproverTotalHours = withApproverHours.reduce((a, c) => Number(a) + Number(c));
        }

        const approvedHours = approvedExpense?.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let approvedTotalHours = 0;
        if (approvedHours.length) {
          approvedTotalHours = approvedHours?.reduce((a, c) => Number(a) + Number(c));
        }

        setExpenseStatusList([
          {
            label: 'WithEmployee',
            totalHours: withEmployeeTotalHours,
            totalCount: withEmployeeExpense.length
          },
          {
            label: 'WithApprover',
            totalHours: withApproverTotalHours,
            totalCount: withApproverExpense.length
          },
          {
            label: 'Approved',
            totalHours: approvedTotalHours,
            totalCount: approvedExpense.length
          },
          {
            label: 'All'
          }
        ]);
      } catch (error) {
        setError(error.message || "couldn't fetch all expense");
      } finally {
        setLoading(false);
      }
    };

    fetchAllExpense();
  }, [filter.endDate, filter.startDate, filter.expenseStatus, setRows, filter.headerStatusChange]);

  return { expenseStatusList, error, loading };
}

function totalHours(startTime, finishTime, date) {
  const startDateMs = parse(startTime, 'HH:mm', new Date(date));
  const endDateMs = parse(finishTime, 'HH:mm', new Date(date));

  const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);
  return diffHr;
}
