import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Box, Checkbox, OutlinedInput, Typography } from '@material-ui/core';
import clientService from '../../../../services/ClientService';
import { toast } from 'react-hot-toast';

const Accounting = ({ row, onCancelChanges, setCustomerTabIndex }) => {
  const [costPlusChecked, setCostPlusChecked] = useState(row.BillableStatus || false);
  const [markUpValue, setMarkUpValue] = useState(row.MarkUpPercentage);

  const handleInputChange = (e) => {
    setMarkUpValue(e.target.value);
  };

  const handleCostPlusSave = () => {
    function isMarkupPercentageValid(costPlusChecked, markUpValue) {
      if (costPlusChecked && markUpValue) return true;
      if (!costPlusChecked && !markUpValue) return true;
      return false;
    }

    if (!isMarkupPercentageValid(costPlusChecked, markUpValue)) {
      return toast.error('Markup percentage is required if cost plus is checked');
    }

    let editClientPayload = {
      MarkUpPercentage: markUpValue,
      BillableStatus: costPlusChecked,
      editId: row.id,
      costPlusChecked: costPlusChecked
    };

    clientService
      .editClient(editClientPayload)
      .then(({ data }) => {
        toast.success('Successfully updated client');
        onCancelChanges();
        setCustomerTabIndex(0);
      })
      .catch((error) => {
        toast.error('Could not edit customer.' || error);
      });
  };

  return (
    <div style={{ height: '700px' }}>
      <DialogTitle id="form-dialog-title">Billable/Variation</DialogTitle>
      <DialogContent>
        <div>
          <Checkbox
            name="superannuationChecked"
            checked={row.BillableStatus ? row.BillableStatus : costPlusChecked}
            onChange={(e) => setCostPlusChecked(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />

          <span style={{ fontWeight: '500', fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>Cost Plus</span>
        </div>
        {costPlusChecked && (
          <div style={{ paddingLeft: '6%' }}>
            <Typography component="div" m={1} style={{ marginBottom: '0.6rem' }}>
              <Box fontWeight="fontWeightMedium"> Mark up(%)</Box>
            </Typography>
            <OutlinedInput
              name="costPlus"
              margin="dense"
              value={markUpValue}
              onChange={handleInputChange}
              type="number"
              fullWidth={true}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCostPlusSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default Accounting;

Accounting.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    DisplayName: PropTypes.string,
    Email: PropTypes.string,
    Phone: PropTypes.string,
    BillableStatus: PropTypes.bool,
    MarkUpPercentage: PropTypes.number,
    costPlusChecked: PropTypes.bool
  }),
  onCancelChanges: PropTypes.func,
  setCustomerTabIndex: PropTypes.func
};
