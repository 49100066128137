import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  InputLabel,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'hidden'
  },
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex'
  }
}));

const VendorAccounting = ({ row, onCancelChanges, onChange, onApplyChanges }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [batchPaymentChecked, setBatchPaymentChecked] = useState(false);
  const [showBankAccountDetails, setShowBankAccountDetails] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    BankAccountName: '',
    BankAccountNumber: '',
    BankBranchIdentifier: '',
    StatementText: ''
  });

  useEffect(() => {
    if (row.BankAccountNumber !== null && row.BankAccountNumber !== '' && row.BankAccountNumber !== undefined) {
      setShowBankAccountDetails(true);
    }
  }, [row.BankAccountNumber]);

  //to handle selected expense
  const handleSelectedExpense = () => {};

  const handleBankAccountDetails = (e) => {
    setShowBankAccountDetails(e.target.checked);
    if (!e.target.checked) {
      setBankDetails({
        ...bankDetails,
        BankAccountName: '',
        BankAccountNumber: '',
        BankBranchIdentifier: '',
        StatementText: ''
      });
    }
  };

  const updateSupplierAccountingInQBo = () => {};

  return (
    <div style={{ height: '700px' }}>
      <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: isSmall ? '1.6rem' : '3rem', marginBottom: '0px', paddingBottom: '0px', lineHeight: '1' }}
      >
        Accounting
      </DialogTitle>

      <DialogContent className={classes.root}>
        <Grid container spacing={3}>
          <Grid item container xs={12} mt={3}>
            <Paper style={{ paddingLeft: isSmall ? '' : theme.spacing(3), paddingBottom: theme.spacing(3) }}>
              {/* header */}

              <Grid item container xs={12} spacing={2} style={{ paddingLeft: isSmall ? '0.7rem' : '1rem' }}>
                {/* Australian Business Number  */}
                <Grid item xs={12} className={classes.iconHeader}>
                  <Typography fontSize={16}>Taxes</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginRight: isSmall ? '' : '1.4 rem' }}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Australian Business Number
                  </InputLabel>
                  <BootstrapInput
                    id="bootstrap"
                    name="TaxIdentifier"
                    value={row.TaxIdentifier || ''}
                    onChange={onChange}
                    style={{ width: ' 100%', paddingRight: isSmall ? '0' : '1.8rem' }}
                    type="text"
                  />
                </Grid>

                {/* batch payment checked */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={batchPaymentChecked}
                    onChange={() => setBatchPaymentChecked(!batchPaymentChecked)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ paddingLeft: '0px' }}
                  />
                  <p style={{ padding: '0', margin: '0', fontSize: '14px', lineHeight: '1' }}>
                    Include this supplier in my annual TPAR
                  </p>
                </Grid>

                {/* Expense rates */}
                <Grid item xs={12} className={classes.iconHeader}>
                  <Typography fontSize={16}>Expense rates</Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginRight: isSmall ? '' : '1.4 rem' }}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Cost rate (/hr){' '}
                  </InputLabel>
                  <BootstrapInput
                    id="bootstrap"
                    name="CostRate"
                    value={row.CostRate || ''}
                    onChange={onChange}
                    className={classes.bootstrapInput}
                    type="number"
                  />
                </Grid>

                {/*  Billing rate (/hr) */}
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Billing rate (/hr)
                  </InputLabel>
                  <BootstrapInput
                    name="BillRate"
                    value={row.BillRate || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                    type="number"
                  />
                </Grid>

                {/* Payments */}
                <Grid item xs={12} className={classes.iconHeader}>
                  <Typography fontSize={16}>Payments</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Terms
                  </InputLabel>
                  <BootstrapInput
                    name="TermRef"
                    value={row.TermRef || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* Account no.*/}
                <Grid item xs={12} sm={6}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Account no.
                  </InputLabel>
                  <BootstrapInput
                    name="AcctNum"
                    value={row.AcctNum || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                    type="number"
                  />
                </Grid>

                {/* I create batch payments for this supplier checked */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingBottom: '0' }}>
                  <Checkbox
                    checked={showBankAccountDetails}
                    onChange={handleBankAccountDetails}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ paddingLeft: '0px' }}
                  />
                  <p style={{ padding: '0', margin: '0', fontSize: '14px', lineHeight: '1rem' }}>
                    I create batch payments for this supplier
                  </p>
                </Grid>

                {/* bank details */}
                {showBankAccountDetails && (
                  <Grid item container spacing={2}>
                    {/* BSB * */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        BSB *
                      </InputLabel>
                      <BootstrapInput
                        name="BankBranchIdentifier"
                        value={row?.BankBranchIdentifier.split('-').join('') || ''}
                        onChange={onChange}
                        id="bootstrap"
                        className={classes.bootstrapInput}
                        type="number"
                      />
                    </Grid>

                    {/* BankAccountName */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Bank account name *
                      </InputLabel>
                      <BootstrapInput
                        name="BankAccountName"
                        value={row.BankAccountName || ''}
                        onChange={onChange}
                        id="bootstrap"
                        className={classes.bootstrapInput}
                      />
                    </Grid>

                    {/* BankAccountNumber */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Bank account number *
                      </InputLabel>
                      <BootstrapInput
                        name="BankAccountNumber"
                        value={row.BankAccountNumber || ''}
                        onChange={onChange}
                        id="bootstrap"
                        className={classes.bootstrapInput}
                      />
                    </Grid>

                    {/* StatementText */}
                    <Grid item xs={12} sm={6}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        Statement text *
                      </InputLabel>
                      <BootstrapInput
                        name="StatementText"
                        value={row.StatementText || ''}
                        onChange={onChange}
                        id="bootstrap"
                        className={classes.bootstrapInput}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Accounting/ expenses list */}
                <Grid item xs={12} className={classes.iconHeader}>
                  <Typography fontSize={16}>Accounting/Expenses</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingRight: isSmall ? '' : '1.8rem' }}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Default expense category
                  </InputLabel>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'myTimeFormData.task'}
                    isSearchable={true}
                    name="task"
                    options={'classRefOptions'}
                    isDisabled
                    onChange={(selectedOption) => {
                      return handleSelectedExpense({ name: selectedOption.value, value: selectedOption.id });
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default VendorAccounting;

VendorAccounting.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func,
  onChange: PropTypes.func,
  onApplyChanges: PropTypes.func
};
