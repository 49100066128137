import * as z from 'zod';

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
const maxFileSize = 3 * 1024 * 1024;

const imageSchema = z.object({
  name: z.string(),
  size: z.number(),
  type: z.string()
});

const imageArraySchema = z.array(imageSchema);

// Define your form schema using Zod
export const ohsCardSchema = z.object({
  ohs_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  ohs_completionDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  ohs_photoOfCard: imageSchema
});

export const driverLicenseSchema = z.object({
  driverLicense_licenseNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  driverLicense_licenseExpiry: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  driverLicense_photoOfCard: imageSchema
});

export const workingWithChildrenSchema = z.object({
  workingWithChildren_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  workingWithChildren_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  workingWithChildren_photoOfCard: imageSchema
});

export const ewpSchema = z.object({
  ewp_licenseNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  ewp_completionDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  ewp_photoOfCard: imageSchema
});

export const longServiceLeaveSchema = z.object({
  longServiceLeave_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  longServiceLeave_photoOfCard: imageSchema
});

export const superannuationSchema = z.object({
  superannuation_memberNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  superannuation_photoOfCard: imageSchema
});

export const redundancySchema = z.object({
  redundancy_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  redundancy_photoOfCard: imageSchema
});

export const unionCardSchema = z.object({
  union_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  union_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  union_photoOfCard: imageSchema
});

export const aGradeSchema = z.object({
  aGrade_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  aGrade_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  aGrade_photoOfCard: imageSchema
});

export const refrigerationHandlingLicenseSchema = z.object({
  refrigerationHandlingLicense_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  refrigerationHandlingLicense_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  refrigerationHandlingLicense_photoOfCard: imageSchema
});

export const vbaLicenseSchema = z.object({
  vbaLicense_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  vbaLicense_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  // eslint-disable-next-line no-undef
  vbaLicense_photoOfCardFrontAndBack: z.union([imageSchema, z.instanceof(FileList)])
});

export const plantLicenseSchema = z.object({
  plantLicense_cardNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  plantLicense_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  plantLicense_photoOfCard: imageSchema
});

export const policeCheckSchema = z.object({
  policeCheck_number: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  policeCheck_issueDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  policeCheck_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  policeCheck_photoOfCheck: imageSchema
});

export const firstAidSchema = z.object({
  firstAid_licenseNumber: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  firstAid_issueDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  firstAid_expiryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  firstAid_photoOfCard: imageSchema
});
