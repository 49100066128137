import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import AddExpenseDialogContent from './AddExpenseDialogContent';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {
  formattedExpensePayload,
  isBillableStatusValid,
  isCustomerRefValid,
  isExpenseRowValid,
  isGlobalTaxCalculationValid,
  isPaidByCompanyValid,
  isPaidByValid
} from './helper';
import { toast } from 'react-hot-toast';
import expenseService from '../../../services/ExpenseService';
import ButtonMenu from '../../MyTime/header/ButtonMenu';
import { getFormattedTableRows } from '../table/helper';

const AddExpenseDialog = ({
  open,
  customerRefs,
  classRefs,
  itemRefs,
  vendorRefs,
  creditCardAccount,
  handleClose,
  expenseFormData,
  setExpenseFormData,
  setExpenseRecords,
  expenseRecords,
  expenseRow,
  setExpenseRow
}) => {
  const [todayDate, setTodayDate] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [timesheetSavingOptions, setTimesheetSavingOptions] = useState([]);

  useEffect(() => {
    let todayDate = format(new Date(), 'yyyy-MM-dd');
    setTodayDate(todayDate);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let options = localStorage.getItem('timesheetSavingOptions');
    if (options && options.length) {
      setTimesheetSavingOptions(JSON.parse(options));
    }

    if (!options) {
      options = [
        { id: 1, value: 'Save as draft' },
        { id: 2, value: 'Save and submit' }
      ];
      setTimesheetSavingOptions(options);
      // eslint-disable-next-line no-undef
      localStorage.setItem('timesheetSavingOptions', JSON.stringify(options));
    }
  }, [setTimesheetSavingOptions]);

  const handleSaveAsDraftExpenseForm = () => {
    if (!isPaidByValid(expenseFormData.paidByEmployee, expenseFormData.paidByCompany)) {
      toast.error('Please tick Paid by employee or company');
      return;
    }
    const validateRows = isExpenseRowValid(expenseRow);
    if (!validateRows.valid) {
      return toast.error(validateRows?.message || 'Please fill all the fields of expense table');
    }

    if (!isCustomerRefValid(expenseFormData?.client)) {
      return toast.error('Client should not be empty');
    }

    if (!isGlobalTaxCalculationValid(expenseFormData)) {
      return toast.error('Must choose Exc/Inc of Tax');
    }

    if (!isPaidByCompanyValid(expenseFormData)) {
      return toast.error('If paidByCompany must choose Supplier and Account');
    }

    if (!isBillableStatusValid(expenseFormData.isBillable, expenseFormData.variationNote)) {
      toast.error(`'Billable' status must have a variation note`);
      setExpenseRow(expenseRow);

      return;
    }

    if (!expenseRow.length) {
      return toast.error('Expense table is not filled');
    }

    if (!expenseFormData.expenseReceiptFile) {
      toast.error('Must have expense receipt to add expense');
      return;
    }

    const expensePayload = formattedExpensePayload(expenseFormData, expenseRow);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    console.log('expensePayload', expensePayload);
    formData.append('expensePayload', JSON.stringify(expensePayload));
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    if (expenseFormData.expenseReceiptFile) {
      const file = expenseFormData.expenseReceiptFile[0];
      formData.append('file', file);
    }

    expenseService
      .saveAsDraftExpense(formData)
      .then((response) => {
        let expenseData = response.data.data;
        const formattedExpense = getFormattedTableRows(expenseData);
        setExpenseRecords([...formattedExpense, ...expenseRecords]);
        setExpenseFormData({
          paidByEmployee: false,
          paidByCompany: false,
          creditCardAccount: '',
          vendor: '',
          date: todayDate,
          client: '',
          task: '',
          item: '',
          qty: '',
          price: '',
          isBillable: false,
          description: '',
          variationNote: '',
          expenseReceiptFile: '',
          BillNumber: '',
          globalTaxCalculation: '',
          purchaseOrder: ''
        });
        setExpenseRow([]);
        handleClose();
        setAnchorEl(false);

        toast.success(response?.data?.data?.message || 'Successfully created expense');
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not create expense');
      });
  };

  const handleOptions = async (e) => {
    e.preventDefault();
    if (
      (e.target.innerText !== timesheetSavingOptions[0].value &&
        e.target.innerText !== timesheetSavingOptions[0].value.toUpperCase()) ||
      (e.target.textContent !== timesheetSavingOptions[0].value &&
        e.target.textContent !== timesheetSavingOptions[0].value.toUpperCase())
    ) {
      const updatedButtonList = timesheetSavingOptions.reverse();
      // eslint-disable-next-line no-undef
      localStorage.setItem('timesheetSavingOptions', JSON.stringify(updatedButtonList));
      setTimesheetSavingOptions(updatedButtonList);
    }
    if (
      e.target.innerText === 'Save as draft' ||
      e.target.innerText === 'SAVE AS DRAFT' ||
      e.target.textContent === 'Save as draft' ||
      e.target.textContent === 'SAVE AS DRAFT'
    ) {
      handleSaveAsDraftExpenseForm();
    }
    if (
      e.target.innerText === 'Save and submit' ||
      e.target.innerText === 'SAVE AND SUBMIT' ||
      e.target.textContent === 'Save and submit' ||
      e.target.textContent === 'SAVE AND SUBMIT'
    ) {
      saveAndSubmitExpenseForm();
    }
  };

  const saveAndSubmitExpenseForm = () => {
    console.log('efa', expenseRow);
    if (!isPaidByValid(expenseFormData.paidByEmployee, expenseFormData.paidByCompany)) {
      toast.error('Please tick Paid by employee or company');
      return;
    }
    const validateRows = isExpenseRowValid(expenseRow);
    if (!validateRows.valid) {
      return toast.error(validateRows?.message || 'Please fill all the fields of expense table');
    }

    if (!isCustomerRefValid(expenseFormData?.client)) {
      return toast.error('Client should not be empty');
    }

    if (!isGlobalTaxCalculationValid(expenseFormData)) {
      return toast.error('Must choose Exc/Inc of Tax');
    }

    if (!isPaidByCompanyValid(expenseFormData)) {
      return toast.error('If paidByCompany must choose Supplier and Account');
    }

    if (!isBillableStatusValid(expenseFormData.isBillable, expenseFormData.variationNote)) {
      toast.error(`'Billable' status must have a variation note`);
      setExpenseRow(expenseRow);

      return;
    }

    if (!expenseRow.length) {
      return toast.error('Expense table is not filled');
    }

    if (!expenseFormData.expenseReceiptFile) {
      toast.error('Must have expense receipt to add expense');
      return;
    }

    const expensePayload = formattedExpensePayload(expenseFormData, expenseRow);

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    console.log('expensePayload', expensePayload);
    formData.append('expensePayload', JSON.stringify(expensePayload));
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    if (expenseFormData.expenseReceiptFile) {
      const file = expenseFormData.expenseReceiptFile[0];
      formData.append('file', file);
    }

    expenseService
      .saveAndSubmitExpense(formData)
      .then((response) => {
        let expenseData = response.data.data;
        const formattedExpense = getFormattedTableRows(expenseData);
        setExpenseRecords([...formattedExpense, ...expenseRecords]);
        setExpenseFormData({
          paidByEmployee: false,
          paidByCompany: false,
          creditCardAccount: '',
          vendor: '',
          date: todayDate,
          client: '',
          task: '',
          item: '',
          qty: '',
          price: '',
          isBillable: false,
          description: '',
          variationNote: '',
          expenseReceiptFile: '',
          BillNumber: '',
          globalTaxCalculation: '',
          purchaseOrder: ''
        });
        setExpenseRow([]);
        handleClose();
        setAnchorEl(false);

        toast.success(response?.data?.data?.message || 'Successfully created expense');
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not create expense');
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">Add Expense</DialogTitle>
        <DialogContent>
          <AddExpenseDialogContent
            expenseFormData={expenseFormData}
            customerRefs={customerRefs}
            classRefs={classRefs}
            setExpenseFormData={setExpenseFormData}
            itemRefs={itemRefs}
            vendorRefs={vendorRefs}
            creditCardAccount={creditCardAccount}
            rows={expenseRow}
            setRows={setExpenseRow}
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: '1.5rem', paddingBottom: '2rem' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <div style={{ display: 'flex', backgroundColor: '#4040a1', borderRadius: '4px' }}>
            <Button
              onClick={timesheetSavingOptions[0]?.id !== 1 ? handleOptions : handleSaveAsDraftExpenseForm}
              color="primary"
              variant="contained"
              type="submit"
              style={{ borderRight: '1px solid white', borderRadius: '4px 0 0 4px' }}
            >
              {timesheetSavingOptions.length ? timesheetSavingOptions[0].value : ''}
            </Button>
            <ButtonMenu
              buttonList={timesheetSavingOptions.filter((t, index) => index !== 0)}
              handleOptions={handleOptions}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              style={{ backgroundColor: 'blue' }}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExpenseDialog;

AddExpenseDialog.propTypes = {
  open: PropTypes.bool,
  customerRefs: PropTypes.array,
  classRefs: PropTypes.array,
  itemRefs: PropTypes.array,
  setAddExpenseOption: PropTypes.func,
  vendorRefs: PropTypes.array,
  creditCardAccount: PropTypes.array,
  handleClose: PropTypes.func,
  expenseFormData: PropTypes.any,
  setExpenseFormData: PropTypes.func,
  setExpenseRecords: PropTypes.func,
  expenseRecords: PropTypes.array,
  expenseRow: PropTypes.array,
  setExpenseRow: PropTypes.func
};
