import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import QualificationList from './QualificationList';
import toast, { Toaster } from 'react-hot-toast';
import { CircularProgress } from '@material-ui/core';

//qualification/:employeeId ,
// list all the updated qualifications of user with each have three div:
//1. existing qualification details, 2. updated qualification details, 3. actions: accept and reject buttons
//once accept/reject button is clicked , toast the message, then list other updated qualifications

const AdminQualification = () => {
  const { employeeId } = useParams();
  const [qualificationRecord, setQualificationRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeUpdatedQualifications = async () => {
      try {
        setIsLoading(true);
        const employeeUpdatedQualifications = await employeeQualificationService.getEmployeeUpdatedQualifications(
          employeeId
        );

        const qualificationRecord = employeeUpdatedQualifications?.data?.data;

        if (qualificationRecord.length) {
          setQualificationRecord(qualificationRecord);
        }
      } catch (error) {
        console.log('error while fetching requested employee qualifications', error);

        const errorMessage = error?.response?.data?.message || 'Could not authenticate employee';
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeUpdatedQualifications();
  }, [employeeId]);

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : qualificationRecord.length ? (
        qualificationRecord?.map((qualificationItem) => {
          return (
            <QualificationList
              qualificationItem={qualificationItem}
              key={qualificationItem[0]?.qualificationId || qualificationItem[1]?.qualificationId}
              setQualificationRecord={setQualificationRecord}
              employeeId={employeeId}
            />
          );
        })
      ) : (
        'No qualification to approve for this employee'
      )}
    </div>
  );
};

export default AdminQualification;
