import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from 'react-select';

//content to add:
// taxFileNumber: '',
// hasApprovedWorkingHolidayVisa: false,
// claimTaxFreeThreshold: false,
// helpDebt: false,
// dateTaxFileDeclarationSigned: '',
//employmentType

const useStyles = makeStyles(() => ({
  textfieldLabel: {
    marginBottom: '5px',
    fontSize: '13px'
  }
}));

const EmployeeTaxDeclaration = ({ employeeData }) => {
  const classes = useStyles();

  const employmentTypeOptions = [
    { value: 'Full time', label: 'Full time' },
    { value: 'Part time ', label: 'Part time ' },
    { value: 'Casual', label: 'Casual' }
  ];

  const areYouOptions = [
    { value: 'An Australian resident for tax purposes', label: 'An Australian resident for tax purposes' },
    { value: 'A foreign resident for tax purposes ', label: 'A foreign resident for tax purposes ' },
    { value: 'A working holiday maker', label: 'A working holiday maker' }
  ];

  const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Tax File Number* </p>
        <TextField
          fullWidth
          required={true}
          variant="outlined"
          size="small"
          id="tax-file-number"
          type="number"
          name="taxFileNumber"
          value={employeeData?.taxFileNumber || ''}
          InputLabelProps={{
            shrink: true
          }}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Employment type*</p>
        <Select options={employmentTypeOptions} defaultValue={employmentTypeOptions[0]} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Are you*</p>

        <Select options={areYouOptions} defaultValue={areYouOptions[0]} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Tax-free threshold*</p>

        <Select options={options} defaultValue={options[0]} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Higher education debt? (HELP)*</p>
        <Select options={options} defaultValue={options[0]} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <p className={classes.textfieldLabel}>Date signed*</p>
        <TextField
          fullWidth
          required={true}
          variant="outlined"
          size="small"
          id="date-signed"
          type="date"
          name="employmentType"
          value={employeeData?.dateTaxFileDeclarationSigned || ''}
          InputLabelProps={{
            shrink: true
          }}
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeTaxDeclaration;

EmployeeTaxDeclaration.propTypes = {
  employeeData: PropTypes.any
};
