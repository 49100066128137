/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useState } from 'react';
import saveAs from 'file-saver';

import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
  EditingState,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  PagingPanel,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  SearchPanel,
  Toolbar,
  TableSelection,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import QboEmployeeDialogContent from './QboEmployeeDialogContent';
/* eslint-disable no-shadow */

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <QboEmployeeDialogContent
        row={row}
        onCancelChanges={onCancelChanges}
        onChange={onChange}
        onApplyChanges={onApplyChanges}
      />
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name
const PopupEditing = React.memo(({ popupComponent: Popup }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name)
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

const getRowId = (row) => row.id;

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

export default function QboEmployeeTable({ rows, setRows, selection, setSelection }) {
  const [columns] = useState([
    { name: 'id', title: 'EmployeeId' },
    { name: 'GivenName', title: 'GivenName' },
    { name: 'MiddleName', title: 'MiddleName' },
    { name: 'FamilyName', title: 'FamilyName' },
    { name: 'Title', title: 'Title' },
    { name: 'DisplayName', title: 'DisplayName' },
    { name: 'PrintOnCheckName', title: 'PrintOnCheckName' },

    { name: 'Active', title: 'Active' },
    { name: 'V4IDPseudonym', title: 'V4IDPseudonym' },
    { name: 'BillableTime', title: 'BillableTime' },
    { name: 'CostRate', title: 'CostRate' },
    { name: 'sparse', title: 'sparse' },
    { name: 'SyncToken', title: 'SyncToken' },

    {
      name: 'Mobile',
      title: 'Mobile',
      getCellValue: (row) => (row.Mobile ? row?.Mobile?.FreeFormNumber : undefined)
    },
    {
      name: 'PrimaryEmailAddr',
      title: 'PrimaryEmailAddr',
      getCellValue: (row) => (row.PrimaryEmailAddr ? row?.PrimaryEmailAddr?.Address : undefined)
    },

    {
      name: 'PrimaryAddrId',
      title: 'PrimaryAddrId',
      getCellValue: (row) => (row.PrimaryAddr ? row?.PrimaryAddr?.Id : undefined)
    },
    {
      name: 'PrimaryAddrLine1',
      title: 'PrimaryAddrLine1',
      getCellValue: (row) => (row.PrimaryAddr ? row?.PrimaryAddr?.Line1 : undefined)
    },
    {
      name: 'PrimaryAddrCity',
      title: 'PrimaryAddrCity',
      getCellValue: (row) => (row.PrimaryAddr ? row?.PrimaryAddr?.City : undefined)
    },
    {
      name: 'PrimaryAddrCountry',
      title: 'PrimaryAddrCountry',
      getCellValue: (row) => (row.PrimaryAddr ? row?.PrimaryAddr?.Country : undefined)
    },
    {
      name: 'PrimaryAddrPostalCode',
      title: 'PrimaryAddrPostalCode',
      getCellValue: (row) => (row.PrimaryAddr ? row?.PrimaryAddr?.PostalCode : undefined)
    }
  ]);

  //it was using useLocalStorage customersColumnWidths, update it once customer columns are done
  const [customerColumnWidths, setCustomerColumnWidths] = useState([
    { columnName: 'id', width: 180 },
    { columnName: 'GivenName', width: 180 },
    { columnName: 'MiddleName', width: 180 },
    { columnName: 'FamilyName', width: 180 },
    { columnName: 'Title', width: 180 },
    { columnName: 'DisplayName', width: 180 },
    { columnName: 'PrintOnCheckName', width: 180 },

    { columnName: 'Active', width: 180 },
    { columnName: 'V4IDPseudonym', width: 180 },
    { columnName: 'BillableTime', width: 180 },
    { columnName: 'CostRate', width: 180 },
    { columnName: 'sparse', width: 180 },
    { columnName: 'SyncToken', width: 180 },

    { columnName: 'Mobile', width: 180 },
    { columnName: 'PrimaryEmailAddr', width: 180 },

    { columnName: 'PrimaryAddrId', width: 180 },
    { columnName: 'PrimaryAddrLine1', width: 180 },
    { columnName: 'PrimaryAddrCity', width: 180 },
    { columnName: 'PrimaryAddrCountry', width: 180 },
    { columnName: 'PrimaryAddrPostalCode', width: 180 }
  ]);
  const [customerColumnOrder, setCustomerColumnOrder] = useState([
    'id',
    'GivenName',
    'MiddleName',
    'FamilyName',
    'Title',
    'DisplayName',
    'PrintOnCheckName',

    'Active',
    'V4IDPseudonym',
    'BillableTime',
    'CostRate',
    'sparse',
    'SyncToken',

    'Mobile',
    'PrimaryEmailAddr',

    'PrimaryAddrId',
    'PrimaryAddrLine1',
    'PrimaryAddrCity',
    'PrimaryAddrCountry',
    'PrimaryAddrPostalCode'
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'GivenName', direction: 'asc' }]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['']);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizes] = useState([100, 200, 300]);
  const [searchValue, setSearchState] = useState('');

  const commitChanges = ({ changed }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  return (
    <div>
      <span>Total rows selected: {selection?.length}</span>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedSelection />

          <DragDropProvider />

          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />

          <SearchState value={searchValue} onValueChange={setSearchState} />
          <IntegratedFiltering />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing columnWidths={customerColumnWidths} onColumnWidthsChange={setCustomerColumnWidths} />
          <TableColumnReordering order={customerColumnOrder} onOrderChange={setCustomerColumnOrder} />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditColumn showEditCommand />
          <PopupEditing popupComponent={Popup} />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rows}
          selection={selection}
          onSave={onSave}
          getRowId={getRowId}
          hiddenColumnNames={hiddenColumnNames}
        />
      </Paper>
    </div>
  );
}
