export function formattedItem(itemList) {
  return itemList?.map((item) => ({
    id: item.Id,
    Name: item.Name || '',
    itemStatus: 'Enabled',
    Description: item.Description || '',
    FullyQualifiedName: item.FullyQualifiedName || '',
    Sku: item.Sku || '',

    Active: item.Active || false,
    Taxable: item.Taxable || false,
    UnitPrice: item.UnitPrice || 0,
    Type: item.Type || '',

    SalesTaxIncluded: item.SalesTaxIncluded || false,
    PurchaseDesc: item.PurchaseDesc || '',
    PurchaseTaxIncluded: item.PurchaseTaxIncluded || false,
    PurchaseCost: item.PurchaseCost || 0,

    IncomeAccountRef: item.IncomeAccountRef || '',
    ExpenseAccountRef: item.ExpenseAccountRef || '',

    PurchaseTaxCodeRef: item.PurchaseTaxCodeRef || '',
    SalesTaxCodeRef: item.SalesTaxCodeRef || '',
    ClassRef: item.ClassRef || '',
    ParentRef: item.ParentRef || '',
    PrefVendorRef: item.PrefVendorRef || '',
    SubItem: item.SubItem || false,

    AssetAccountRef: item.AssetAccountRef || '',
    InvStartDate: item.InvStartDate || '',

    TrackQtyOnHand: item.TrackQtyOnHand || false,
    domain: item.domain || '',
    Id: item.Id,
    sparse: item.sparse || false,
    SyncToken: item.SyncToken || ''
  }));
}
