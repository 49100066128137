import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import toast from 'react-hot-toast';
import assetService from '../../../services/AssetService';
import { formattedAssets } from '../table/hepler';

//employee in asset are operator: once employee is selected for any asset, set them as operator in asset; that's the only logic here
const AssignAssetToEmployee = ({ rows, employeeRefs, selection, setSelection, setRows }) => {
  const employeeRefsOptions = employeeRefs.map((c) => {
    return { value: c.name, label: c.name, id: c.value };
  });
  const [employeeSelected, setEmployeeSelected] = useState(false);
  const [selectedEmployeeRef, setSelectedEmployeeRef] = useState();

  const handleAssignedAssetItem = (selected) => {
    setSelectedEmployeeRef(selected.name);
    setEmployeeSelected(true);
  };

  const handleAssetEmployeeVisibility = () => {
    const visibleAssetIds = rows.filter((r) => selection.find((sel) => r.id === sel)).map((r) => r.assetId);

    const assetData = {
      operator: selectedEmployeeRef,
      assetIds: visibleAssetIds
    };

    assetService
      .updateAssetOperator(assetData)
      .then((data) => {
        const formatAsset = formattedAssets(data?.data?.data);

        const changedRows = rows.map((row) => {
          const matchedAsset = formatAsset.find((asset) => row.assetId === asset.assetId);

          // If matchedAsset is found, return the row with matchedAsset added, else return the original row.
          return matchedAsset ? { ...row, matchedAsset } : row;
        });

        setRows(changedRows);

        toast.success(`Successfully added operator to the asset ${visibleAssetIds}`);
      })
      .catch((error) => {
        toast.error(error || 'Server error');
      })
      .finally(() => {
        setSelection([]);
      });
  };

  const handleResetEmployeeRef = () => {
    //just reset employeeRef to default
    setSelection([]);
  };

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item container xs={12}>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          Assign {selection.length} asset to
        </Grid>

        <Grid item xs={4}>
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue=""
            isSearchable={true}
            name="assignEmployee"
            options={employeeRefsOptions}
            onChange={(selectedOption) => {
              return handleAssignedAssetItem({ name: selectedOption.value, value: selectedOption.id });
            }}
          />
        </Grid>

        {employeeSelected && (
          <Grid item xs={4} style={{ paddingLeft: '1rem' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleAssetEmployeeVisibility}
              style={{ marginRight: '1rem' }}
            >
              Ok
            </Button>
            <Button color="secondary" variant="outlined" onClick={handleResetEmployeeRef}>
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AssignAssetToEmployee;

AssignAssetToEmployee.propTypes = {
  employeeRefs: PropTypes.any,
  rows: PropTypes.any,
  selection: PropTypes.any,
  setSelection: PropTypes.any,
  setRows: PropTypes.func
};
