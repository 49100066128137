import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function ImportAssetDialog({
  open,
  setOpen,
  uploadAssetFiles,
  setAssetRecords,
  buttonSelectedIndex,
  setAssetImages,
  uploadAssetImages,
  assetUploadLoading
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssetFiles = (e) => {
    e.preventDefault();
    setAssetRecords(e.target.files[0]);
  };

  const handleAssetImages = (e) => {
    setAssetImages(e.target.files);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {buttonSelectedIndex === 1
            ? 'Import Asset records'
            : buttonSelectedIndex === 2
            ? 'Import Asset images'
            : null}
        </DialogTitle>
        <DialogContent>
          {assetUploadLoading ? (
            <div className={classes.root}>
              <CircularProgress size={20} />
              &nbsp;&nbsp;&nbsp;
            </div>
          ) : (
            <DialogContentText>Please select the file to import</DialogContentText>
          )}

          {buttonSelectedIndex === 1 ? (
            <input
              accept=".xlsx,.csv"
              id="contained-button-file"
              type="file"
              onChange={handleAssetFiles}
              disabled={assetUploadLoading}
            />
          ) : buttonSelectedIndex === 2 ? (
            <input
              accept=".jpeg, .jpg, .png"
              id="contained-button-file"
              type="file"
              multiple
              onChange={handleAssetImages}
              disabled={assetUploadLoading}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={assetUploadLoading} color="primary">
            Cancel
          </Button>
          <Button
            onClick={
              buttonSelectedIndex === 1 ? uploadAssetFiles : buttonSelectedIndex === 2 ? uploadAssetImages : null
            }
            disabled={assetUploadLoading}
            color="primary"
            autoFocus
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ImportAssetDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  uploadAssetFiles: PropTypes.func,
  setAssetRecords: PropTypes.func,
  buttonSelectedIndex: PropTypes.number,
  setAssetImages: PropTypes.func,
  uploadAssetImages: PropTypes.func,
  assetUploadLoading: PropTypes.bool
};
