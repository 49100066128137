import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import ReactSelect from 'react-select';
import { startOfWeek, lastDayOfWeek } from 'date-fns';

import accountService from '../../../../services/AccountService';
import linkEmployeeService from '../../../../services/LinkEmployeeService';
import vendorService from '../../../../services/VendorService';
import itemService from '../../../../services/ItemService';
import taxCodeService from '../../../../services/TaxCodeService';
import { isLinkEmployeeValid, isSuperAnnuationValid, isSupplierBillValid } from '../../helper/helper';

import { Box, Checkbox, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

const UserAccounting = ({ row, onCancelChanges }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [linkEmployeeLoading, setLinkEmployeeLoading] = useState(false);
  const [vendorListLoading, setVendorListLoading] = useState(false);
  const [productsAndServicesLoading, setProductsAndServicesLoading] = useState(false);
  const [taxCodeLoading, setTaxCodeLoading] = useState(false);
  const [superannuationAccountLoading, setSuperannuationAccountLoading] = useState(false);

  const [vendorList, setVendorList] = useState([]);
  const [productsAndServicesList, setProductsAndServicesList] = useState([]);
  const [taxCodeList, setTaxCodeList] = useState([]);
  const [superannuationAccountList, setSuperannuationAccountList] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState({
    value: '',
    label: '',
    id: ''
  });
  const [selectedProductsAndServices, setSelectedProductsAndServices] = useState({
    value: '',
    label: '',
    id: ''
  });
  const [selectedTaxCode, setSelectedTaxCode] = useState({
    value: '',
    label: '',
    id: ''
  });
  const [selectedSuperannuationAccount, setSelectedSuperannuationAccount] = useState({
    value: '',
    label: '',
    id: ''
  });
  const [selectedSuperannuationPayableAccount, setSelectedSuperannuationPayableAccount] = useState({
    value: '',
    label: '',
    id: ''
  });

  const [hourRate, setHourRate] = useState(0);
  const [superPercentage, setSuperPercentage] = useState(0);
  const [superChecked, setSuperChecked] = useState(false);
  const [supplierBillChecked, setSupplierBillChecked] = useState(false);
  const [voidBillChecked, setVoidBillChecked] = useState(false);

  useEffect(() => {
    const fetchLinkedEmployee = async () => {
      try {
        setLinkEmployeeLoading(true);
        const payload = {
          // eslint-disable-next-line no-undef
          token: localStorage.getItem('token'),
          employeeId: row.id
        };

        const result = await linkEmployeeService.getlinkedEmployee(payload);
        const existingLinkEmployee = result.data.data;

        const vendorDefaultValue = {
          value: existingLinkEmployee.vendorRef.name,
          label: existingLinkEmployee.vendorRef.name,
          id: existingLinkEmployee.vendorRef.value
        };
        setSelectedVendor(vendorDefaultValue);

        const productAndServicesDefaultValue = {
          value: existingLinkEmployee.itemRef.name,
          label: existingLinkEmployee.itemRef.name,
          id: existingLinkEmployee.itemRef.value
        };
        setSelectedProductsAndServices(productAndServicesDefaultValue);

        const taxCodeDefaultValue = {
          value: existingLinkEmployee.taxType.name,
          label: existingLinkEmployee.taxType.name,
          id: existingLinkEmployee.taxType.value
        };
        setSelectedTaxCode(taxCodeDefaultValue);

        const superannuationAccountDefaultValue = {
          value: existingLinkEmployee.superAccount.name,
          label: existingLinkEmployee.superAccount.name,
          id: existingLinkEmployee.superAccount.value
        };
        setSelectedSuperannuationAccount(superannuationAccountDefaultValue);

        const superannuationPayableAccountDefaultValue = {
          value: existingLinkEmployee.superPayableAccount.name,
          label: existingLinkEmployee.superPayableAccount.name,
          id: existingLinkEmployee.superPayableAccount.value
        };
        setSelectedSuperannuationPayableAccount(superannuationPayableAccountDefaultValue);

        setHourRate(existingLinkEmployee.hourRate);
        setSuperPercentage(existingLinkEmployee.superPercentage);
        setSuperChecked(existingLinkEmployee.superannuationChecked);
        setSupplierBillChecked(existingLinkEmployee.supplierChecked);
        setVoidBillChecked(existingLinkEmployee.supplierVoidChecked);
      } catch (error) {
        console.error('error is', error);
      } finally {
        setLinkEmployeeLoading(false);
      }
    };
    fetchLinkedEmployee();
  }, [row.id]);

  useEffect(() => {
    const fetchVendorList = async () => {
      try {
        setVendorListLoading(true);
        const vendorList = await vendorService.getVendorsFromQBO();

        const vendorListOption = vendorList.data.data.QueryResponse.Vendor.map((c) => {
          return { value: c.DisplayName, label: c.DisplayName, id: c.Id };
        });

        setVendorList(vendorListOption);
      } catch (error) {
        toast.error('could not fetch vendor list');
      } finally {
        setVendorListLoading(false);
      }
    };
    fetchVendorList();
  }, []);

  useEffect(() => {
    const fetchProductsAndServicesList = async () => {
      try {
        setProductsAndServicesLoading(true);
        const productsAndServicesList = await itemService.getItemsFromQBO();

        const productsAndServicesListOption = productsAndServicesList.data.data.QueryResponse.Item.map((c) => {
          return { value: c.FullyQualifiedName, label: c.FullyQualifiedName, id: c.Id };
        });
        setProductsAndServicesList(productsAndServicesListOption);
      } catch (error) {
        toast.error('Could not fetch item list');
      } finally {
        setProductsAndServicesLoading(false);
      }
    };
    fetchProductsAndServicesList();
  }, []);

  useEffect(() => {
    const fetchTaxTypeList = async () => {
      try {
        setTaxCodeLoading(true);
        const taxCodeList = await taxCodeService.getTaxCodeFromQBO();

        const taxCodeListOption = taxCodeList.data.data.QueryResponse.TaxCode.map((c) => {
          return { value: c.Name, label: c.Name, id: c.Id };
        });

        setTaxCodeList(taxCodeListOption);
      } catch (error) {
        toast.error('Could not fetch tax code data');
      } finally {
        setTaxCodeLoading(false);
      }
    };
    fetchTaxTypeList();
  }, []);

  useEffect(() => {
    const fetchSuperannuationAccountList = async () => {
      try {
        setSuperannuationAccountLoading(true);
        const superannuationAccountList = await accountService.getAccountsFromQbo();

        const superannuationAccountListOption = superannuationAccountList.data.data.QueryResponse.Account.map((c) => {
          return {
            value: c.Name,
            label: c.Name,
            id: c.Id
          };
        });

        setSuperannuationAccountList(superannuationAccountListOption);
      } catch (error) {
        toast.error('Could not fetch super account data');
      } finally {
        setSuperannuationAccountLoading(false);
      }
    };
    fetchSuperannuationAccountList();
  }, []);

  const handleSelectedVendor = (selectedVendor) => {
    setSelectedVendor(selectedVendor);
  };

  const handleSelectedProductsAndServices = (selectedItem) => {
    setSelectedProductsAndServices(selectedItem);
  };

  const handleSelectedTaxType = (selectedTaxCode) => {
    setSelectedTaxCode(selectedTaxCode);
  };

  const handleSelectedSuper = (selectedSuper) => {
    setSelectedSuperannuationAccount(selectedSuper);
  };

  const handleSelectedSuperPayable = (selectedSuperPayable) => {
    setSelectedSuperannuationPayableAccount(selectedSuperPayable);
  };

  const handleSuperChecked = (event) => {
    setSuperChecked(event.target.checked);
    if (!event.target.checked) {
      setSuperPercentage(0);
      setSelectedSuperannuationAccount();
      setSelectedSuperannuationPayableAccount();
    }
  };

  const handleLinkEmployee = () => {
    const sDate = startOfWeek(new Date(), { weekStartsOn: 3 });
    const eDate = lastDayOfWeek(new Date(), { weekStartsOn: 3 });

    // eslint-disable-next-line no-undef
    const getAddedEmployeeId = JSON.parse(localStorage.getItem('addedUser'));

    const linkEmployeePayload = {
      // eslint-disable-next-line no-undef
      token: localStorage.getItem('token'),
      employeeId: row.id || getAddedEmployeeId.id || '',
      userFamilyname: row.FamilyName,
      displayName: row.DisplayName,
      itemRef: selectedProductsAndServices,
      vendorRef: selectedVendor,
      taxType: selectedTaxCode,
      hourRate: hourRate,
      superPercentage: superPercentage ? superPercentage : '',
      superAccount: selectedSuperannuationAccount ? selectedSuperannuationAccount : '',
      superPayableAccount: selectedSuperannuationPayableAccount ? selectedSuperannuationPayableAccount : '',
      superannuationChecked: superChecked,
      sDate: sDate,
      eDate: eDate,
      supplierChecked: supplierBillChecked,
      supplierVoidChecked: voidBillChecked
    };

    if (!isLinkEmployeeValid(linkEmployeePayload)) {
      return toast.error('Any of the following is not provided supplier or items or tax type or hour  or employeeId');
    }

    if (!isSupplierBillValid(supplierBillChecked)) {
      return toast.error('Please tick import supplier bill. ');
    }

    if (
      !isSuperAnnuationValid(
        superChecked,
        superPercentage,
        selectedSuperannuationAccount,
        selectedSuperannuationPayableAccount
      )
    ) {
      return toast.error('Please fill all data if super is ticked');
    }

    linkEmployeeService
      .linkEmployee(linkEmployeePayload)
      .then(() => {
        toast.success('Successfully linked suppliers');
        // action if not in edit employee/ if employee is linked after employee is added
        if (getAddedEmployeeId?.id) {
          setSelectedVendor();
          setSelectedProductsAndServices();
          setSelectedTaxCode();
          setSelectedSuperannuationAccount();
          setSelectedSuperannuationPayableAccount();
          setHourRate(0);
          setSuperPercentage(0);
          setSuperChecked(false);
          setSupplierBillChecked(false);
          setVoidBillChecked(false);
          onCancelChanges();
          //once added user's accounting is saved close the dialog and remove addedUsers details from the localStorage
          // eslint-disable-next-line no-undef
          localStorage.removeItem('addedUser');
        }
      })
      .catch(() => {
        toast.error('Could not link supplier');
      });
  };

  return (
    <div style={{ height: '800px' }}>
      <h2 style={{ borderBottom: 'none', padding: isSmall ? '10px 1rem' : '10px 2rem', overflowX: 'hidden' }}>
        Link Supplier
      </h2>

      <Grid container spacing={2} style={{ padding: isSmall ? '0 2rem' : '0 3rem', overflowX: 'hidden' }}>
        {/* select supplier */}
        <Grid item xs={12}>
          <Typography component="div" m={1}>
            <Box fontWeight="fontWeightMedium"> Select Supplier to link</Box>
          </Typography>
          {vendorListLoading ? (
            <>
              <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          ) : (
            <ReactSelect
              required
              className="basic-single"
              classNamePrefix="select"
              value={selectedVendor}
              isSearchable={true}
              name="vendor"
              options={vendorList}
              onChange={handleSelectedVendor}
            />
          )}
        </Grid>

        {/* select product and services or item */}
        <Grid item xs={12}>
          <Typography component="div" m={1}>
            <Box fontWeight="fontWeightMedium"> Select Products and Services to link</Box>
          </Typography>
          {productsAndServicesLoading ? (
            <>
              <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          ) : (
            <ReactSelect
              required
              className="basic-single"
              classNamePrefix="select"
              value={selectedProductsAndServices}
              isSearchable={true}
              name="productsAndServices"
              options={productsAndServicesList}
              onChange={handleSelectedProductsAndServices}
            />
          )}
        </Grid>

        {/* hourly rate input */}
        <Grid item xs={12}>
          <Typography component="div">
            <Box fontWeight="fontWeightMedium" sx={{ mt: 1, mb: 1 }}>
              Hourly Rate
            </Box>
          </Typography>
          <TextField
            required={true}
            variant="outlined"
            size="small"
            value={hourRate}
            onChange={(e) => setHourRate(e.target.value)}
            autoFocus
            id="hour"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            name="hour"
            fullWidth={true}
            type="number"
          />
        </Grid>

        {/* select tax code */}
        <Grid item xs={12}>
          <Typography component="div" m={1}>
            <Box fontWeight="fontWeightMedium"> Select Tax Type</Box>
          </Typography>
          {taxCodeLoading ? (
            <>
              <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          ) : (
            <ReactSelect
              required
              className="basic-single"
              classNamePrefix="select"
              value={selectedTaxCode}
              isSearchable={true}
              name="taxType"
              options={taxCodeList}
              onChange={handleSelectedTaxType}
            />
          )}
        </Grid>

        {/* superannuation check */}
        <Grid item xs={12} style={{ paddingLeft: '1px' }}>
          <Checkbox
            checked={superChecked}
            onChange={handleSuperChecked}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name="superChecked"
          />
          <span>Superannuation</span>
        </Grid>

        {superChecked && (
          <>
            <Grid item xs={12}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" sx={{ mt: 1, mb: 1 }}>
                  Superannuation Percentage (%)
                </Box>
              </Typography>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                value={superPercentage}
                onChange={(e) => setSuperPercentage(e.target.value)}
                autoFocus
                id="superPercentage"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                name="superPercentage"
                fullWidth={true}
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="div" m={1}>
                <Box fontWeight="fontWeightMedium"> Superannuation Account</Box>
              </Typography>
              {superannuationAccountLoading ? (
                <>
                  <CircularProgress size={20} style={{ color: 'blue', textAlign: 'center' }} />
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedSuperannuationAccount}
                  isSearchable={true}
                  name="superannuationAccount"
                  options={superannuationAccountList}
                  onChange={handleSelectedSuper}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography component="div" m={1}>
                <Box fontWeight="fontWeightMedium"> Superannuation Payable Account</Box>
              </Typography>
              {superannuationAccountLoading ? (
                <>
                  <CircularProgress size={20} style={{ color: 'blue' }} />
                  &nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedSuperannuationPayableAccount}
                  isSearchable={true}
                  name="superannuationPayableAccount"
                  options={superannuationAccountList}
                  onChange={handleSelectedSuperPayable}
                />
              )}
            </Grid>
          </>
        )}

        {/* import supplier bill */}
        <Grid item xs={12} style={{ paddingLeft: '0' }}>
          <Checkbox
            name="importSupplierBillChecked"
            checked={supplierBillChecked}
            onChange={(e) => setSupplierBillChecked(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <span>Import Supplier bill </span>
        </Grid>

        {/* import void supplier */}
        <Grid item xs={12} style={{ paddingLeft: '0' }}>
          <Checkbox
            name="importVoidChecked"
            checked={voidBillChecked}
            onChange={(e) => setVoidBillChecked(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <span>Import Void Supplier </span>
        </Grid>
      </Grid>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '1rem',
          paddingRight: isSmall ? '2rem' : '3rem',
          overflowX: 'hidden'
        }}
      >
        <Button autoFocus color="secondary" onClick={onCancelChanges}>
          Close
        </Button>
        <Button autoFocus variant="contained" color="primary" onClick={handleLinkEmployee}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserAccounting;

UserAccounting.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
