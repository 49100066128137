import React, { useState } from 'react';
import { startOfWeek, lastDayOfWeek, parse, format } from 'date-fns';
import { Toaster } from 'react-hot-toast';

import Footer from './footer/Footer';
import MyTimeFilter, { timesheetPeriodOptions } from './filter/MyTimeFilter';
import ButtonMenu from './header/ButtonMenu';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import AddTimeDialog from './header/AddTimeDialog';
import useFetchCustomerRefRecords from './hooks/useFetchCustomerRefs';
import useFetchClassRefRecords from './hooks/useFetchClassRefs';
import useFetchEmployeeTimesheet from './hooks/useFetchEmployeeTimesheet';
import VerdTimesheetTable from './table/VerdTimesheetTable';
import SearchIcon from '@material-ui/icons/Search';
import RequestLeaveFormDialog from './requestLeave/RequestLeaveFormDialog';
import RequestLeaveForm from './requestLeave/RequestLeaveForm';

const useStyles = makeStyles(() => ({
  filterButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '2rem',
    cursor: 'pointer'
  },

  buttonWrapper: {
    display: 'flex',
    borderRadius: '4px',
    // backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  addTimeButton: {
    borderRight: '1px solid white',
    borderRadius: '4px 0 0 4px'
  },

  searchContainer: {
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
    float: 'right',
    paddingLeft: '20px'
  }
}));

export default function MyTime() {
  const classes = useStyles();

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const [myTimeFormData, setMyTimeFormData] = useState({
    startTime: '07:00',
    finishTime: '15:00',
    hour: 8,
    date: todayDate,
    client: '',
    task: '',
    isBillable: false,
    description: '',
    variationNote: '',
    workType: '',
    medicalStatus: false,
    timeSheetImageUrl: ''
  });
  const { customerRefs, customerRefLoading } = useFetchCustomerRefRecords();
  const { classRefs, classRefLoading } = useFetchClassRefRecords();

  const [filter, setFilter] = useState({
    timesheetPeriod: timesheetPeriodOptions[0],
    startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
    searchQuery: ''
  });
  const { rows, setRows } = useFetchEmployeeTimesheet(filter);
  const [selection, setSelection] = useState([]);

  const [timesheetOptions] = useState(['Take Leave', 'Request Leave']);
  const [takeLeaveOption, setTakeLeaveOption] = useState(false);
  const [requestLeaveOption, setRequestLeaveOption] = useState(false);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideComponent] = useState(false);
  const [timesheetSavingOptions, setTimesheetSavingOptions] = useState([]);
  const [showLeaveBalance, setShowLeaveBalance] = useState();
  const [sickLeaveBalance, setSickLeaveBalance] = useState();

  const [columns] = useState([
    {
      name: 'EmployeeRef',
      title: 'Employee Name',
      getCellValue: (row) => (row.EmployeeRef ? row.EmployeeRef.name : undefined)
    },
    { name: 'StartTime', title: 'Start Time', required: true },
    { name: 'FinishTime', title: 'End Time', required: true },
    {
      name: 'Hours',
      title: 'Hours',
      getCellValue: (row) => {
        if (row.StartTime && row.FinishTime) {
          const startDateMs = parse(row.StartTime, 'HH:mm', new Date(row.Date));
          const endDateMs = parse(row.FinishTime, 'HH:mm', new Date(row.Date));

          const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);

          return Number(diffHr);
        }
      },
      required: true
    },
    {
      name: 'Day',
      title: 'Day',
      required: true,
      getCellValue: (row) => {
        if (row.Date) {
          const columnDay = format(new Date(row.Date), 'EEEE');
          return columnDay;
        }
      }
    },
    { name: 'Date', title: 'Date', required: true },
    { name: 'Description', title: 'Comments', required: true },
    { name: 'status', title: 'Status', required: true },
    {
      name: 'CustomerRef',
      title: 'Customer Ref',
      getCellValue: (row) => (row.CustomerRef ? row.CustomerRef.name : undefined),
      required: true
    },
    {
      name: 'ClassRef',
      title: 'Service',
      getCellValue: (row) => (row.ClassRef ? row.ClassRef.name : undefined),
      required: true
    },
    { name: 'BillableStatus', title: 'Billable Status' },
    { name: 'variationNote', title: 'Variation Note' },
    { name: 'timeSheetImageUrl', title: 'Image' },
    { name: 'VendorRef', title: 'Supplier', getCellValue: (row) => (row.VendorRef ? row.VendorRef.name : undefined) },
    { name: 'email', title: 'Email' },
    { name: 'jobTitle', title: 'Job Title' }
  ]);

  //user cannot edit employee ref
  const [editingStateColumnExtensions] = useState([
    { columnName: 'KeyPayEmployeeId', editingEnabled: false },
    { columnName: 'KeyPayEmployeeId', editingEnabled: false },
    { columnName: 'status', editingEnabled: false },
    { columnName: 'Hours', editingEnabled: false },
    { columnName: 'EmployeeRef', editingEnabled: false },
    { columnName: 'jobTitle', editingEnabled: false },
    { columnName: 'email', editingEnabled: false },
    { columnName: 'VendorRef', editingEnabled: false },
    { columnName: 'Day', editingEnabled: false },
    { columnName: 'workType', allowEditingWhenEditing: true, allowEditingWhenInserting: false },
    { columnName: 'timeSheetImageUrl', editingEnabled: false }
  ]);

  const dateColumns = ['Date'];
  const [sorting, setSorting] = useState([{ columnName: 'EmployeeRef', direction: 'asc' }]);

  const rowSelectionEnabled = (row) => row.status !== 'Approved' && row.status !== 'WithApprover';

  const changeSelection = (selection) => {
    setSelection(selection);
  };

  const handleAddTimeClick = (e) => {
    e.preventDefault();
    setTakeLeaveOption(false);
    setRequestLeaveOption(false);
    setOpen(true);
    // eslint-disable-next-line no-undef
    let options = localStorage.getItem('timesheetSavingOptions');
    if (options && options.length) {
      setTimesheetSavingOptions(JSON.parse(options));
    }

    if (!options) {
      options = [
        { id: 1, value: 'Save as draft' },
        { id: 2, value: 'Save and submit' }
      ];
      setTimesheetSavingOptions(options);
      // eslint-disable-next-line no-undef
      localStorage.setItem('timesheetSavingOptions', JSON.stringify(options));
    }
  };

  const handleOptions = (e, option) => {
    e.preventDefault();
    // console.log('e is:', e.target.textContent);
    if (option === 'Take Leave') {
      setTakeLeaveOption(true);
      setOpen(true);
    }

    if (option === 'Request Leave') {
      setRequestLeaveOption(true);
      setOpen(true);
    }

    // eslint-disable-next-line no-undef
    let options = localStorage.getItem('timesheetSavingOptions');
    if (options && options.length) {
      setTimesheetSavingOptions(JSON.parse(options));
    }

    if (!options) {
      options = [
        { id: 1, value: 'Save as draft' },
        { id: 2, value: 'Save and submit' }
      ];
      setTimesheetSavingOptions(options);
      // eslint-disable-next-line no-undef
      localStorage.setItem('timesheetSavingOptions', JSON.stringify(options));
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setRequestLeaveOption(false);
    setAnchorEl(false);
    setMyTimeFormData({
      startTime: '07:00',
      finishTime: '15:00',
      hour: 8,
      date: todayDate,
      client: '',
      task: '',
      isBillable: false,
      description: '',
      variationNote: '',
      workType: '',
      medicalStatus: false,
      timeSheetImageUrl: ''
    });
    setShowLeaveBalance();
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.filterButtonWrapper}>
        <MyTimeFilter filter={filter} setFilter={setFilter} />

        <div className={classes.buttonWrapper} style={{ backgroundColor: classRefLoading ? '' : '#4040a1' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.addTimeButton}
            onClick={handleAddTimeClick}
            disabled={classRefLoading}
          >
            {classRefLoading ? (
              <>
                <CircularProgress size={20} style={{ color: 'white' }} />
                &nbsp;&nbsp;&nbsp;
              </>
            ) : (
              <></>
            )}
            Add Time
          </Button>

          <ButtonMenu
            buttonList={timesheetOptions}
            handleOptions={handleOptions}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
          />
        </div>
      </div>

      {requestLeaveOption && open && (
        <RequestLeaveForm
          setOpen={setOpen}
          open={requestLeaveOption}
          handleClose={handleDialogClose}
          setAnchorEl={setAnchorEl}
        />
      )}

      {classRefs.length > 0 && !requestLeaveOption && (
        <AddTimeDialog
          setOpen={setOpen}
          open={open}
          handleClose={handleDialogClose}
          customerRefList={customerRefs}
          classRefList={classRefs}
          rows={rows}
          setRows={setRows}
          takeLeaveOption={takeLeaveOption}
          requestLeaveOption={requestLeaveOption}
          myTimeFormData={myTimeFormData}
          setMyTimeFormData={setMyTimeFormData}
          timesheetSavingOptions={timesheetSavingOptions}
          setTimesheetSavingOptions={setTimesheetSavingOptions}
          setAnchorEl={setAnchorEl}
          showLeaveBalance={showLeaveBalance}
          setShowLeaveBalance={setShowLeaveBalance}
          sickLeaveBalance={sickLeaveBalance}
          setSickLeaveBalance={setSickLeaveBalance}
        />
      )}

      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          value={filter.searchQuery}
          onChange={(e) => setFilter({ ...filter, searchQuery: e.target.value })}
          placeholder="Search..."
          style={{ border: 'none', outline: 'none' }}
        />
      </div>

      <VerdTimesheetTable
        customerRefs={customerRefs}
        classRefs={classRefs}
        rows={rows}
        setRows={setRows}
        selection={selection}
        rowSelectionEnabled={rowSelectionEnabled}
        changeSelection={changeSelection}
        disableEditAndDelete={(row) => {
          return row.status !== 'WithEmployee';
        }}
        columns={columns}
        hideComponent={hideComponent}
        dateColumns={dateColumns}
        sorting={sorting}
        setSorting={setSorting}
        editingStateColumnExtensions={editingStateColumnExtensions}
      />
      <Footer rows={rows} setRows={setRows} selection={selection} />
    </div>
  );
}
