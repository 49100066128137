import { useEffect, useState } from 'react';
import clientService from '../../../services/ClientService';
import { getFormattedCustomer } from '../table/helper';
import { toast } from 'react-hot-toast';

export default function useFetchCustomer() {
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerRows, setCustomerRows] = useState([]);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setCustomerLoading(true);
        const customerRecords = await clientService.getClientListFromQBO();

        const formattedCustomerList = getFormattedCustomer(customerRecords.data.data.QueryResponse.Customer);

        const customerFromDB = await clientService.getClientListFromDatabase();
        const customerDbRecords = customerFromDB.data.data;

        const updatedRows = formattedCustomerList.map((row) => {
          const exists = customerDbRecords.find((c) => row.id === c.Id);
          return exists
            ? ((row.customerStatus = exists.customerStatus ? 'Enabled' : 'Disabled'),
              (row.BillableStatus = exists.BillableStatus),
              (row.MarkUpPercentage = exists.MarkUpPercentage),
              row)
            : row;
        });

        setCustomerRows(updatedRows);
      } catch (error) {
        toast.error('Could not fetch customer');
      } finally {
        setCustomerLoading(false);
      }
    };
    fetchCustomer();
  }, []);
  return { customerRows, setCustomerRows, customerLoading };
}
