import { useEffect, useState } from 'react';
import keyPayEmployeeService from '../../../services/KeypayEmployee';

export default function useFetchKeyPayEmployeeRecords() {
  const [keyPayEmployeeRecords, setKeyPayEmployeeRecords] = useState([]);
  const [keyPayError, setKeyPayError] = useState('');
  const [keyPayLoading, setKeyPayLoading] = useState(false);

  useEffect(() => {
    const fetchKeyPayEmployeeRecords = async () => {
      try {
        const keyPayEmployees = await keyPayEmployeeService.getAllKeyPayEmployeeList();

        const keyPayEmployeeRecords = keyPayEmployees.data.data;
        setKeyPayEmployeeRecords(keyPayEmployeeRecords);
      } catch (error) {
        setKeyPayError(error?.message);
      } finally {
        setKeyPayLoading(false);
        setKeyPayError('');
      }
    };
    fetchKeyPayEmployeeRecords();
  }, []);

  return { keyPayEmployeeRecords, keyPayError, keyPayLoading };
}
