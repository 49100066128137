import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  arrowDropDown: { color: 'white', marginTop: '5px', marginLeft: '2px' },
  menuList: {
    cursor: 'pointer'
  },
  menu: {
    zIndex: 999,
    marginTop: '10px',
    '@media (max-width: 600px)': {
      marginTop: '10px'
    }
  },
  menuItem: {
    '&:hover': {
      color: '#4040a1'
    }
  }
}));

export default function ButtonMenu({ buttonList, handleOptions, setAnchorEl, anchorEl, filter }) {
  const classes = useStyles();

  return (
    <div style={{ padding: '0px 3px' }}>
      <ArrowDropDownIcon
        className={classes.arrowDropDown}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className={classes.menu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        disablePortal
      >
        {buttonList.map((buttonItem) => (
          <MenuItem
            key={buttonItem}
            onClick={handleOptions}
            className={classes.menuItem}
            value={buttonItem}
            style={{
              display:
                filter.expenseStatus === 'WithEmployee' && buttonItem === 'Send Reminder'
                  ? 'block'
                  : filter.expenseStatus === 'WithApprover' && buttonItem === 'Reject and send reminder'
                  ? 'block'
                  : filter.expenseStatus === 'Approved' && buttonItem === 'Send back to WithApprover'
                  ? 'block'
                  : 'none'
            }}
          >
            {buttonItem}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

// && buttonItem === 'Send Reminder'

ButtonMenu.propTypes = {
  buttonList: PropTypes.array,
  handleOptions: PropTypes.func,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.any,
  filter: PropTypes.shape({
    expensePeriod: PropTypes.string,
    startDate: PropTypes.null || PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    expenseStatus: PropTypes.string
  })
};
