import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Paper } from '@material-ui/core';
import ReactSelect from 'react-select';

import { makeStyles } from '@material-ui/core/styles';
import assetService from '../../../../services/AssetService';
import linkAssetService from '../../../../services/LinkAssetService';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex',
    marginBottom: '1rem'
  }
}));

const LinkAssetDialog = ({ row, onCancelChanges }) => {
  const classes = useStyles();

  const [childAsset, setChildAsset] = useState(null);
  const [assets, setAssets] = useState();
  const [linkedAsset, setLinkedAsset] = useState(null);

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const assets = await assetService.getAssets();

        const assetRecords = assets?.data?.data?.map((asset) => {
          return { description: asset.description, assetId: asset.assetId };
        });

        setAssets(assetRecords);
      } catch (error) {
        console.log('error is:', error);
      }
    };

    fetchAsset();
  }, [row]);

  useEffect(() => {
    const fetchLinkedAsset = async () => {
      try {
        if (row?.assetId) {
          const linkedAsset = await linkAssetService.getLinkedAsset(row?.assetId);

          if (linkedAsset?.data?.data.length > 0) {
            const linkedRecord = linkedAsset?.data?.data[0];
            setLinkedAsset({
              value: linkedRecord.description,
              label: linkedRecord.description,
              id: linkedRecord.assetId
            });
          }
        }
      } catch (error) {
        console.log('error is:', error);
      }
    };
    fetchLinkedAsset();
  }, [row]);

  const assetOptions = assets?.map((row) => {
    return {
      value: row.description,
      label: row.description,
      id: row.assetId
    };
  });

  const handleSelectedAsset = (selectedOption) => {
    setChildAsset([{ assetId: selectedOption?.id, description: selectedOption?.label || '' }]);
    setLinkedAsset(selectedOption);
  };

  const handleLinkAsset = () => {
    const linkedAsset = {
      childAsset,
      parentAsset: {
        assetId: row?.assetId,
        description: row?.description
      }
    };

    linkAssetService
      .createLinkAsset(linkedAsset)
      .then((data) => {
        toast.success(
          `${data?.data?.message}` || 'Successfully linked asset, both of the assets will have beacons location'
        );
      })
      .catch((error) => {
        toast.error(error?.message || 'Internal Server error');
      });
  };

  return (
    <div style={{ height: '700px' }}>
      <div style={{ padding: '1rem 0 0.5rem 1rem', fontSize: '1rem' }}>Link Asset </div>
      <DialogContent>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <ReactSelect
                // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                value={linkedAsset}
                isSearchable={true}
                name="selectedOption"
                options={assetOptions}
                onChange={(selectedOption) => handleSelectedAsset(selectedOption)}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleLinkAsset} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default LinkAssetDialog;

LinkAssetDialog.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
