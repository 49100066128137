import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, makeStyles, CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import officeInfoService from '../../../../services/OfficeInfoService';

const useStyles = makeStyles(() => ({
  textfieldLabel: {
    marginBottom: '5px',
    fontSize: '13px'
  }
}));
const UserOfficeInfo = ({ employeeId }) => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [officePinCode, setOfficePinCode] = useState(null);
  const [pinCode, setPinCode] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchOfficePinCode = async () => {
      try {
        if (employeeId) {
          const payload = {
            // eslint-disable-next-line no-undef
            employeeId: employeeId
          };
          const response = await officeInfoService.getOfficeInfo(payload);

          if (response?.data?.data?.length > 0) {
            const pinCode = response?.data?.data[0]?.officePinCode;
            setOfficePinCode(pinCode);
          } else {
            setOfficePinCode(null); // Reset pin code if no data exists
            setMessage("User hasn't updated pin code");
          }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not show office info');
      }
    };

    fetchOfficePinCode();
  }, [employeeId]);

  const handleChange = (e) => {
    setOfficePinCode(e.target.value);
    setPinCode(e.target.value);
  };

  const handleUpdateButtonClick = async () => {
    setIsSubmitting(true);

    try {
      const officeData = { officePinCode: pinCode, employeeId: employeeId };
      const response = await officeInfoService.updateOfficeInfo(officeData);

      setOfficePinCode(response?.data?.data?.officePinCode);
      setMessage(null);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Could not add pin code');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3} style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item xs={12} sm={3}>
        <p className={classes.textfieldLabel}>Office pin code </p>
        <TextField
          required={true}
          variant="outlined"
          size="small"
          type="number"
          name="pinCode"
          value={officePinCode || pinCode}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={3} style={{ marginTop: '1rem' }}>
        <Button onClick={handleUpdateButtonClick} variant="outlined" color="primary" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
            </>
          ) : (
            <span>Update</span>
          )}
        </Button>
      </Grid>
      {message && (
        <Grid item xs={12} sm={12}>
          <p style={{ color: 'red' }}>{message}</p>
        </Grid>
      )}
    </Grid>
  );
};

export default UserOfficeInfo;

UserOfficeInfo.propTypes = {
  employeeId: PropTypes.any
};
