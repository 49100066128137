/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { requestLeaveFormSchema } from '../header/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const RequestLeaveFormDialog = ({ handleClose }) => {
  const classes = useStyles();

  const [requestLeaveForm, setRequestLeaveForm] = useState({
    leaveType: '',
    numberOfDays: '',
    firstDayOfLeave: null,
    lastDayOfLeave: '',
    firstDayBackToWork: ''
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlRequestLeave,
    handleSubmit: handleRequestLeaveSubmit,
    reset: resetRequestLeaveForm,
    getValues: getRequestLeaveFormValues,
    formState: { errors: requestLeaveFormErrors, dirtyFields: dirtyFieldsRequestLeave }
  } = useForm({
    resolver: zodResolver(requestLeaveFormSchema),
    defaultValues: requestLeaveForm
  });

  const onSubmitRequestLeaveForm = async (data) => {
    console.log('request leave form data is:', data);
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleRequestLeaveSubmit(onSubmitRequestLeaveForm)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> Leave Type</h5>
              <Controller
                name="driverLicense_licenseNumber"
                control={controlRequestLeave}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="driver-license-number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!requestLeaveFormErrors.driverLicense_licenseNumber}
                    helperText={requestLeaveFormErrors.driverLicense_licenseNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> Number of days</h5>
              <Controller
                name="driverLicense_licenseExpiry"
                control={controlRequestLeave}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="driver-license-license-expiry"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!requestLeaveFormErrors.driverLicense_licenseExpiry}
                    helperText={requestLeaveFormErrors.driverLicense_licenseExpiry?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> First day of leave</h5>
              <Controller
                name="driverLicense_licenseExpiry"
                control={controlRequestLeave}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="driver-license-license-expiry"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!requestLeaveFormErrors.driverLicense_licenseExpiry}
                    helperText={requestLeaveFormErrors.driverLicense_licenseExpiry?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> Last day of leave</h5>
              <Controller
                name="driverLicense_licenseExpiry"
                control={controlRequestLeave}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="driver-license-license-expiry"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!requestLeaveFormErrors.driverLicense_licenseExpiry}
                    helperText={requestLeaveFormErrors.driverLicense_licenseExpiry?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> First day back to work</h5>
              <Controller
                name="driverLicense_licenseExpiry"
                control={controlRequestLeave}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="driver-license-license-expiry"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!requestLeaveFormErrors.driverLicense_licenseExpiry}
                    helperText={requestLeaveFormErrors.driverLicense_licenseExpiry?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Submit</span>
                  </>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default RequestLeaveFormDialog;
