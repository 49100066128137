import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import timesheetService from '../../../../services/TimesheetService';
import { addDays } from 'date-fns';
import { getFormattedTableRows } from '../../../MyTime/table/helpers';

export default function useFetchAllTimesheetStatus(filter, setRows) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [timesheetStatusList, setTimesheetStatusList] = useState([]);

  useEffect(() => {
    const fetchAllTimesheet = async () => {
      try {
        const timesheetRecords = await timesheetService.listAllTimesheet();
        const formattedTimesheet = getFormattedTableRows(timesheetRecords.data.data);

        setLoading(false);

        const filteredRows = formattedTimesheet.filter((row) => {
          if (
            (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
            new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }
          if (filter.headerStatusChange) {
            if (
              (filter.startDate === null || new Date(row.Date) >= new Date(filter.startDate).getTime()) &&
              new Date(row.Date) <= addDays(new Date(filter.endDate), 1).getTime()
            ) {
              return true;
            }
          }
        });

        const withEmployeeTimesheet = filteredRows.filter((timesheet) => timesheet.status === 'WithEmployee');
        if (filter.timesheetStatus === 'WithEmployee') {
          setRows(withEmployeeTimesheet);
        }
        const withApproverTimesheet = filteredRows.filter((timesheet) => timesheet.status === 'WithApprover');
        if (filter.timesheetStatus === 'WithApprover') {
          setRows(withApproverTimesheet);
        }
        const approvedTimesheet = filteredRows.filter((timesheet) => timesheet.status === 'Approved');
        if (filter.timesheetStatus === 'Approved') {
          setRows(approvedTimesheet);
        }

        const withEmployeeHours = withEmployeeTimesheet.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let withEmployeeTotalHours = 0;
        if (withEmployeeHours.length) {
          withEmployeeTotalHours = withEmployeeHours.reduce((a, c) => Number(a) + Number(c));
        }

        const withApproverHours = withApproverTimesheet.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let withApproverTotalHours = 0;
        if (withApproverHours.length) {
          withApproverTotalHours = withApproverHours.reduce((a, c) => Number(a) + Number(c));
        }

        const approvedHours = approvedTimesheet?.map((d) => totalHours(d.StartTime, d.FinishTime, d.Date));
        let approvedTotalHours = 0;
        if (approvedHours.length) {
          approvedTotalHours = approvedHours?.reduce((a, c) => Number(a) + Number(c));
        }

        setTimesheetStatusList([
          {
            label: 'WithEmployee',
            totalHours: withEmployeeTotalHours,
            totalCount: withEmployeeTimesheet.length
          },
          {
            label: 'WithApprover',
            totalHours: withApproverTotalHours,
            totalCount: withApproverTimesheet.length
          },
          {
            label: 'Approved',
            totalHours: approvedTotalHours,
            totalCount: approvedTimesheet.length
          },
          {
            label: 'All'
          }
        ]);
      } catch (error) {
        setError(error.message || "couldn't fetch all timesheet");
      } finally {
        setLoading(false);
      }
    };

    fetchAllTimesheet();
  }, [filter.endDate, filter.startDate, filter.timesheetStatus, setRows, filter.headerStatusChange]);

  return { timesheetStatusList, error, loading };
}

function totalHours(startTime, finishTime, date) {
  const startDateMs = parse(startTime, 'HH:mm', new Date(date));
  const endDateMs = parse(finishTime, 'HH:mm', new Date(date));

  const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);
  return diffHr;
}
