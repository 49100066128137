import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import notificationService from '../../../services/NotificationService';
import toast from 'react-hot-toast';

const NotificationItem = ({ notificationItem }) => {
  const [loading, setLoading] = useState(false);

  const handleNotificationStatus = (e, notificationId, notificationStatus) => {
    e.preventDefault();

    setLoading(true);

    const notificationData = {
      isRead: true,
      assetStatus: 'available',
      notificationStatus: notificationStatus,
      notificationId: notificationId,
      assetId: notificationItem.assetId[0].assetId
    };

    notificationService
      .updateNotification(notificationData)
      .then((data) => {
        toast.success(`Successfully ${notificationStatus} the asset request`);
        // eslint-disable-next-line no-undef
        window.location.reload();
      })
      .catch((error) => {
        console.log('error is:', error);
        toast.error(error?.response?.data?.message || 'Some data missing to accept or decline asset');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid style={{ border: '1px solid', padding: '1rem' }}>
      {notificationItem?.type === 'assetApprovalRequest' ? (
        <Grid>
          {notificationItem?.senderDisplayName} has requested you asset:&nbsp;
          <b>{notificationItem?.assetId[0]?.description || ' asset description'}</b>
        </Grid>
      ) : (
        <Grid>
          {notificationItem?.senderDisplayName} has {notificationItem?.notificationStatus} asset:&nbsp;
          <b>{notificationItem?.assetId[0]?.description || ' asset description'}</b>
        </Grid>
      )}

      {notificationItem?.type === 'assetApprovalRequest' ? (
        <Grid style={{ cursor: 'pointer' }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={(e) => {
              handleNotificationStatus(e, notificationItem?._id, 'accepted');
            }}
            style={{ marginRight: '10px' }}
          >
            Accept
          </Button>
          <Button
            onClick={(e) => {
              handleNotificationStatus(e, notificationItem?._id, 'declined');
            }}
            color="secondary"
            variant="outlined"
            size="small"
            disabled={loading}
          >
            Decline
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  notificationItem: PropTypes.any
};
