/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  TableInlineCellEditing,
  TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';
import ReactSelect from 'react-select';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import itemService from '../../../services/ItemService';
import { getFormattedItemRecords } from '../../MyTime/table/helpers';
import AsyncSelect from 'react-select/async';
import { useDebounce } from 'react-use';

const getRowId = (row) => row.id;

const FocusableCell = ({ onClick, ...restProps }) => <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />;

const LookupEditor = ({ value, onValueChange, column, onFocus, onBlur, autoFocus, availableColumnValues }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const columnName = column.name;
  const isClassRef = columnName === 'ClassRef';

  // Predefined options for ClassRef
  const classRefOptions = availableColumnValues['ClassRef']?.map((item) => ({
    value: item.name || item,
    label: item.name || item,
    id: item.value || item
  }));

  const loadOptions = async (inputValue) => {
    try {
      let queryParams;
      if (inputValue?.length > 3 && onBlur) {
        queryParams = {
          take: 100,
          skip: 100,
          queryString: inputValue
        };

        const itemRefs = await itemService.getItemsFromQBO(queryParams);

        if (itemRefs?.data?.data?.QueryResponse?.Item?.length > 0) {
          const formattedItemRefs = getFormattedItemRecords(itemRefs.data.data.QueryResponse.Item);

          const options = formattedItemRefs?.map((item) => ({
            value: item.name || item,
            label: item.name || item,
            id: item.value || item,
            Description: item.Description || '',
            ClassRef: item.ClassRef || ''
          }));

          return options;
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  // Determine the options to use based on columnName
  const options = isClassRef ? classRefOptions : [];

  // const debouncedLoadOptions = useDebounce(loadOptions, 1000); // Adjust the debounce delay as needed

  const dropdownValue = { value, label: value };
  return (
    <>
      {isClassRef ? (
        <ReactSelect
          onBlur={isSmall ? null : onBlur} //blur doesn't work in mobile phones
          className="basic-single"
          classNamePrefix="select"
          defaultValue={dropdownValue}
          isSearchable={true}
          name="color"
          options={options}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onChange={(selectedOption) => {
            onValueChange({
              name: selectedOption.value,
              value: selectedOption.id
            });
          }}
        />
      ) : (
        <AsyncSelect
          onBlur={isSmall ? null : onBlur} //blur doesn't work in mobile phones
          className="basic-single"
          classNamePrefix="select"
          defaultValue={dropdownValue}
          isSearchable={true}
          name="color"
          loadOptions={loadOptions} // Use the loadOptions function for async loading
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onChange={(selectedOption) => {
            if (columnName === 'ItemRef') {
              onValueChange({
                name: selectedOption.value,
                value: selectedOption.id,
                Description: selectedOption.Description,
                ClassRef: selectedOption.ClassRef
              });
            } else {
              onValueChange(selectedOption.value);
            }
          }}
        />
      )}
    </>
  );
};

const LookupFormatter = ({ availableColumnValues, ...props }) => (
  <DataTypeProvider
    editorComponent={(params) => <LookupEditor availableColumnValues={availableColumnValues} {...params} />}
    {...props}
  />
);

const AddExpenseDialogTable = ({ itemRefs, classRefs, rows, setRows }) => {
  const [columns] = useState([
    {
      name: 'ItemRef',
      title: 'Item',
      getCellValue: (row) => (row?.ItemRef ? row?.ItemRef.name : '')
    },

    { name: 'Description', title: 'Description' },
    {
      name: 'ClassRef',
      title: 'Class',
      getCellValue: (row) => (row?.ClassRef ? row?.ClassRef.name : '')
    },
    { name: 'Qty', title: 'Qty' },
    { name: 'Price', title: 'Price' }
  ]);

  const [columnWidths, setColumnWidths] = useState([
    { columnName: 'ItemRef', width: 160 },
    { columnName: 'Description', width: 200 },
    { columnName: 'Qty', width: 90 },
    { columnName: 'Price', width: 90 },
    { columnName: 'ClassRef', width: 180 }
  ]);

  const [lookupColumns] = useState(['ItemRef', 'ClassRef']);

  const [editingCells, setEditingCells] = useState([]);

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      const startingAddedId = rows.length > 0 ? Math.max(rows[rows.length - 1].id, rows[0].id) + 1 : 0;
      changedRows = [
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row
        })),
        ...rows
      ];
      setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
    }
    if (changed) {
      if (changed[rows.length - 1]?.ItemRef) {
        changed[rows.length - 1].Description = changed[rows.length - 1].ItemRef.Description;
        changed[rows.length - 1].ClassRef = changed[rows.length - 1].ItemRef.ClassRef;
      }
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      // eslint-disable-next-line no-undef
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
    }
    console.log('changedRows', changedRows);
    setRows(changedRows);
  };

  const addEmptyRow = () => commitChanges({ added: [{}] });

  const availableColumnValues = {
    ItemRef: itemRefs,
    ClassRef: classRefs
  };

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <LookupFormatter for={lookupColumns} availableColumnValues={availableColumnValues} />

        <EditingState
          onCommitChanges={commitChanges}
          editingCells={editingCells}
          onEditingCellsChange={setEditingCells}
          addedRows={[]}
          onAddedRowsChange={addEmptyRow}
        />
        <Table cellComponent={FocusableCell} />
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableHeaderRow />
        <TableInlineCellEditing selectTextOnEditStart />
        <TableEditColumn showAddCommand showDeleteCommand />
      </Grid>
    </Paper>
  );
};

export default AddExpenseDialogTable;
