/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { longServiceLeave } from '../helper';
import { longServiceLeaveSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2)
  }
}));

const LongServiceLeaveQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [longServiceLeaveQualification, setLongServiceLeaveQualification] = useState({
    longServiceLeave_cardNumber: '',
    longServiceLeave_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlLongServiceLeave,
    handleSubmit: handleLongServiceLeaveSubmit,
    reset: resetLongServiceLeave,
    getValues: getLongServiceLeaveValues,
    formState: { errors: errorsLongServiceLeave, dirtyFields: dirtyFieldsLongServiceLeave }
  } = useForm({
    resolver: zodResolver(longServiceLeaveSchema),
    defaultValues: longServiceLeaveQualification
  });

  useEffect(() => {
    const fetchEmployeeQualification = async () => {
      try {
        setSubmitting(true);
        if (expanded === 'panel6') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName('Long Service Leave');
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let longServiceLeaveQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'Long Service Leave').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'Long Service Leave')[0];
            longServiceLeaveQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              longServiceLeave_cardNumber: '',
              longServiceLeave_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'Long Service Leave')[0];
            longServiceLeaveQualification = longServiceLeave(filteredQualification);
          }
          setLongServiceLeaveQualification(longServiceLeaveQualification);
          resetLongServiceLeave(longServiceLeaveQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get long service leave qualification');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualification();
  }, [resetLongServiceLeave, expanded]);

  const onSubmitLongServiceLeave = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getLongServiceLeaveValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        referenceNumber: dirtyValues.longServiceLeave_cardNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsLongServiceLeave.longServiceLeave_photoOfCard) {
        formData.append('avatar', dirtyValues.longServiceLeave_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }
      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedQualification = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   longServiceLeave_cardNumber: dataObj.referenceNumber || '',
          //   longServiceLeave_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetLongServiceLeave(updatedQualification);
          // toast.success('Successfully updated long service leave card');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update long service leave qualification');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleLongServiceLeaveSubmit(onSubmitLongServiceLeave)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <h5> Card Number</h5>
              <Controller
                name="longServiceLeave_cardNumber"
                control={controlLongServiceLeave}
                render={({ field }) => (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsLongServiceLeave.longServiceLeave_cardNumber}
                    helperText={errorsLongServiceLeave.longServiceLeave_cardNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <h5>Photo of Card</h5>
              <Controller
                name="longServiceLeave_photoOfCard"
                control={controlLongServiceLeave}
                render={({ field }) => (
                  <div>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsLongServiceLeave.longServiceLeave_photoOfCard && (
                      <p style={{ color: 'red' }}>{errorsLongServiceLeave?.longServiceLeave_photoOfCard?.message}</p>
                    )}
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>Long Service Leave</b> update request to the admin. You will get notified
                  once it gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default LongServiceLeaveQualification;

LongServiceLeaveQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
