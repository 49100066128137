import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core/styles';

import employeeQualificationService from '../../../../services/EmployeeQualificationService';
import toast from 'react-hot-toast';
import Qualification from './Qualification';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const UserQualification = ({ row, onCancelChanges }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [expanded, setExpanded] = useState(false);
  const [qboQualificationRecord, setQboQualificationRecord] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        const qboQualificationResponse = await employeeQualificationService.getQboQualification();
        const qboQualificationData = qboQualificationResponse?.data?.data;

        setQboQualificationRecord(qboQualificationData);
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get qbo qualification');
      }
    };
    fetchEmployeeQualificationById();
  }, []);

  return (
    <div style={{ height: '800px' }}>
      <h2 style={{ borderBottom: 'none', padding: isSmall ? '10px 1rem' : '10px 2rem', overflowX: 'hidden' }}>
        User Qualifications
      </h2>
      <div style={{ padding: isSmall ? '10px 1rem' : '10px 2rem' }}>
        {qboQualificationRecord.length > 0 &&
          qboQualificationRecord.map((qualification, index) => {
            return (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                key={qualification.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography className={classes.heading}>{qualification.name}</Typography>
                </AccordionSummary>
                <AccordionDetails key={qualification.id}>
                  {expanded === `panel${index}` && (
                    <Qualification
                      qualificationId={qualification.id}
                      KeyPayEmployeeId={row.KeyPayEmployeeId}
                      displayName={row.DisplayName}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: isSmall ? '1rem' : '2rem',
          paddingTop: '1rem',
          paddingBottom: '2rem'
        }}
      >
        <Button autoFocus variant="outlined" color="secondary" onClick={onCancelChanges}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default UserQualification;

UserQualification.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
