import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//list the timesheet of admin and/or each empoyee in their respective profile
async function listEmployeeTimesheet() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };

  const endpoint = BASE_URL + 'timesheet/listEmployeeTimesheet';
  return axios.post(endpoint, payload);
}

//add timesheet by admin or any employee, save as draft option
function addTimesheet(formData) {
  const endpoint = BASE_URL + 'timesheet/addTimesheet';
  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//add and submit timesheet by admin or any employee, save and submit option
function saveAndSubmitTimesheet(formData) {
  const endpoint = BASE_URL + 'timesheet/saveAndSubmitTimesheet';
  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

function editTimesheet(editRow, editData) {
  const endpoint = BASE_URL + `timesheet/editTimesheet/${editRow}`;
  return axios.put(
    endpoint,
    {
      editData: editData
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//after submit for approval button is clicked
function handleSubmitForApprover(timesheetIds) {
  const endpoint = BASE_URL + 'timesheet/changeTimesheetStatus';
  return axios.put(
    endpoint,
    {
      timesheetIds: timesheetIds
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//list all the employees
function listEmployeeByStatus(payload) {
  const endpoint = BASE_URL + 'timesheet/listEmployeeByStatus';
  return axios.post(endpoint, payload);
}

//list all the timesheet to count different status
function listAllTimesheet() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'timesheet/listAllTimesheet';
  return axios.post(endpoint, payload);
}

//list all the timesheet to count different status
function listEmployeeTimesheetByStatus(payload) {
  const endpoint = BASE_URL + 'timesheet/listEmployeeTimesheetByStatus';
  return axios.post(endpoint, payload);
}

//send remainder to the users
function sendEmail(filteredUsers, message) {
  const endpoint = BASE_URL + 'employee/sendEmail';
  return axios.post(
    endpoint,
    {
      filteredUsers: filteredUsers,
      message: message
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//change all employee timesheet status submitted by admin
function changeTimesheetStatusSubmittedByAdmin(selectedTimesheetIds) {
  const endpoint = BASE_URL + 'timesheet/timesheetSubmitByAdmin';
  return axios.put(
    endpoint,
    {
      timesheetIds: selectedTimesheetIds
    },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//timesheet search by admin
function listAdminSearchTimesheetByDate(payload) {
  const endpoint = BASE_URL + 'timesheet/listAdminSearchTimesheetByDate';
  return axios.post(endpoint, payload);
}

//once the admin link employee/supplier with the qbo supplier save the user/employee data in linkemployee/employeesQBO
function linkEmployee(payload) {
  const endpoint = BASE_URL + 'employee/linkEmployee';
  return axios.post(endpoint, payload);
}

//delete timesheet
function deleteTimesheet(deleteId) {
  const url = BASE_URL + `timesheet/deleteTimesheet/${deleteId}`;
  return axios.delete(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//change status approved to WithApprover timesheet
function changeStatusBackToWithApprover(timesheetIds) {
  const url = BASE_URL + 'timesheet/changeStatusBackToWithApprover';
  return axios.put(
    url,
    { timesheetIds: timesheetIds },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//change status to WithEmployee and send rejected email/notification to the employee
function rejectAndSendReminder(timesheetIds, filteredUsers, message) {
  const url = BASE_URL + 'timesheet/rejectAndSendReminder';
  return axios.put(
    url,
    { timesheetIds: timesheetIds, filteredUsers: filteredUsers, message: message },

    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//get timesheet of employee excluding "WithEmployee" status to show in approve timesheet component
function getEmployeeSubmittedTimesheet() {
  const url = BASE_URL + 'timesheet/getEmployeeSubmittedTimesheet';
  return axios.get(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const timesheetService = {
  listEmployeeTimesheet,
  addTimesheet,
  saveAndSubmitTimesheet,
  editTimesheet,
  handleSubmitForApprover,
  listEmployeeByStatus,
  listAllTimesheet,
  listEmployeeTimesheetByStatus,
  sendEmail,
  changeTimesheetStatusSubmittedByAdmin,
  listAdminSearchTimesheetByDate,
  linkEmployee,
  deleteTimesheet,
  changeStatusBackToWithApprover,
  rejectAndSendReminder,
  getEmployeeSubmittedTimesheet
};
export default timesheetService;
