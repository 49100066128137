import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//link parent-child assets, to show same location of parent-child location by beacon
function createLinkAsset(linkAssetRecord) {
  const endpoint = BASE_URL + 'linkAsset/createLinkAsset';

  return axios.post(
    endpoint,
    { linkAssetRecord: linkAssetRecord },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

function getLinkedAsset(linkedAssetId) {
  const endpoint = BASE_URL + `linkAsset/getLinkedAsset/${linkedAssetId}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const linkAssetService = {
  createLinkAsset,
  getLinkedAsset
};

export default linkAssetService;
