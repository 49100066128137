import { useEffect, useState } from 'react';
import clientService from '../../../services/ClientService';
import { getFormattedCustomerRecords } from '../table/helpers';
import { BASE_URL } from '../../Constant';
import axios from 'axios';

export default function useFetchCustomerRefRecords() {
  const [customerRefs, setCustomerRefs] = useState([]);
  const [error, setError] = useState(null);
  const [customerRefLoading, setCustomerRefLoading] = useState(false);

  useEffect(() => {
    const fetchCustomerRefRecords = async () => {
      try {
        setCustomerRefLoading(true);
        setError(null);

        const url1 = BASE_URL + 'client/getCustomersFromQBO';
        const url2 = BASE_URL + 'client/getCustomersFromDatabase';

        const payload = {
          // eslint-disable-next-line no-undef
          token: localStorage.getItem('token')
        };

        const customerRefList = await axios.post(url1, payload);
        const formattedCustomerRefs = getFormattedCustomerRecords(customerRefList.data.data.QueryResponse.Customer);

        const customerFromDB = await axios.post(url2, payload);
        const custmerRecords = customerFromDB.data.data;

        const updatedRowsByCustomerStatus = formattedCustomerRefs.filter((row) => {
          return custmerRecords.find((c) => row.value === c.Id && c.customerStatus);
        });

        setCustomerRefs(updatedRowsByCustomerStatus);
      } catch (e) {
        setError(e.message || 'Could not fetch timesheet data');
      } finally {
        setCustomerRefLoading(false);
      }
    };

    fetchCustomerRefRecords();
  }, []);

  return { customerRefs, customerRefLoading, error };
}
