import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileUpload from './FileUpload';

export default function ItemFileUploadDialog({ setItemFileUploadOpen, open, handleItemFiles, setItemFiles }) {
  return (
    <div>
      <Dialog open={open} onClose={() => setItemFileUploadOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload multiple product and services file.</DialogTitle>
        <DialogContent>
          <FileUpload setItemFileUploadOpen={setItemFileUploadOpen} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

ItemFileUploadDialog.propTypes = {
  setItemFileUploadOpen: PropTypes.func,
  open: PropTypes.bool,
  handleFileChange: PropTypes.func,
  handleItemFiles: PropTypes.func,
  setItemFiles: PropTypes.func
};
