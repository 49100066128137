export default function formattedQboEmployee(qboEmployeeList) {
  return qboEmployeeList.map((item) => ({
    PrimaryAddr: item.PrimaryAddr || '',
    id: item.Id,
    BillableTime: item.BillableTime || '',
    CostRate: item.CostRate || '',
    sparse: item.sparse || '',
    Id: item.Id || '',
    SyncToken: item.SyncToken || '',

    Title: item.Title || '',
    GivenName: item.GivenName || '',
    MiddleName: item.MiddleName || '',
    FamilyName: item.FamilyName || '',
    DisplayName: item.DisplayName || '',
    PrintOnCheckName: item.PrintOnCheckName || '',
    Active: item.Active || '',
    V4IDPseudonym: item.V4IDPseudonym || '',
    Mobile: item.Mobile || '',
    PrimaryEmailAddr: item.PrimaryEmailAddr || ''
  }));
}
