import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from '@material-ui/core';
import employeeService from '../../../services/EmployeeService';

const useStyles = makeStyles(() => ({
  arrowDropDown: { color: 'white', marginTop: '5px', marginLeft: '2px' },
  menuList: {
    cursor: 'pointer'
  },
  menu: {
    zIndex: 999,
    marginTop: '10px',
    '@media (max-width: 600px)': {
      marginTop: '10px'
    }
  },
  menuItem: {
    '&:hover': {
      color: '#4040a1'
    }
  }
}));

export default function ButtonMenu({ buttonList, handleOptions, setAnchorEl, anchorEl }) {
  const classes = useStyles();
  const [keyPayEmployeeId, setKeyPayEmployeeId] = useState('');

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      const employeeDetails = await employeeService.getEmployeeDetails();
      setKeyPayEmployeeId(employeeDetails.data.data.KeyPayEmployeeId);
    };

    fetchEmployeeDetails();
  }, []);

  return (
    <div style={{ padding: '0px 3px' }}>
      <ArrowDropDownIcon
        className={classes.arrowDropDown}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className={classes.menu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
      >
        {buttonList.map((option) => (
          <MenuItem
            onClick={(e) => handleOptions(e, option.value || option)}
            className={classes.menuItem}
            value={option.value || option}
            key={option.value || option}
            disabled={
              (option === 'Take Leave' && !keyPayEmployeeId) || (option === 'Request Leave' && !keyPayEmployeeId)
                ? true
                : false
            }
          >
            {option.value || option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ButtonMenu.propTypes = {
  buttonList: PropTypes.array,
  handleOptions: PropTypes.func,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.any
};
