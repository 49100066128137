/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { ewp } from '../helper';
import { ewpSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const EWPQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [ewpQualification, setEWPQualification] = useState({
    ewp_licenseNumber: '',
    ewp_completionDate: '',
    ewp_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlEWP,
    handleSubmit: handleEWPSubmit,
    reset: resetEWP,
    getValues: getEWPValues,
    formState: { errors: errorsEWP, dirtyFields: dirtyFieldsEWP }
  } = useForm({
    resolver: zodResolver(ewpSchema),
    defaultValues: ewpQualification
  });

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        setSubmitting(true);
        if (expanded === 'panel3') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName('EWP (Scizzor lift)');
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let ewpQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'EWP (Scizzor lift)').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'EWP (Scizzor lift)')[0];
            ewpQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              ewp_licenseNumber: '',
              ewp_completionDate: '',
              ewp_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'EWP (Scizzor lift)')[0];
            ewpQualification = ewp(filteredQualification);
          }
          setEWPQualification(ewpQualification);
          resetEWP(ewpQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get EWP (Scizzor lift) card');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualificationById();
  }, [resetEWP, expanded]);

  const onSubmitEWP = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getEWPValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        issueDate: dirtyValues.ewp_completionDate,
        referenceNumber: dirtyValues.ewp_licenseNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsEWP.ewp_photoOfCard) {
        formData.append('avatar', dirtyValues.ewp_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }
      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedQualification = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   ewp_licenseNumber: dataObj.referenceNumber || '',
          //   ewp_completionDate: dataObj.issueDate ? format(new Date(dataObj.issueDate), 'yyyy-MM-dd') : '',
          //   ewp_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetEWP(updatedQualification);
          // toast.success('Successfully updated EWP (Scizzor lift) card');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update EWP (Scizzor lift) card');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleEWPSubmit(onSubmitEWP)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> License Number</h5>
              <Controller
                name="ewp_licenseNumber"
                control={controlEWP}
                render={({ field }) => (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsEWP.ewp_licenseNumber}
                    helperText={errorsEWP.ewp_licenseNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Completion Date</h5>
              <Controller
                name="ewp_completionDate"
                control={controlEWP}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsEWP.ewp_completionDate}
                    helperText={errorsEWP.ewp_completionDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card</h5>
              <Controller
                name="ewp_photoOfCard"
                control={controlEWP}
                render={({ field }) => (
                  <div>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsEWP.ewp_photoOfCard && <p style={{ color: 'red' }}>{errorsEWP?.ewp_photoOfCard?.message}</p>}
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>EWP (Scizzor lift)</b> update request to the admin. You will get notified
                  once it gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default EWPQualification;

EWPQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
