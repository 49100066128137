import { useEffect, useState } from 'react';
import timesheetService from '../../../services/TimesheetService';
import { getFormattedTableRows } from '../table/helpers';
import { addDays } from 'date-fns';

export default function useFetchEmployeeTimesheet(filter) {
  const [error, setError] = useState(null);
  const [loading, setloading] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchEmployeeTimesheet = async () => {
      try {
        setError(null);
        setloading(true);

        const timesheetList = await timesheetService.listEmployeeTimesheet();

        const formattedRows = getFormattedTableRows(timesheetList.data.data);

        const filteredRows = formattedRows.filter((r) => {
          if (filter.timesheetPeriod === 'All') return true;

          if (
            (filter.startDate === null || new Date(r.Date) >= new Date(filter.startDate).getTime()) &&
            new Date(r.Date) <= addDays(new Date(filter.endDate), 1).getTime()
          ) {
            return true;
          }

          return false;
        });

        const searchedRows = searchRows(filteredRows, filter.searchQuery);

        setRows(searchedRows);
      } catch (e) {
        setError(e.message || 'Could not fetch timesheet data');
      } finally {
        setloading(false);
      }
    };

    fetchEmployeeTimesheet();
  }, [filter.endDate, filter.startDate, filter.timesheetPeriod, filter.searchQuery]);
  return { rows, setRows, error, loading };
}

function searchRows(allRows, query) {
  const searchedRows = allRows.filter((r) => {
    return (
      r.CustomerRef?.name.toLowerCase().includes(query) ||
      r.EmployeeRef?.name.toLowerCase().includes(query) ||
      r.status.toLowerCase().includes(query) ||
      r.variationNote.toLowerCase().includes(query) ||
      r.Description.toLowerCase().includes(query) ||
      r.ClassRef?.name.toLowerCase().includes(query) ||
      r.VendorRef?.name.toLowerCase().includes(query)
    );
  });

  return searchedRows;
}
