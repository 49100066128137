import { useEffect, useState } from 'react';
import classService from '../../../services/ClassService';
import { getFormattedClassRecords } from '../table/helpers';

export default function useFetchClassRefRecords() {
  const [classRefs, setClassRefs] = useState([]);
  const [classRefError, setClassRefError] = useState(null);
  const [classRefLoading, setClassRefLoading] = useState(false);

  useEffect(() => {
    const fetchClassRefRecords = async () => {
      try {
        setClassRefLoading(true);
        setClassRefError(null);

        const classRefList = await classService.getClassListFromQBO();

        if (classRefList.data.data.QueryResponse.Class.length) {
          const formattedClassRefs = getFormattedClassRecords(classRefList.data.data.QueryResponse.Class);

          const classFromDB = await classService.getClassListFromDatabase();
          const classRecords = classFromDB.data.data;

          const updatedRowsByClassStatus = formattedClassRefs.filter((row) => {
            return classRecords.find((c) => row.value === c.Id && c.classStatus);
          });

          setClassRefs(updatedRowsByClassStatus);
        }
      } catch (e) {
        setClassRefError(e.message || 'Could not fetch timesheet data');
      } finally {
        setClassRefLoading(false);
      }
    };

    fetchClassRefRecords();
  }, []);

  return { classRefs, classRefLoading, classRefError };
}
