import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get all the employee qualification
function getOfficeInfo(officePayload) {
  const endpoint = BASE_URL + 'officeInfo/getOfficeInfo';
  return axios.post(endpoint, officePayload, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//create or update employee qualification
function createOfficeInfo(officeData) {
  const endpoint = BASE_URL + 'officeInfo/createOfficeInfo';
  return axios.post(endpoint, officeData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//create or update employee qualification
function updateOfficeInfo(officeData) {
  const endpoint = BASE_URL + 'officeInfo/updateOfficeInfo';
  return axios.put(endpoint, officeData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const officeInfoService = {
  getOfficeInfo,
  createOfficeInfo,
  updateOfficeInfo
};

export default officeInfoService;
