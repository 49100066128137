import React from 'react';
import PropTypes from 'prop-types';

import { Marker, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const VehicleTrackerItem = ({ vehicleTrackerItem }) => {
  const latitude = -37.79869;
  const longitude = 144.89608;
  const position = [latitude, longitude];

  return (
    <Marker
      key={vehicleTrackerItem.deviceName}
      position={[vehicleTrackerItem.lat || latitude, vehicleTrackerItem.long || longitude]}
    >
      <Popup>
        {vehicleTrackerItem.deviceName} <br />
      </Popup>
      <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
        <span>{vehicleTrackerItem.deviceName}</span>
      </Tooltip>
    </Marker>
  );
};

export default VehicleTrackerItem;

VehicleTrackerItem.propTypes = {
  vehicleTrackerItem: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
    deviceName: PropTypes.string
  })
};
