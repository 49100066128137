import React, { useState } from 'react';
import useFetchEmployeeAssetRecords from './hooks/useFetchEmployeeAssetRecords';
import MyAssetTable from './table/MyAssetTable';
import AssetRequestSelect from './select/AssetRequestSelect';

const MyAsset = () => {
  const { employeeAssets, setEmployeeAssets } = useFetchEmployeeAssetRecords();

  const [columns] = useState([
    { name: 'assetId', title: 'AssetId' },
    { name: 'tokenMac', title: 'TokenMac' },
    { name: 'make', title: 'Make' },
    { name: 'model', title: 'Model' },
    { name: 'description', title: 'Description' },
    { name: 'tradeSpecific', title: 'TradeSpecific' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'category', title: 'Category' },
    { name: 'dateOfPurchase', title: 'DateOfPurchase' },
    { name: 'owner', title: 'Owner' },
    { name: 'operator', title: 'Operator' },
    { name: 'location', title: 'Location' },
    { name: 'locationDate', title: 'LocationDate' },
    { name: 'insuranceName', title: 'InsuranceName' },
    { name: 'insurancePolicy', title: 'InsurancePolicy' },
    { name: 'insuranceExpiry', title: 'InsuranceExpiry' },
    { name: 'maintenanceSchedule', title: 'MaintenanceSchedule' },
    { name: 'disposalDate', title: 'DisposalDate' },
    { name: 'image', title: 'Image' },
    { name: 'comments', title: 'Comments' }
  ]);

  return (
    <div>
      <AssetRequestSelect />
      <MyAssetTable rows={employeeAssets} setRows={setEmployeeAssets} columns={columns} />
    </div>
  );
};

export default MyAsset;
