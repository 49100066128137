import React from 'react';
import PropTypes from 'prop-types';
import { read, utils } from 'xlsx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ImportVendorDialog = ({ open, setImportFileDialogOpen, handleImportedVendor, setImportFile }) => {
  const handleInputChange = (event) => {
    const file = event.target.files[0];

    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = (e) => {
      // eslint-disable-next-line no-undef
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Get the first worksheet
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert the worksheet data to JSON object
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 }).map((row) => {
        const obj = {};
        row.forEach((cell, index) => {
          const headerCell = worksheet[utils.encode_col(index) + '1'];
          const headerCellValue = headerCell ? headerCell.v : '';
          obj[headerCellValue] = cell;
        });
        return obj;
      });

      // Pass the JSON data to the handleFileUpload function
      setImportFile(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setImportFileDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Import Vendor</DialogTitle>
        <DialogContent>
          <DialogContentText>Select file to import vendor.</DialogContentText>

          <input type="file" id="file-select" accept=".xlsx, .csv" onChange={handleInputChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImportFileDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleImportedVendor} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportVendorDialog;

ImportVendorDialog.propTypes = {
  open: PropTypes.bool,
  setImportFileDialogOpen: PropTypes.func,
  handleImportedVendor: PropTypes.func,
  setImportFile: PropTypes.any
};
