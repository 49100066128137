import { useEffect, useState } from 'react';
import itemService from '../../../services/ItemService';
import { getFormattedItemRecords } from '../table/helpers';

export default function useFetchItemRefs() {
  const [itemRefs, setItemRefs] = useState([]);
  const [itemRefsError, setItemRefsError] = useState('');
  const [itemRefsLoading, setItemRefsLoading] = useState(false);

  const [queryParams] = useState({
    take: 100,
    skip: 100,
    queryString: ''
  });

  useEffect(() => {
    const fetchItemRefs = async () => {
      try {
        setItemRefsLoading(true);
        const itemRefsRecords = await itemService.getItemsFromQBO(queryParams);

        const formattedItemRefs = getFormattedItemRecords(itemRefsRecords.data.data.QueryResponse.Item);

        const itemFromDB = await itemService.getItemsFromDatabase();
        const itemRecords = itemFromDB.data.data;

        const updatedRowsByItemStatus = formattedItemRefs.filter((row) => {
          return itemRecords.find((c) => row.value === c.Id && c.itemStatus);
        });

        setItemRefs(updatedRowsByItemStatus);
      } catch (error) {
        setItemRefsError(error);
      } finally {
        setItemRefsLoading(false);
      }
    };

    fetchItemRefs();
  }, [queryParams]);

  return { itemRefs, itemRefsError, itemRefsLoading };
}
