import { Button } from '@material-ui/core';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const QboEmployeeDialogContent = ({ onCancelChanges, onApplyChanges }) => {
  return (
    <div style={{ height: '700px' }}>
      <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: '3rem', marginBottom: '0px', paddingBottom: '0px', lineHeight: '1' }}
      >
        QboEmployeeDialogContent
      </DialogTitle>
      <DialogContent>
        {' '}
        <div>Working on..</div>
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default QboEmployeeDialogContent;
