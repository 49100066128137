/**
 * Format expense data api response
 */
export function getFormattedTableRows(expenseList) {
  return expenseList.map((item) => ({
    id: item._id,
    BillableStatus: new Boolean(item.BillableStatus).toString(),
    EmployeeRef: item.EmployeeRef || '',
    CustomerRef: item.CustomerRef || '',
    ClassRef: item.ClassRef || '',
    ItemRef: item.ItemRef || '',
    AccountRef: item.AccountRef || '',
    Date: new Date(item.Date) || '',
    Description: item.Description || '',
    variationNote: item.variationNote || '',
    ExpenseReceiptUrl: item.expenseReceiptImageUrl || '',
    status: item.status || '',
    workType: item.workType || '',
    KeyPayEmployeeId: item.KeyPayEmployeeId || '',
    VendorRef: item.VendorRef || '',
    jobTitle: item.jobTitle || '',
    email: item.email || '',
    PaidBy: item.paidByEmployee ? 'Paid by employee' : 'Paid by company',
    Price: item.Price || '',
    Qty: item.Qty || '',
    purchaseOrder: item.purchaseOrder || '',
    GlobalTaxCalculation: item.GlobalTaxCalculation || '',
    DocNumber: item.DocNumber || ''
  }));
}

export function getFormattedGlobalTaxCalculation() {
  return ['TaxExcluded', 'TaxInclusive'];
}
