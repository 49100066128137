import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import requestLeaveService from '../../../services/RequestLeaveService';

const useStyles = makeStyles(() => ({
  requestLeaveOuterWrapper: {
    display: 'flex',
    boxShadow:
      '0px 2px 9px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: '2rem',
    padding: '1rem'
  },

  requestLeaveWrapper: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column'
  },

  leaveRequestActionWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20%',
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  leaveRequestP: {
    fontSize: '14px',
    marginBottom: '0.5rem',
    textAlign: 'left'
  },

  verticalLine: {
    height: '200px',
    borderLeft: '1px solid rgb(223 227 245)',
    marginLeft: '10px'
  }
}));

const RequestLeave = ({ leaveRequestItem, setLeaveRequestRecord, employeeId }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptLeaveRequest = async (e, leaveRequestId) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = await requestLeaveService.approveLeaveRequest(leaveRequestId);

      const successMessage = data?.data?.message;
      const employeeLeaveRequest = data?.data?.data;

      // Update state with the incoming list (even if it's empty)
      setLeaveRequestRecord(employeeLeaveRequest || []);

      toast.success(successMessage || 'Successfully accepted leave request and notified the employee');
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      toast.error(errorMessage || 'Internal Server error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectLeaveRequest = async (e, leaveRequestId) => {
    e.preventDefault();
    setIsLoading(true);

    // eslint-disable-next-line no-undef
    const rejectReason = prompt('Reason to reject the leave request', '');

    if (rejectReason !== null && rejectReason !== '') {
      requestLeaveService
        .rejectLeaveRequest(leaveRequestId, rejectReason)
        .then((data) => {
          const employeeLeaveRequest = data?.data?.data;

          // Update state with the incoming list (even if it's empty)
          setLeaveRequestRecord(employeeLeaveRequest || []);
          toast.success(`${leaveRequestItem?.employeeDisplayName}'s ${leaveRequestItem.leaveType} request rejected.`);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Internal Server error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      return toast.error('Please write a reason to reject the leave request');
    }
  };

  return (
    <div>
      <div className={classes.requestLeaveOuterWrapper}>
        <div className={classes.requestLeaveWrapper}>
          <h3>Leave Request by {leaveRequestItem?.employeeDisplayName}</h3>
          <div>
            <div style={{ textAlign: 'left' }}>
              <p className={classes.leaveRequestP}>
                Leave Type : <b>{leaveRequestItem?.leaveType}</b>
              </p>
              <p className={classes.leaveRequestP}>
                Leave Type :<b>{leaveRequestItem?.numberOfDays}</b>
              </p>
              <p className={classes.leaveRequestP}>
                Last Day of Leave: <b> {format(new Date(leaveRequestItem?.firstDayOfLeave), 'dd-MM-yyyy')}</b>
              </p>
              <p className={classes.leaveRequestP}>
                First Day of Leave: <b>{format(new Date(leaveRequestItem?.lastDayOfLeave), 'dd-MM-yyyy')}</b>
              </p>
              <p className={classes.leaveRequestP}>
                First Day Back To Work: <b>{format(new Date(leaveRequestItem?.firstDayBackToWork), 'dd-MM-yyyy')}</b>
              </p>
            </div>
          </div>
        </div>

        <div className={classes.verticalLine}></div>

        <div className={classes.leaveRequestActionWrapper}>
          <h3>Actions</h3>
          <div className={classes.actionWrapper}>
            <Button
              onClick={(e) => handleRejectLeaveRequest(e, leaveRequestItem?._id)}
              variant="outlined"
              color="secondary"
              style={{
                marginRight: '1rem'
              }}
              disabled={isLoading}
            >
              Reject
            </Button>
            <Button
              onClick={(e) => handleAcceptLeaveRequest(e, leaveRequestItem?._id)}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestLeave;

RequestLeave.propTypes = {
  leaveRequestItem: PropTypes.any,
  setLeaveRequestRecord: PropTypes.func,
  employeeId: PropTypes.string
};
