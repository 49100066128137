import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//once admin upload all the assets file
function createAssets(formData) {
  const endpoint = BASE_URL + 'asset/createAssets';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin upload all the assets file
function uploadAssetImages(formData) {
  const endpoint = BASE_URL + 'asset/uploadAssetImages';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//get all the assets list for admin
function getAssets() {
  const endpoint = BASE_URL + 'asset/getAssets';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get all the assets list for admin
function getEmployeeAssets() {
  // const endpoint = BASE_URL + 'asset/getEmployeeAssets';

  // eslint-disable-next-line no-undef
  const employeeDisplayName = localStorage.getItem('name');
  const endpoint = `${BASE_URL}asset/getEmployeeAssets?employeeDisplayName=${encodeURIComponent(employeeDisplayName)}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update asset by admin
function updateAssetAndImage(formData) {
  const endpoint = BASE_URL + 'asset/updateAssetAndImage';

  return axios.put(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin add 'new asset' with the image file
function createAsset(formData) {
  const endpoint = BASE_URL + 'asset/createAsset';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin add 'new asset' with the image file
function updateAssetOperator(assetData) {
  const endpoint = BASE_URL + 'asset/updateAssetOperator';

  return axios.put(endpoint, assetData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//once admin add 'new asset' with the image file
function deleteAsset(assetId) {
  const endpoint = BASE_URL + `asset/deleteAsset/${assetId}`;

  return axios.delete(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const assetService = {
  createAssets,
  getAssets,
  uploadAssetImages,
  getEmployeeAssets,
  updateAssetAndImage,
  createAsset,
  updateAssetOperator,
  deleteAsset
};

export default assetService;
