import axios from 'axios';
import { BASE_URL } from '../components/Constant';

const payload = {
  // eslint-disable-next-line no-undef
  token: localStorage.getItem('token')
};

//link users with QBO details in user's accounting edit
function linkEmployee(payload) {
  const endpoint = BASE_URL + 'linkEmployee/linkEmployee';
  return axios.post(endpoint, payload);
}

//get linked employee details in accounting
function getlinkedEmployee(payload) {
  const endpoint = BASE_URL + 'linkEmployee/getlinkedEmployee';
  return axios.post(endpoint, payload);
}

const linkEmployeeService = {
  linkEmployee,
  getlinkedEmployee
};

export default linkEmployeeService;
