/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useState } from 'react';
import saveAs from 'file-saver';

import General from '../tabs/General/General';
import CustomerTab from './CustomerTab';

import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
  EditingState,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
  PagingPanel,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  SearchPanel,
  Toolbar,
  TableSelection,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel
} from '@devexpress/dx-react-grid-material-ui';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Accounting from '../tabs/Accounting/Accounting';
import { useLocalStorage } from 'react-use';
import { getFormattedCustomerPayload } from './helper';
import clientService from '../../../services/ClientService';
import { toast } from 'react-hot-toast';
/* eslint-disable no-shadow */

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open, customerTabIndex, setCustomerTabIndex }) => {
  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      {/* Dialog contains tab and tab contents: CustomerTab is tab part */}
      <CustomerTab customerTabIndex={customerTabIndex} setCustomerTabIndex={setCustomerTabIndex} />

      {/* General and Accounting is the content of tab  */}
      {customerTabIndex === 0 && (
        <General row={row} onChange={onChange} onApplyChanges={onApplyChanges} onCancelChanges={onCancelChanges} />
      )}
      {customerTabIndex === 1 && (
        <Accounting row={row} onCancelChanges={onCancelChanges} setCustomerTabIndex={setCustomerTabIndex} />
      )}
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name
const PopupEditing = React.memo(({ popupComponent: Popup, customerTabIndex, setCustomerTabIndex }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name)
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });

              //Important : Always include sparse true, Id  , SyncToken, FullyQualifiedName, DisplayName,
              let customerPayload = {
                Id: editedRow.id,
                sparse: true,
                SyncToken: editedRow.SyncToken
              };

              //update customerpayload according to quickbook
              let updateCustomerPayload;
              if (rowChanges[rowId]) {
                updateCustomerPayload = getFormattedCustomerPayload(customerPayload, rowChanges[rowId]);
              }

              clientService
                .updateQuickBookCustomer(updateCustomerPayload)
                .then(() => {
                  toast.success('Successfully updated customer in quickbook');
                })
                .catch((error) => {
                  toast.error('Could not update customer in quickbook' || error);
                });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
              setCustomerTabIndex(0);
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
              customerTabIndex={customerTabIndex}
              setCustomerTabIndex={setCustomerTabIndex}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

const getRowId = (row) => row.id;

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

export default function CustomerTable({ rows, setRows, selection, setSelection }) {
  const [customerTabIndex, setCustomerTabIndex] = useState(0);

  const [columns] = useState([
    { name: 'DisplayName', title: 'CustomerName' },
    { name: 'Email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
    { name: 'customerStatus', title: 'Action' },
    { name: 'id', title: 'CustomerId' },

    { name: 'GivenName', title: 'GivenName' },
    { name: 'MiddleName', title: 'MiddleName' },
    { name: 'FamilyName', title: 'FamilyName' },
    { name: 'CompanyName', title: 'CompanyName' },
    { name: 'PrintOnCheckName', title: 'PrintOnCheckName' },

    { name: 'Active', title: 'Active' },
    { name: 'Title', title: 'Title' },
    { name: 'Suffix', title: 'Suffix' },
    { name: 'Taxable', title: 'Taxable' },
    { name: 'SyncToken', title: 'SyncToken' },

    { name: 'Line1', title: 'Line1' },
    { name: 'City', title: 'City' },
    { name: 'CountrySubDivisionCode', title: 'CountrySubDivisionCode' },
    { name: 'PostalCode', title: 'PostalCode' },

    { name: 'Job', title: 'Job' },
    { name: 'ParentRef', title: 'ParentRef' },
    { name: 'BillWithParent', title: 'BillWithParent' },
    { name: 'PreferredDeliveryMethod', title: 'PreferredDeliveryMethod' },
    { name: 'BalanceWithJobs', title: 'BalanceWithJobs' },
    { name: 'IsProject', title: 'IsProject' }
  ]);

  //it was using useLocalStorage customersColumnWidths, update it once customer columns are done
  const [customerColumnWidths, setCustomerColumnWidths] = useState([
    { columnName: 'DisplayName', width: 180 },
    { columnName: 'Email', width: 180 },
    { columnName: 'Phone', width: 180 },
    { columnName: 'customerStatus', width: 180 },
    { columnName: 'id', width: 180 },

    { columnName: 'GivenName', width: 180 },
    { columnName: 'MiddleName', width: 180 },
    { columnName: 'FamilyName', width: 180 },
    { columnName: 'CompanyName', width: 180 },
    { columnName: 'PrintOnCheckName', width: 180 },

    { columnName: 'Active', width: 180 },
    { columnName: 'Title', width: 180 },
    { columnName: 'Suffix', width: 180 },
    { columnName: 'Taxable', width: 180 },
    { columnName: 'SyncToken', width: 180 },

    { columnName: 'Line1', width: 180 },
    { columnName: 'City', width: 180 },
    { columnName: 'CountrySubDivisionCode', width: 180 },
    { columnName: 'PostalCode', width: 180 },

    { columnName: 'Job', width: 180 },
    { columnName: 'ParentRef', width: 180 },
    { columnName: 'BillWithParent', width: 180 },
    { columnName: 'PreferredDeliveryMethod', width: 180 },
    { columnName: 'BalanceWithJobs', width: 180 },
    { columnName: 'IsProject', width: 180 }
  ]);
  //it was using useLocalStorage customersColumnOrder, update it once customer columns are done
  const [customerColumnOrder, setCustomerColumnOrder] = useState([
    'DisplayName',
    'Email',
    'Phone',
    'customerStatus',
    'id',

    'GivenName',
    'MiddleName',
    'FamilyName',
    'CompanyName',
    'PrintOnCheckName',

    'Active',
    'Title',
    'Suffix',
    'Taxable',
    'SyncToken',

    'Line1',
    'City',
    'CountrySubDivisionCode',
    'PostalCode',

    'Job',
    'ParentRef',
    'BillWithParent',
    'PreferredDeliveryMethod',
    'BalanceWithJobs',
    'IsProject'
  ]);

  const [sorting, setSorting] = useState([{ columnName: 'DisplayName', direction: 'asc' }]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['']);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100]);
  const [searchValue, setSearchState] = useState('');

  const commitChanges = ({ changed }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  return (
    <div>
      <span>Total rows selected: {selection.length}</span>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedSelection />

          <DragDropProvider />

          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />

          <SearchState value={searchValue} onValueChange={setSearchState} />
          <IntegratedFiltering />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing columnWidths={customerColumnWidths} onColumnWidthsChange={setCustomerColumnWidths} />
          <TableColumnReordering order={customerColumnOrder} onOrderChange={setCustomerColumnOrder} />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditColumn showEditCommand />
          <PopupEditing
            popupComponent={Popup}
            customerTabIndex={customerTabIndex}
            setCustomerTabIndex={setCustomerTabIndex}
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rows}
          selection={selection}
          onSave={onSave}
          getRowId={getRowId}
          hiddenColumnNames={hiddenColumnNames}
        />
      </Paper>
    </div>
  );
}
