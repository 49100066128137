import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import keyPayEmployeeService from '../../../services/KeypayEmployee';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import toast from 'react-hot-toast';

const useStyles = makeStyles(() => ({
  textfieldLabel: {
    marginBottom: '5px',
    fontSize: '13px'
  }
}));

const EmployeeLeaveBalance = ({ employeeKeyPayId }) => {
  const classes = useStyles();

  const [annualLeave, setAnnualLeave] = useState(0);
  const [rdo, setRdo] = useState(0);

  useEffect(() => {
    const fetchEmployeeLeaveBalance = async () => {
      try {
        if (employeeKeyPayId) {
          const keyPayEmployeeLeaveBalance = await keyPayEmployeeService.getEmployeeLeaveBalance(employeeKeyPayId);

          const employeeLeaveBalanceData = await keyPayEmployeeLeaveBalance.data.data;
          employeeLeaveBalanceData.map((leaveItem) => {
            if (leaveItem.leaveCategoryId === 55346) {
              setAnnualLeave(leaveItem.accruedAmount);
            }
            if (leaveItem.leaveCategoryId === 403364) {
              setRdo(leaveItem.accruedAmount);
            }
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not show leave balances');
      }
    };
    fetchEmployeeLeaveBalance();
  }, [employeeKeyPayId]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <p className={classes.textfieldLabel}>Annual Leave </p>
        <TextField
          required={true}
          variant="outlined"
          size="small"
          type="number"
          name="annualLeave"
          value={annualLeave || ''}
          InputLabelProps={{
            shrink: true
          }}
          disabled
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <p className={classes.textfieldLabel}>RDO</p>
        <TextField
          required={true}
          variant="outlined"
          size="small"
          type="number"
          name="rdo"
          value={rdo || ''}
          InputLabelProps={{
            shrink: true
          }}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeLeaveBalance;

EmployeeLeaveBalance.propTypes = {
  employeeKeyPayId: PropTypes.any
};
