import axios from 'axios';
import { BASE_URL } from '../components/Constant';

const payload = {
  // eslint-disable-next-line no-undef
  token: localStorage.getItem('token')
};

//list keypay employee from keypay
function getAllKeyPayEmployeeList() {
  const endpoint = BASE_URL + 'keyPayEmployee/getAllKeyPayEmployeeList';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get one keypay employee from keypay
function getOneKeyPayEmployee(keyPayEmployeeId) {
  const endpoint = BASE_URL + `keyPayEmployee/getOneKeyPayEmployee/${keyPayEmployeeId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get employee leave balance
function getEmployeeLeaveBalance(keyPayEmployeeId) {
  const endpoint = BASE_URL + `keyPayEmployee/getEmployeeLeaveBalance/${keyPayEmployeeId}`;
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const keyPayEmployeeService = {
  getAllKeyPayEmployeeList,
  getOneKeyPayEmployee,
  getEmployeeLeaveBalance
};

export default keyPayEmployeeService;
