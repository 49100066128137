import React from 'react';
import PropTypes from 'prop-types';

import { Box, Checkbox, Grid, TextField, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ReactSelect from 'react-select';
import AddExpenseDialogTable from './AddExpenseDialogTable';

const useStyles = makeStyles(() => ({
  typography: {
    marginBottom: '5px'
  }
}));

const AddExpenseDialogContent = ({
  expenseFormData,
  customerRefs,
  classRefs,
  setExpenseFormData,
  itemRefs,
  vendorRefs,
  creditCardAccount,
  rows,
  setRows
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const customerRefOptions = customerRefs.map((c) => {
    return { value: c.name, label: c.name, id: c.value };
  });

  const vendorRefOptions = vendorRefs.map((v) => {
    return { value: v.name, label: v.name, id: v.value };
  });

  const creditCardAccountOptions = creditCardAccount.map((v) => {
    return { value: v.name, label: v.name, id: v.value };
  });

  const globalTaxCalculationOptions = [
    { value: 'TaxExcluded', label: 'TaxExcluded' },
    { value: 'TaxInclusive', label: 'TaxInclusive' }
  ];

  const handleSelectedClient = (selected) => {
    setExpenseFormData({ ...expenseFormData, client: selected });
  };

  const handleSelectedVendor = (selected) => {
    setExpenseFormData({ ...expenseFormData, vendor: selected });
  };

  const handleSelectedCreditCard = (selected) => {
    setExpenseFormData({ ...expenseFormData, creditCardAccount: selected });
  };

  const handleExpenseFormData = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'paidByEmployee' && checked) {
      setExpenseFormData({
        ...expenseFormData,
        paidByEmployee: true,
        paidByCompany: false,
        vendor: '',
        creditCardAccount: ''
      });
    } else if (name === 'paidByCompany' && checked) {
      setExpenseFormData({
        ...expenseFormData,
        paidByEmployee: false,
        paidByCompany: true
      });
    } else if (name === 'isBillable' && checked) {
      setExpenseFormData({
        ...expenseFormData,
        isBillable: true
      });
    } else {
      setExpenseFormData({
        ...expenseFormData,
        // paidByEmployee: false,
        // paidByCompany: false,
        [name]: value
      });
    }
  };

  const handleExpenseReceiptChange = (e) => {
    setExpenseFormData({ ...expenseFormData, expenseReceiptFile: e.target.files });
  };

  const handleSelectedGlobalTaxCalculation = (selected) => {
    setExpenseFormData({ ...expenseFormData, globalTaxCalculation: selected.value });
  };

  return (
    <div>
      <Grid container spacing={3}>
        {/* paid by  */}
        <Grid item xs={6}>
          <Box component="span" m={1}>
            <Typography>Paid by:</Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={expenseFormData.paidByEmployee}
              onChange={handleExpenseFormData}
              name="paidByEmployee"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Typography>Employee</Typography>
          </Grid>

          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={expenseFormData.paidByCompany}
              onChange={handleExpenseFormData}
              name="paidByCompany"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Typography>Company</Typography>
          </Grid>
        </Grid>

        {expenseFormData.paidByCompany && (
          <Grid item container spacing={2} style={{ paddingTop: isSmall && '0px' }}>
            {/* supplier list item */}
            <Grid item xs={12} sm={6}>
              <Typography className={classes.typography}>Supplier*</Typography>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={expenseFormData.vendor}
                isSearchable={true}
                name="vendor"
                // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={vendorRefOptions}
                onChange={(selectedOption) => {
                  return handleSelectedVendor({ name: selectedOption.value, value: selectedOption.id });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.typography}>Account*</Typography>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={expenseFormData.creditCardAccount}
                isSearchable={true}
                name="creditCardAccount"
                options={creditCardAccountOptions}
                onChange={(selectedOption) => {
                  return handleSelectedCreditCard({ name: selectedOption.value, value: selectedOption.id });
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid item container spacing={2}>
          {/* date  */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography} style={{ paddingBottom: '2px' }}>
              Date*
            </Typography>
            <TextField
              required={true}
              variant="outlined"
              size="small"
              id="date"
              name="date"
              type="date"
              value={expenseFormData.date}
              onChange={handleExpenseFormData}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth={true}
            />
          </Grid>

          {/* customer item */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>Client*</Typography>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              defaultValue={expenseFormData.client}
              isSearchable={true}
              name="client"
              options={customerRefOptions}
              // eslint-disable-next-line no-undef
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(selectedOption) => {
                return handleSelectedClient({ name: selectedOption.value, value: selectedOption.id });
              }}
            />
          </Grid>

          {/* Exc/Inc of Tax */}
          <Grid item xs={12} sm={4}>
            <Typography className={classes.typography}>Exc/Inc of Tax*</Typography>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              defaultValue={expenseFormData.globalTaxCalculation}
              isSearchable={true}
              name="globalTaxCalculation"
              options={globalTaxCalculationOptions}
              // eslint-disable-next-line no-undef
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={(selectedOption) => {
                return handleSelectedGlobalTaxCalculation({ name: selectedOption.value, value: selectedOption.value });
              }}
            />
          </Grid>

          {isSmall && (
            <Grid item xs={12}>
              <Typography className={classes.typography}>Purchase Order</Typography>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                value={expenseFormData.purchaseOrder}
                onChange={handleExpenseFormData}
                id="purchaseOrder"
                type="text"
                name="purchaseOrder"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth={true}
              />
            </Grid>
          )}
        </Grid>

        {/* <Grid item container spacing={2}> */}
        {/* Purchase Order */}
        {!isSmall && (
          <Grid item xs={6} sm={4}>
            <Typography className={classes.typography}>Purchase Order</Typography>
            <TextField
              required={true}
              variant="outlined"
              size="small"
              value={expenseFormData.purchaseOrder}
              onChange={handleExpenseFormData}
              id="purchaseOrder"
              type="text"
              name="purchaseOrder"
              fullWidth={true}
            />
          </Grid>
        )}

        {/* variation */}
        <Grid item xs={12} sm={6}>
          <Typography className={classes.typography}>Variation</Typography>
          <Checkbox
            checked={expenseFormData.isBillable}
            onChange={handleExpenseFormData}
            name="isBillable"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            style={{ paddingTop: '0px' }}
          />
          {expenseFormData.isBillable && (
            <Grid item xs={12}>
              <p>Variation Note*</p>
              <TextField
                id="variationNote"
                type="text"
                name="variationNote"
                variant="outlined"
                multiline
                maxRows={3}
                fullWidth={true}
                value={expenseFormData.variationNote}
                onChange={handleExpenseFormData}
              />
            </Grid>
          )}
        </Grid>
        {/* </Grid> */}
      </Grid>

      <div style={{ margin: '1rem 0', fontSize: '16px' }}>
        *Click
        <b> New </b>
        to fill all the details of expense receipt*
      </div>

      <AddExpenseDialogTable
        itemRefs={itemRefs}
        classRefs={classRefs}
        rows={rows}
        setRows={setRows}
        setExpenseFormData={setExpenseFormData}
        expenseFormData={expenseFormData}
      />

      <Grid item xs={12} style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
        <Typography>Upload an expense receipt.*</Typography>

        <input
          type="file"
          id="expenseReceiptImage"
          name="expenseReceiptImage"
          accept="image/png, image/jpeg"
          onChange={handleExpenseReceiptChange}
          style={{ marginTop: '0.6rem' }}
        />
      </Grid>
    </div>
  );
};

export default AddExpenseDialogContent;
1;

AddExpenseDialogContent.propTypes = {
  expenseFormData: PropTypes.any,
  customerRefs: PropTypes.array,
  classRefs: PropTypes.array,
  setExpenseFormData: PropTypes.func,
  itemRefs: PropTypes.array,
  vendorRefs: PropTypes.array,
  creditCardAccount: PropTypes.array,
  rows: PropTypes.array,
  setRows: PropTypes.func
};
