import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },

  formControlWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

export default function ChangePasswordDialog({
  open,
  setOpen,
  handleChangePassword,
  handleClickShowPassword,
  handleChange,
  values,
  handleMouseDownPassword,
  isSubmitting
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Your password must be at least six characters.</DialogContentText>

          <div className={classes.formControlWrapper}>
            <FormControl className={clsx(classes.margin)} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">Current Password</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showCurrentPassword ? 'text' : 'password'}
                value={values.currentPassword}
                onChange={handleChange('currentPassword')}
                name="showCurrentPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showCurrentPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl className={clsx(classes.margin)} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">New Password</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                name="showNewPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showNewPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl className={clsx(classes.margin)} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">Retype New Password</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showRetypeNewPassword ? 'text' : 'password'}
                value={values.reTypeNewPassword}
                onChange={handleChange('reTypeNewPassword')}
                name="showRetypeNewPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showRetypeNewPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showRetypeNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleChangePassword: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.any,
  setValues: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  isSubmitting: PropTypes.bool
};
