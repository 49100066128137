import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import UserGeneralDialogContent from './UserGeneralDialogContent';

export default function UserGeneral({
  row,
  onChange,
  onCancelChanges,
  onApplyChanges,
  vendorRecords,
  keyPayEmployeeRecords,
  selectedEmployeeToRegister,
  setSelectedEmployeeToRegister,
  selectedUserType,
  setSelectedUserType,
  approvalRequired,
  setApprovalRequired,
  selectedApprover,
  setSelectedApprover,
  open,
  portalAccess,
  setPortalAccess,
  portalAccessDialog,
  setPortalAccessDialog,
  setRows,
  rows
}) {
  return (
    <div style={{ height: '800px' }}>
      {/* <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: '3rem', marginBottom: '0px', paddingBottom: '0px', lineHeight: '1' }}
      >
        User
      </DialogTitle> */}
      <DialogContent>
        <UserGeneralDialogContent
          row={row}
          onChange={onChange}
          vendorRecords={vendorRecords}
          keyPayEmployeeRecords={keyPayEmployeeRecords}
          selectedEmployeeToRegister={selectedEmployeeToRegister}
          setSelectedEmployeeToRegister={setSelectedEmployeeToRegister}
          selectedUserType={selectedUserType}
          setSelectedUserType={setSelectedUserType}
          approvalRequired={approvalRequired}
          setApprovalRequired={setApprovalRequired}
          selectedApprover={selectedApprover}
          setSelectedApprover={setSelectedApprover}
          open={open}
          portalAccessDialog={portalAccessDialog}
          setPortalAccessDialog={setPortalAccessDialog}
          portalAccess={portalAccess}
          setPortalAccess={setPortalAccess}
          setRows={setRows}
          rows={rows}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
}

UserGeneral.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func,
  vendorRecords: PropTypes.array,
  keyPayEmployeeRecords: PropTypes.array,
  selectedEmployeeToRegister: PropTypes.any,
  setSelectedEmployeeToRegister: PropTypes.func,
  selectedUserType: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  setSelectedUserType: PropTypes.func,
  approvalRequired: PropTypes.bool,
  setApprovalRequired: PropTypes.func,
  selectedApprover: PropTypes.any,
  setSelectedApprover: PropTypes.func,
  open: PropTypes.bool,
  portalAccess: PropTypes.bool,
  setPortalAccess: PropTypes.func,
  portalAccessDialog: PropTypes.bool,
  setPortalAccessDialog: PropTypes.func,
  setRows: PropTypes.func,
  rows: PropTypes.any
};
