import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import qboEmployeeService from '../../services/QboEmployee';
import QboEmployeeTable from './table/QboEmployeeTable';
import formattedQboEmployee from './table/helper';

const queryClient = new QueryClient();

const QboEmployee = () => {
  const [qboEmployees, setQboEmployees] = useState([]);
  const [selection, setSelection] = useState([]);

  const { data, isLoading, error } = useQuery(
    'qboEmployees',
    async () => await qboEmployeeService.getEmployeesFromQbo(),
    {
      onSuccess: (data) => {
        const formattedRows = formattedQboEmployee(data?.data?.Employee);
        setQboEmployees(formattedRows);
      }
    }
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div>
      {qboEmployees && qboEmployees.length > 0 && (
        <QboEmployeeTable
          rows={qboEmployees}
          setRows={setQboEmployees}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </div>
  );
};

export default function Wrapped() {
  return (
    <QueryClientProvider client={queryClient}>
      <QboEmployee />
    </QueryClientProvider>
  );
}
