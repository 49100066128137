import * as z from 'zod';
import { format } from 'date-fns';

export function getFormattedUserData(employeeData) {
  const userData = {
    FirstName: employeeData.firstName ? employeeData.firstName : '',
    FamilyName: employeeData.surname ? employeeData.surname : '',
    Title: employeeData.title ? employeeData.title : '',
    PrimaryPhone: employeeData.mobilePhone ? employeeData.mobilePhone : '',
    Country: employeeData.residentialCountry ? employeeData.residentialCountry : '',
    CountrySubDivisionCode: employeeData.residentialState ? employeeData.residentialState : '',
    PostalCode: employeeData.residentialPostCode ? employeeData.residentialPostCode : '',
    Line1: employeeData.residentialStreetAddress ? employeeData.residentialStreetAddress : '',
    email: employeeData.emailAddress ? employeeData.emailAddress : '',
    DateOfBirth: employeeData.dateOfBirth ? employeeData.dateOfBirth : '',
    AnniversaryDate: employeeData.anniversaryDate ? employeeData.anniversaryDate : '',
    bankAccount1_AccountName: employeeData.bankAccount1_AccountName ? employeeData.bankAccount1_AccountName : '',
    bankAccount1_BSB: employeeData.bankAccount1_BSB ? employeeData.bankAccount1_BSB : '',
    bankAccount1_AccountNumber: employeeData.bankAccount1_AccountNumber ? employeeData.bankAccount1_AccountNumber : '',
    superFund1_ProductCode: employeeData.superFund1_ProductCode ? employeeData.superFund1_ProductCode : '',
    superFund1_MemberNumber: employeeData.superFund1_MemberNumber ? employeeData.superFund1_MemberNumber : '',
    superFund1_FundName: employeeData.superFund1_FundName ? employeeData.superFund1_FundName : '',
    emergencyContact1_ContactNumber: employeeData.emergencyContact1_ContactNumber
      ? employeeData.emergencyContact1_ContactNumber
      : '',
    emergencyContact1_Name: employeeData.emergencyContact1_Name ? employeeData.emergencyContact1_Name : '',
    emergencyContact1_Relationship: employeeData.emergencyContact1_Relationship
      ? employeeData.emergencyContact1_Relationship
      : '',
    bankAccount1_AllocatedPercentage: employeeData.bankAccount1_AllocatedPercentage
      ? employeeData.bankAccount1_AllocatedPercentage
      : '',
    superFund1_AllocatedPercentage: employeeData.superFund1_AllocatedPercentage
      ? employeeData.superFund1_AllocatedPercentage
      : '',
    KeyPayEmployeeId: employeeData.id
  };

  return userData;
}

export function validateKeyPayUser(currentUserData) {
  if (
    currentUserData.FirstName !== '' &&
    currentUserData.FamilyName !== '' &&
    currentUserData.PrimaryPhone !== '' &&
    currentUserData.Country !== '' &&
    currentUserData.CountrySubDivisionCode !== '' &&
    currentUserData.PostalCode !== '' &&
    currentUserData.Line1 !== '' &&
    currentUserData.email !== '' &&
    currentUserData.DateOfBirth !== '' &&
    currentUserData.bankAccount1_AccountName !== '' &&
    currentUserData.bankAccount1_BSB !== '' &&
    currentUserData.bankAccount1_AccountNumber !== '' &&
    currentUserData.superFund1_ProductCode !== '' &&
    currentUserData.superFund1_MemberNumber !== '' &&
    currentUserData.superFund1_FundName !== '' &&
    currentUserData.emergencyContact1_ContactNumber !== '' &&
    currentUserData.emergencyContact1_Name !== '' &&
    currentUserData.emergencyContact1_Relationship !== '' &&
    currentUserData.bankAccount1_AllocatedPercentage !== '' &&
    currentUserData.superFund1_AllocatedPercentage !== '' &&
    currentUserData.KeyPayEmployeeId
  )
    return true;

  return false;
}

export function getFormattedCurrentUser(employeeData) {
  const userData = {
    FirstName: employeeData.DisplayName ? employeeData.DisplayName.split(' ')[0] : '',
    FamilyName: employeeData.FamilyName ? employeeData.FamilyName : '',
    Title: employeeData.Title ? employeeData.Title : '',
    PrimaryPhone: employeeData.PrimaryPhone?.FreeFormNumber,
    Country: employeeData.Country ? employeeData.Country : '',
    CountrySubDivisionCode: employeeData.CountrySubDivisionCode ? employeeData.CountrySubDivisionCode : '',
    PostalCode: employeeData.PostalCode ? employeeData.PostalCode : '',
    Line1: employeeData.Line1 ? employeeData.Line1 : '',
    email: employeeData.email,
    DateOfBirth: employeeData.DateOfBirth,
    AnniversaryDate: employeeData.AnniversaryDate,
    KeyPayEmployeeId: employeeData.KeyPayEmployeeId
  };
  return userData;
}

export function validateDBUser(currentUserData) {
  if (
    currentUserData.FirstName !== '' &&
    currentUserData.FamilyName !== '' &&
    currentUserData.email !== '' &&
    currentUserData.Country !== '' &&
    currentUserData.CountrySubDivisionCode !== '' &&
    currentUserData.PostalCode !== '' &&
    currentUserData.Line1 !== ''
  )
    return true;

  return false;
}

export const employeeKeyPaySchema = z.object({
  title: z.string().min(2, { message: 'Too short' }).max(50, { message: 'Too long' }),
  firstName: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  preferredName: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }).or(z.literal('')),
  middleName: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }).or(z.literal('')),
  surname: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  jobTitle: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }).or(z.literal('')),
  rate: z.number().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }).or(z.literal('')),
  gender: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  emailAddress: z.string().email().min(14, { message: 'Too short' }).max(500, { message: 'Too long' }),
  homePhone: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }).or(z.literal('')),
  mobilePhone: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  startDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  dateOfBirth: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  anniversaryDate: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  residentialStreetAddress: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  residentialAddressLine2: z
    .string()
    .min(2, { message: 'Too short' })
    .max(500, { message: 'Too long' })
    .or(z.literal('')),
  residentialSuburb: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  residentialCountry: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  residentialState: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  residentialPostCode: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  bankAccount1_AccountName: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  bankAccount1_BSB: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  bankAccount1_AccountNumber: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  superFund1_FundName: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  superFund1_ProductCode: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  superFund1_MemberNumber: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  emergencyContact1_Name: z.string().min(2, { message: 'Too short' }).max(200, { message: 'Too long' }),
  emergencyContact1_Relationship: z.string().min(2, { message: 'Too short' }).max(200, { message: 'Too long' }),
  emergencyContact1_ContactNumber: z.string().min(2, { message: 'Too short' }).max(200, { message: 'Too long' }),
  emergencyContact1_Address: z.string().min(2, { message: 'Too short' }).max(500, { message: 'Too long' }),
  taxFileNumber: z.string().min(2, { message: 'Too short' }).max(100, { message: 'Too long' }),
  hasApprovedWorkingHolidayVisa: z.boolean(),
  claimTaxFreeThreshold: z.boolean(),
  helpDebt: z.boolean(),
  dateTaxFileDeclarationSigned: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  })
});

export function makeKeyPayEmployeeObject(keyPayEmployeeData) {
  const keyPayEmployee = {
    title: keyPayEmployeeData.title || '',
    firstName: keyPayEmployeeData.firstName || '',
    preferredName: keyPayEmployeeData.preferredName || '',
    middleName: keyPayEmployeeData.middleName || '',
    surname: keyPayEmployeeData.surname || '',
    jobTitle: keyPayEmployeeData.jobTitle || '',
    rate: keyPayEmployeeData.rate || 0,
    gender: keyPayEmployeeData.gender || '',
    emailAddress: keyPayEmployeeData.emailAddress || '',
    homePhone: keyPayEmployeeData.homePhone || '',
    mobilePhone: keyPayEmployeeData.mobilePhone || '',
    startDate: keyPayEmployeeData.startDate ? format(new Date(keyPayEmployeeData.startDate), 'yyyy-MM-dd') : '',
    dateOfBirth: keyPayEmployeeData.dateOfBirth ? format(new Date(keyPayEmployeeData.dateOfBirth), 'yyyy-MM-dd') : '',
    anniversaryDate: keyPayEmployeeData.anniversaryDate
      ? format(new Date(keyPayEmployeeData.anniversaryDate), 'yyyy-MM-dd')
      : '',
    residentialStreetAddress: keyPayEmployeeData.residentialStreetAddress || '',
    residentialAddressLine2: keyPayEmployeeData.residentialAddressLine2 || '',
    residentialSuburb: keyPayEmployeeData.residentialSuburb || '',
    residentialCountry: keyPayEmployeeData.residentialCountry || '',
    residentialState: keyPayEmployeeData.residentialState || '',
    residentialPostCode: keyPayEmployeeData.residentialPostCode || '',
    bankAccount1_AccountName: keyPayEmployeeData.bankAccount1_AccountName || '',
    bankAccount1_BSB: keyPayEmployeeData.bankAccount1_BSB || '',
    bankAccount1_AccountNumber: keyPayEmployeeData.bankAccount1_AccountNumber || '',
    superFund1_FundName: keyPayEmployeeData.superFund1_FundName || '',
    superFund1_ProductCode: keyPayEmployeeData.superFund1_ProductCode || '',
    superFund1_MemberNumber: keyPayEmployeeData.superFund1_MemberNumber || '',
    emergencyContact1_Name: keyPayEmployeeData.emergencyContact1_Name || '',
    emergencyContact1_Relationship: keyPayEmployeeData.emergencyContact1_Relationship || '',
    emergencyContact1_ContactNumber: keyPayEmployeeData.emergencyContact1_ContactNumber || '',
    emergencyContact1_Address: keyPayEmployeeData.emergencyContact1_Address || '',
    KeyPayEmployeeId: keyPayEmployeeData.id || '',
    australianResident: keyPayEmployeeData.australianResident || false,
    hasApprovedWorkingHolidayVisa: keyPayEmployeeData.hasApprovedWorkingHolidayVisa || false,
    claimTaxFreeThreshold: keyPayEmployeeData.claimTaxFreeThreshold || false,
    helpDebt: keyPayEmployeeData.helpDebt || false,
    dateTaxFileDeclarationSigned: keyPayEmployeeData.dateTaxFileDeclarationSigned
      ? format(new Date(keyPayEmployeeData.dateTaxFileDeclarationSigned), 'yyyy-MM-dd')
      : '',
    bankAccount1_AllocatedPercentage: keyPayEmployeeData.bankAccount1_AllocatedPercentage || '',
    superFund1_AllocatedPercentage: keyPayEmployeeData.superFund1_AllocatedPercentage || '',
    taxFileNumber: keyPayEmployeeData.taxFileNumber || ''
  };
  return keyPayEmployee;
}

export const employeeUniformSchema = z.object({
  shirtSize: z.string().min(1, { message: 'Shirt size is required' }),
  jumperSize: z.string().min(1, { message: 'Jumper size is required' }),
  jacketSize: z.string().min(1, { message: 'Jacket size is required' }),
  employeeId: z.string().or(z.literal(''))
});

export function formatEmployeeUniform(employeeUniform) {
  const uniformRecord = {
    shirtSize: employeeUniform.shirtSize,
    jacketSize: employeeUniform.jacketSize,
    jumperSize: employeeUniform.jumperSize,
    employeeId: employeeUniform.employeeId
  };
  return uniformRecord;
}
