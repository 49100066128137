import React, { useEffect, useState } from 'react';
import NotificationItem from './NotificationItem';
import PropTypes from 'prop-types';
import notificationService from '../../../services/NotificationService';
import { Toaster } from 'react-hot-toast';
import { socket } from '../../../socket';

const NotificationList = () => {
  const [employeeNotificationRecords, setEmployeeNotificationRecords] = useState([]);

  useEffect(() => {
    const fetchEmployeeNotification = async () => {
      try {
        const records = await notificationService.getNotification();

        const employeeNotificationRecords = records.data.data;

        // const unReadNotificationCount = employeeNotificationRecords.filter((n) => n.isRead === false).length;
        // setUnReadNotification(unReadNotificationCount);

        setEmployeeNotificationRecords(employeeNotificationRecords);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchEmployeeNotification();

    // socket.on('connect', () => {
    //   setSocketId(socket.id);
    // });

    // Subscribe to Socket.io event for new notifications
    socket.on('newNotification', (newNotification) => {
      newNotification?.recipientDisplayName?.forEach((item) => {
        // eslint-disable-next-line no-undef
        if (item === localStorage.getItem('name')) {
          setEmployeeNotificationRecords((prevRecords) => [newNotification, ...prevRecords]);
        }
      });
    });

    // Clean up function to unsubscribe from Socket.io event
    return () => {
      socket.off('newNotification');
      socket.disconnect();
    };
  }, [setEmployeeNotificationRecords]);

  useEffect(() => {
    const updateNotification = async () => {
      const unReadNotification = employeeNotificationRecords
        .filter((n) => n.isRead === false && n.type === 'notificationStatusChange')
        .map((e) => e._id);

      if (unReadNotification.length > 0) {
        try {
          const data = await notificationService.updateNotificationIsRead(unReadNotification);
        } catch (error) {
          console.log('error is:', error);
        }
      }
    };

    updateNotification();
  }, [employeeNotificationRecords]);

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div
        style={{
          marginTop: '-8px',
          marginBottom: '2rem',
          backgroundColor: '#fafafa',
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '2rem',
          flexDirection: 'column'
        }}
      >
        {employeeNotificationRecords.length > 0 ? (
          employeeNotificationRecords.map((r) => <NotificationItem key={r._id} notificationItem={r} />)
        ) : (
          <div>No new notifications</div>
        )}
      </div>
    </>
  );
};

export default NotificationList;

NotificationList.propTypes = {
  employeeNotificationRecords: PropTypes.any,
  notificationListClicked: PropTypes.any
};
