import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { format, subWeeks, startOfWeek, lastDayOfWeek } from 'date-fns';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  div: {
    marginRight: '1rem',
    marginLeft: '1rem'
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '2px 6px',
    borderRadius: '4px',
    marginRight: '1rem',
    '@media (max-width: 600px)': {
      marginBottom: '1rem'
    }
  },
  dateWrapper: {
    display: 'flex',
    '@media (max-width: 600px)': {
      marginBottom: '1rem'
    }
  }
}));

export const timesheetPeriodOptions = ['This Week', 'Last Week', 'All'];

export default function TimesheetFilter({ filter, setFilter }) {
  const classes = useStyles();

  const thisWeekStartDate = startOfWeek(new Date(), { weekStartsOn: 3 });
  const thisWeekEndDate = lastDayOfWeek(new Date(), { weekStartsOn: 3 });

  return (
    <div className={classes.wrapper}>
      <div className={classes.selectWrapper}>
        <select
          className={classes.select}
          value={filter.timesheetPeriod}
          onChange={(e) => {
            const period = e.target.value;

            if (period === 'Last Week') {
              return setFilter({
                ...filter,
                timesheetPeriod: period,
                startDate: subWeeks(thisWeekStartDate, 1),
                endDate: subWeeks(thisWeekEndDate, 1)
              });
            }

            if (period === 'This Week') {
              return setFilter({
                ...filter,
                timesheetPeriod: period,
                startDate: thisWeekStartDate,
                endDate: thisWeekEndDate
              });
            }

            if (period === 'All') {
              return setFilter({
                ...filter,
                timesheetPeriod: period,
                startDate: null,
                endDate: new Date()
              });
            }
          }}
          // disabled={filter.timesheetStatus !== 'All'}
        >
          {timesheetPeriodOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.dateWrapper}>
        <TextField
          type="date"
          value={format(new Date(filter.startDate), 'yyyy-MM-dd')}
          onChange={(e) => {
            setFilter({ ...filter, startDate: e.target.value });
          }}
          disabled={filter.timesheetPeriod === 'All'}
        />

        <div className={classes.div}>to</div>
        <TextField
          type="date"
          value={format(new Date(filter.endDate), 'yyyy-MM-dd')}
          onChange={(e) => {
            setFilter({ ...filter, endDate: e.target.value });
          }}
          disabled={filter.timesheetPeriod === 'All'}
        />
      </div>
    </div>
  );
}

TimesheetFilter.propTypes = {
  filter: PropTypes.shape({
    timesheetPeriod: PropTypes.string,
    startDate: PropTypes.null || PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    timesheetStatus: PropTypes.string
  }),
  setFilter: PropTypes.func
};
