import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function VendorAlertDialog({ open, setOpen, handleVendorStatus, message, handleDialogClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ paddingTop: '1.7rem' }}>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleVendorStatus} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VendorAlertDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.any,
  handleVendorStatus: PropTypes.func,
  message: PropTypes.string,
  handleDialogClose: PropTypes.func
};
