import React from 'react';
import PropTypes from 'prop-types';

import { Button, Divider, List, ListItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import itemService from '../../../services/ItemService';
import { toast } from 'react-hot-toast';

const FileUpload = ({ setItemFileUploadOpen }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    register,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    defaultValues: {
      items: [{ itemId: '', itemFile: null }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
    rules: {
      required: 'Please append at least 1 item.'
    }
  });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('userId', userId);

    const itemsData = data.items.map((item) => {
      return { itemId: item.itemId };
    });
    formData.append('items', JSON.stringify(itemsData));

    data.items.forEach((item) => {
      formData.append('itemFile', item.itemFile[0]);
    });

    itemService
      .uploadItemsFilesInQbo(formData)
      .then(() => {
        toast.success('Successfully added images to the product and services in qbo.');
        setItemFileUploadOpen(false);
      })
      .catch((error) => {
        toast.error(error?.message || 'Could not add image in product and services in qbo.');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <List>
        {fields.map((item, index) => {
          return (
            <>
              <ListItem
                key={item.id}
                style={{ display: 'flex', justifyContent: 'space-between', flexWrap: isSmall ? 'wrap' : 'nowrap' }}
              >
                <Controller
                  render={({ field }) => (
                    <div>
                      <label htmlFor="fileName">
                        <span>Item Id</span>
                      </label>
                      <TextField
                        {...field}
                        style={{ marginRight: '1rem', marginTop: '0' }}
                        margin="dense"
                        variant="outlined"
                        id="fileName"
                        required={true}
                        type="number"
                      />
                    </div>
                  )}
                  name={`items.${index}.itemId`}
                  control={control}
                  valueAsNumber={true}
                />

                <label>
                  <span>File</span>
                  <input
                    {...register(`items.${index}.itemFile`, { required: true })}
                    type="file"
                    accept="image/*"
                    style={{
                      marginTop: isSmall ? '0.5rem' : '1rem',
                      marginRight: '1rem',
                      marginBottom: isSmall ? '0.5rem' : ''
                    }}
                  />
                </label>

                <Button type="button" variant="outlined" onClick={() => remove(index)}>
                  Delete
                </Button>
              </ListItem>
              <Divider variant="fullWidth" component="li" />
            </>
          );
        })}
      </List>
      {fields.length < 20 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="button"
            onClick={() => {
              append({ itemId: '', itemFile: null });
            }}
            variant="outlined"
          >
            Add another item
            <AddIcon />
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
          Only 20 items can be added at a time.
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem', marginBottom: '1rem' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setItemFileUploadOpen(false)}
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="outlined" color="primary" margin="dense">
          Submit
        </Button>
      </div>
    </form>
  );
};
export default FileUpload;

FileUpload.propTypes = {
  setItemFileUploadOpen: PropTypes.func
};
