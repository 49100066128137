import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import { Button, Grid, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import employeeUniformService from '../../../../services/EmployeeUniform';
import { employeeUniformSchema } from '../../helper/helper';
import { formatEmployeeUniform } from '../../../MyProfile/helper/helper';

const UserUniform = ({ row, onCancelChanges }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSubmitting, setSubmitting] = useState(false);
  const [employeeUniform, setEmployeeUniform] = useState({
    shirtSize: '',
    jumperSize: '',
    jacketSize: '',
    employeeId: ''
  });

  const sizeOptions = [
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: '3XL', label: '3XL' }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(employeeUniformSchema),
    defaultValues: employeeUniform
  });

  useEffect(() => {
    const fetchEmployeeUniform = async () => {
      try {
        const payload = {
          // eslint-disable-next-line no-undef
          token: localStorage.getItem('token'),
          employeeId: row.id
        };

        const response = await employeeUniformService.getEmployeeUniform(payload);
        // console.log('response is:', response);
        const employeeUniformData = response?.data?.data;

        if (employeeUniformData.length < 1) {
          setEmployeeUniform(null);
          reset(null);
        } else {
          const uniformRecord = formatEmployeeUniform(employeeUniformData[0]);
          setEmployeeUniform(uniformRecord);
          reset(uniformRecord);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get employee uniform details.');
      }
    };
    fetchEmployeeUniform();
  }, [reset, row.id]);

  const onSubmitEmployeeUniform = async (data) => {
    try {
      setSubmitting(true);
      if (row.id) {
        employeeUniformService
          .updateEmployeeUniform({ ...data, employeeId: row.id })
          .then((response) => {
            toast.success('Successfully updated uniform details');

            const uniformRecord = formatEmployeeUniform(response?.data?.data);

            setEmployeeUniform(uniformRecord);
            reset(uniformRecord);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || 'Could not get update uniform details');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Could not authorize');
    }
  };

  return (
    <div style={{ height: '800px' }}>
      <h2 style={{ borderBottom: 'none', padding: isSmall ? '10px 1rem' : '10px 2rem', overflowX: 'hidden' }}>
        User Uniform
      </h2>

      <form
        onSubmit={handleSubmit(onSubmitEmployeeUniform)}
        style={{ width: '100%', padding: isSmall ? '0 2rem' : '0 3rem', overflowX: 'hidden' }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <label>Shirt Size</label>
            <Controller
              name="shirtSize"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    options={sizeOptions}
                    value={sizeOptions.find((c) => c.value === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                    error={!!errors.shirtSize}
                    helperText={errors.shirtSize?.message}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                  {errors.shirtSize?.message ? (
                    <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>
                      {errors.shirtSize?.message}{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <label>Jumper Size</label>
            <Controller
              name="jumperSize"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    options={sizeOptions}
                    value={sizeOptions.find((c) => c.value === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                    error={!!errors.jumperSize}
                    helperText={errors.jumperSize?.message}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                  {errors.jumperSize?.message ? (
                    <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>
                      {errors.jumperSize?.message}{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <label>Jacket Size</label>
            <Controller
              name="jacketSize"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    options={sizeOptions}
                    value={sizeOptions.find((c) => c.value === field.value)}
                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                    error={!!errors.jacketSize}
                    helperText={errors.jacketSize?.message}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                  {errors.jacketSize?.message ? (
                    <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>
                      {errors.jacketSize?.message}{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '2rem' }}>
            <Button autoFocus variant="outlined" color="secondary" onClick={onCancelChanges}>
              Close
            </Button>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              style={{ marginLeft: '1rem' }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                </>
              ) : (
                <span>Update</span>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default UserUniform;

UserUniform.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
