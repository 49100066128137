import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Notification from '../../Notification/Notification';

export default function NavMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const [name, setName] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setName(localStorage.getItem('name'));
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-undef
    localStorage.removeItem('token');
    // eslint-disable-next-line no-undef
    localStorage.removeItem('refreshToken');
    // eslint-disable-next-line no-undef
    localStorage.removeItem('name');
    history.push('/login');
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link to="/notification">
        <Notification />
      </Link>

      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Avatar alt={name} src="/static/images/avatar/1.jpg" />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
      >
        <Link to="/myprofile">
          <MenuItem onClick={() => setAnchorEl(null)} style={{ color: 'black' }}>
            Profile
          </MenuItem>
        </Link>
        <Link to="/myqualification">
          <MenuItem onClick={() => setAnchorEl(null)} style={{ color: 'black' }}>
            Qualification
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
