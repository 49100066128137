import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { parse, format } from 'date-fns';

import timesheetService from '../../../services/TimesheetService';
import {
  getFormattedTableRows,
  isLeaveTimesheetFormDataValid,
  isTimesheetDateInFuture,
  isTimesheetFormDataValid
} from '../table/helpers';

import { Grid, TextField, Checkbox, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect from 'react-select';
import ButtonMenu from './ButtonMenu';
import { toast } from 'react-hot-toast';
import keyPayEmployeeService from '../../../services/KeypayEmployee';
import employeeService from '../../../services/EmployeeService';
import { checkLeaveCategory, isLeaveHoursValid } from './helper';
import useFetchCustomer from '../../CustomerNew/hooks/useFetchCustomer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  textField: {
    width: '233px',
    maxWidth: '100%'
  },
  element: {
    marginRight: theme.spacing(1)
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem'
  }
}));

const AddTimeDialogContent = ({
  myTimeFormData,
  setMyTimeFormData,
  customerRefList,
  classRefList,
  handleTimesheetAdd,
  handleLeaveTimesheetAdd,
  handleClose,
  takeLeaveOption,
  timesheetSavingOptions,
  setTimesheetSavingOptions,
  rows,
  setRows,
  setOpen,
  showLeaveBalance,
  setShowLeaveBalance,
  sickLeaveBalance,
  setSickLeaveBalance,
  setProceedFutureDate,
  proceedFutureDate,
  setDateCheckDialogOpen
}) => {
  const [todayDate, setTodayDate] = useState();

  useEffect(() => {
    let todayDate = format(new Date(), 'yyyy-MM-dd');
    setTodayDate(todayDate);
  }, []);

  const classes = useStyles();
  // const [billableCheckboxChecked, setBillableCheckboxChecked] = useState(myTimeFormData.isBillable);
  const [medicalCheckboxChecked, setMedicalCheckboxChecked] = useState(myTimeFormData.medicalStatus);
  const [leaveCategory] = useState([
    'Annual Leave',
    "Personal/Carer's Leave",
    'Leave without pay',
    'Long Service Leave',
    'School',
    'Rostered Day Off',
    'Public Holiday'
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { customerRows } = useFetchCustomer();
  const [selectedClient, setSelectedClient] = useState();

  const handleOptions = async (e) => {
    e.preventDefault();
    if (
      (e.target.innerText !== timesheetSavingOptions[0].value &&
        e.target.innerText !== timesheetSavingOptions[0].value.toUpperCase()) ||
      (e.target.textContent !== timesheetSavingOptions[0].value &&
        e.target.textContent !== timesheetSavingOptions[0].value.toUpperCase())
    ) {
      const updatedButtonList = timesheetSavingOptions.reverse();
      // eslint-disable-next-line no-undef
      localStorage.setItem('timesheetSavingOptions', JSON.stringify(updatedButtonList));
      setTimesheetSavingOptions(updatedButtonList);
    }
    if (
      e.target.innerText === 'Save as draft' ||
      e.target.innerText === 'SAVE AS DRAFT' ||
      e.target.textContent === 'Save as draft' ||
      e.target.textContent === 'SAVE AS DRAFT'
    ) {
      if (takeLeaveOption) {
        handleLeaveTimesheetAdd();
      }
      if (!takeLeaveOption) {
        handleTimesheetAdd();
      }
    }
    if (
      e.target.innerText === 'Save and submit' ||
      e.target.innerText === 'SAVE AND SUBMIT' ||
      e.target.textContent === 'Save and submit' ||
      e.target.textContent === 'SAVE AND SUBMIT'
    ) {
      if (takeLeaveOption) {
        saveAndSubmitLeaveTimesheet();
      }
      if (!takeLeaveOption) {
        saveAndSubmitTimesheet();
      }
    }
  };

  //save and submit button: save timesheet and change status to "WithApprover" or "Approved" for timesheet
  const saveAndSubmitTimesheet = async () => {
    const timesheetPayload = isTimesheetFormDataValid(myTimeFormData);
    if (!timesheetPayload) {
      return toast.error('Please fill out all the mandatory data');
    }

    let timesheetDateCheck = false;

    if (!proceedFutureDate) {
      //check if the timesheet date entered is within this week or not
      timesheetDateCheck = isTimesheetDateInFuture(myTimeFormData.date);
    }

    //popup  a dialog
    if (timesheetDateCheck && !proceedFutureDate) {
      setDateCheckDialogOpen(true);
    }

    if (!timesheetDateCheck || proceedFutureDate) {
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('timesheetPayload', JSON.stringify(timesheetPayload));
      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (myTimeFormData.timeSheetImageUrl) {
        const file = myTimeFormData.timeSheetImageUrl[0];
        formData.append('file', file);
      }

      timesheetService
        .saveAndSubmitTimesheet(formData)
        .then(({ data }) => {
          toast.success('Successfully added timesheet');
          let timesheetData = [];
          timesheetData.push(data.data);
          const formattedTimesheet = getFormattedTableRows(timesheetData);
          setRows([formattedTimesheet[0], ...rows]);
          setMyTimeFormData({
            startTime: '07:00',
            finishTime: '15:00',
            hour: 8,
            date: todayDate,
            client: '',
            task: '',
            isBillable: false,
            description: '',
            variationNote: '',
            workType: '',
            medicalStatus: false,
            timeSheetImageUrl: ''
          });
          setOpen(false);
        })
        .catch((error) => {
          console.log('Error while save as draft leave form timesheet.', error);

          console.log('error is:', error);
          toast.error(
            error?.response?.data?.error ||
              error?.response?.data?.data?.message ||
              error?.response?.data?.message ||
              'Could not add and submit timesheet'
          );
        });
    }
  };

  //save and submit button: save leave timesheet and change status to "WithApprover" or "Approved" for timesheet
  const saveAndSubmitLeaveTimesheet = async () => {
    const timesheetPayload = isLeaveTimesheetFormDataValid(myTimeFormData);
    if (!timesheetPayload) {
      return toast.error('Please fill out all the mandatory data');
    }

    if (checkLeaveCategory(myTimeFormData.workType)) {
      if (!isLeaveHoursValid(showLeaveBalance, myTimeFormData.hour)) {
        return toast.error(`Not enough leave balance to take ${myTimeFormData.workType}`);
      }
    }

    if (myTimeFormData.workType === "Personal/Carer's Leave") {
      if (!isLeaveHoursValid(sickLeaveBalance, myTimeFormData.hour)) {
        return toast.error(`Not enough leave balance to take ${myTimeFormData.workType}`);
      }
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('timesheetPayload', JSON.stringify(timesheetPayload));
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    if (myTimeFormData.timeSheetImageUrl) {
      const file = myTimeFormData.timeSheetImageUrl[0];
      formData.append('file', file);
    }

    timesheetService
      .saveAndSubmitTimesheet(formData)
      .then(({ data }) => {
        toast.success('Successfully added timesheet');
        let timesheetData = [];
        timesheetData.push(data.data);
        const formattedTimesheet = getFormattedTableRows(timesheetData);
        setRows([formattedTimesheet[0], ...rows]);
        setMyTimeFormData({
          startTime: '07:00',
          finishTime: '15:00',
          hour: 8,
          date: todayDate,
          client: '',
          task: '',
          isBillable: false,
          description: '',
          variationNote: '',
          workType: '',
          medicalStatus: false,
          timeSheetImageUrl: ''
        });
        setOpen(false);
        setAnchorEl(false);
      })
      .catch((error) => {
        console.log('Error while save and submit leave form timesheet.', error);
        toast.error(
          error?.response?.data?.error || error?.response?.data?.message || 'Could not add and submit leave form'
        );
      });
  };

  const customerRefOptions = customerRefList.map((c) => {
    return { value: c.name, label: c.name, id: c.value };
  });

  const classRefOptions = classRefList.map((c) => {
    return { value: c.name, label: c.name, id: c.value };
  });

  const leaveCategoryOptions = leaveCategory.map((l) => {
    return { value: l, label: l };
  });

  //difference between starttime and finishtime: hour
  useEffect(() => {
    if (myTimeFormData.startTime && myTimeFormData.finishTime) {
      const startDateMs = parse(myTimeFormData.startTime, 'HH:mm', new Date(myTimeFormData.date));
      const endDateMs = parse(myTimeFormData.finishTime, 'HH:mm', new Date(myTimeFormData.date));

      const diffHr = Math.abs((endDateMs - startDateMs) / (1000 * 60 * 60)).toFixed(1);
      setMyTimeFormData({ ...myTimeFormData, hour: diffHr });
      setProceedFutureDate(false);
    }
  }, [myTimeFormData.startTime, myTimeFormData.finishTime, myTimeFormData.date, setMyTimeFormData]);

  const handleChange = (e) => {
    setMyTimeFormData({ ...myTimeFormData, [e.target.name]: e.target.value });
  };

  //when variation in checked
  const handleVariationChange = (e) => {
    setMyTimeFormData({ ...myTimeFormData, isBillable: e.target.checked });
  };

  //when medical certificate is checked
  const handleMedicalCertificateChange = (e) => {
    setMedicalCheckboxChecked(e.target.checked);
    setMyTimeFormData({ ...myTimeFormData, medicalStatus: e.target.checked });
  };

  const handleTimesheetFile = (e) => {
    setMyTimeFormData({ ...myTimeFormData, timeSheetImageUrl: e.target.files });
  };

  //when client item is clicked :
  const handleClientItem = (selectedItem) => {
    if (customerRows.length) {
      const selectedClientBillableStatus = customerRows.find((c) => c.Id === selectedItem.value);

      const isClientBillable = selectedClientBillableStatus && selectedClientBillableStatus.BillableStatus;

      if (isClientBillable) setSelectedClient(isClientBillable);

      setMyTimeFormData({ ...myTimeFormData, client: selectedItem, isBillable: isClientBillable });
    }
  };

  //when task item is clicked
  const handleTaskItem = (selectedItem) => {
    setMyTimeFormData({ ...myTimeFormData, task: selectedItem });
  };

  //when leave category is choosen
  const handleWorkTypeCategory = async (selectedItem) => {
    if (
      selectedItem === 'Annual Leave' ||
      selectedItem === 'Rostered Day Off' ||
      selectedItem === "Personal/Carer's Leave"
    ) {
      //get keyPayEmployeeId to get leavebalance
      const employeeDetails = await employeeService.getEmployeeDetails();
      const keyPayEmployeeId = employeeDetails.data.data.KeyPayEmployeeId;

      const leaveBalanceResult = await keyPayEmployeeService.getEmployeeLeaveBalance(keyPayEmployeeId);

      const selectedLeave = leaveBalanceResult.data.data.filter((l) => l.leaveCategoryName == selectedItem);

      if (selectedItem !== "Personal/Carer's Leave") {
        const showLeaveBalance = selectedLeave[0].accruedAmount;
        setShowLeaveBalance(showLeaveBalance);
      }
      const sickLeaveBalance = selectedLeave[0].accruedAmount;
      setSickLeaveBalance(sickLeaveBalance);
    }

    setMyTimeFormData({ ...myTimeFormData, workType: selectedItem });
  };

  return (
    <div>
      <form onSubmit={handleTimesheetAdd}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* start time input */}
            <Grid item xs={6}>
              <p>Start Time*</p>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                id="startTime"
                type="time"
                name="startTime"
                value={myTimeFormData.startTime}
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300 // 5 min
                }}
              />
            </Grid>

            {/* finish time input */}
            <Grid item xs={6}>
              <p>End Time*</p>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                id="finishTime"
                type="time"
                name="finishTime"
                value={myTimeFormData.finishTime}
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300 // 5 min
                }}
              />
            </Grid>

            {/* hour input */}
            <Grid item xs={6}>
              <p>Hour*</p>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                value={myTimeFormData.hour}
                onChange={handleChange}
                className={classes.textField}
                autoFocus
                id="hour"
                // type="text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                name="hour"
                disabled
              />
            </Grid>

            {/* date input*/}
            <Grid item xs={6}>
              <p>Date*</p>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                id="date"
                name="date"
                type="date"
                value={myTimeFormData.date}
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            {/* client input  */}
            {!takeLeaveOption && (
              <Grid item xs={12}>
                <p className={classes.element}>Client*</p>
                {!customerRows.length ? (
                  <CircularProgress />
                ) : (
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={myTimeFormData.client}
                    isSearchable={true}
                    name="client"
                    options={customerRefOptions}
                    onChange={(selectedOption) => {
                      return handleClientItem({ name: selectedOption.value, value: selectedOption.id });
                    }}
                  />
                )}
              </Grid>
            )}

            {/* activity input */}
            {!takeLeaveOption && (
              <Grid item xs={12}>
                <p className={classes.element}>Activity*</p>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={myTimeFormData.task}
                  isSearchable={true}
                  name="task"
                  options={classRefOptions}
                  onChange={(selectedOption) => {
                    return handleTaskItem({ name: selectedOption.value, value: selectedOption.id });
                  }}
                />
              </Grid>
            )}

            {/* leave category input */}
            {takeLeaveOption && (
              <Grid item xs={12}>
                <p className={classes.element}>Leave Category*</p>
                <ReactSelect
                  maxMenuHeight={250}
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={myTimeFormData.workType}
                  isSearchable={true}
                  name="workType"
                  options={leaveCategoryOptions}
                  onChange={(selectedOption) => {
                    return handleWorkTypeCategory(selectedOption.value);
                  }}
                  styles={{
                    menuList: (base) => ({
                      ...base,
                      height: '165px',
                      '::-webkit-scrollbar': {
                        width: '9px'
                      },
                      '::-webkit-scrollbar-thumb': {
                        background: '#888'
                      },
                      '::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                      }
                    })
                  }}
                />
              </Grid>
            )}

            {myTimeFormData.workType === 'Annual Leave' || myTimeFormData.workType === 'Rostered Day Off' ? (
              <Grid item xs={12}>
                Leave Balance : {showLeaveBalance} hours
              </Grid>
            ) : (
              ''
            )}

            {takeLeaveOption && myTimeFormData.workType === "Personal/Carer's Leave" && (
              <Grid item xs={12}>
                <p>Medical Certificate </p>
                <Checkbox
                  checked={medicalCheckboxChecked}
                  id="medicalStatus"
                  name="medicalStatus"
                  onChange={handleMedicalCertificateChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  style={{ paddingTop: '0px' }}
                />
                {medicalCheckboxChecked && (
                  <Grid item xs={12}>
                    <p>Medical Certificate*</p>
                    <input
                      id="timeSheetImageUrl"
                      type="file"
                      name="timeSheetImageUrl"
                      style={{ paddingTop: '0px' }}
                      onChange={handleTimesheetFile}
                      accept="image/*"
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {/* description input */}
            <Grid item xs={12} style={{ marginBottom: takeLeaveOption ? '1rem' : '' }}>
              <p>Comments*</p>
              <TextField
                id="description"
                type="text"
                name="description"
                variant="outlined"
                multiline
                maxRows={4}
                fullWidth={true}
                value={myTimeFormData.description}
                onChange={handleChange}
              />
            </Grid>

            {/* variation input */}
            {!takeLeaveOption && (
              <Grid item xs={12}>
                <p>Variation</p>
                <Checkbox
                  checked={myTimeFormData.isBillable}
                  id="isBillable"
                  name="isBillable"
                  onChange={handleVariationChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  style={{ paddingTop: '0px' }}
                  disabled={selectedClient}
                />
                {myTimeFormData.isBillable && (
                  <Grid item xs={12}>
                    <p>Variation Note</p>
                    <TextField
                      id="variationNote"
                      type="text"
                      name="variationNote"
                      variant="outlined"
                      multiline
                      maxRows={4}
                      fullWidth={true}
                      value={myTimeFormData.variationNote}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {/* variation input end */}

            {/* image to upload */}
            {!takeLeaveOption && (
              <Grid item xs={12}>
                <p>Image</p>
                <input
                  id="timeSheetImageUrl"
                  type="file"
                  name="timeSheetImageUrl"
                  style={{ paddingTop: '0px' }}
                  onChange={handleTimesheetFile}
                  accept="image/*"
                />
              </Grid>
            )}
          </Grid>
        </div>

        <div className={classes.buttonDiv}>
          <Button onClick={handleClose} color="secondary" style={{ marginRight: '10px' }}>
            Cancel
          </Button>
          {timesheetSavingOptions && timesheetSavingOptions.length > 0 && (
            <div style={{ display: 'flex', backgroundColor: '#4040a1', borderRadius: '4px' }}>
              <Button
                onClick={
                  timesheetSavingOptions[0].id !== 1
                    ? handleOptions
                    : takeLeaveOption
                    ? handleLeaveTimesheetAdd
                    : handleTimesheetAdd
                }
                color="primary"
                variant="contained"
                type="submit"
                style={{ borderRight: '1px solid white', borderRadius: '4px 0 0 4px' }}
              >
                {timesheetSavingOptions.length ? timesheetSavingOptions[0].value : ''}
              </Button>
              <ButtonMenu
                buttonList={timesheetSavingOptions.filter((t, index) => index !== 0)}
                handleOptions={handleOptions}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                style={{ backgroundColor: 'blue' }}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddTimeDialogContent;

AddTimeDialogContent.propTypes = {
  myTimeFormData: PropTypes.shape({
    startTime: PropTypes.string,
    finishTime: PropTypes.string,
    hour: PropTypes.any,
    date: PropTypes.any,
    client: PropTypes.any,
    task: PropTypes.any,
    workType: PropTypes.string,
    isBillable: PropTypes.bool,
    description: PropTypes.string,
    variationNote: PropTypes.string,
    medicalStatus: PropTypes.bool,
    timeSheetImageUrl: PropTypes.any
  }),
  setMyTimeFormData: PropTypes.func,
  customerRefList: PropTypes.array,
  classRefList: PropTypes.array,
  handleTimesheetAdd: PropTypes.func,
  handleLeaveTimesheetAdd: PropTypes.func,
  handleClose: PropTypes.func,
  takeLeaveOption: PropTypes.bool,
  timesheetSavingOptions: PropTypes.array,
  setTimesheetSavingOptions: PropTypes.func,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  setOpen: PropTypes.func,
  showLeaveBalance: PropTypes.number,
  setShowLeaveBalance: PropTypes.func,
  sickLeaveBalance: PropTypes.number,
  setSickLeaveBalance: PropTypes.func,
  setProceedFutureDate: PropTypes.func,
  proceedFutureDate: PropTypes.bool,
  setDateCheckDialogOpen: PropTypes.func
};
