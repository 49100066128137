// TODO: prop types
/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useState } from 'react';

import { format } from 'date-fns';
import saveAs from 'file-saver';
import ReactSelect from 'react-select';
import { toast } from 'react-hot-toast';
import { useLocalStorage } from 'react-use';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  SortingState,
  SelectionState,
  EditingState,
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSelection,
  IntegratedSummary,
  SummaryState,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  TableColumnReordering,
  TableFixedColumns,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableColumnResizing,
  TableSelection,
  ExportPanel,
  TableSummaryRow,
  DragDropProvider,
  TableInlineCellEditing
} from '@devexpress/dx-react-grid-material-ui';
import { Getter, Plugin } from '@devexpress/dx-react-core';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { TextField, makeStyles } from '@material-ui/core';
import {
  getFormattedBillableStatus,
  getFormattedTableRows,
  isBillableStatusValid,
  isTimesheetFormDataValid
} from '../../../MyTime/table/helpers';
import timesheetService from '../../../../services/TimesheetService';
import {
  calculateHours,
  checkWorkType,
  formattedWorkType,
  isClassAndCustomerRefValid,
  isEditedTimeSheetValid,
  isEmployeeRefValidInEditedTimesheet,
  isLeaveBalanceValid
} from '../helper/helper';
import keyPayEmployeeService from '../../../../services/KeypayEmployee';
import { BASE_URL } from '../../../Constant';
import ImageFullScreenDialog from '../../../../shared/dialog/ImageFullScreenDialog';

const PREFIX = 'Demo';
const classes = {
  lookupEditCell: `${PREFIX}-lookupEditCell`,
  dialog: `${PREFIX}-dialog`,
  inputRoot: `${PREFIX}-inputRoot`,
  selectMenu: `${PREFIX}-selectMenu`
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.lookupEditCell}`]: {
    padding: theme.spacing(1)
  },
  [`& .${classes.dialog}`]: {
    width: 'calc(100% - 16px)'
  },
  [`& .${classes.inputRoot}`]: {
    width: '100%'
  },
  [`& .${classes.selectMenu}`]: {
    position: 'absolute !important'
  }
}));

const useStyles = makeStyles({
  root: {
    '& tfoot': {
      display: 'none'
    }
  }
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: 'center' }}>
    <Button color="primary" onClick={onExecute} title="Create new row">
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row" size="large">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (confirm('Are you sure you want to delete this row?')) {
        onExecute();
      }
    }}
    title="Delete row"
    size="large"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

const LookupEditCell = ({ availableColumnValues, value, onValueChange, column, row, onBlur, autoFocus }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const columnName = column.name;

  const options = availableColumnValues[columnName].map((item) => {
    return {
      value: item.name || item,
      label: item.name || item,
      id: item.value || item
    };
  });

  const dropdownValue = { value, label: value };
  return (
    <>
      <StyledTableCell className={classes.lookupEditCell}>
        <ReactSelect
          onBlur={isSmall ? null : onBlur} //blur doesn't work in mobile phones
          autoFocus={autoFocus}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={dropdownValue}
          isSearchable={true}
          name="color"
          options={options}
          isClearable={true}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onChange={(selectedOption) => {
            if (selectedOption === null) {
              // Handle the case when the selected option is cleared
              onValueChange(null); // or handle it based on your requirements
            } else {
              if (
                columnName === 'ClassRef' ||
                columnName === 'CustomerRef' ||
                columnName === 'EmployeeRef' ||
                columnName === 'VendorRef'
                // ||
                // columnName === 'workType'
              ) {
                onValueChange({ name: selectedOption.value, value: selectedOption.id });
              } else {
                onValueChange(selectedOption.value);
              }
            }
          }}
        />
      </StyledTableCell>
    </>
  );
};

const FocusableCell = ({ onClick, invalidRows, setInvalidRows, ...restProps }) => {
  const { row, column } = restProps;

  const isInvalidRow = invalidRows.includes(row.id);

  const handleCellClick = () => {
    // Check if the clicked row is invalid, and if so, remove the styles and remove from invalidRows.
    if (isInvalidRow) {
      restProps.style = {};
      const clickedRowId = row.id;

      const updatedInvalidRows = invalidRows.filter((id) => id !== clickedRowId);
      setInvalidRows(updatedInvalidRows);
    }
    onClick();
  };

  if (restProps.column.name === 'timeSheetImageUrl') {
    return (
      <Table.Cell {...restProps} value={<img src={restProps.value} style={{ width: '30px', height: '30px' }} />} />
    );
  }

  if (column.name === 'CustomerRef' || column.name === 'ClassRef' || column.name === 'workType') {
    return (
      <Table.Cell
        {...restProps}
        tabIndex={0}
        onFocus={handleCellClick}
        style={{
          ...restProps.style, // Keep the existing styles
          border: isInvalidRow ? '1px solid red' : '',
          cursor: isInvalidRow ? 'pointer' : ''
        }}
      />
    );
  }

  return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />;
};

const Calendar = ({ ...props }) => {
  const { value, onValueChange, column, autoFocus, onBlur } = props;

  return (
    <td>
      <TextField
        autoFocus={autoFocus}
        onBlur={onBlur}
        type={column.name === 'Date' ? 'date' : 'time'}
        onChange={(event) => onValueChange(event.target.value)}
        name={column.name}
        value={column.name === 'Date' ? format(new Date(value), 'yyyy-MM-dd') : value}
      />
    </td>
  );
};

const CalendarProvider = (props) => {
  return <DataTypeProvider formatterComponent={DateFormatter} editorComponent={Calendar} {...props} />;
};

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};

const EditCommandCell = ({ errors, disableEditAndDelete, ...props }) => {
  const children = props.children;
  const row = props.row;

  // const disableEditAndDelete = row.status !== 'WithEmployee';

  const renderEditAndDelete = (child) => {
    if (disableEditAndDelete(row)) {
      return <>-</>;
    }

    return child;
  };

  return (
    <TableEditColumn.Cell {...props}>
      {React.Children.map(children, (child) =>
        child?.props.id === 'commit'
          ? React.cloneElement(child, { disabled: errors[props.tableRow.rowId] })
          : renderEditAndDelete(child)
      )}
    </TableEditColumn.Cell>
  );
};

class PatchedIntegratedSelection extends React.PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props;

    return (
      <Plugin>
        <Getter
          name="rows"
          computed={({ rows }) => {
            this.rows = rows;
            return rows.filter(rowSelectionEnabled);
          }}
        />
        <IntegratedSelection {...restProps} />
        <Getter name="rows" computed={() => this.rows} />
      </Plugin>
    );
  }
}

class PatchedTableSelection extends React.PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props;

    return (
      <TableSelection
        cellComponent={(props) =>
          rowSelectionEnabled(props.tableRow.row) ? <TableSelection.Cell {...props} /> : <Table.StubCell {...props} />
        }
        {...restProps}
      />
    );
  }
}

const TableHeaderContent = ({ totalEmployeeCount, totalDateCount, totalHours, column, children, ...restProps }) => {
  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      {column.name === 'EmployeeRef' ? <div style={{ marginRight: '5px' }}>({totalEmployeeCount}) </div> : null}
      {column.name === 'Hours' ? <div style={{ marginRight: '5px' }}>({totalHours}) </div> : null}
      {column.name === 'Day' ? <div style={{ marginRight: '5px' }}>({totalDateCount}) </div> : null}
      {children}
    </TableHeaderRow.Content>
  );
};

const DateFormatter = ({ value }) => <span>{format(value, 'dd/MM/yyyy')}</span>;

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;

//customize description cell before downloading
const customizeCell = (cell, row, column) => {
  if (column.name === 'Description') {
    cell.value =
      format(row.Date, 'dd/MM/yyyy') + ' ' + row.EmployeeRef.name + ' ' + row.Description + ' ' + row.variationNote;
  }
  if (column.name === 'Date') {
    cell.value = format(row.Date, 'dd/MM/yyyy');
  }
};

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

const LookupFormatter = ({ availableColumnValues, ...props }) => (
  <DataTypeProvider
    editorComponent={(params) => <LookupEditCell availableColumnValues={availableColumnValues} {...params} />}
    {...props}
  />
);

const getRowId = (row) => row.id;

export default function AdminTimesheetTable({
  rows,
  columns,
  setRows,
  classRefs,
  customerRefs,
  employeeRefs,
  selection,
  rowSelectionEnabled,
  changeSelection,
  disableEditAndDelete,
  hideComponent,
  dateColumns,
  sorting,
  setSorting,
  filter,
  editingStateColumnExtensions,
  workType,
  invalidRows,
  setInvalidRows,
  currentPage,
  setCurrentPage,
  pageSize,
  handlePageSizeChange,
  pageSizes
}) {
  const [columnOrder, setColumnOrder] = useLocalStorage('defaultTimeSheetColumnOrder', [
    'EmployeeRef',
    'KeyPayEmployeeId',
    'StartTime',
    'FinishTime',
    'Hours',
    'Day',
    'Date',
    'Description',
    'status',
    'CustomerRef',
    'ClassRef',
    'BillableStatus',
    'variationNote',
    'workType',
    'timeSheetImageUrl',
    'VendorRef',
    'email',
    'jobTitle'
  ]);
  const [defaultColumnWidths, setDefaultColumnWidths] = useLocalStorage('defaultTimeSheetColumnWidths', [
    { columnName: 'EmployeeRef', width: 180 },
    { columnName: 'KeyPayEmployeeId', width: 180 },
    { columnName: 'StartTime', width: 120 },
    { columnName: 'FinishTime', width: 120 },
    { columnName: 'Hours', width: 120 },
    { columnName: 'Day', width: 120 },
    { columnName: 'Date', width: 180 },
    { columnName: 'Description', width: 180 },
    { columnName: 'status', width: 180 },
    { columnName: 'CustomerRef', width: 180 },
    { columnName: 'ClassRef', width: 180 },
    { columnName: 'BillableStatus', width: 180 },
    { columnName: 'variationNote', width: 180 },
    { columnName: 'workType', width: 180 },
    { columnName: 'timeSheetImageUrl', width: 240 },
    { columnName: 'VendorRef', width: 180 },
    { columnName: 'email', width: 180 },
    { columnName: 'jobTitle', width: 180 }
  ]);

  const [hiddenColumnNames, setHiddenColumnNames] = useLocalStorage('hiddenTimeSheetColumnNames', [
    'timeSheetImageUrl',
    'KeyPayEmployeeId',
    'workType',
    'jobTitle',
    'email',
    'VendorRef'
  ]);

  const [editingRowIds, getEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  // const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);
  // const [pageSizes] = useState([5, 25, 50, 100]);
  const [validationErrors, setValidationErrors] = useState({});

  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);

  const [totalSummaryItems] = useState([
    { columnName: 'Hours', type: 'Hours' },
    { columnName: 'Date', type: 'Date' },
    { columnName: 'KeyPayEmployeeId', type: 'KeyPayEmployeeId' },
    { columnName: 'EmployeeRef', type: 'EmployeeRef' }
  ]);

  const [totalDateCount, setTotalDateCount] = useState(0);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [addedRows, setAddedRows] = useState([]);

  const classes = useStyles();

  //to calculate timesheet date
  const summaryCalculator = (type, rows, getValue) => {
    // eslint-disable-next-line no-undef
    const selectionSet = new Set(selection);
    const selectedRows = rows.filter((row) => selectionSet.has(row.id));
    if (type === 'Date') {
      const changedRows = selectedRows.length
        ? selectedRows.map((row) => new Date(getValue(row)).getTime()).filter(Number)
        : rows.map((row) => new Date(getValue(row)).getTime()).filter(Number);
      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalDateCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    if (type === 'KeyPayEmployeeId') {
      const changedRows = selectedRows.length
        ? selectedRows
            .map((row) => {
              if (!getValue(row)) return;
              getValue(row);
            })
            .filter(Number)
        : rows
            .map((row) => {
              if (!getValue(row)) return;
              getValue(row);
            })
            .filter(Number);
      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      return duplicateRemovedRowsLength;
    }
    if (type === 'Hours') {
      const totalHours = selectedRows.length
        ? selectedRows
            .map((row) => getValue(row))
            .filter(Number)
            .reduce((a, c) => a + c, 0)
        : rows
            .map((row) => getValue(row))
            .filter(Number)
            .reduce((a, c) => a + c, 0);

      setTotalHours(totalHours);
      return totalHours;
    }
    if (type === 'EmployeeRef') {
      const changedRows = selectedRows.length
        ? selectedRows.map((row) => {
            if (!getValue(row)) return;
            return getValue(row);
          })
        : rows.map((row) => {
            if (!getValue(row)) return;
            return getValue(row);
          });

      // eslint-disable-next-line no-undef
      const duplicateRemovedRows = new Set([...changedRows]);
      let duplicateRemovedRowsLength = duplicateRemovedRows.size;
      setTotalEmployeeCount(duplicateRemovedRowsLength);
      return duplicateRemovedRowsLength;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const formatlessSummaryTypes = ['Hours', 'Date', 'KeyPayEmployeeId', 'EmployeeRef'];

  const messages = {
    Hours: 'TotalHours',
    Date: 'TotalDays',
    KeyPayEmployeeId: 'TotalEmployees',
    EmployeeRef: 'TotalEmployees'
  };

  const exporterRef = useRef(null);

  const [lookupColumns] = useState(['CustomerRef', 'ClassRef', 'EmployeeRef', 'workType', 'BillableStatus']);

  const [calendarColumn] = useState(['Date']);

  const [imageColumn] = useState(['timeSheetImageUrl']);

  const availableColumnValues = {
    CustomerRef: customerRefs,
    ClassRef: classRefs,
    EmployeeRef: employeeRefs,
    workType: workType,
    BillableStatus: getFormattedBillableStatus()
  };

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows;

    //if new button is clicked
    if (added) {
      const addedRow = added[0];

      const toBoolean = addedRow.BillableStatus === 'false' ? false : true;

      const addedTimesheet = {
        startTime: addedRow.StartTime,
        finishTime: addedRow.FinishTime,
        date: addedRow.Date,
        description: addedRow.Description ? addedRow.Description : '',
        isBillable: toBoolean,
        client: addedRow.CustomerRef,
        task: addedRow.ClassRef,
        variationNote: addedRow.variationNote ? addedRow.variationNote : '',
        employeeRef: addedRow.EmployeeRef
      };

      const timesheetPayload = isTimesheetFormDataValid(addedTimesheet);

      if (!timesheetPayload) {
        return toast.error('Please fill required data');
      }

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('timesheetPayload', JSON.stringify(timesheetPayload));
      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      timesheetService
        .addTimesheet(formData)
        .then(({ data }) => {
          toast.success('Successfully added timesheet.');

          let timesheetData = [];
          timesheetData.push(data.data);
          const formattedTimesheet = getFormattedTableRows(timesheetData);
          setRows([formattedTimesheet[0], ...rows]);
        })
        .catch((error) => {
          toast.error('could not add timesheet' || error);
        });
    }

    //if edit button clicked
    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

      const changedRowId = Object.keys(changed)[0];
      const changedRow = changedRows.find((r) => r.id === changedRowId);
      //timesheet cannot be saved if workType/classRef/customerRef are empty
      //either timesheet must have classRef and customerRef or workTpe

      if (!changedRow.workType && !changedRow.ClassRef && !changedRow.CustomerRef) {
        toast.error('Include Customer and Client or WorkType. All three cannot be empty');
        setRows(rows);
        return;
      }

      if (changedRow.workType && changedRow.ClassRef && changedRow.CustomerRef) {
        toast.error('Include Customer and Client or WorkType.');
        setRows(rows);
        return;
      }

      if (changedRow && changed[changedRowId].ClassRef) {
        const billableStatusValid = isBillableStatusValid(changedRow.BillableStatus, changedRow.variationNote);
        const validClassAndCustomerRef = isClassAndCustomerRefValid(changedRow);
        const validEmployeeRef = isEmployeeRefValidInEditedTimesheet(changedRow);

        // first check if added timesheet is valid or not
        // if (!validClassAndCustomerRef) {
        //   toast.error("Timesheet must have 'Work Type' or 'Customer Ref and Class Ref' ");
        //   setRows(rows);
        //   return;
        // }

        //second check if employeeRef is valid or not
        if (!validEmployeeRef) {
          toast.error("Timesheet must have 'Employee Name' ");
          setRows(rows);
          return;
        }

        if (!billableStatusValid) {
          toast.error(`'Billable' status must have a variation note`);
          setRows(rows);

          return;
        }
      }

      if (changed[changedRowId]?.workType || (!changed[changedRowId].ClassRef && !changed[changedRowId].CustomerRef)) {
        if (checkWorkType(changed[changedRowId]?.workType)) {
          // //if worktype is selected timesheet should not have customerRef and classRef

          // const validTimesheet =

          //check for annual leave balance, rdo and sick leave balance
          const leaveBalanceResult = await keyPayEmployeeService.getEmployeeLeaveBalance(changedRow.KeyPayEmployeeId);

          const selectedLeave = leaveBalanceResult.data.data.filter(
            (l) => l.leaveCategoryName == changed[changedRowId].workType
          )[0];

          const changedRowHours = calculateHours(changedRow.StartTime, changedRow.FinishTime, changedRow.Date);

          const leaveBalanceValid = isLeaveBalanceValid(selectedLeave.accruedAmount, changedRowHours);

          if (!leaveBalanceValid) {
            toast.error(`Not enough 'Leave Balance' to take ${changed[changedRowId].workType} work type timesheet.`);
            setRows(rows);

            return;
          }
        }
      }

      // }

      let updatedRow = changed[changedRowId];

      updatedRow = formattedWorkType(updatedRow);

      //update edited data
      if (updatedRow) {
        timesheetService
          .editTimesheet(changedRowId, updatedRow)
          .then(({ data }) => {
            const updatedRows = changedRows.map((row) => {
              if (row.id === data.data._id) {
                row.Date = new Date(data.data.Date);
                row.workType = data.data.workType || '';
                row.KeyPayEmployeeId = data.data.KeyPayEmployeeId;
              }
              return row;
            });
            setRows(updatedRows);

            toast.success('Successfully edited timesheet');
          })
          .catch((error) => {
            toast.error(error?.message || "Couldn't update timesheet. Something's wrong...");
          });
      }
    }

    //if delete button clicked
    if (deleted) {
      // eslint-disable-next-line no-undef
      if (window.confirm('Are you sure you want to delete this row?')) {
        changedRows = deleteRows(deleted);

        timesheetService
          .deleteTimesheet(deleted[0])
          .then(() => {
            toast.success('Successfully deleted selected timesheet');
            setRows(changedRows);
          })
          .catch((error) => {
            toast.error('Could not delete selected timesheet' || error);
          });
      }
    }
  };

  const validate = (rows, columns) =>
    Object.entries(rows).reduce(
      (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columns.some((column) => column.required && row[column.name] === '')
      }),
      {}
    );

  const onEdited = (edited) => setValidationErrors(validate(edited, columns));

  // const onAdded = (added) => setValidationErrors(validate(added, columns));

  const addEmptyRow = () => {
    const employeeRefValue =
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_BASE_URL_LOCAL ? '6454ef3f03c41e24a9d555ed' : '639131d00084ae1c61d21562';
    const employeeRefLabel = 'Asmita G';
    commitChanges({
      added: [
        {
          Hours: 8,
          EmployeeRef: {
            value: employeeRefValue,
            label: employeeRefLabel,
            Id: employeeRefValue
          },
          StartTime: '07:00',
          FinishTime: '15:00',
          Date: new Date(),
          CustomerRef: { value: customerRefs[0]?.value, label: customerRefs[0]?.name, id: customerRefs[0]?.value },
          ClassRef: { value: classRefs[0]?.value, label: classRefs[0]?.name, id: classRefs[0]?.value },
          status: 'WithEmployee',
          BillableStatus: 'false',
          Description: 'Note'
        }
      ]
    });
  };

  return (
    <div>
      <div style={{ margin: '10px 0px' }}>
        <div>Total rows selected: {selection ? selection.length : 0}</div>
      </div>
      <Paper className={classes.root}>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <CalendarProvider for={calendarColumn} />
          <LookupFormatter for={lookupColumns} availableColumnValues={availableColumnValues} />
          <ImageProvider for={imageColumn} />

          <SortingState sorting={sorting} onSortingChange={setSorting} />

          <SelectionState selection={selection} onSelectionChange={changeSelection} />

          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={getEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={(changes) => {
              onEdited(changes);
              setRowChanges(changes);
            }}
            // addedRows={addedRows}
            // onAddedRowsChange={(added) => {
            //   console.log('added is:', added);
            //   onAdded(added);
            //   changeAddedRows(added);
            // }}
            addedRows={[]}
            onAddedRowsChange={addEmptyRow}
            // onRowChangesChange={onEdited}
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />

          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          {/* <IntegratedSelection /> */}
          <PatchedIntegratedSelection rowSelectionEnabled={rowSelectionEnabled} />
          <IntegratedPaging />

          <IntegratedSummary calculator={summaryCalculator} />
          {/* <CurrencyTypeProvider for={currencyColumns} />
        <PercentTypeProvider for={percentColumns} /> */}
          <DragDropProvider />
          <DateTypeProvider for={dateColumns} />

          {/* <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} /> */}
          {/* <SearchState value={searchValue} onValueChange={setSearchState} /> */}
          {/* <IntegratedFiltering /> */}
          <Table
            cellComponent={(props) => (
              <FocusableCell invalidRows={invalidRows} setInvalidRows={setInvalidRows} {...props} />
            )}
          />

          {/* TableSelection replaced by PatchedTableSelection */}
          {/* <TableSelection showSelectAll cellComponent={SelectionCell} /> */}
          <PatchedTableSelection showSelectAll highlightRow rowSelectionEnabled={rowSelectionEnabled} />

          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            onColumnWidthsChange={setDefaultColumnWidths}
          />
          <TableHeaderRow
            contentComponent={(props) => (
              <TableHeaderContent
                {...props}
                totalEmployeeCount={totalEmployeeCount}
                totalDateCount={totalDateCount}
                totalHours={totalHours}
              />
            )}
            showSortingControls
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <Toolbar />
          {/* <SearchPanel /> */}
          <ColumnChooser />

          {/* <TableEditRow cellComponent={editCellComponent} /> */}
          <TableInlineCellEditing startEditAction="click" selectTextOnEditStart={true} />

          {/* show new button only in admin timesheet */}
          {(hideComponent && filter.timesheetStatus === 'All') ||
          (hideComponent && filter.timesheetStatus === 'WithEmployee') ? (
            <TableEditColumn
              width={170}
              showAddCommand={!addedRows.length}
              // showEditCommand
              showDeleteCommand
              // commandComponent={Command}
              cellComponent={(props) => (
                <EditCommandCell {...props} disableEditAndDelete={disableEditAndDelete} errors={validationErrors} />
              )}
            />
          ) : (
            <TableEditColumn
              width={170}
              // showEditCommand
              showDeleteCommand
              // commandComponent={Command}
              cellComponent={(props) => (
                <EditCommandCell {...props} disableEditAndDelete={disableEditAndDelete} errors={validationErrors} />
              )}
            />
          )}

          <TableSummaryRow formatlessSummaryTypes={formatlessSummaryTypes} messages={messages} />

          <TableFixedColumns leftColumns={leftFixedColumns} />
          <PagingPanel pageSizes={pageSizes} />
          {hideComponent && <ExportPanel startExport={startExport} />}
        </Grid>

        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            hiddenColumnNames={hiddenColumnNames}
            columns={columns}
            selection={selection}
            onSave={onSave}
            getRowId={getRowId}
            customizeCell={customizeCell}
          />
        </div>
      </Paper>
    </div>
  );
}
