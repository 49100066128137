export function formattedClass(classList) {
  return classList.map((item) => ({
    id: item.Id,
    FullyQualifiedName: item.FullyQualifiedName || '',
    domain: item.domain,
    Name: item.Name || '',
    SyncToken: item.SyncToken || '',
    SubClass: item.SubClass || '',
    sparse: item.sparse || '',
    Active: item.Active || '',
    Id: item.Id,
    classStatus: 'Enabled'
  }));
}
