import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//list vendor/supplier from database
function getVendorsFromDatabase() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'vendor/getVendorsFromDatabase';
  return axios.post(endpoint, payload);
}

//get vendor from quickbooks
function getVendorsFromQBO() {
  const endpoint = BASE_URL + 'vendor/getVendorsFromQBO';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update quickbooks vendor
function updateQboVendor(vendorPayload) {
  const endpoint = BASE_URL + 'vendor/updateQboVendor';
  return axios.put(
    endpoint,
    { vendorPayload: vendorPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update vendor status, enable or disable
function updateVendorStatus(selectedVendorIds, vendorStatus) {
  const endpoint = BASE_URL + 'vendor/updateVendorStatus';

  return axios.put(
    endpoint,
    { selectedVendorIds: selectedVendorIds, vendorStatus: vendorStatus },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//collection of vendor are modified, update them in qbo or if new create in qbo
function updateImportedVendorsInQbo(vendorsPayload) {
  const endpoint = BASE_URL + 'vendor/updateImportedVendorsInQbo';

  return axios.put(
    endpoint,
    { vendorsPayload: vendorsPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const vendorService = {
  getVendorsFromDatabase,
  getVendorsFromQBO,
  updateQboVendor,
  updateVendorStatus,
  updateImportedVendorsInQbo
};

export default vendorService;
