import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import toast, { Toaster } from 'react-hot-toast';
import { CircularProgress } from '@material-ui/core';
import RequestLeave from './RequestLeave';
import requestLeaveService from '../../../services/RequestLeaveService';

//requestLeave/:employeeId ,
// list all the leave request employee with each have two div:
//1.  requested leave details, 3. actions: accept and reject buttons
//once accept/reject button is clicked , toast the message, then list other leave request of the employee

const AdminRequestLeave = () => {
  const { employeeId } = useParams();
  const [requestLeaveRecord, setLeaveRequestRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeRequestLeave = async () => {
      try {
        setIsLoading(true);
        const employeeLeaveRequest = await requestLeaveService.getLeaveRequest(employeeId);

        const requestLeaveRecord = employeeLeaveRequest?.data?.data;

        if (requestLeaveRecord.length) {
          setLeaveRequestRecord(requestLeaveRecord);
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message || 'Could not authenticate employee';
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeRequestLeave();
  }, [employeeId]);

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : requestLeaveRecord.length ? (
        requestLeaveRecord?.map((leaveRequestItem) => {
          return (
            <RequestLeave
              leaveRequestItem={leaveRequestItem}
              key={leaveRequestItem?._id}
              setLeaveRequestRecord={setLeaveRequestRecord}
              employeeId={employeeId}
            />
          );
        })
      ) : (
        'No leave request to approve for this employee'
      )}
    </div>
  );
};

export default AdminRequestLeave;
