/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useLocalStorage } from 'react-use';

import VendorTab from './VendorTab';
import VendorGeneral from '../tabs/General/VendorGeneral';
import VendorAccounting from '../tabs/Accounting/VendorAccounting';
import vendorService from '../../../services/VendorService';

import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';

import { GridExporter } from '@devexpress/dx-react-grid-export';

import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  IntegratedSelection,
  SelectionState,
  SortingState,
  IntegratedSorting
} from '@devexpress/dx-react-grid';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableEditColumn,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  SearchPanel,
  ExportPanel,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import { getFormattedVendorPayload } from './helper';
import { toast } from 'react-hot-toast';

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open, vendorTabIndex, setVendorTabIndex }) => {
  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      {/* Dialog contains tab and tab contents: CustomerTab is tab part */}
      <VendorTab vendorTabIndex={vendorTabIndex} setVendorTabIndex={setVendorTabIndex} />

      {/* General and Accounting is the content of tab  */}
      {vendorTabIndex === 0 && (
        <VendorGeneral
          row={row}
          onChange={onChange}
          onApplyChanges={onApplyChanges}
          onCancelChanges={onCancelChanges}
        />
      )}
      {vendorTabIndex === 1 && (
        <VendorAccounting
          row={row}
          onChange={onChange}
          onCancelChanges={onCancelChanges}
          onApplyChanges={onApplyChanges}
        />
      )}
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name, react/prop-types
const PopupEditing = React.memo(({ popupComponent: Popup, vendorTabIndex, setVendorTabIndex, setRows }) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(
          { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
          {
            changeRow,
            changeAddedRow,
            commitChangedRows,
            commitAddedRows,
            stopEditRows,
            cancelAddedRows,
            cancelChangedRows
          }
        ) => {
          const isNew = addedRows.length > 0;
          let editedRow;
          let rowId;
          if (isNew) {
            rowId = 0;
            editedRow = addedRows[rowId];
          } else {
            [rowId] = editingRowIds;
            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
            editedRow = { ...targetRow, ...rowChanges[rowId] };
          }

          const processValueChange = ({ target: { name, value } }) => {
            const changeArgs = {
              rowId,
              change: createRowChange(editedRow, value, name)
            };
            if (isNew) {
              changeAddedRow(changeArgs);
            } else {
              changeRow(changeArgs);
            }
          };
          const rowIds = isNew ? [0] : editingRowIds;
          const applyChanges = () => {
            if (isNew) {
              commitAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              commitChangedRows({ rowIds });
              if (!rowChanges[rowId]) {
                return toast.error('Please change any fields to update vendor in QuickBooks');
              }

              vendorService
                .updateQboVendor(editedRow)
                .then((response) => {
                  const vendorData = response.data.data;
                  const updatedRow = rows.map((r) => {
                    if (r.id === vendorData.Id) {
                      return { ...r, ...editedRow };
                    }
                    return r;
                  });

                  setRows(updatedRow);
                  toast.success('Successfully updated vendor in quickbook');
                })
                .catch((error) => {
                  const errorMessage = error.response?.data?.message;
                  toast.error(errorMessage || 'Could not update vendor in quickbook');
                });
            }
          };
          const cancelChanges = () => {
            if (isNew) {
              cancelAddedRows({ rowIds });
            } else {
              stopEditRows({ rowIds });
              cancelChangedRows({ rowIds });
              setVendorTabIndex(0);
            }
          };

          const open = editingRowIds.length > 0 || isNew;
          return (
            <Popup
              open={open}
              row={editedRow}
              onChange={processValueChange}
              onApplyChanges={applyChanges}
              onCancelChanges={cancelChanges}
              vendorTabIndex={vendorTabIndex}
              setVendorTabIndex={setVendorTabIndex}
            />
          );
        }}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
));

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

const getRowId = (row) => row.id;

const VendorTable = ({ rows, setRows, selection, setSelection }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100]);

  const [vendorTabIndex, setVendorTabIndex] = useState(0);

  const [columns] = useState([
    { name: 'DisplayName', title: 'DisplayName' },
    { name: 'Email', title: 'Email' },
    { name: 'PrimaryPhone', title: 'PrimaryPhone' },
    { name: 'vendorStatus', title: 'Action' },
    { name: 'VendorId', title: 'VendorId' },

    { name: 'BillAddrId', title: 'BillAddrId' },
    { name: 'Line1', title: 'Line1' },
    { name: 'City', title: 'City' },
    { name: 'CountrySubDivisionCode', title: 'CountrySubDivisionCode' },
    { name: 'PostalCode', title: 'PostalCode' },

    { name: 'TaxIdentifier', title: 'TaxIdentifier' },
    { name: 'TermRef', title: 'TermRef' },

    { name: 'Balance', title: 'Balance' },
    { name: 'BillRate', title: 'BillRate' },
    { name: 'Vendor1099', title: 'Vendor1099' },

    { name: 'BankAccountName', title: 'BankAccountName' },
    { name: 'BankBranchIdentifier', title: 'BankBranchIdentifier' },
    { name: 'BankAccountNumber', title: 'BankAccountNumber' },
    { name: 'StatementText', title: 'StatementText' },

    { name: 'AcctNum', title: 'AcctNum' },
    { name: 'CostRate', title: 'CostRate' },
    { name: 'sparse', title: 'sparse' },
    { name: 'SyncToken', title: 'SyncToken' },

    { name: 'Suffix', title: 'Suffix' },
    { name: 'Active', title: 'Active' },
    { name: 'V4IDPseudonym', title: 'V4IDPseudonym' },

    { name: 'GivenName', title: 'GivenName' },
    { name: 'MiddleName', title: 'MiddleName' },
    { name: 'FamilyName', title: 'FamilyName' },
    { name: 'CompanyName', title: 'CompanyName' },
    { name: 'PrintOnCheckName', title: 'PrintOnCheckName' }
  ]);

  const [vendorColumnWidths, setVendorColumnWidths] = useState([
    { columnName: 'DisplayName', width: 180 },
    { columnName: 'Email', width: 180 },
    { columnName: 'PrimaryPhone', width: 180 },
    { columnName: 'vendorStatus', width: 180 },
    { columnName: 'VendorId', width: 180 },

    { columnName: 'BillAddrId', width: 180 },
    { columnName: 'Line1', width: 180 },
    { columnName: 'City', width: 180 },
    { columnName: 'CountrySubDivisionCode', width: 180 },
    { columnName: 'PostalCode', width: 180 },

    { columnName: 'TaxIdentifier', width: 180 },
    { columnName: 'TermRef', width: 180 },
    { columnName: 'Balance', width: 180 },
    { columnName: 'BillRate', width: 180 },
    { columnName: 'Vendor1099', width: 180 },

    { columnName: 'BankAccountName', width: 180 },
    { columnName: 'BankBranchIdentifier', width: 180 },
    { columnName: 'BankAccountNumber', width: 180 },
    { columnName: 'StatementText', width: 180 },

    { columnName: 'AcctNum', width: 180 },
    { columnName: 'CostRate', width: 180 },
    { columnName: 'sparse', width: 180 },
    { columnName: 'SyncToken', width: 180 },

    { columnName: 'Suffix', width: 180 },
    { columnName: 'Active', width: 180 },
    { columnName: 'V4IDPseudonym', width: 180 },

    { columnName: 'GivenName', width: 180 },
    { columnName: 'MiddleName', width: 180 },
    { columnName: 'FamilyName', width: 180 },
    { columnName: 'CompanyName', width: 180 },
    { columnName: 'PrintOnCheckName', width: 180 }
  ]);
  const [vendorColumnOrder, setVendorColumnOrder] = useState([
    'DisplayName',
    'Email',
    'PrimaryPhone',
    'vendorStatus',
    'VendorId',
    'BillAddrId',
    'Line1',
    'City',
    'CountrySubDivisionCode',
    'PostalCode',
    'TaxIdentifier',
    'TermRef',
    'Balance',
    'BillRate',
    'Vendor1099',
    'BankAccountName',
    'BankBranchIdentifier',
    'BankAccountNumber',
    'StatementText',
    'AcctNum',
    'CostRate',
    'sparse',
    'SyncToken',
    'Suffix',
    'Active',
    'V4IDPseudonym',
    'GivenName',
    'MiddleName',
    'FamilyName',
    'CompanyName',
    'PrintOnCheckName'
  ]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState(['']);
  const [searchValue, setSearchState] = useState('');
  const [sorting, setSorting] = useState([{ columnName: 'DisplayName', direction: 'asc' }]);

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  const commitChanges = ({ changed }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    setRows(changedRows);
  };

  return (
    <Paper>
      <span>Total rows selected: {selection.length}</span>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />

        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <EditingState onCommitChanges={commitChanges} />

        <IntegratedSorting />
        <IntegratedSelection />

        <DragDropProvider />

        <SearchState value={searchValue} onValueChange={setSearchState} />
        <IntegratedFiltering />
        <IntegratedPaging />

        <Table />

        <TableColumnResizing columnWidths={vendorColumnWidths} onColumnWidthsChange={setVendorColumnWidths} />
        <TableColumnReordering order={vendorColumnOrder} onOrderChange={setVendorColumnOrder} />

        <TableHeaderRow showSortingControls />
        <TableSelection showSelectAll />
        <TableEditColumn showEditCommand />
        <PopupEditing
          popupComponent={Popup}
          vendorTabIndex={vendorTabIndex}
          setVendorTabIndex={setVendorTabIndex}
          setRows={setRows}
        />

        <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} onHiddenColumnNamesChange={setHiddenColumnNames} />
        <Toolbar />
        <SearchPanel />
        <ColumnChooser />
        <PagingPanel pageSizes={pageSizes} />
        <ExportPanel startExport={startExport} />
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        hiddenColumnNames={hiddenColumnNames}
        selection={selection}
        onSave={onSave}
        getRowId={getRowId}
      />
    </Paper>
  );
};

export default VendorTable;

VendorTable.propTypes = {
  rows: PropTypes.any,
  setRows: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func
};
