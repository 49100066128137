/* eslint-disable no-undef */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Toaster } from 'react-hot-toast';
import DriverLicenseQualification from './Qualifications/DriverLicenseQualification';
import AGradeQualification from './Qualifications/AGradeQualification';
import OHSQualification from './Qualifications/OHSQualification';
import SuperannuationQualification from './Qualifications/SuperannuationQualification';
import FirstAidQualification from './Qualifications/FirstAidQualification';
import PlantLicenseQualification from './Qualifications/PlantLicenceQualification';
import PoliceCheckQualification from './Qualifications/PoliceCheckQualification';
import RefrigerationHandlingLicenseQualification from './Qualifications/RefrigerationHandlingLicenceQualification';
import UnionCardQualification from './Qualifications/UnionCardQualification';
import WorkingWithChildrenQualification from './Qualifications/WorkingWithChildrenQualification';
import EWPQualification from './Qualifications/EWPQualification';
import LongServiceLeaveQualification from './Qualifications/LongServiceLeaveQualification';
import RedundancyQualification from './Qualifications/RedundancyQualification';
import VBALicenseQualification from './Qualifications/VBALicenceQualification';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const MyQualification = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />
      <div>
        {/* OH&S (White Card)  */}
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>OH&S (White Card)</Typography>
            {/* <Typography className={classes.secondaryHeading}>Update</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <OHSQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Driver License */}
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography className={classes.heading}>Driver License</Typography>
            {/* <Typography className={classes.secondaryHeading}>You are currently not an owner</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <DriverLicenseQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Typography className={classes.heading}>EWP</Typography>
            {/* <Typography className={classes.secondaryHeading}>You are currently not an owner</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <EWPQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* CBUS */}
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
            <Typography className={classes.heading}>Superannuation </Typography>
            {/* <Typography className={classes.secondaryHeading}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <SuperannuationQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* A Grade */}
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header">
            <Typography className={classes.heading}>A Grade</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AGradeQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Co-Invest */}
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6-content" id="panel6-header">
            <Typography className={classes.heading}>Long Service Leave</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LongServiceLeaveQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Plant License */}
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
            <Typography className={classes.heading}>Plant License</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PlantLicenseQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Police check */}
        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8bh-header">
            <Typography className={classes.heading}>Police Check</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PoliceCheckQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Redundancy  */}
        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
            <Typography className={classes.heading}>Redundancy </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RedundancyQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Refrigeration Handling License */}
        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10bh-content" id="panel10bh-header">
            <Typography className={classes.heading}>Refrigeration Handling License </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RefrigerationHandlingLicenseQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Union Card */}
        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11bh-content" id="panel11bh-header">
            <Typography className={classes.heading}>Union Card </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnionCardQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* Working With Children   */}
        <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel12bh-content" id="panel12bh-header">
            <Typography className={classes.heading}>Working With Children </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WorkingWithChildrenQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* First Aid */}
        <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel13bh-content" id="panel13bh-header">
            <Typography className={classes.heading}>First Aid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FirstAidQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>

        {/* VBA */}
        <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel14bh-content" id="panel14bh-header">
            <Typography className={classes.heading}>VBA License(Plumbing)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VBALicenseQualification expanded={expanded} setExpanded={setExpanded} />
          </AccordionDetails>
        </Accordion>
      </div>

      {/* <Grid container spacing={3}>
        <OHSQualification />
        <DriverLicenseQualification />
        <EWPQualification />
        <SuperannuationQualification />
        <AGradeQualification />
        <CoInvestQualification />
        <FirstAidQualification />
        <PlantLicenseQualification />
        <PoliceCheckQualification />
        <ProtectQualification />
        <RefrigerationHandlingLicenseQualification />
        <UnionCardQualification />
        <WorkingWithChildrenQualification />
      </Grid> */}
    </div>
  );
};

export default MyQualification;
