import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { Box, Grid, Typography } from '@material-ui/core';
import { getFormattedEmployeeFromSelectedObj } from '../../helper/helper';

const UserGeneralTopbar = ({ vendorRecords, keyPayEmployeeRecords, setSelectedEmployeeToRegister }) => {
  const [selectedEmployee, setSelectedEmployee] = useState({ value: '', label: '', id: '' });
  const [selectedCustomer, setSelectedCustomer] = useState({ value: '', label: '', id: '' });

  const keyPayEmployeeOptions = keyPayEmployeeRecords
    .sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    })
    .map((e) => {
      return { value: e.firstName + ' ' + e.surname, label: e.firstName + ' ' + e.surname, id: e.id };
    });

  const vendorOptions = vendorRecords.map((s) => {
    return { value: s.DisplayName, label: s.DisplayName, id: s.Id };
  });

  const handleSelectedKeyPayEmployee = (selectedEmployee) => {
    setSelectedEmployee(selectedEmployee);
    const selectedEmployeeDetails = keyPayEmployeeRecords.filter((item) => item.id === selectedEmployee.id)[0];

    const formattedEmployee = getFormattedEmployeeFromSelectedObj(selectedEmployeeDetails);

    setSelectedEmployeeToRegister(formattedEmployee);

    setSelectedCustomer();
  };

  const handleSelectedCustomer = (selectedCustomer) => {
    setSelectedCustomer(selectedCustomer);
    // handleQboObject(selectedCustomer);
    setSelectedEmployee();
  };

  return (
    <div>
      <Grid container spacing={3} style={{ padding: '0px 10px' }}>
        {/* employee */}
        <Grid item xs={12} sm={6}>
          <Typography component="div" m={1}>
            <Box fontWeight="fontWeightMedium"> Select Employee </Box>
          </Typography>

          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={selectedEmployee}
            isSearchable={true}
            name="vendor"
            options={keyPayEmployeeOptions}
            onChange={handleSelectedKeyPayEmployee}
          />
        </Grid>

        {/* supplier */}
        <Grid item xs={12} sm={6}>
          <Typography component="div" m={1}>
            <Box fontWeight="fontWeightMedium"> Select Supplier</Box>
          </Typography>

          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            value={selectedCustomer}
            isSearchable={true}
            name="vendor"
            options={vendorOptions}
            onChange={handleSelectedCustomer}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserGeneralTopbar;

UserGeneralTopbar.propTypes = {
  vendorRecords: PropTypes.array,
  keyPayEmployeeRecords: PropTypes.array,
  handleQboObject: PropTypes.func,
  setSelectedEmployeeToRegister: PropTypes.func
};
