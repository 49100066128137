import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import NavMenu from './nav/NavMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  appTitle: {
    display: 'flex',
    color: 'white',
    paddingTop: '2rem',
    paddingLeft: '1rem',
    marginBottom: '14px'
  },
  hideAppTitle: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      display: 'none'
    },
    cursor: 'pointer'
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (min-width: 600px)': {
      justifyContent: 'flex-end'
    }
  }
}));

const Nav = ({ handleDrawerToggle, handleAppTitle }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.nav}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.hideAppTitle} onClick={handleAppTitle}>
            <div>
              <img
                src="boxshall_logo.png"
                alt="website_logo"
                style={{ maxHeight: '30px', height: '100%', maxWidth: '42px', width: '100%', objectFit: 'cover' }}
              />
            </div>
            <div style={{ lineHeight: '1' }}>
              <span style={{ fontSize: '20px', color: '#ecab53' }}>Verd</span>
            </div>
          </div>
          <NavMenu />
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Nav;

Nav.propTypes = {
  handleDrawerToggle: PropTypes.func,
  handleAppTitle: PropTypes.any
};
