/**
 * Format vendor data api response
 *  fully qualified name is the  vendor display name in qbo : that'll be our reference, which cannot be modify
 */
export function getFormattedVendorFromQuickbooks(vendorList) {
  return vendorList.map((item) => ({
    id: item.Id,
    Email: item.PrimaryEmailAddr?.Address || '',
    vendorStatus: 'Enabled',
    PrimaryPhone: item.PrimaryPhone?.FreeFormNumber || '',
    VendorId: item.Id,

    BillAddrId: item.BillAddr?.Id || '',
    Line1: item.BillAddr?.Line1 || '',
    City: item.BillAddr?.City || '',
    CountrySubDivisionCode: item.BillAddr?.CountrySubDivisionCode || '',
    PostalCode: item.BillAddr?.PostalCode || '',

    TaxIdentifier: item.TaxIdentifier || '',
    TermRef: item.TermRef?.value || '',

    Balance: item.Balance || '',
    BillRate: item.BillRate || '',
    Vendor1099: item.Vendor1099 || '',

    CurrencyRef: {
      value: 'AUD',
      name: 'Australian Dollar'
    },

    // VendorPaymentBankDetail
    BankBranchIdentifier: item.VendorPaymentBankDetail?.BankBranchIdentifier || '',
    BankAccountNumber: item.VendorPaymentBankDetail?.BankAccountNumber || '',
    BankAccountName: item.VendorPaymentBankDetail?.BankAccountName || '',
    StatementText: item.VendorPaymentBankDetail?.StatementText || '',

    AcctNum: item.AcctNum || '',

    CostRate: item.CostRate || '',
    domain: 'QBO',
    sparse: item.sparse || '',
    SyncToken: item.SyncToken || '',

    Suffix: item.Suffix || '',
    Active: item.Active || '',
    V4IDPseudonym: item.V4IDPseudonym || '',

    GivenName: item.GivenName || '',
    MiddleName: item.MiddleName || '',
    FamilyName: item.FamilyName || '',
    DisplayName: item.DisplayName || '',
    CompanyName: item.CompanyName || '',
    PrintOnCheckName: item.PrintOnCheckName || '',
    PrimaryEmailAddr: {
      Address: item.PrimaryEmailAddr?.Address || ''
    }
  }));
}

/**
 * format vendor payload
 * while updating vendor it must contains id
 */
export function getFormattedVendorPayload(vendorPayload, changedRow) {
  if (changedRow.Email) {
    vendorPayload = {
      ...vendorPayload,
      PrimaryEmailAddr: { Address: changedRow.Email }
    };
  }

  if (changedRow.Phone) {
    vendorPayload = {
      ...vendorPayload,
      Mobile: { FreeFormNumber: changedRow.Phone }
    };
  }

  if (changedRow.Line1) {
    vendorPayload = {
      ...vendorPayload,
      BillAddr: { Line1: changedRow.Line1 }
    };
  }

  if (changedRow.CountrySubDivisionCode) {
    vendorPayload = {
      ...vendorPayload,
      BillAddr: { CountrySubDivisionCode: changedRow.CountrySubDivisionCode }
    };
  }
  if (changedRow.City) {
    vendorPayload = {
      ...vendorPayload,
      BillAddr: { City: changedRow.City }
    };
  }
  if (changedRow.PostalCode) {
    vendorPayload = {
      ...vendorPayload,
      BillAddr: { PostalCode: changedRow.PostalCode }
    };
  } else if (
    changedRow.FamilyName ||
    changedRow.GivenName ||
    changedRow.Title ||
    changedRow.MiddleName ||
    changedRow.CompanyName
  ) {
    vendorPayload = {
      ...vendorPayload,
      ...changedRow
    };
  }
  return vendorPayload;
}
