import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get qbo Employee saved in db
function getQboEmployeesFromDb() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'qboEmployee/getQboEmployeesFromDb';
  return axios.post(endpoint, payload);
}

//get all qbo employee from database
function getEmployeesFromQbo() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'qboEmployee/getEmployeesFromQbo';
  return axios.post(endpoint, payload);
}

const qboEmployeeService = {
  getQboEmployeesFromDb,
  getEmployeesFromQbo
};

export default qboEmployeeService;
