import * as z from 'zod';
import { format, getUnixTime } from 'date-fns';

export function checkLeaveCategory(workType) {
  if (workType === 'Annual Leave' || workType === 'Rostered Day Off') return true;
  return false;
}

//annual leave and rdo leave should be valid if leaveBalance - hour is greater than -2
export function isLeaveHoursValid(leaveBalance, hour) {
  if (leaveBalance - hour >= -2) return true;
  return false;
}

//check sick leave category and sick leave balance, should be greater than 6
export function isSickLeaveBalanceValid(leaveBalance, hour) {
  if (leaveBalance - hour >= 6) return true;
  return false;
}

//format credit card account
export function formatCreditCardAccount(accountList) {
  return accountList.map((item) => ({
    value: item.Id,
    name: item.FullyQualifiedName
  }));
}

export function formatAccount(accountList) {
  return accountList.map((item) => ({
    value: item.Id,
    name: item.FullyQualifiedName
  }));
}

export function formatTaxCode(taxCodeList) {
  return taxCodeList.map((item) => ({
    value: item.Id,
    name: item.Name
  }));
}

//requestLeaveForm content: License number, expiry date, photo of card
export function requestLeaveForm(requestForm) {
  const requestLeaveForm = {
    leaveType: requestForm.leaveType || '',
    numberOfDays: requestForm.numberOfDays || 0,
    firstDayOfLeave: requestForm.firstDayOfLeave ? format(new Date(requestForm.firstDayOfLeave), 'yyyy-MM-dd') : '',
    lastDayOfLeave: requestForm.lastDayOfLeave ? format(new Date(requestForm.lastDayOfLeave), 'yyyy-MM-dd') : '',
    firstDayBackToWork: requestForm.firstDayBackToWork
      ? format(new Date(requestForm.firstDayBackToWork), 'yyyy-MM-dd')
      : ''
  };
  return requestLeaveForm;
}

export const requestLeaveFormSchema = z.object({
  leaveType: z.string().min(2, { message: 'Required' }).max(50, { message: 'Too long' }),
  numberOfDays: z.coerce.number().optional(),
  firstDayOfLeave: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  lastDayOfLeave: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  }),
  firstDayBackToWork: z.string().refine((value) => /^\d{4}-\d{2}-\d{2}$/.test(value), {
    message: 'Invalid date format, use YYYY-MM-DD'
  })
});
//min(1, { message: 'Number of days must be at least 1' })

export function checkLeaveDate(firstDayOfLeave, lastDayOfLeave) {
  if (getUnixTime(new Date(firstDayOfLeave)) <= getUnixTime(new Date(lastDayOfLeave))) {
    return true;
  }
  return false;
}

export function checkWorkReturnDate(lastDayOfLeave, firstDayBackToWork) {
  if (getUnixTime(new Date(lastDayOfLeave)) < getUnixTime(new Date(firstDayBackToWork))) {
    return true;
  }
  return false;
}
