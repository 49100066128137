import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-hot-toast';

import ChangePasswordDialog from './dialogBox/ChangePasswordDialog';
import employeeService from '../../services/EmployeeService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  passwordAndSecurity: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width:600px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },

    paddingBottom: '0.7rem',
    borderBottom: '0.0625rem solid rgba(0, 0, 0, 0.05)'
  },
  password: {
    color: '#525f7f',
    fontWeight: '450',
    '@media (max-width:600px)': {
      paddingBottom: '5px'
    }
  }
}));

const ChangePassword = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    reTypeNewPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showRetypeNewPassword: false
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => () => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    function validatePassword(currentPassword, newPassword) {
      if (currentPassword !== '' && newPassword !== '' && newPassword.length > 5 && currentPassword.length > 5) {
        return true;
      } else {
        return false;
      }
    }

    function validateNewPassword(newPassword, reTypeNewPassword) {
      if (newPassword.localeCompare(reTypeNewPassword) === 0) return true;
      return false;
    }

    if (!validatePassword(values.currentPassword, values.newPassword)) {
      return toast.error('Password should be at least 6 characters');
    }

    if (!validateNewPassword(values.newPassword, values.reTypeNewPassword)) {
      return toast.error('New Password and Retype password should match');
    }

    const passwordDetails = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    };

    employeeService
      .changeEmployeePassword(passwordDetails)
      .then((data) => {
        const displayName = data.data.DisplayName;
        const userId = data.data._id;
        const access = data.data.type === 'employee' ? 2 : 1;
        const today = new Date();
        // console.log('data is:', data);
        // eslint-disable-next-line no-undef
        localStorage.setItem('time', today.getHours());
        // eslint-disable-next-line no-undef
        localStorage.setItem('token', data.token);
        // eslint-disable-next-line no-undef
        localStorage.setItem('name', displayName);
        // eslint-disable-next-line no-undef
        localStorage.setItem('access', access);
        // eslint-disable-next-line no-undef
        localStorage.setItem('userId', userId);

        // eslint-disable-next-line no-undef
        localStorage.setItem(
          'role',
          data.data['user_type'] ? data.data['user_type'] : data.data['type'] ? data.data['type'] : ''
        );

        toast.success('Successfully changed password');
        // eslint-disable-next-line no-undef
        setTimeout(() => {
          history.push('/login');
          // eslint-disable-next-line no-undef
          window.location.reload();
        }, 3000); // Adjust the delay according to your preference
      })
      .catch(() => {
        toast.error("Current password didn't match, could not update password");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <div className={classes.passwordAndSecurity}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          Change
        </Button>
      </div>

      <ChangePasswordDialog
        open={open}
        setOpen={setOpen}
        handleClickShowPassword={handleClickShowPassword}
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        handleMouseDownPassword={handleMouseDownPassword}
        handleChangePassword={handleChangePassword}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ChangePassword;
