/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { driverLicense } from '../helper';
import { driverLicenseSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const DriverLicenseQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [driverQualification, setDriverQualification] = useState({
    driverLicense_licenseNumber: '',
    driverLicense_licenseExpiry: '',
    driverLicense_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlDriverLicense,
    handleSubmit: handleDriverLicenseSubmit,
    reset: resetDriverLicense,
    getValues: getDriverLicenseValues,
    formState: { errors: errorsDriverLicense, dirtyFields: dirtyFieldsDriverLicense }
  } = useForm({
    resolver: zodResolver(driverLicenseSchema),
    defaultValues: driverQualification
  });

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        setSubmitting(true);
        if (expanded === 'panel2') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName('Driver License');
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let driverLicenseQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'Driver License').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'Driver License')[0];
            driverLicenseQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              driverLicense_licenseNumber: '',
              driverLicense_licenseExpiry: '',
              driverLicense_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'Driver License')[0];
            driverLicenseQualification = driverLicense(filteredQualification);
          }
          setDriverQualification(driverLicenseQualification);
          resetDriverLicense(driverLicenseQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get driver license qualification');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualificationById();
  }, [resetDriverLicense, expanded]);

  const onSubmitDriverLicense = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getDriverLicenseValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        expiryDate: dirtyValues.driverLicense_licenseExpiry,
        referenceNumber: dirtyValues.driverLicense_licenseNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsDriverLicense.driverLicense_photoOfCard) {
        formData.append('avatar', dirtyValues.driverLicense_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }
      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedDriverLicense = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   driverLicense_licenseNumber: dataObj.referenceNumber || '',
          //   driverLicense_licenseExpiry: dataObj.expiryDate ? format(new Date(dataObj.expiryDate), 'yyyy-MM-dd') : '',
          //   driverLicense_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetDriverLicense(updatedDriverLicense);
          // toast.success('Successfully updated driver license');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update driver license');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleDriverLicenseSubmit(onSubmitDriverLicense)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> License Number</h5>
              <Controller
                name="driverLicense_licenseNumber"
                control={controlDriverLicense}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="driver-license-number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsDriverLicense.driverLicense_licenseNumber}
                    helperText={errorsDriverLicense.driverLicense_licenseNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> License Expiry</h5>
              <Controller
                name="driverLicense_licenseExpiry"
                control={controlDriverLicense}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="driver-license-license-expiry"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsDriverLicense.driverLicense_licenseExpiry}
                    helperText={errorsDriverLicense.driverLicense_licenseExpiry?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card</h5>
              <Controller
                name="driverLicense_photoOfCard"
                control={controlDriverLicense}
                render={({ field }) => (
                  <div>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsDriverLicense.driverLicense_photoOfCard && (
                      <p style={{ color: 'red' }}>{errorsDriverLicense?.driverLicense_photoOfCard?.message}</p>
                    )}
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>Driver License</b> update request to the admin. You will get notified once it
                  gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default DriverLicenseQualification;

DriverLicenseQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
