import { useEffect, useState } from 'react';

import { getFormattedEmployeeRecords } from '../helper/helper';
import employeeService from '../../../../services/EmployeeService';

export default function useFetchEmployeeRefRecords() {
  const [employeeRefs, setEmployeeRefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEmployeeRefRecords = async () => {
      try {
        setLoading(true);

        const employees = await employeeService.getEmployees();
        const employeeRecords = employees.data.data;

        const formattedEmployeeRecords = getFormattedEmployeeRecords(employeeRecords);

        setEmployeeRefs(formattedEmployeeRecords);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeRefRecords();
  }, []);

  return { employeeRefs };
}
