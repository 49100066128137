import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GeneralDialogContent from './GeneralDialogContent';

export default function General({ row, onChange, onCancelChanges, onApplyChanges }) {
  return (
    <div style={{ height: '700px' }}>
      <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: '3rem', marginBottom: '0px', paddingBottom: '0px', lineHeight: '1' }}
      >
        Customer
      </DialogTitle>
      <DialogContent>
        <GeneralDialogContent row={row} onChange={onChange} />
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
}

General.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func
};
