import axios from 'axios';
import { BASE_URL } from '../components/Constant';

function getItemsFromDatabase() {
  const url = BASE_URL + 'item/getItemsFromDatabase';
  return axios.get(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//item list from QBO
function getItemsFromQBO(queryParams) {
  const url = BASE_URL + 'item/getItemsFromQBO';
  return axios.get(
    url,

    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      },
      params: queryParams
    }
  );
}

//update itemStatus in db only related to verd app to show/hide item to employee
function updateItemStatus(selectedItemIds, itemStatus) {
  const url = BASE_URL + 'item/updateItemStatus';
  return axios.put(
    url,
    { selectedItemIds: selectedItemIds, itemStatus: itemStatus },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

//update item in qbo from verd app
function updateItemInQbo(formData) {
  const url = BASE_URL + 'item/updateItemInQbo';
  return axios.put(url, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//upload 20 item's file at a time in qbo
function uploadItemsFilesInQbo(formData) {
  const url = BASE_URL + 'item/uploadItemsFilesInQbo';
  return axios.post(url, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//delete product and services attached image
function deleteProductAndServiceAttachable(idOrEntity) {
  const url = BASE_URL + `item/deleteProductAndServiceAttachable/${idOrEntity}`;
  return axios.delete(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const itemService = {
  getItemsFromDatabase,
  getItemsFromQBO,
  updateItemStatus,
  updateItemInQbo,
  uploadItemsFilesInQbo,
  deleteProductAndServiceAttachable
};

export default itemService;
