/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { vbaLicense } from '../helper';
import { vbaLicenseSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2)
  }
}));

const VBALicenseQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [vbaLicenseQualification, setVBALicenseQualification] = useState({
    vbaLicense_cardNumber: '',
    vbaLicense_expiryDate: '',
    vbaLicense_photoOfCardFrontAndBack: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlVBALicense,
    handleSubmit: handleVBALicenseSubmit,
    reset: resetVBALicense,
    getValues: getVBALicenseValues,
    formState: { errors: errorsVBALicense, dirtyFields: dirtyFieldsVBALicense }
  } = useForm({
    resolver: zodResolver(vbaLicenseSchema),
    defaultValues: vbaLicenseQualification
  });

  useEffect(() => {
    const fetchEmployeeQualificationById = async () => {
      try {
        setSubmitting(true);

        if (expanded === 'panel14') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const qualificationVerdDB = await employeeQualificationService.getQualificationByName(
            'VBA License(Plumbing)'
          );
          const pendingQualification = qualificationVerdDB?.data?.data[0];

          if (pendingQualification) {
            setDisableUpdate(true);
          }

          let vbaLicenseQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'VBA License(Plumbing)').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'VBA License(Plumbing)')[0];
            vbaLicenseQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              vbaLicense_cardNumber: '',
              vbaLicense_expiryDate: '',
              vbaLicense_photoOfCardFrontAndBack: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter(
              (q) => q.name === 'VBA License(Plumbing)'
            )[0];
            vbaLicenseQualification = vbaLicense(filteredQualification);
          }
          console.log('filteredQualification', vbaLicenseQualification);

          setVBALicenseQualification(vbaLicenseQualification);
          resetVBALicense(vbaLicenseQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get VBA License(Plumbing)');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualificationById();
  }, [resetVBALicense, expanded]);

  const onSubmitVBALicense = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getVBALicenseValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        expiryDate: dirtyValues.vbaLicense_expiryDate,
        referenceNumber: dirtyValues.vbaLicense_cardNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsVBALicense.vbaLicense_photoOfCardFrontAndBack) {
        Array.from(dirtyValues.vbaLicense_photoOfCardFrontAndBack).map((file) => {
          formData.append('avatar', file);
        });
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }

      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update VBA License');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleVBALicenseSubmit(onSubmitVBALicense)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> Card Number</h5>
              <Controller
                name="vbaLicense_cardNumber"
                control={controlVBALicense}
                render={({ field }) => (
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsVBALicense.vbaLicense_cardNumber}
                    helperText={errorsVBALicense.vbaLicense_cardNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Expiry Date</h5>
              <Controller
                name="vbaLicense_expiryDate"
                control={controlVBALicense}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsVBALicense.vbaLicense_expiryDate}
                    helperText={errorsVBALicense.vbaLicense_expiryDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card Front And Back</h5>
              <Controller
                name="vbaLicense_photoOfCardFrontAndBack"
                control={controlVBALicense}
                render={({ field }) => (
                  <div>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value ? (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {/* eslint-disable-next-line no-undef */}
                    {field?.value && Array.from(field.value) && field.value.length > 0 && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {/* {
                        Array.from(field.value).map
                      } */}
                        {field?.value &&
                          Array.from(field.value).map((file, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`photo of the card ${index + 1}`}
                              style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                            />
                          ))}
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files)}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        style={{ display: 'none' }}
                        multiple
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsVBALicense.vbaLicense_photoOfCardFrontAndBack && (
                      <p style={{ color: 'red' }}>{errorsVBALicense?.vbaLicense_photoOfCardFrontAndBack?.message}</p>
                    )}
                  </div>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} />
                    <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>VBA License(Plumbing)</b> update request to the admin. You will get notified
                  once it gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default VBALicenseQualification;

VBALicenseQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
