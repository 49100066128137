import { Box, Button, Checkbox, OutlinedInput, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import employeeService from '../../services/EmployeeService';

const Setup = () => {
  const [costPlusChecked, setCostPlusChecked] = useState(false);
  const [markUpValue, setMarkUpValue] = useState('');
  const [employeeLoading, setEmployeeLoading] = useState(false);

  const handleInputChange = (e) => {
    setMarkUpValue(e.target.value);
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        setEmployeeLoading(true);
        const employeeData = await employeeService.getEmployeeDetails();
        setCostPlusChecked(employeeData.data.data.BillableStatus);
        setMarkUpValue(employeeData.data.data.MarkUpPercentage ? employeeData.data.data.MarkUpPercentage : '');
      } catch (error) {
        toast.error('Could not fetch customer setup');
      } finally {
        setEmployeeLoading(false);
      }
    };
    fetchEmployeeDetails();
  }, []);

  const handleCostPlusSave = () => {
    function isMarkupPercentageValid(costPlusChecked, markUpValue) {
      if (costPlusChecked && markUpValue) return true;
      if (!costPlusChecked && !markUpValue) return true;
      return false;
    }

    if (!isMarkupPercentageValid(costPlusChecked, markUpValue)) {
      return toast.error('Markup percentage is required if cost plus is checked');
    }

    const markupData = {
      MarkUpPercentage: markUpValue,
      BillableStatus: costPlusChecked
    };

    employeeService
      .updateEmployeeMarkupPercentage(markupData)
      .then(({ data }) => {
        toast.success('Successfully updated customer setup');
      })
      .catch((error) => {
        toast.error('Could not update customer setup' || error);
      });
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <h3>Billable/Variation</h3>
      <div>
        <Checkbox
          name="superannuationChecked"
          checked={costPlusChecked}
          onChange={(e) => setCostPlusChecked(e.target.checked)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />

        <span style={{ fontWeight: '500', fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
          Default Markup Percentage
        </span>
      </div>
      {costPlusChecked && (
        <div style={{ paddingLeft: '6%' }}>
          <Typography component="div" m={1} style={{ marginBottom: '0.6rem' }}>
            <Box fontWeight="fontWeightMedium"> Mark up(%)</Box>
          </Typography>
          <OutlinedInput
            name="costPlus"
            margin="dense"
            value={markUpValue}
            onChange={handleInputChange}
            type="number"
            fullWidth={true}
          />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <Button onClick={handleCostPlusSave} color="primary" variant="contained">
          Save
        </Button>
      </div>
    </div>
  );
};

export default Setup;
