import React, { useEffect, useState } from 'react';
import vendorService from '../../services/VendorService';
import VendorTable from './table/VendorTable';
import { Toaster, toast } from 'react-hot-toast';
import { getFormattedVendorFromQuickbooks } from './table/helper';
import { Button, CircularProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ButtonMenu from './ButtonMenu/ButtonMenu';
import VendorAlertDialog from './dialog/VendorAlertDialog';
import ImportVendorDialog from './dialog/ImportVendorDialog';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    borderRadius: '4px',
    // backgroundColor: '#4040a1',
    '@media (max-width: 600px)': {
      marginTop: '1rem'
    }
  },
  disableButton: {
    borderRight: '1px solid white',
    borderRadius: '4px 0 0 4px'
  }
}));

const Vendor = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [vendorError, setVendorError] = useState(null);
  const [buttonOptions] = useState(['Enable', 'Import vendor']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [vendorStatus, setVendorStatus] = useState(false);
  const [importFileDialogOpen, setImportFileDialogOpen] = useState(false);
  const [importFile, setImportFile] = useState([]);

  useEffect(() => {
    const fetchVendorRecords = async () => {
      try {
        setVendorLoading(true);
        const vendors = await vendorService.getVendorsFromQBO();

        const formattedVendorRecords = getFormattedVendorFromQuickbooks(vendors.data.data.QueryResponse.Vendor);

        const vendorFromDB = await vendorService.getVendorsFromDatabase();
        const vendorRecords = vendorFromDB.data.data;

        const updatedVendor = formattedVendorRecords.map((row) => {
          const exists = vendorRecords.find((v) => row.id === v.Id);
          return exists ? ((row.vendorStatus = exists.vendorStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setRows(updatedVendor);
      } catch (error) {
        setVendorError(error.message || 'Could not fetch vendor from quickbooks');
      } finally {
        setVendorLoading(false);
      }
    };
    fetchVendorRecords();
  }, []);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDisableButton = () => {
    if (!selection.length) {
      return toast.error('Please select vendor to disable.');
    }
    setOpen(true);
    setMessage('Are you sure you want to disable the selected vendor?');
    setVendorStatus(false);
  };

  const handleOptions = (e, option) => {
    setAnchorEl(false);

    if (option === 'Enable') {
      if (!selection.length) {
        return toast.error('Please select vendor to disable.');
      }
      setOpen(true);
      setMessage('Are you sure you want to enable the selected vendor?');
      setVendorStatus(true);
    }

    if (option === 'Import vendor') {
      setImportFileDialogOpen(true);
      setMessage('Select file to import');
    }
  };

  //vendor status is set enable or disable to show and hide from verd's employee. It's not related to quickbook active status
  const handleVendorStatus = () => {
    vendorService
      .updateVendorStatus(selection, vendorStatus)
      .then((data) => {
        const vendors = data.data.vendor;

        const updatedRows = rows.map((row) => {
          const exists = vendors.find((c) => row.id === c.Id);
          return exists ? ((row.vendorStatus = exists.vendorStatus ? 'Enabled' : 'Disabled'), row) : row;
        });

        setRows(updatedRows);

        toast.success('Successfully updated vendor status');
      })
      .catch((error) => {
        toast.error('Could not update vendor status' || error);
      })
      .finally(() => {
        setSelection([]);
      });

    setOpen(false);
  };

  //once import vendor save button is clicked
  const handleImportedVendor = async () => {
    const vendorsData = importFile.slice(1);
    let filteredPayload = vendorsData.filter((obj) => Object.keys(obj).length !== 0);

    // Modifying the "PrimaryPhone" field type to string
    let vendorsPayload = filteredPayload.map((vendor) => {
      return {
        ...vendor,
        PrimaryPhone: vendor.PrimaryPhone?.toString() || '',
        SyncToken: vendor.SyncToken?.toString() || ''
      };
    });

    console.log('vendorsPayload', vendorsPayload);

    vendorService
      .updateImportedVendorsInQbo(vendorsPayload)
      .then((response) => {
        console.log('response', response);

        return toast.success('Successfully updated vendors.');
      })
      .catch((error) => {
        let errorMessage, errorDetails;

        if (error?.response?.data?.message[0]?.Fault?.Error[0]?.Detail) {
          errorMessage = error?.response?.data?.message[0]?.Fault?.Error[0]?.Message;
          errorDetails = error?.response?.data?.message[0]?.Fault?.Error[0]?.Detail;
          if (errorMessage === 'Stale Object Error') {
            return toast.error('Vendor is already updated. To update again export and then import with changes');
          }
          return toast.error(errorMessage);
        }

        if (error?.response?.data?.message) {
          let originalString = error?.response?.data?.message;
          errorMessage = originalString?.replace(/\\|"|\[|\]/g, '');
          return toast.error(errorMessage || 'Could not update vendor');
        }

        return toast.error(errorDetails || errorMessage || 'Could not update vendor.');
      })
      .finally(() => {
        setImportFileDialogOpen(false);
      });
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.disableButton}
          onClick={handleDisableButton}
          disabled={vendorLoading}
        >
          {vendorLoading && (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          Disable
        </Button>

        <ButtonMenu
          buttonList={buttonOptions}
          handleOptions={handleOptions}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          vendorLoading={vendorLoading}
        />
      </div>

      {vendorLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <VendorTable rows={rows} setRows={setRows} selection={selection} setSelection={setSelection} />
      )}

      <VendorAlertDialog
        open={open}
        setOpen={setOpen}
        handleVendorStatus={handleVendorStatus}
        message={message}
        handleDialogClose={handleDialogClose}
      />

      <ImportVendorDialog
        open={importFileDialogOpen}
        setImportFileDialogOpen={setImportFileDialogOpen}
        handleImportedVendor={handleImportedVendor}
        setImportFile={setImportFile}
      />
    </div>
  );
};

export default Vendor;
