import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const ClassDialogContent = ({ row, onChange, onCancelChanges, onApplyChanges }) => {
  return (
    <div style={{ height: '700px' }}>
      <DialogTitle
        id="form-dialog-title"
        style={{ paddingLeft: '3rem', marginBottom: '0px', paddingBottom: '0px', lineHeight: '1' }}
      >
        Class
      </DialogTitle>
      <DialogContent>
        {' '}
        <div>Working on..</div>
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default ClassDialogContent;

ClassDialogContent.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func
};
