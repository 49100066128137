import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Button, Grid, CircularProgress } from '@material-ui/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { employeeUniformSchema, formatEmployeeUniform } from '../helper/helper';
import employeeUniformService from '../../../services/EmployeeUniform';
import toast from 'react-hot-toast';

const EmployeeUniform = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [employeeUniform, setEmployeeUniform] = useState({
    shirtSize: '',
    jumperSize: '',
    jacketSize: '',
    employeeId: ''
  });

  const sizeOptions = [
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: '3XL', label: '3XL' }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(employeeUniformSchema),
    defaultValues: employeeUniform
  });

  useEffect(() => {
    const fetchEmployeeUniform = async () => {
      try {
        const payload = {
          // eslint-disable-next-line no-undef
          token: localStorage.getItem('token'),
          // eslint-disable-next-line no-undef
          employeeId: localStorage.getItem('userId')
        };
        const response = await employeeUniformService.getEmployeeUniform(payload);
        // console.log('response is:', response);
        const employeeUniformData = response?.data?.data;

        if (employeeUniformData.length < 1) {
          setEmployeeUniform(null);
          reset(null);
        } else {
          const uniformRecord = formatEmployeeUniform(employeeUniformData[0]);
          setEmployeeUniform(uniformRecord);
          reset(uniformRecord);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get employee uniform details.');
      }
    };
    fetchEmployeeUniform();
  }, [reset]);

  const onSubmitEmployeeUniform = async (data) => {
    try {
      setSubmitting(true);
      if (data.employeeId) {
        employeeUniformService
          .updateEmployeeUniform(data)
          .then((response) => {
            toast.success('Successfully updated uniform details');

            const uniformRecord = formatEmployeeUniform(response?.data?.data);
            setEmployeeUniform(uniformRecord);
            reset(uniformRecord);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || 'Could not get update uniform details');
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else if (!data.employeeId) {
        employeeUniformService
          .createEmployeeUniform(data)
          .then((response) => {
            toast.success('Successfully created uniform details');

            const uniformRecord = formatEmployeeUniform(response?.data?.data);

            setEmployeeUniform(uniformRecord);
            reset(uniformRecord);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || 'Could not get create uniform details');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Could not authorize');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitEmployeeUniform)} style={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <label>Shirt Size</label>
          <Controller
            name="shirtSize"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  options={sizeOptions}
                  value={sizeOptions.find((c) => c.value === field.value)}
                  onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  error={!!errors.shirtSize}
                  helperText={errors.shirtSize?.message}
                />
                {errors.shirtSize?.message ? (
                  <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>{errors.shirtSize?.message} </p>
                ) : (
                  ''
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <label>Jumper Size</label>
          <Controller
            name="jumperSize"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  options={sizeOptions}
                  value={sizeOptions.find((c) => c.value === field.value)}
                  onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  error={!!errors.jumperSize}
                  helperText={errors.jumperSize?.message}
                />
                {errors.jumperSize?.message ? (
                  <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>{errors.jumperSize?.message} </p>
                ) : (
                  ''
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <label>Jacket Size</label>
          <Controller
            name="jacketSize"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  options={sizeOptions}
                  value={sizeOptions.find((c) => c.value === field.value)}
                  onChange={(selectedOption) => field.onChange(selectedOption.value)}
                  error={!!errors.jacketSize}
                  helperText={errors.jacketSize?.message}
                />
                {errors.jacketSize?.message ? (
                  <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '0' }}>{errors.jacketSize?.message} </p>
                ) : (
                  ''
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '1.5rem ' }}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            style={{ padding: '0.5rem 5rem' }}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
              </>
            ) : (
              <span>Update</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeUniform;
