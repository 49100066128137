/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Grid, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { toast } from 'react-hot-toast';
import employeeQualificationService from '../../../services/EmployeeQualificationService';
import { zodResolver } from '@hookform/resolvers/zod';
import { ohsCard } from '../helper';
import { ohsCardSchema } from './QualificationSchema/QualificationShcema';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const OHSQualification = ({ expanded, setExpanded }) => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);

  const [ohsCardQualification, setOhsCardQualification] = useState({
    ohs_cardNumber: '',
    ohs_completionDate: '',
    ohs_photoOfCard: null
  });

  const [disableUpdate, setDisableUpdate] = useState(false);

  const {
    control: controlOHS,
    handleSubmit: handleOHSSubmit,
    reset: resetOHSCard,
    getValues: getOHSCardValues,
    formState: { errors: errorsOHS, dirtyFields: dirtyFieldsOHSCard }
  } = useForm({
    resolver: zodResolver(ohsCardSchema),
    defaultValues: ohsCardQualification
  });

  useEffect(() => {
    const fetchEmployeeQualification = async () => {
      try {
        setSubmitting(true);
        if (expanded === 'panel1') {
          const qboQualificationData = await employeeQualificationService.getQboQualification();
          const qboQualificationRecord = qboQualificationData?.data?.data;

          const employeeQualificationData = await employeeQualificationService.getEmployeeQualifications();
          const employeeQualificationRecord = employeeQualificationData.data.data;

          const ohsQualificationVerdDB = await employeeQualificationService.getQualificationByName('OH&S (White Card)');
          const pendingOHSQualification = ohsQualificationVerdDB?.data?.data[0];

          if (pendingOHSQualification) {
            setDisableUpdate(true);
          }

          let ohsQualification;

          //condition if no qualifications are added by employee from verd
          if (
            !employeeQualificationRecord.length ||
            !employeeQualificationRecord.filter((q) => q.name === 'OH&S (White Card)').length
          ) {
            const filteredQualification = qboQualificationRecord.filter((q) => q.name === 'OH&S (White Card)')[0];
            ohsQualification = {
              qualificationName: filteredQualification.name || '',
              qualificationId: filteredQualification.id || null,
              documentId: null,
              ohs_cardNumber: '',
              ohs_completionDate: '',
              ohs_photoOfCard: null
            };
          } else {
            const filteredQualification = employeeQualificationRecord.filter((q) => q.name === 'OH&S (White Card)')[0];
            ohsQualification = ohsCard(filteredQualification);
          }

          setOhsCardQualification(ohsQualification);
          resetOHSCard(ohsQualification);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get employee OH&S card');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeQualification();
  }, [resetOHSCard, expanded]);

  const onSubmitOHSCard = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getOHSCardValues();

      const qualificationData = {
        qualificationId: dirtyValues.qualificationId,
        name: dirtyValues.qualificationName,
        issueDate: dirtyValues.ohs_completionDate,
        referenceNumber: dirtyValues.ohs_cardNumber
      };

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      formData.append('qualificationData', JSON.stringify(qualificationData));

      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (dirtyFieldsOHSCard.ohs_photoOfCard) {
        formData.append('avatar', dirtyValues.ohs_photoOfCard);
      }

      if (dirtyValues.documentId) {
        formData.append('documentId', dirtyValues.documentId);
      }

      employeeQualificationService
        .notifyAdminEmployeeQualificationUpdate(formData)
        .then((response) => {
          // const dataObj = data?.data?.data || data?.data;

          // const updatedOHSCard = {
          //   qualificationName: dataObj.name || '',
          //   qualificationId: dataObj.qualificationId || 1,
          //   documentId: dataObj.documents[0]?.id || null,
          //   ohs_cardNumber: dataObj.referenceNumber || '',
          //   ohs_completionDate: dataObj.issueDate ? format(new Date(dataObj.issueDate), 'yyyy-MM-dd') : '',
          //   ohs_photoOfCard: dataObj.content?.bytes
          //     ? base64toBlob(dataObj.content?.bytes, dataObj.content?.contentType, dataObj.content?.filename)
          //     : null
          // };
          // resetOHSCard(updatedOHSCard);
          // toast.success('Successfully updated OH&S card');

          setExpanded(false);

          toast.success(response?.data?.message || 'Notification has been sent to the admin for the all changes');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not update OH&S card');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
      <form onSubmit={handleOHSSubmit(onSubmitOHSCard)}>
        <Paper className={classes.control}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <h5> Card Number</h5>
              <Controller
                name="ohs_cardNumber"
                control={controlOHS}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="ohs-card-number"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsOHS.ohs_cardNumber}
                    helperText={errorsOHS.ohs_cardNumber?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5> Completion Date</h5>
              <Controller
                name="ohs_completionDate"
                control={controlOHS}
                render={({ field }) => (
                  <TextField
                    type="date"
                    id="ohs-completion-date"
                    fullWidth
                    variant="outlined"
                    {...field}
                    error={!!errorsOHS.ohs_completionDate}
                    helperText={errorsOHS.ohs_completionDate?.message}
                    disabled={isSubmitting || disableUpdate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <h5>Photo of Card</h5>
              <Controller
                name="ohs_photoOfCard"
                control={controlOHS}
                render={({ field }) => (
                  <>
                    {/* eslint-disable-next-line no-undef */}
                    {field.value instanceof Blob && field?.value && (
                      <div
                        style={{
                          paddingBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-undef
                            URL.createObjectURL(field.value) || ''
                          }
                          alt="photo of the card"
                          style={{ width: '30%', height: '30%', objectFit: 'contain' }}
                        />
                      </div>
                    )}

                    <Button variant="contained" component="label" fullWidth disabled={isSubmitting || disableUpdate}>
                      Upload File
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        style={{ display: 'none' }}
                      />
                    </Button>

                    {/* <p>{field.value?.name || 'No file selected'}</p> */}
                    {errorsOHS.ohs_photoOfCard && <p style={{ color: 'red' }}>{errorsOHS?.ohs_photoOfCard?.message}</p>}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button type="submit" variant="outlined" color="primary" disabled={isSubmitting || disableUpdate}>
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              {disableUpdate ? (
                <p>
                  You have already sent <b>OH&S (White Card)</b> update request to the admin. You will get notified once
                  it gets accepted/rejected.
                </p>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default OHSQualification;

OHSQualification.propTypes = {
  expanded: PropTypes.any,
  setExpanded: PropTypes.func
};
