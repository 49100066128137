import { useEffect, useState } from 'react';
import accountService from '../../../services/AccountService';
import { formatAccount } from '../header/helper';

export function useFetchQboAccounts() {
  const [qboAccounts, setQboAccount] = useState([]);
  const [qboAccountError, setQboAccountError] = useState('');
  const [qboAccountLoading, setQboAccountLoading] = useState(false);

  useEffect(() => {
    const fetchQboAccount = async () => {
      try {
        setQboAccountLoading(true);
        const qboAccounts = await accountService.getAccountsFromQbo();

        const formattedAccounts = formatAccount(qboAccounts.data.data.QueryResponse.Account);
        setQboAccount(formattedAccounts);
      } catch (error) {
        setQboAccountError(error?.message || 'Could not fetch accounts from qbo');
      } finally {
        setQboAccountLoading(false);
        setQboAccountError();
      }
    };
    fetchQboAccount();
  }, []);

  return { qboAccounts, qboAccountError, qboAccountLoading };
}
