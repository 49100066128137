import { useEffect, useState } from 'react';
import { formatTaxCode } from '../header/helper';
import taxCodeService from '../../../services/TaxCodeService';

export function useFetchTaxCode() {
  const [taxCode, setTaxCode] = useState([]);
  const [taxCodeError, setTaxCodeError] = useState('');
  const [taxCodeLoading, setTaxCodeLoading] = useState(false);

  useEffect(() => {
    const fetchTaxCode = async () => {
      try {
        setTaxCodeLoading(true);
        const taxCode = await taxCodeService.getTaxCodeFromQBO();

        const formattedTaxCode = formatTaxCode(taxCode.data.data.QueryResponse.TaxCode);
        setTaxCode(formattedTaxCode);
      } catch (error) {
        setTaxCodeError(error?.message || 'Could not fetch accounts from qbo');
      } finally {
        setTaxCodeLoading(false);
        setTaxCodeError();
      }
    };
    fetchTaxCode();
  }, []);

  return { taxCode, taxCodeError, taxCodeLoading };
}
