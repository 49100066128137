import React from 'react';
import PropTypes from 'prop-types';

export const userListOptions = ['All Users', 'Active Users'];

const UserFilter = ({ filter, setFilter }) => {
  return (
    <select
      value={filter.userList}
      onChange={(e) => {
        const users = e.target.value;

        if (users === 'All Users') {
          // eslint-disable-next-line no-undef
          localStorage.setItem('userList', users);
          return setFilter({ ...filter, userList: users });
        }

        if (users === 'Active Users') {
          // eslint-disable-next-line no-undef
          localStorage.setItem('userList', users);

          return setFilter({ ...filter, userList: users });
        }
      }}
      style={{ padding: '0.4rem 1rem', borderRadius: '4px' }}
    >
      {userListOptions.map((option) => {
        return (
          <option value={option} key={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export default UserFilter;

UserFilter.propTypes = {
  filter: PropTypes.shape({
    userList: PropTypes.string
  }),
  setFilter: PropTypes.func
};
