/**
 * Format customer data api response
 *  fully qualified name is the  customer display name in qbo : that'll be our reference, which cannot be modify
 */

export function getFormattedCustomer(customerList) {
  return customerList.map((item) => ({
    id: item.Id,
    Email: item.PrimaryEmailAddr?.Address || '',
    Phone: item.PrimaryPhone?.FreeFormNumber || '',
    BillableStatus: item.BillableStatus ? item.BillableStatus : false,
    MarkUpPercentage: item.MarkUpPercentage || '',
    customerStatus: 'Enabled',
    Taxable: item.Taxable || '',

    BillAddrId: item.BillAddr?.Id || '',
    Line1: item.BillAddr?.Line1 || '',
    City: item.BillAddr?.City || '',
    CountrySubDivisionCode: item.BillAddr?.CountrySubDivisionCode || '',
    PostalCode: item.BillAddr?.PostalCode || '',
    Lat: item.BillAddr?.Lat || '',
    Long: item.BillAddr?.Long,

    ShipAddrId: item.ShipAddr?.Id || '',
    ShipAddrLine1: item.ShipAddr?.Line1 || '',
    ShipAddrCity: item.ShipAddr?.City || '',
    ShipAddrCountrySubDivisionCode: item.ShipAddr?.CountrySubDivisionCode || '',
    ShipAddrPostalCode: item.ShipAddr?.PostalCode || '',
    ShipAddrLat: item.ShipAddr?.Lat || '',
    ShipAddrLong: item.ShipAddr?.Long,

    Job: item.Job || '',
    ParentRef: item?.ParentRef?.value || '',
    BillWithParent: item.BillWithParent || '',
    Balance: item.Balance || '',
    BalanceWithJobs: item.BalanceWithJobs || '',

    PreferredDeliveryMethod: item.PreferredDeliveryMethod || '',
    IsProject: item.IsProject || '',
    domain: 'QBO',
    sparse: item.sparse || '',
    Id: item.Id || '',
    SyncToken: item.SyncToken || '',

    Title: item.Title || '',
    Suffix: item.Suffix || '',
    GivenName: item.GivenName || '',
    MiddleName: item.MiddleName || '',
    FamilyName: item.FamilyName || '',
    FullyQualifiedName: item.FullyQualifiedName || '',
    CompanyName: item.CompanyName || '',
    DisplayName: item.DisplayName || '',
    PrintOnCheckName: item.PrintOnCheckName || '',
    Active: item.Active || '',
    Mobile: {
      FreeFormNumber: item.Mobile?.FreeFormNumber || ''
    },
    PrimaryEmailAddr: {
      Address: item.PrimaryEmailAddr?.Address || ''
    }
  }));
}

/**
 * format customer payload
 * while updating customer it must contains sparse true, id and SyncToken
 */
export function getFormattedCustomerPayload(customerPayload, changedRow) {
  if (changedRow.Email) {
    customerPayload = {
      ...customerPayload,
      PrimaryEmailAddr: { Address: changedRow.Email }
    };
  }

  if (changedRow.Phone) {
    customerPayload = {
      ...customerPayload,
      Mobile: { FreeFormNumber: changedRow.Phone }
    };
  }

  if (changedRow.Line1) {
    customerPayload = {
      ...customerPayload,
      BillAddr: { Line1: changedRow.Line1 }
    };
  }

  if (changedRow.CountrySubDivisionCode) {
    customerPayload = {
      ...customerPayload,
      BillAddr: { CountrySubDivisionCode: changedRow.CountrySubDivisionCode }
    };
  }
  if (changedRow.City) {
    customerPayload = {
      ...customerPayload,
      BillAddr: { City: changedRow.City }
    };
  }
  if (changedRow.PostalCode) {
    customerPayload = {
      ...customerPayload,
      BillAddr: { PostalCode: changedRow.PostalCode }
    };
  } else if (
    changedRow.FamilyName ||
    changedRow.GivenName ||
    changedRow.Title ||
    changedRow.MiddleName ||
    changedRow.CompanyName
  ) {
    customerPayload = {
      ...customerPayload,
      ...changedRow
    };
  }
  return customerPayload;
}
