import { useEffect, useState } from 'react';
import { getFormattedVendorRecords } from '../table/helpers';
import vendorService from '../../../services/VendorService';

export default function useFetchVendorRefRecords() {
  const [vendorRefs, setVendorRefs] = useState([]);
  const [vendorRefError, setVendorRefError] = useState(null);
  const [vendorRefLoading, setVendorRefLoading] = useState(false);

  useEffect(() => {
    const fetchVendorRefRecords = async () => {
      try {
        setVendorRefLoading(true);
        setVendorRefError(null);

        const vendorRefList = await vendorService.getVendorsFromQBO();

        if (vendorRefList.data.data.QueryResponse.Vendor.length) {
          const formattedVendorRefs = getFormattedVendorRecords(vendorRefList.data.data.QueryResponse.Vendor);
          setVendorRefs(formattedVendorRefs);
        }
      } catch (e) {
        setVendorRefError(e.message || 'Could not fetch vendors from QBO');
      } finally {
        setVendorRefLoading(false);
      }
    };

    fetchVendorRefRecords();
  }, []);

  return { vendorRefs, vendorRefLoading, vendorRefError };
}
