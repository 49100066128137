import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get tracker current position details from db
function getTrackerRecords() {
  const endpoint = BASE_URL + 'vehicleTracker/getTrackerRecords';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const vehicleTrackerService = {
  getTrackerRecords
};

export default vehicleTrackerService;
