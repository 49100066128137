import React, { useState } from 'react';
import { startOfWeek, lastDayOfWeek } from 'date-fns';
import { Toaster, toast } from 'react-hot-toast';

import MyExpenseTable from './table/MyExpenseTable';
import useFetchCustomerRefRecords from '../MyTime/hooks/useFetchCustomerRefs';
import useFetchClassRefRecords from '../MyTime/hooks/useFetchClassRefs';
import useFetchItemRefs from '../MyTime/hooks/useFetchItemRefs';
import useFetchVendorRefRecords from '../MyTime/hooks/useFetchVendorRefs';
import { useFetchCreditCardAccounts } from '../MyTime/hooks/useFetchCreditCardAccounts';
import MyTimeFilter, { timesheetPeriodOptions } from '../MyTime/filter/MyTimeFilter';
import useFetchExpense from './hooks/useFetchExpense';
import { getFormattedTableRows } from './table/helper';
import expenseService from '../../services/ExpenseService';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddExpenseDialog from './dialog/AddExpenseDialog';

const useStyles = makeStyles(() => ({
  buttonFilterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '2rem'
  },
  addExpenseButton: {
    '@media (max-width: 700px)': {
      marginTop: '1rem'
    }
  },

  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem'
  },
  searchContainer: {
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
    float: 'right',
    paddingLeft: '20px'
  }
}));

const UserExpense = () => {
  const classes = useStyles();

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const [filter, setFilter] = useState({
    timesheetPeriod: timesheetPeriodOptions[0],
    startDate: startOfWeek(new Date(), { weekStartsOn: 3 }),
    endDate: lastDayOfWeek(new Date(), { weekStartsOn: 3 }),
    searchQuery: ''
  });

  const { expenseRecords, setExpenseRecords } = useFetchExpense(filter);

  const { customerRefs, customerRefLoading } = useFetchCustomerRefRecords();
  const { classRefs, classRefLoading } = useFetchClassRefRecords();
  const { itemRefs, itemRefsLoading } = useFetchItemRefs();
  const { vendorRefs, vendorRefLoading } = useFetchVendorRefRecords();
  const { creditCardAccount, creditCardAccountLoading } = useFetchCreditCardAccounts();
  const [selection, setSelection] = useState([]);
  const [submitApprovalLoading, setSubmitApprovalLoading] = useState(false);
  const [addExpenseOption, setAddExpenseOption] = useState(false);
  const [expenseRow, setExpenseRow] = useState([]);

  const [expenseFormData, setExpenseFormData] = useState({
    paidByEmployee: false,
    paidByCompany: false,
    creditCardAccount: '',
    vendor: '',
    date: todayDate,
    client: '',
    task: '',
    item: '',
    qty: '',
    price: '',
    isBillable: false,
    description: '',
    variationNote: '',
    expenseReceiptFile: '',
    BillNumber: '',
    globalTaxCalculation: '',
    purchaseOrder: ''
  });

  const rowSelectionEnabled = (row) => row.status !== 'Approved' && row.status !== 'WithApprover';

  const changeSelection = (selection) => {
    setSelection(selection);
  };

  const handleAddExpense = (e) => {
    e.preventDefault();
    setAddExpenseOption(true);
  };

  const handleSubmitForApproval = () => {
    setSubmitApprovalLoading(true);

    expenseService
      .updateExpenseStatus(selection)
      .then(({ data }) => {
        const formattedData = getFormattedTableRows(data.data);
        const ids = formattedData.map((d) => d.id);
        const updatedRows = expenseRecords.map((r) => {
          if (ids.includes(r.id)) {
            return formattedData.find((d) => d.id === r.id);
          }

          return r;
        });

        setExpenseRecords(updatedRows);
        setSelection([]);
        toast.success('Successfully submitted expense');
      })
      .catch((error) => {
        return toast.error(error?.response?.data?.message || 'Could not submit expense');
      })
      .finally(() => setSubmitApprovalLoading(false));
  };

  const handleExpenseDialogClose = () => {
    setExpenseFormData({
      paidByEmployee: false,
      paidByCompany: false,
      creditCardAccount: '',
      vendor: '',
      date: todayDate,
      client: '',
      task: '',
      item: '',
      qty: '',
      price: '',
      isBillable: false,
      description: '',
      variationNote: '',
      expenseReceiptFile: '',
      BillNumber: '',
      globalTaxCalculation: '',
      purchaseOrder: ''
    });
    setAddExpenseOption(false);
    setExpenseRow([]);
  };

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <div className={classes.buttonFilterWrapper}>
        <MyTimeFilter filter={filter} setFilter={setFilter} />

        <Button
          variant="contained"
          color="primary"
          className={classes.addExpenseButton}
          onClick={handleAddExpense}
          disabled={
            customerRefLoading || classRefLoading || itemRefsLoading || vendorRefLoading || creditCardAccountLoading
          }
        >
          {customerRefLoading || classRefLoading || itemRefsLoading || vendorRefLoading || creditCardAccountLoading ? (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
              <span>Add Expense</span>
            </>
          ) : (
            <span>Add Expense</span>
          )}
        </Button>
      </div>

      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          value={filter.searchQuery}
          onChange={(e) => setFilter({ ...filter, searchQuery: e.target.value })}
          placeholder="Search..."
          style={{ border: 'none', outline: 'none' }}
        />
      </div>

      {customerRefs.length > 0 &&
        classRefs.length > 0 &&
        itemRefs.length > 0 &&
        vendorRefs.length > 0 &&
        creditCardAccount.length > 0 && (
          <AddExpenseDialog
            open={addExpenseOption}
            customerRefs={customerRefs}
            classRefs={classRefs}
            itemRefs={itemRefs}
            setAddExpenseDialog={setAddExpenseOption}
            vendorRefs={vendorRefs}
            creditCardAccount={creditCardAccount}
            expenseFormData={expenseFormData}
            setExpenseFormData={setExpenseFormData}
            handleClose={handleExpenseDialogClose}
            setExpenseRecords={setExpenseRecords}
            expenseRecords={expenseRecords}
            expenseRow={expenseRow}
            setExpenseRow={setExpenseRow}
          />
        )}

      <MyExpenseTable
        rows={expenseRecords}
        setRows={setExpenseRecords}
        customerRefs={customerRefs}
        classRefs={classRefs}
        itemRefs={itemRefs}
        vendorRefs={vendorRefs}
        creditCardAccount={creditCardAccount}
        selection={selection}
        changeSelection={changeSelection}
        disableEditAndDelete={(row) => {
          return row.status !== 'WithEmployee';
        }}
        rowSelectionEnabled={rowSelectionEnabled}
      />

      <div className={classes.submitButton}>
        <Button variant="contained" color="primary" onClick={handleSubmitForApproval} disabled={submitApprovalLoading}>
          {submitApprovalLoading && (
            <>
              <CircularProgress size={20} style={{ color: 'white' }} />
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          Submit for Approval
        </Button>
      </div>
    </div>
  );
};

export default UserExpense;
