import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get account from database
function getAccountsFromDatabase() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'account/getAccountsFromDatabase';
  return axios.post(endpoint, payload);
}

//get account from quickbooks
function getAccountsFromQbo() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'account/getAccountsFromQbo';
  return axios.post(endpoint, payload);
}

//get payable accounts from quickbooks
function payableAccountsForBills() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'account/payableAccountsForBills';
  return axios.post(endpoint, payload);
}

//only get accounts with AccountType= Credit Card
function getCreditCardAccountsFromQbo() {
  const url = BASE_URL + 'account/getCreditCardAccountsFromQbo';
  return axios.get(url, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

const accountService = {
  getAccountsFromDatabase,
  getAccountsFromQbo,
  payableAccountsForBills,
  getCreditCardAccountsFromQbo
};

export default accountService;
