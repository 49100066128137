import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//list vendor/supplier from database
function getTaxCodeFromDatabase() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'taxCode/getTaxCodeFromDatabase';
  return axios.post(endpoint, payload);
}

//sync client database  with quickbooks
function getTaxCodeFromQBO() {
  const payload = {
    // eslint-disable-next-line no-undef
    token: localStorage.getItem('token')
  };
  const endpoint = BASE_URL + 'taxCode/getTaxCodeFromQBO';
  return axios.post(endpoint, payload);
}

const taxCodeService = {
  getTaxCodeFromDatabase,
  getTaxCodeFromQBO
};

export default taxCodeService;
