import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PropTypes } from 'prop-types';

const UserPortalAccessDialog = ({
  open,
  portalAccess,
  handleClose,
  sendLoginDetailsToUser,
  removeLoginDetailsOfUser,
  employeeId
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">
          {(!employeeId && !portalAccess) || (employeeId && !portalAccess)
            ? 'Provide Portal Access to the user?'
            : (!employeeId && portalAccess) || (employeeId && portalAccess)
            ? 'Are you sure you want to remove portal access of this user?'
            : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {(!employeeId && !portalAccess) || (employeeId && !portalAccess)
              ? 'Mail will be sent to the user with their login credentials.'
              : (!employeeId && portalAccess) || (employeeId && portalAccess)
              ? "User credentials will be removed, they won't be able to login."
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={portalAccess ? removeLoginDetailsOfUser : sendLoginDetailsToUser} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserPortalAccessDialog;

UserPortalAccessDialog.propTypes = {
  open: PropTypes.bool,
  portalAccess: PropTypes.bool,
  handleClose: PropTypes.func,
  sendLoginDetailsToUser: PropTypes.func,
  removeLoginDetailsOfUser: PropTypes.func,
  employeeId: PropTypes.string
};
