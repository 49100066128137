import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import InboxIcon from '@material-ui/icons/Inbox';
import ClassIcon from '@material-ui/icons/Class';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

const useStyles = makeStyles(() => ({
  selected: {
    borderLeft: '2px solid green',
    color: 'white'
  },
  notSelected: {
    color: 'gba(0,0,0,.5)'
  }
}));

const SideNavItem = ({ mobileOpen, role, selectedItem, handleListItemClick }) => {
  const classes = useStyles();

  return (
    <div>
      {role && role === 'User' && (
        <>
          <Link to="/mytime">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('My Time') : () => {}}
              style={{
                borderLeft: selectedItem === 'My Time' ? '2px solid green' : '',
                color: selectedItem === 'My Time' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'My Time' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'My Time' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AccessTimeIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'My Time'} />
            </ListItem>
          </Link>

          <Link to="/myexpenses">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('My Expenses') : () => {}}
              style={{
                borderLeft: selectedItem === 'My Expenses' ? '2px solid green' : '',
                color: selectedItem === 'My Expenses' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'My Expenses' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'My Expenses' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AttachMoneyIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'My Expenses'} />
            </ListItem>
          </Link>

          <Link to="/approvetimesheets">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Approve Timesheets') : () => {}}
              style={{
                borderLeft: selectedItem === 'Approve Timesheets' ? '2px solid green' : '',
                color: selectedItem === 'Approve Timesheets' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Approve Timesheets' ? true : false}
            >
              <ListItemIcon
                style={{
                  color: selectedItem === 'Approve Timesheets' ? 'white' : '#8898aa',
                  minWidth: '25px'
                }}
              >
                <CheckCircleOutlineIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Approve Timesheets'} />
            </ListItem>
          </Link>

          <Link to="/myasset">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('My Asset') : () => {}}
              style={{
                borderLeft: selectedItem === 'My Asset' ? '2px solid green' : '',
                color: selectedItem === 'My Asset' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'My Asset' ? true : false}
            >
              <ListItemIcon
                style={{
                  color: selectedItem === 'My Asset' ? 'white' : '#8898aa',
                  minWidth: '25px'
                }}
              >
                <EmojiTransportationIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'My Asset'} />
            </ListItem>
          </Link>
        </>
      )}

      {role && role === 'Admin' && (
        <>
          <Link to="/dashboard">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Dashboard') : () => {}}
              style={{
                borderLeft: selectedItem === 'Dashboard' ? '2px solid green' : '',
                color: selectedItem === 'Dashboard' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Dashboard' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Dashboard' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <DashboardIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} />
            </ListItem>
          </Link>

          <Link to="/timesheets">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Timesheets') : () => {}}
              style={{
                borderLeft: selectedItem === 'Timesheets' ? '2px solid green' : '',
                color: selectedItem === 'Timesheets' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Timesheets' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Timesheets' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <ListAltIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Timesheets'} />
            </ListItem>
          </Link>

          <Link to="/expenses">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Expenses') : () => {}}
              style={{
                borderLeft: selectedItem === 'Expenses' ? '2px solid green' : '',
                color: selectedItem === 'Expenses' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Expenses' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Expenses' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AttachMoneyIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Expenses'} />
            </ListItem>
          </Link>

          <Link to="/users">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Users') : () => {}}
              style={{
                borderLeft: selectedItem === 'Users' ? '2px solid green' : '',
                color: selectedItem === 'Users' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Users' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Users' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <PeopleAltIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Users'} />
            </ListItem>
          </Link>

          <Link to="/customers">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Customers') : () => {}}
              style={{
                borderLeft: selectedItem === 'Customers' ? '2px solid green' : '',
                color: selectedItem === 'Customers' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Customers' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Customers' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AccountBoxIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Customers'} />
            </ListItem>
          </Link>

          <Link to="/suppliers">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Suppliers') : () => {}}
              style={{
                borderLeft: selectedItem === 'Suppliers' ? '2px solid green' : '',
                color: selectedItem === 'Suppliers' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Suppliers' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Suppliers' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <PeopleAltIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Suppliers'} />
            </ListItem>
          </Link>

          <Link to="/item">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Item') : () => {}}
              style={{
                borderLeft: selectedItem === 'Item' ? '2px solid green' : '',
                color: selectedItem === 'Item' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Item' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Item' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <InboxIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Item'} />
            </ListItem>
          </Link>

          <Link to="/class">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Class') : () => {}}
              style={{
                borderLeft: selectedItem === 'Class' ? '2px solid green' : '',
                color: selectedItem === 'Class' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Class' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Class' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <ClassIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Class'} />
            </ListItem>
          </Link>

          <Link to="/qboEmployee">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('QboEmployee') : () => {}}
              style={{
                borderLeft: selectedItem === 'QboEmployee' ? '2px solid green' : '',
                color: selectedItem === 'Setup' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'QboEmployee' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'QboEmployee' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <LibraryAddIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'QboEmployee'} />
            </ListItem>
          </Link>
          {/* 
          <Link to="/reports">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Reports') : () => {}}
              style={{
                borderLeft: selectedItem === 'Reports' ? '2px solid green' : '',
                color: selectedItem === 'Reports' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Reports' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Reports' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AssessmentOutlinedIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Reports'} />
            </ListItem>
          </Link> */}

          <Link to="/setup">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Setup') : () => {}}
              style={{
                borderLeft: selectedItem === 'Setup' ? '2px solid green' : '',
                color: selectedItem === 'Setup' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Setup' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Setup' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <SettingsIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Setup'} />
            </ListItem>
          </Link>

          <Link to="/asset">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Asset') : () => {}}
              style={{
                borderLeft: selectedItem === 'Asset' ? '2px solid green' : '',
                color: selectedItem === 'Asset' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Asset' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Asset' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <EmojiTransportationIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Asset'} />
            </ListItem>
          </Link>

          <Link to="/maps">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('Maps') : () => {}}
              style={{
                borderLeft: selectedItem === 'Maps' ? '2px solid green' : '',
                color: selectedItem === 'Maps' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'Maps' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'Maps' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <EmojiTransportationIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'Maps'} />
            </ListItem>
          </Link>

          {/* not in use for now */}
          {/* <Link to="/myaccount">
            <ListItem
              button
              onClick={mobileOpen ? () => handleListItemClick('My Account') : () => {}}
              style={{
                borderLeft: selectedItem === 'My Account' ? '2px solid green' : '',
                color: selectedItem === 'My Account' ? 'white' : '#8898aa'
              }}
              selected={selectedItem === 'My Account' ? true : false}
            >
              <ListItemIcon style={{ color: selectedItem === 'My Account' ? 'white' : '#8898aa', minWidth: '25px' }}>
                <AccountCircleIcon style={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText primary={'My Account'} />
            </ListItem>
          </Link> */}
        </>
      )}
    </div>
  );
};

export default SideNavItem;

SideNavItem.propTypes = {
  mobileOpen: PropTypes.bool,
  handleListItemClick: PropTypes.func,
  role: PropTypes.string,
  selectedItem: PropTypes.string
};
