/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { employeeKeyPaySchema, makeKeyPayEmployeeObject } from './helper/helper';
import employeeService from '../../services/EmployeeService';

import { Toaster, toast } from 'react-hot-toast';
import { Grid, TextField, Button, CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { zodResolver } from '@hookform/resolvers/zod';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import BusinessIcon from '@material-ui/icons/Business';
import SecurityIcon from '@material-ui/icons/Security';

import EmployeeLeaveBalance from './EmployeeLeaveBalance/EmployeeLeaveBalance';
import EmployeeTaxDeclaration from './EmployeeTaxDeclaration/EmployeeTaxDeclaration';
import keyPayEmployeeService from '../../services/KeypayEmployee';
import EmployeeUniform from './EmployeeUniform/EmployeeUniform';
import OfficeInfo from './OfficeInfo/OfficeInfo';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme) => ({
  textfieldLabel: {
    marginBottom: '5px',
    fontSize: '13px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: 'red'
  }
}));

const MyProfileNew = () => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [employeeKeyPayRecord, setEmployeeKeyPayRecord] = useState({
    title: '',
    firstName: '',
    preferredName: '',
    middleName: '',
    surname: '',
    jobTitle: '',
    rate: 0,
    gender: '',
    emailAddress: '',
    homePhone: '',
    mobilePhone: '',
    startDate: '',
    dateOfBirth: '',
    anniversaryDate: '',
    residentialStreetAddress: '',
    residentialAddressLine2: '',
    residentialSuburb: '',
    bankAccount1_AccountName: '',
    bankAccount1_BSB: '',
    bankAccount1_AccountNumber: '',
    superFund1_FundName: '',
    superFund1_ProductCode: '',
    superFund1_MemberNumber: '',
    emergencyContact1_Name: '',
    emergencyContact1_Relationship: '',
    emergencyContact1_ContactNumber: '',
    emergencyContact1_Address: '',
    KeyPayEmployeeId: '',
    australianResident: false,
    hasApprovedWorkingHolidayVisa: false,
    claimTaxFreeThreshold: false,
    helpDebt: false,
    dateTaxFileDeclarationSigned: '',
    bankAccount1_AllocatedPercentage: '',
    superFund1_AllocatedPercentage: '',
    taxFileNumber: '',

    //newly added properties : don't need to show, just to update in verd db
    residentialCountry: '',
    residentialState: '',
    residentialPostCode: ''
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(employeeKeyPaySchema),
    defaultValues: employeeKeyPayRecord
  });

  useEffect(() => {
    const fetchEmployeeKeyPayRecord = async () => {
      try {
        setSubmitting(true);
        const verdEmployeeData = await employeeService.getEmployeeDetails();
        const employeeRecord = verdEmployeeData.data.data;
        if (employeeRecord) {
          const response = await keyPayEmployeeService.getOneKeyPayEmployee(employeeRecord.KeyPayEmployeeId);
          const keyPayEmployeeDetails = response?.data?.data;

          const formattedKeyPayEmployee = makeKeyPayEmployeeObject(keyPayEmployeeDetails);

          setEmployeeKeyPayRecord(formattedKeyPayEmployee);
          reset(formattedKeyPayEmployee);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Could not get employee data from KeyPay');
      } finally {
        setSubmitting(false);
      }
    };
    fetchEmployeeKeyPayRecord();
  }, [reset]);

  const onSubmitEmployeeKeyPayRecord = () => {
    try {
      setSubmitting(true);
      const dirtyValues = getValues();

      const employeeData = {
        FirstName: dirtyValues.firstName,
        FamilyName: dirtyValues.surname,
        MiddleName: dirtyValues.middleName,
        Title: dirtyValues.title,
        PrimaryPhone: dirtyValues.mobilePhone,
        Country: dirtyValues.residentialCountry,
        CountrySubDivisionCode: dirtyValues.residentialState,
        City: dirtyValues.residentialSuburb,
        PostalCode: dirtyValues.residentialPostCode,
        Line1: dirtyValues.residentialStreetAddress,
        DateOfBirth: dirtyValues.dateOfBirth,
        AnniversaryDate: dirtyValues.anniversaryDate,
        bankAccount1_AccountName: dirtyValues.bankAccount1_AccountName,
        bankAccount1_AccountNumber: dirtyValues.bankAccount1_AccountNumber,
        bankAccount1_BSB: dirtyValues.bankAccount1_BSB,
        superFund1_ProductCode: dirtyValues.superFund1_ProductCode,
        superFund1_MemberNumber: dirtyValues.superFund1_MemberNumber,
        superFund1_FundName: dirtyValues.superFund1_FundName,
        emergencyContact1_Address: dirtyValues.emergencyContact1_Address,
        emergencyContact1_ContactNumber: dirtyValues.emergencyContact1_ContactNumber,
        emergencyContact1_Name: dirtyValues.emergencyContact1_Name,
        emergencyContact1_Relationship: dirtyValues.emergencyContact1_Relationship,
        bankAccount1_AllocatedPercentage: dirtyValues.bankAccount1_AllocatedPercentage,
        superFund1_AllocatedPercentage: dirtyValues.superFund1_AllocatedPercentage,
        KeyPayEmployeeId: dirtyValues.KeyPayEmployeeId
      };

      employeeService
        .updateEmployeeProfile(employeeData)
        .then((response) => {
          const updatedEmployeeData = response?.data?.data;
          if (updatedEmployeeData) {
            const updatedEmployeeRecord = makeKeyPayEmployeeObject(updatedEmployeeData);

            reset(updatedEmployeeRecord);
            return toast.success('Successfully updated profile');
          }
        })
        .catch((error) => {
          return toast.error(error?.response?.data?.message || 'Could not update employee data in keypay.');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error('Please fill in all required fields correctly.');
    }
  };

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />

      <Grid container spacing={3}>
        {/* all the general information, bank details and superfund details */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmitEmployeeKeyPayRecord)}>
            <Paper>
              <Grid container spacing={3} style={{ padding: '0 10px ' }}>
                <Grid item xs={12}>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6bh-content"
                      id="panel6bh-header"
                    >
                      <Typography className={classes.heading}>General Information</Typography>
                      <Typography className={classes.secondaryHeading}>
                        {errors.title?.message ||
                          errors.firstName?.message ||
                          errors.surname?.message ||
                          errors.emailAddress?.message ||
                          errors.mobilePhone?.message ||
                          errors.startDate?.message ||
                          errors.dateOfBirth?.message ||
                          errors.anniversaryDate?.message ||
                          errors.residentialStreetAddress?.message ||
                          errors.gender?.message}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                          <p className={classes.textfieldLabel}> Title*</p>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                disabled
                                size="small"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> First Name*</p>
                          <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                                disabled
                                size="small"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Preferred Name</p>
                          <Controller
                            name="preferredName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.preferredName}
                                helperText={errors.preferredName?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Middle Name</p>
                          <Controller
                            name="middleName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.middleName}
                                helperText={errors.middleName?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Last Name*</p>
                          <Controller
                            name="surname"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.surname}
                                helperText={errors.surname?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Job Title</p>
                          <Controller
                            name="jobTitle"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.jobTitle}
                                helperText={errors.jobTitle?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Pay Rate</p>
                          <Controller
                            name="rate"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="number"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.rate}
                                helperText={errors.rate?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Gender*</p>
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.gender}
                                helperText={errors.gender?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Email*</p>
                          <Controller
                            name="emailAddress"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="emailAddress"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.emailAddress}
                                helperText={errors.emailAddress?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Home Phone</p>
                          <Controller
                            name="homePhone"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="tel"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.homePhone}
                                helperText={errors.homePhone?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Mobile Phone*</p>
                          <Controller
                            name="mobilePhone"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="tel"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.mobilePhone}
                                helperText={errors.mobilePhone?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Start Date*</p>
                          <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="date"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.startDate}
                                helperText={errors.startDate?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Date of Birth*</p>
                          <Controller
                            name="dateOfBirth"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="date"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.dateOfBirth}
                                helperText={errors.dateOfBirth?.message}
                                disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Anniversary Date*</p>
                          <Controller
                            name="anniversaryDate"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="date"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.anniversaryDate}
                                helperText={errors.anniversaryDate?.message}
                                // disabled
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Residential Address Line 1*</p>
                          <Controller
                            name="residentialStreetAddress"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.residentialStreetAddress}
                                helperText={errors.residentialStreetAddress?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Residential Address Line 2</p>
                          <Controller
                            name="residentialAddressLine2"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.residentialAddressLine2}
                                helperText={errors.residentialAddressLine2?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Residential Suburb</p>
                          <Controller
                            name="residentialSuburb"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.residentialSuburb}
                                helperText={errors.residentialSuburb?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/* bank details */}
                <Grid item xs={12}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>Bank Details</Typography>
                      <Typography className={classes.secondaryHeading}>
                        {errors.bankAccount1_AccountName?.message ||
                          errors.bankAccount1_AccountNumber?.message ||
                          errors.bankAccount1_BSB?.message}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <p className={classes.textfieldLabel}> Name*</p>
                          <Controller
                            name="bankAccount1_AccountName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.bankAccount1_AccountName}
                                helperText={errors.bankAccount1_AccountName?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <p className={classes.textfieldLabel}> BSB </p>
                          <Controller
                            name="bankAccount1_BSB"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.bankAccount1_BSB}
                                helperText={errors.bankAccount1_BSB?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Account Number*</p>
                          <Controller
                            name="bankAccount1_AccountNumber"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.bankAccount1_AccountNumber}
                                helperText={errors.bankAccount1_AccountNumber?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>{' '}
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/* Superannuation details */}
                <Grid item xs={12}>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className={classes.heading}>Superannuation Details</Typography>
                      <Typography className={classes.secondaryHeading}>
                        {errors.superFund1_FundName?.message ||
                          errors.superFund1_MemberNumber?.message ||
                          errors.superFund1_ProductCode?.message}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <p className={classes.textfieldLabel}> Name*</p>
                          <Controller
                            name="superFund1_FundName"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.superFund1_FundName}
                                helperText={errors.superFund1_FundName?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <p className={classes.textfieldLabel}>Product Code* </p>
                          <Controller
                            name="superFund1_ProductCode"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.superFund1_ProductCode}
                                helperText={errors.superFund1_ProductCode?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <p className={classes.textfieldLabel}> Member Number*</p>
                          <Controller
                            name="superFund1_MemberNumber"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.superFund1_MemberNumber}
                                helperText={errors.superFund1_MemberNumber?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                {/* emergency contact details */}
                <Grid item xs={12}>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography className={classes.heading}>Emergency Contact</Typography>
                      <Typography className={classes.secondaryHeading}>
                        {errors.emergencyContact1_Address?.message ||
                          errors.emergencyContact1_ContactNumber?.message ||
                          errors.emergencyContact1_Name?.message ||
                          errors.emergencyContact1_Relationship?.message}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Name*</p>
                          <Controller
                            name="emergencyContact1_Name"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.emergencyContact1_Name}
                                helperText={errors.emergencyContact1_Name?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Relationship*</p>
                          <Controller
                            name="emergencyContact1_Relationship"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.emergencyContact1_Relationship}
                                helperText={errors.emergencyContact1_Relationship?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Mobile*</p>
                          <Controller
                            name="emergencyContact1_ContactNumber"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="number"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.emergencyContact1_ContactNumber}
                                helperText={errors.emergencyContact1_ContactNumber?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <p className={classes.textfieldLabel}> Address*</p>
                          <Controller
                            name="emergencyContact1_Address"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...field}
                                error={!!errors.emergencyContact1_Address}
                                helperText={errors.emergencyContact1_Address?.message}
                                disabled={isSubmitting}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '1.5rem ' }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ padding: '0.5rem 5rem' }}
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress size={20} style={{ marginRight: '10px' }} /> <span>Update</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Grid>

        {/* change password */}
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
              <SecurityIcon style={{ marginRight: '4px' }} />
              <Typography className={classes.heading}>Change password</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChangePassword />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/*Uniform details */}
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
              <ShoppingBasketIcon style={{ marginRight: '4px' }} />
              <Typography className={classes.heading}>Uniform Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EmployeeUniform />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/*Office info details */}
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8bh-header">
              <BusinessIcon style={{ marginRight: '4px' }} />
              <Typography className={classes.heading}>Office Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OfficeInfo />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Tax File Declaration details */}
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
              <AccountBalanceIcon style={{ marginRight: '4px' }} />
              <Typography className={classes.heading}>Tax File Declaration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EmployeeTaxDeclaration employeeData={employeeKeyPayRecord} />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Leave Balance details */}
        <Grid item xs={12}>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
              <FlightTakeoffIcon style={{ marginRight: '4px' }} />
              <Typography> Leave Balance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EmployeeLeaveBalance employeeKeyPayId={employeeKeyPayRecord.KeyPayEmployeeId} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default MyProfileNew;
