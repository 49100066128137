import React, { useEffect, useState } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import notificationService from '../../services/NotificationService';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { socket } from '../../socket';
import { Toaster } from 'react-hot-toast';

const Notification = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [unReadNotification, setUnReadNotification] = useState(0);

  const [employeeNotificationRecords, setEmployeeNotificationRecords] = useState([]);

  useEffect(() => {
    const fetchEmployeeNotification = async () => {
      try {
        const records = await notificationService.getNotification();

        const employeeNotificationRecords = records.data.data;

        const unReadNotificationCount = employeeNotificationRecords.filter((n) => n.isRead === false).length;
        setUnReadNotification(unReadNotificationCount);

        setEmployeeNotificationRecords(employeeNotificationRecords);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchEmployeeNotification();

    // socket.on('connect', () => {
    //   setSocketId(socket.id);
    // });

    // Subscribe to Socket.io event for new notifications
    socket.on('newNotification', (newNotification) => {
      console.log('newNotification yeha bata aako ho ta', newNotification);
      // eslint-disable-next-line no-undef
      if (newNotification?.recipientEmployeeRef?.value === localStorage.getItem('userId')) {
        setEmployeeNotificationRecords((prevRecords) => [newNotification, ...prevRecords]);
        setUnReadNotification((prevCount) => prevCount + 1);
      }
    });

    // Clean up function to unsubscribe from Socket.io event
    return () => {
      socket.off('newNotification');
      socket.disconnect();
    };
  }, [setEmployeeNotificationRecords]);

  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: '#36c95e',
              color: '#fff'
            }
          },
          error: {
            style: {
              background: '#f5251d',
              color: '#fff'
            }
          }
        }}
      />
      <div style={{ cursor: 'pointer' }}>
        <div style={{ position: 'relative' }}>
          <NotificationsIcon style={{ color: 'white' }} />
        </div>
        <p
          style={{
            position: 'absolute',
            top: isSmall ? '6px' : '9px',
            right: isSmall ? '5rem' : '5.4rem',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '16px'
          }}
        >
          {unReadNotification > 0 ? unReadNotification : ''}
        </p>
      </div>
    </div>
  );
};

export default Notification;

Notification.propTypes = {
  employeeNotificationRecords: PropTypes.any,
  notificationListClicked: PropTypes.any,
  setNotificationListClicked: PropTypes.func,
  setEmployeeNotificationRecords: PropTypes.func
};
