import { useEffect, useState } from 'react';
import vendorService from '../../../services/VendorService';

export default function useFetchVendorRecords() {
  const [vendorLoading, setVendorLoading] = useState(false);
  const [vendorError, setVendorError] = useState('');
  const [vendorRecords, setVendorRecords] = useState([]);

  useEffect(() => {
    const fetchVendorRecords = async () => {
      try {
        setVendorLoading(true);
        const vendorList = await vendorService.getVendorsFromQBO();
        const vendorRecords = vendorList.data.data.QueryResponse.Vendor;

        setVendorRecords(vendorRecords);
      } catch (error) {
        setVendorError(error?.message || 'Could not fetch vendor from quicbooks');
      } finally {
        setVendorLoading(false);
        setVendorError('');
      }
    };
    fetchVendorRecords();
  }, []);
  return { vendorRecords, vendorError, vendorLoading };
}
