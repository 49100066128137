import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddTimeDialogContent from './AddTimeDialogContent';
import timesheetService from '../../../services/TimesheetService';

import { toast } from 'react-hot-toast';
import {
  getFormattedTableRows,
  isLeaveTimesheetFormDataValid,
  isTimesheetDateInFuture,
  isTimesheetFormDataValid
} from '../table/helpers';
import { checkLeaveCategory, isLeaveHoursValid } from './helper';
import DateCheckDialog from '../dateCheckDialog/DateCheckDialog';

export default function AddTimeDialog({
  open,
  setOpen,
  handleClose,
  customerRefList,
  classRefList,
  rows,
  setRows,
  takeLeaveOption,
  requestLeaveOption,
  myTimeFormData,
  setMyTimeFormData,
  timesheetSavingOptions,
  setTimesheetSavingOptions,
  setAnchorEl,
  showLeaveBalance,
  setShowLeaveBalance,
  sickLeaveBalance,
  setSickLeaveBalance
}) {
  const [todayDate, setTodayDate] = useState();
  const [proceedFutureDate, setProceedFutureDate] = useState(false);
  const [dateCheckDialogOpen, setDateCheckDialogOpen] = useState(false);

  useEffect(() => {
    let todayDate = format(new Date(), 'yyyy-MM-dd');
    setTodayDate(todayDate);
  }, []);

  //once My Time save as draft button is clicked
  const handleTimesheetAdd = (e) => {
    e.preventDefault();
    const timesheetPayload = isTimesheetFormDataValid(myTimeFormData);
    if (!timesheetPayload) {
      return toast.error('Please fill out all the mandatory data');
    }

    let timesheetDateCheck = false;

    if (!proceedFutureDate) {
      //check if the timesheet date entered today or not, should  proceed future date
      timesheetDateCheck = isTimesheetDateInFuture(myTimeFormData.date);
    }

    //popup  a dialog
    if (timesheetDateCheck && !proceedFutureDate) {
      setDateCheckDialogOpen(true);
    }

    if (!timesheetDateCheck || proceedFutureDate) {
      // eslint-disable-next-line no-undef
      const formData = new FormData();

      formData.append('timesheetPayload', JSON.stringify(timesheetPayload));
      // eslint-disable-next-line no-undef
      const userId = localStorage.getItem('userId');
      formData.append('userId', userId);

      if (myTimeFormData.timeSheetImageUrl) {
        const file = myTimeFormData.timeSheetImageUrl[0];
        formData.append('file', file);
      }

      timesheetService
        .addTimesheet(formData)
        .then(({ data }) => {
          toast.success('Successfully added timesheet');
          let timesheetData = [];
          timesheetData.push(data.data);
          const formattedTimesheet = getFormattedTableRows(timesheetData);
          setRows([formattedTimesheet[0], ...rows]);
          setMyTimeFormData({
            startTime: '07:00',
            finishTime: '15:00',
            hour: 8,
            date: todayDate,
            client: '',
            task: '',
            isBillable: false,
            description: '',
            variationNote: '',
            workType: '',
            medicalStatus: false,
            timeSheetImageUrl: ''
          });
          setOpen(false);
          setProceedFutureDate(false);
        })
        .catch((error) => {
          console.log('error is:', error);
          toast.error(
            error?.response?.data?.error ||
              error?.response?.data?.data?.message ||
              error?.response?.data?.message ||
              'Could not add and submit timesheet'
          );
        });
    }
  };

  //once My Leave save as draft button is clicked
  const handleLeaveTimesheetAdd = (e) => {
    e.preventDefault();
    const timesheetPayload = isLeaveTimesheetFormDataValid(myTimeFormData);

    if (!timesheetPayload) {
      return toast.error('Please fill out all the mandatory data');
    }

    if (checkLeaveCategory(myTimeFormData.workType)) {
      if (!isLeaveHoursValid(showLeaveBalance, myTimeFormData.hour)) {
        return toast.error(`Not enough leave balance to take ${myTimeFormData.workType}`);
      }
    }

    if (myTimeFormData.workType === "Personal/Carer's Leave") {
      if (!isLeaveHoursValid(sickLeaveBalance, myTimeFormData.hour)) {
        return toast.error(`Not enough leave balance to take ${myTimeFormData.workType}`);
      }
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('timesheetPayload', JSON.stringify(timesheetPayload));
    // eslint-disable-next-line no-undef
    const userId = localStorage.getItem('userId');
    formData.append('userId', userId);

    if (myTimeFormData.timeSheetImageUrl) {
      const file = myTimeFormData.timeSheetImageUrl[0];
      formData.append('file', file);
    }

    timesheetService
      .addTimesheet(formData)
      .then(({ data }) => {
        toast.success('Successfully added timesheet');
        let timesheetData = [];
        timesheetData.push(data.data);
        const formattedTimesheet = getFormattedTableRows(timesheetData);
        setRows([formattedTimesheet[0], ...rows]);
        setMyTimeFormData({
          startTime: '07:00',
          finishTime: '15:00',
          hour: 8,
          date: todayDate,
          client: '',
          task: '',
          isBillable: false,
          description: '',
          variationNote: '',
          workType: '',
          medicalStatus: false,
          timeSheetImageUrl: ''
        });
        setOpen(false);
        setAnchorEl(false);
      })
      .catch((error) => {
        console.log('error is:', error);
        toast.error(error?.response?.data?.error || 'Could not add leave form.');
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {takeLeaveOption && !requestLeaveOption
            ? 'My Leave'
            : !takeLeaveOption && !requestLeaveOption
            ? 'My Time'
            : 'My Leave Request'}
        </DialogTitle>
        <DialogContent>
          <AddTimeDialogContent
            myTimeFormData={myTimeFormData}
            setMyTimeFormData={setMyTimeFormData}
            customerRefList={customerRefList}
            classRefList={classRefList}
            handleTimesheetAdd={handleTimesheetAdd}
            handleClose={handleClose}
            takeLeaveOption={takeLeaveOption}
            handleLeaveTimesheetAdd={handleLeaveTimesheetAdd}
            timesheetSavingOptions={timesheetSavingOptions}
            setTimesheetSavingOptions={setTimesheetSavingOptions}
            rows={rows}
            setRows={setRows}
            setOpen={setOpen}
            showLeaveBalance={showLeaveBalance}
            setShowLeaveBalance={setShowLeaveBalance}
            sickLeaveBalance={sickLeaveBalance}
            setSickLeaveBalance={setSickLeaveBalance}
            setProceedFutureDate={setProceedFutureDate}
            setDateCheckDialogOpen={setDateCheckDialogOpen}
            proceedFutureDate={proceedFutureDate}
          />
        </DialogContent>
        <DialogActions></DialogActions>
        {dateCheckDialogOpen && (
          <DateCheckDialog
            setProceedFutureDate={setProceedFutureDate}
            date={myTimeFormData.date}
            setDateCheckDialogOpen={setDateCheckDialogOpen}
            dateCheckDialogOpen={dateCheckDialogOpen}
          />
        )}
      </Dialog>
    </div>
  );
}

AddTimeDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  customerRefList: PropTypes.array,
  classRefList: PropTypes.array,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  takeLeaveOption: PropTypes.bool,
  setOpen: PropTypes.func,
  myTimeFormData: PropTypes.shape({
    startTime: PropTypes.string,
    finishTime: PropTypes.string,
    hour: PropTypes.any,
    date: PropTypes.any,
    client: PropTypes.any,
    task: PropTypes.any,
    workType: PropTypes.string,
    isBillable: PropTypes.bool,
    description: PropTypes.string,
    variationNote: PropTypes.string,
    medicalStatus: PropTypes.bool,
    timeSheetImageUrl: PropTypes.any
  }),
  setMyTimeFormData: PropTypes.func,
  timesheetSavingOptions: PropTypes.array,
  setTimesheetSavingOptions: PropTypes.func,
  setAnchorEl: PropTypes.func,
  showLeaveBalance: PropTypes.number,
  setShowLeaveBalance: PropTypes.func,
  sickLeaveBalance: PropTypes.number,
  setSickLeaveBalance: PropTypes.func
};
