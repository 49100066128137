import { lastDayOfWeek, format } from 'date-fns';
import { customAlphabet } from 'nanoid';

export function formattedExpensePayload(expenseFormData, expenseRow) {
  const expensePayload = [];
  const thisWeekEndDate = format(lastDayOfWeek(new Date(), { weekStartsOn: 3 }), 'dd-MM-yy');
  // eslint-disable-next-line no-undef
  const userName = localStorage.getItem('name');
  const userFamilyName = userName.split(' ')[1];
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 10);
  const randomId = nanoid();
  const DocNumber = thisWeekEndDate + ' ' + userFamilyName[0] + ' ' + randomId;

  for (const row in expenseRow) {
    const expenseItem = {
      paidByEmployee: expenseFormData.paidByEmployee,
      paidByCompany: expenseFormData.paidByCompany,
      AccountRef: expenseFormData.creditCardAccount,
      VendorRef: expenseFormData.vendor,
      Date: expenseFormData.date,
      CustomerRef: expenseFormData.client,
      ClassRef: expenseRow[row].ClassRef,
      ItemRef: expenseRow[row].ItemRef,
      Qty: expenseRow[row].Qty,
      Price: expenseRow[row].Price,
      BillableStatus: expenseFormData.isBillable,
      Description: expenseRow[row].Description,
      variationNote: expenseFormData.variationNote,
      DocNumber: DocNumber,
      GlobalTaxCalculation: expenseFormData.globalTaxCalculation,
      purchaseOrder: expenseFormData.purchaseOrder
    };
    expensePayload.push(expenseItem);
  }
  return expensePayload;
}

export function isPriceValid(price) {
  if (!isNaN(price) && typeof price !== 'boolean') return true;
  return false;
}

export function isQtyValid(qty) {
  if (!isNaN(qty) && typeof qty !== 'boolean') return true;
  return false;
}

export function isBillableStatusValid(billableStatus, variationNote) {
  if (billableStatus && variationNote) return true;

  if (!billableStatus && !variationNote) return true;

  return false;
}

export function isCustomerRefValid(clientRef) {
  if (clientRef) return true;
  return false;
}

export function isClassRefValid(classRef) {
  if (classRef) return true;
  return false;
}

export function isItemRefValid(itemRef) {
  if (itemRef) return true;
  return false;
}

export function isDescriptionValid(desc) {
  if (desc !== '' && desc.trim() !== '') return true;
  return false;
}

export function isPaidByValid(paidByEmployee, paidByCompany) {
  if ((paidByCompany === true && paidByEmployee === false) || (paidByCompany === false && paidByEmployee === true))
    return true;
  return false;
}

export function isPaidByCompanyValid(expenseFormData) {
  if (expenseFormData.paidByCompany === false && expenseFormData.paidByEmployee === true) return true;

  if (expenseFormData.paidByCompany === true && expenseFormData.paidByEmployee === false) {
    if (expenseFormData.creditCardAccount && expenseFormData.vendor) return true;
  }

  return false;
}

export function isGlobalTaxCalculationValid(expenseFormData) {
  if (expenseFormData.globalTaxCalculation) return true;
  return false;
}

export function isExpenseRowValid(rows) {
  for (const element of rows) {
    // Check if the element has all the required properties
    if (
      !('ItemRef' in element) ||
      !('ClassRef' in element) ||
      !('Price' in element) ||
      !('Qty' in element) ||
      !('Description' in element)
    ) {
      return { valid: false, message: 'One of the field in table is empty.' };
    }

    // Convert Price and Qty to numbers (if they are numeric strings)
    const parsedPrice = parseFloat(element.Price);
    const parsedQty = parseFloat(element.Qty);

    // Check if Price and Qty are valid numbers
    if (isNaN(parsedPrice) || isNaN(parsedQty)) {
      return { valid: false, message: 'Price and Qty must be number' };
    }

    // Check if any of the properties are empty
    if (!element.ItemRef.name || !element.ItemRef.value) {
      return { valid: false, message: 'ItemRef must be selected' };
    }

    if (!element.ClassRef.name || !element.ClassRef.value) {
      return { valid: false, message: 'ClassRef must be selected' };
    }
  }

  return { valid: true };
}
