import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//create leave request by employee
function createLeaveRequest(leaveRequestData) {
  const endpoint = BASE_URL + 'requestLeave/createLeaveRequest';

  return axios.post(
    endpoint,
    { leaveRequestData: leaveRequestData },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//get leave request
function getLeaveRequest(employeeId) {
  const endpoint = BASE_URL + `requestLeave/getLeaveRequest/${employeeId}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    }
  });
}

//reject leave request: update the leaveRequestStatus to rejected, and send email to the employee
function rejectLeaveRequest(leaveRequestId, rejectReason) {
  const endpoint = BASE_URL + 'requestLeave/rejectLeaveRequest';

  return axios.put(
    endpoint,
    { leaveRequestId: leaveRequestId, rejectReason: rejectReason },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

//accept leave request: create the leaveRequestStatus to rejected, and send email to the employee
function approveLeaveRequest(leaveRequestId) {
  const endpoint = BASE_URL + 'requestLeave/approveLeaveRequest';

  return axios.put(
    endpoint,
    { leaveRequestId: leaveRequestId },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

const requestLeaveService = {
  createLeaveRequest,
  getLeaveRequest,
  rejectLeaveRequest,
  approveLeaveRequest
};

export default requestLeaveService;
