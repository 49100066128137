import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import timesheetService from '../../../services/TimesheetService';
import { toast } from 'react-hot-toast';
import { getFormattedTableRows } from '../table/helpers';

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem'
  }
}));

const Footer = ({ rows, setRows, selection }) => {
  const classes = useStyles();

  const [submitApprovalLoading, setSubmitApprovalLoading] = useState(false);

  // const filteredSelection = filterSelectedRows(rows, selection);

  const handleSubmitForApproval = () => {
    setSubmitApprovalLoading(true);

    timesheetService
      .handleSubmitForApprover(selection)
      .then(({ data }) => {
        const formattedData = getFormattedTableRows(data.data);
        const ids = formattedData.map((d) => d.id);
        const updatedRows = rows.map((r) => {
          if (ids.includes(r.id)) {
            return formattedData.find((d) => d.id === r.id);
          }

          return r;
        });

        setRows(updatedRows);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => setSubmitApprovalLoading(false));
  };

  return (
    <div className={classes.footer}>
      <Button variant="contained" color="primary" onClick={handleSubmitForApproval} disabled={submitApprovalLoading}>
        {submitApprovalLoading && (
          <>
            <CircularProgress size={20} style={{ color: 'white' }} />
            &nbsp;&nbsp;&nbsp;
          </>
        )}
        Submit for Approval
      </Button>
    </div>
  );
};

function filterSelectedRows(rows, selectionIds) {
  // remove selected rows that contain withapprover status
  const res = rows.filter((r) => selectionIds.includes(r.id) && r.status !== 'WithApprover').map((r) => r.id);

  return res;
}

export default Footer;

Footer.propTypes = {
  rows: PropTypes.array,
  setRows: PropTypes.func,
  selection: PropTypes.array
};
